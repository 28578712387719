import * as yup from "yup";

const imageUrlSchema = yup.string().url();

const imageObjectSchema = yup.object({
  type: yup.string().required(),
  src: yup.string().url().required(),
  webp: yup.string().url().notRequired(),
});

export type TImage =
  | string
  | {
      type?: string;
      src?: string;
      webp?: string;
    };

export type TImageObject = yup.InferType<typeof imageObjectSchema>;

// Define the mixed schema to validate either type
export const imageSchema = yup
  .mixed<TImage>()
  .test(
    "is-valid-image",
    "Must be a valid image URL or object",
    (value): value is TImage => {
      if (value === undefined || value === null) return true; // Allow null/undefined (optional)

      // Validate based on the type of value
      if (typeof value === "string") {
        return imageUrlSchema.isValidSync(value);
      } else if (typeof value === "object") {
        return imageObjectSchema.isValidSync(value);
      }

      return false; // Invalid if it's neither a string nor an object
    }
  );
