import { useCallback } from "react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

const Amount = ({
  min,
  max,
  value,
  onChange,
  step = 1,
  disabled,
  className = null,
}) => {
  const isMin = min >= value;
  const isMax = max <= value;

  const onDecrease = useCallback(() => {
    onChange(value - step);
  }, [value, step, onChange]);

  const onIncrease = useCallback(() => {
    onChange(value + step);
  }, [value, step, onChange]);

  return (
    <div
      className={clsx(["flex flex-row items-center select-none", className])}
    >
      <PlusCircleIcon
        className={clsx(
          "w-10 h-10 py-2",
          disabled || isMax
            ? "text-muted-foreground cursor-not-allowed"
            : "cursor-pointer"
        )}
        onClick={!(disabled || isMax) ? onIncrease : undefined}
      />
      <h6 className="font-medium my-2 text-center w-6 text-lg">{value}</h6>
      <MinusCircleIcon
        onClick={!(disabled || isMin) ? onDecrease : undefined}
        className={clsx(
          "w-10 h-10 py-2",
          disabled || isMin
            ? "text-muted-foreground cursor-not-allowed"
            : "cursor-pointer"
        )}
      />
    </div>
  );
};

export default Amount;
