import { paymentMethodTypes } from "@gethere/common/enums";
import { CardPayload } from "@gethere/common/types";
// import { captureException } from "@sentry/react";

// import clsx from "clsx";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useUser } from "../contexts/UserContext";
import { userSelector } from "../state/reducers/user";
import { RootState } from "../state/store";
import useToggle from "../utils/useToggle";
import Modal from "./Modal";

export const getCreditCardIcon = (type: string | null) => {
  switch (type) {
    case "visa":
      return "fab fa-cc-visa";
    case "mastercard":
      return "fab fa-cc-mastercard";
    case "american-express":
      return "fab fa-cc-amex";
    case "diners-club":
      return "fab fa-cc-diners-club";
    case "discover":
      return "fab fa-cc-discover";
    case "jcb":
      return "fab fa-cc-jsb";
    case "unionpay":
    case "maestro":
    case "mir":
    case "elo":
    case "hiper":
    case "hipercard":
    default:
      return "far fa-credit-card";
  }
};

// const AddCreditCard = ({ onComplete, currency }) => {
//   const theme = useTheme();
//   const stripe = useStripe();
//   const elements = useElements();
//   const { user } = useUser();
//   const dispatch = useDispatch();

//   const [{ loading, error }, setState] = useState({
//     loading: false,
//     error: null,
//   });

//   const handleAdd = async (e) => {
//     try {
//       e.preventDefault();

//       setState((s) => ({ ...s, loading: true, error: null }));

//       const { error, paymentMethod } = await createStripePaymentMethod({
//         stripe,
//         user,
//         card: elements.getElement(CardElement),
//       });

//       if (error) {
//         return setState((s) => ({
//           ...s,
//           loading: false,
//           error: error.message,
//         }));
//       }

//       const { data } = await client({
//         url: "/me/paymentmethods",
//         method: "post",
//         data: {
//           pmId: paymentMethod?.id,
//         },
//       });

//       dispatch(onUserUpdate(data));
//       setState((s) => ({ ...s, loading: false, error: null }));
//       onComplete?.();
//     } catch (error) {
//       setState((s) => ({
//         ...s,
//         loading: false,
//         error: errorify(error).message,
//       }));
//     }
//   };

//   if (!stripe) return null;

//   return (
//     <form onSubmit={handleAdd}>
//       <ErrorBoundary
//         fallback={<div>failed to load card component, please reload.</div>}
//       >
//         <CardElement
//           className="h-6 my-5"
//           options={{
//             disabled: loading,
//             hidePostalCode: true,
//             style: {
//               base: {
//                 fontSmoothing: "antialiased",
//                 fontFamily: theme.typography.fontFamily,
//                 iconColor: theme.palette.text.hint,
//                 fontSize: theme.typography.body1.fontSize as string,
//                 color: theme.palette.text.primary,
//                 "::placeholder": {
//                   color: theme.palette.text.disabled,
//                 },
//               },
//             },
//           }}
//         />
//         {error && (
//           <Alert
//             className="mb-5
//         "
//           >
//             {error}
//           </Alert>
//         )}
//         <Btn type="submit" fullWidth disabled={loading}>
//           Add Card
//         </Btn>
//       </ErrorBoundary>
//     </form>
//   );
// };

const PaymentMethodButton = ({
  id,
  selected = false,
  disabled = false,
  onSelect = (...args: any): any => {},
}) => {
  const intl = useIntl();

  const pm = useSelector(
    (s: RootState) => s.user.entities?.paymentMethods?.[id]
  );

  if (pm) {
    if ([paymentMethodTypes.CARD, "CREDIT_CARD"].includes(pm.type)) {
      //TODO: CLEAN
      const payload = pm.payload as CardPayload;
      const cardIconClass = getCreditCardIcon(payload.brand);
      const expYear = payload.expYear;
      const expMonth = payload.expMonth;

      return (
        <div>
          {`${intl.formatMessage(
            { id: "transaction_method" },
            { method: pm.type }
          )}, ${payload.brand}`}
          secondary=
          {`${expMonth}/${expYear} ${payload.last4} ${payload.brand || ""}`}
        </div>
      );
      // return (
      //   <ListItem
      //     selected={selected}
      //     button
      //     disabled={disabled}
      //     onClick={(e) => onSelect(id)}
      //   >
      //     <ListItemIcon>
      //       <Icon
      //         className={clsx(cardIconClass)}
      //         color={selected ? "primary" : "disabled"}
      //       />
      //     </ListItemIcon>
      //     <ListItemText
      //       primary={`${intl.formatMessage(
      //         { id: "transaction_method" },
      //         { method: pm.type }
      //       )}, ${payload.brand}`}
      //       secondary={`${expMonth}/${expYear} ${payload.last4} ${
      //         payload.brand || ""
      //       }`}
      //     />
      //     {selected && (
      //       <ListItemSecondaryAction>
      //         <Icon color="primary">done</Icon>
      //       </ListItemSecondaryAction>
      //     )}
      //   </ListItem>
      // );
    }
  }
  return null;
};

// export const PaymentMethodSecurityLabel = () => {
//   const intl = useIntl();
//   return (
//     <div className="flex flex-row items-center">
//       <Icon className="text-green-400 dark:text-green-700 mr-5">lock</Icon>
//       <p className="text-muted-foreground text-[12px]">
//         {intl.formatMessage({
//           id: "add_credit_card_security",
//           defaultMessage:
//             "Payment information is stored securely on PCI DSS Level 1 standard for future usage.",
//         })}
//       </p>
//     </div>
//   );
// };

// const AddPaymentMethodDialog = ({ open, onClose, currency, onComplete }) => {
//   return (
//     <Modal maxWidth="sm" open={Boolean(open)} onClose={onClose}>
//       <div className="flex items-center flex-row w-full ml-auto">
//         <CloseIcon onClick={onClose} />
//       </div>
//       <AddPaymentMethod currency={currency} onComplete={onComplete} />
//     </Modal>
//   );
// };
const UpdatePaymentMethodDialog = ({ id, onClose }) => {
  // const intl = useIntl();

  const { pm, isDefault } = useSelector((s: RootState) => ({
    isDefault: userSelector(s)?.defaultPaymentMethod === id,
    pm: id ? s.user.entities?.paymentMethods?.[id] : null,
  }));

  // const {
  //   isOpen: isRemoving,
  //   onOpen: onRemove,
  //   // onClose: closeRemove,
  // } = useToggle(false);

  // const dispatch = useDispatch();

  // const [loading, setloading] = useState(false);

  // const setDefault = useCallback(async () => {
  //   try {
  //     if (pm?.id) {
  //       setloading(true);
  //       const response = await client.put("/me", {
  //         defaultPaymentMethod: pm.id,
  //       });
  //       dispatch(onUserUpdate(response.data));
  //     }
  //   } catch (error) {
  //   } finally {
  //     setloading(false);
  //   }
  // }, [dispatch, pm?.id]);

  // const handleDelete = useCallback(async () => {
  //   try {
  //     if (pm?.id) {
  //       setloading(true);
  //       const response = await client.delete(`/me/paymentmethods/${pm.id}`);
  //       dispatch(onUserUpdate(response.data));
  //       onClose?.();
  //     }
  //   } catch (error) {
  //     captureException(error);
  //     console.error(error);
  //   } finally {
  //     setloading(false);
  //   }
  // }, [dispatch, onClose, pm?.id]);

  if (!pm) return null;

  // let title = "";
  // let subheader = "";
  // let avatar = null;

  // if (pm.type === paymentMethodTypes.CARD) {
  //   const cardIconClass = getCreditCardIcon(pm.payload.provider);

  //   title = `${pm.payload?.brand} ${pm.payload.last4}`;

  //   subheader = `${pm.payload?.expMonth}/${pm.payload?.expYear}`;

  //   avatar = <i className={clsx(cardIconClass)} color={"disabled"} />;
  // }

  return null;

  // return (
  //   <Modal maxWidth="sm" open={Boolean(id)} onClose={onClose}>
  //     {/* <CardHeader
  //       title={title?.toUpperCase?.()}
  //       subheader={subheader}
  //       avatar={avatar}
  //       titleTypographyProps={
  //         {
  //           // className: classes.brandName,
  //         }
  //       }
  //       subheaderTypographyProps={
  //         {
  //           // className: classes.ends,
  //         }
  //       }
  //     />
  //     <DialogContent>
  //       {isRemoving && (
  //         <Typography variant="h6">
  //           {intl.formatMessage(
  //             { id: "are_you_sure_intent_value" },
  //             {
  //               intent: intl.formatMessage({
  //                 id: "delete",
  //                 defaultMessage: "delete",
  //               }),
  //               value: intl.formatMessage(
  //                 { id: "transaction_method" },
  //                 { method: pm.type }
  //               ),
  //             }
  //           )}
  //         </Typography>
  //       )}
  //     </DialogContent>
  //     {isRemoving ? (
  //       <DialogActions>
  //         <Button
  //           onClick={handleDelete}
  //           disabled={loading}
  //           startIcon={<Icon>delete</Icon>}
  //         >
  //           {intl.formatMessage({ id: "confirm", defaultMessage: "Confirm" })}
  //         </Button>
  //         <Button onClick={onClose} disabled={loading}>
  //           {intl.formatMessage({ id: "cancel", defaultMessage: "Cancel" })}
  //         </Button>
  //       </DialogActions>
  //     ) : (
  //       <DialogActions>
  //         <Button
  //           onClick={setDefault}
  //           disabled={isDefault || loading}
  //           startIcon={<Icon>{isDefault ? "done-all" : "done"}</Icon>}
  //         >
  //           {intl.formatMessage(
  //             {
  //               id: isDefault ? "selected_default" : "set_what",
  //               defaultMessage: "Default Payment Method",
  //             },
  //             { what: intl.formatMessage({ id: "selected_default" }) }
  //           )}
  //         </Button>
  //         <Button
  //           disabled={loading}
  //           onClick={onRemove}
  //           startIcon={<Icon>delete</Icon>}
  //         >
  //           {intl.formatMessage({ id: "delete", defaultMessage: "Delete" })}
  //         </Button>
  //         <Button disabled={loading} onClick={onClose}>
  //           {intl.formatMessage({ id: "back", defaultMessage: "Back" })}
  //         </Button>
  //       </DialogActions>
  //     )} */}
  //   </Modal>
  // );
};

export const UserPaymentMethods = () => {
  const { user } = useUser();
  const { defaultPaymentMethod } = user;
  const paymentMethods: any = user?.paymentMethods;

  const numOfMethods = paymentMethods?.length || 0;
  const hasMethods = numOfMethods >= 1;

  const {
    isOpen: addIsOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useToggle(!hasMethods);

  const {
    isOpen: methodOpen,
    onOpen: onMethodOpen,
    onClose: onMethodClose,
  } = useToggle(null);

  const onAddComplete = useCallback(
    (data) => {
      onAddClose();
    },
    [onAddClose]
  );

  const handlePmSelect = useCallback(
    (id) => {
      onMethodOpen(null, id);
    },
    [onMethodOpen]
  );

  return (
    <div>
      {paymentMethods?.map?.((id) => (
        <PaymentMethodButton
          disabled={false}
          onSelect={handlePmSelect}
          selected={id === defaultPaymentMethod}
          key={id}
          id={id}
        />
      ))}
      {/* <Button
        color="primary"
        variant="contained"
        size="large"
        // className={classes.title}
        fullWidth
        startIcon={<Icon>add</Icon>}
        onClick={onAddOpen}
      >
        {intl.formatMessage(
          { id: "add_what", defaultMessage: "Add {what}" },
          {
            what: intl.formatMessage({
              id: "settings_items_credit_cards_title",
              defaultMessage: "Payment Method",
            }),
          }
        )}
      </Button> */}

      {/* <AddPaymentMethodDialog
        currency={currency}
        onComplete={onAddComplete}
        onClose={onAddClose}
        open={addIsOpen}
      /> */}

      <UpdatePaymentMethodDialog
        id={methodOpen}
        onClose={onMethodClose}
        key={String(methodOpen)}
      />
    </div>
  );
};
