import clsx from "clsx";
import { useMemo, useRef } from "react";
import MainPortal from "../containers/MainPortal";
import { getSafeArea } from "../contexts/ThemeContext";
import useMeasure, { UseMeasureRef } from "../utils/useMeasure";

export const BottomSpacer = ({
  className = "null",
  heightClassName = "h-20",
}) => (
  <div className={clsx("visible clear-both", heightClassName, className)} />
);

export const SafeBottomArea = ({
  className = null,
  children,
  forwardRef = null,
  style,
}: {
  className?: string;
  children: React.ReactNode;
  forwardRef?: UseMeasureRef<HTMLDivElement>;
  style?: React.CSSProperties;
}) => {
  const safearea = useMemo(() => getSafeArea(), []);
  return (
    <div
      style={style}
      ref={forwardRef}
      className={clsx(
        !["0", "0px"].includes(safearea.bottom) && "safe-area-bottom",
        "clear-both",
        className
      )}
    >
      {children}
    </div>
  );
};

export default function BottomPageContainer({
  children,
  className = null,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [contentRef, contentRect] = useMeasure<HTMLDivElement>();
  const [spacerRef, spacerRect] = useMeasure<HTMLDivElement>();
  const paddingTop = contentRect.height;

  return (
    <>
      <div
        ref={spacerRef}
        className="bottom-page-spacer"
        style={{
          paddingTop,
        }}
      />
      <div
        ref={ref}
        className={clsx("bottom-page-container", "bottom-0 w-full")}
      >
        <SafeBottomArea
          forwardRef={contentRef}
          className={className}
          style={{ width: spacerRect?.width }}
        >
          {children}
        </SafeBottomArea>
      </div>
    </>
  );
}

export const BottomPageContainerPortal = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <MainPortal>
      <BottomPageContainer {...props}>{children}</BottomPageContainer>
    </MainPortal>
  );
};
