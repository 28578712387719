import { NormalizedSessionEntities } from "@gethere/common/entities";
import { SessionState, SessionTypes, TipStatus } from "@gethere/common/enums";
import {
  applyAutoPromotionDiscounts,
  calculateSessionCheck,
} from "@gethere/common/utilities";
import { pointInPolygon } from "@gethere/common/utils/geo";
import { TCollection } from "@gethere/common/yup/Collection";
import {
  sessionPromoSchema,
  TSession,
  TSessionMeta,
} from "@gethere/common/yup/Session";
import { TUserAddress } from "@gethere/common/yup/UserAddress";
import { produce } from "immer";
import { DateTime } from "luxon";
import { createSelector } from "reselect";
import mergeEntities from "../../utils/mergeEntities";
import type { RootState } from "../store";
import { USER_ACTIONS } from "../actions";
import { captureException } from "@sentry/react";

type SessionReducerState = {
  result: string;
  entities: Partial<NormalizedSessionEntities["entities"]>;
  selectedPaymentMethodId: string;
  selectedAddressId: string;
  eligbleAddresses: string[];
  tempEligbleAddress: string;
};

const initialState: SessionReducerState = {
  result: null,
  entities: {},
  selectedPaymentMethodId: null,
  selectedAddressId: null,
  eligbleAddresses: [],
  tempEligbleAddress: null,
};

export const SESSION_ACTIONS = {
  RESET: "RESET",
  TOGGLE_SERVICE_FEE: "TOGGLE_SERVICE_FEE",
  SESSION_LOADED: "SESSION_LOADED",
  ADD_PROMO_CODE: "ADD_PROMO_CODE",
  SESSION_UPDATED: "SESSION_UPDATED",
  SELECT_PAYMENT_METHOD: "SELECT_PAYMENT_METHOD",
  SELECT_DELIVERY_ADDRESS: "SELECT_DELIVERY_ADDRESS",
  UPDATE_LOCAL_PENDING_TIP: "UPDATE_LOCAL_PENDING_TIP",
  UPDATE_LOCAL_META: "UPDATE_LOCAL_META",
  ADD_OFFLINE_ORDER_ITEM: "ADD_OFFLINE_ORDER_ITEM",
  UPDATE_OFFLINE_ORDER_ITEM: "UPDATE_OFFLINE_ORDER_ITEM",
};

const sessionReducer = (
  state: SessionReducerState = initialState,
  { type, payload }: any
): SessionReducerState => {
  switch (type) {
    case SESSION_ACTIONS.UPDATE_OFFLINE_ORDER_ITEM: {
      return produce(state, (draft) => {
        const session = draft.entities.sessions[draft.result];

        const oii = session.orderItems.findIndex(
          (oi) => (oi.id || oi.meta?.localId) === payload.id
        );

        if (oii !== -1) {
          session.orderItems[oii] = {
            ...session.orderItems[oii],
            ...payload.payload,
          };

          applyAutoPromotionDiscounts({
            promotions: Object.values(state.entities.promotions || {}),
            session: session as any,
            items: state.entities.items,
            isServer: false,
          });

          session.check = calculateSessionCheck(session);
          session.updatedAt = new Date().toISOString();
        } else {
          captureException(new Error("order item not found"));
        }
      });
    }

    case SESSION_ACTIONS.TOGGLE_SERVICE_FEE: {
      return produce(state, (draft) => {
        const session = draft.entities.sessions[draft.result];
        session.meta.rejectedServiceCharge = Boolean(payload.reject);
        session.check = calculateSessionCheck(session);
        session.updatedAt = new Date().toISOString();
      });
    }

    case SESSION_ACTIONS.UPDATE_LOCAL_PENDING_TIP: {
      return produce(state, (draft) => {
        const session = draft.entities.sessions[draft.result];
        if (!payload.tip) {
          session.tips = session.tips.filter(
            (t) => t.status !== TipStatus.PENDING
          );
        } else {
          const tipIndex = session.tips.findIndex(
            (t) => t.id === payload.tip.id || !t.id
          ); // local one
          if (tipIndex !== -1) {
            session.tips[tipIndex] = payload.tip;
          } else {
            session.tips.push(payload.tip);
          }
        }
        session.check = calculateSessionCheck(session);
        session.updatedAt = new Date().toISOString();
      });
    }
    case SESSION_ACTIONS.ADD_PROMO_CODE: {
      const { promoId, by } = payload;
      return produce(state, (draft) => {
        const session = draft.entities.sessions[draft.result];
        if (!session.meta.promos) session.meta.promos = [];

        const promo = sessionPromoSchema.cast({
          promoId,
          by,
          at: DateTime.utc().toISO(),
        });

        session.meta.promos.push(promo);

        applyAutoPromotionDiscounts({
          promotions: Object.values(state.entities.promotions || {}),
          session: session as any,
          items: state.entities.items,
        });

        session.check = calculateSessionCheck(session);
        session.updatedAt = new Date().toISOString();
      });
    }
    case SESSION_ACTIONS.SELECT_PAYMENT_METHOD: {
      const { id } = payload;
      return produce(state, (draft) => {
        draft.selectedPaymentMethodId = id;
      });
    }
    case SESSION_ACTIONS.SELECT_DELIVERY_ADDRESS: {
      const { id } = payload;
      return produce(state, (draft) => {
        const session = draft.entities.sessions[draft.result];
        session.meta.delivery.refAddressId = id;
      });
    }
    case SESSION_ACTIONS.SESSION_UPDATED: {
      const { result, entities } = payload;
      return {
        ...state,
        result,
        entities: mergeEntities(state.entities, entities),
      };
    }

    case USER_ACTIONS.ON_UPDATE: {
      return produce(state, (draft) => {
        if (draft.result) {
          // has session;
          const session = draft.entities.sessions[draft.result];
          if (
            session?.type === SessionTypes.delivery &&
            session.state === SessionState.draft
          ) {
            draft.eligbleAddresses = getEligblesUserAddresses({
              session,
              user: payload.entities.users[payload.result],
              addresses: payload.entities.addresses || {},
              place: draft.entities?.places?.[session.placeId],
            });
          }
        }
      });
    }

    case USER_ACTIONS.UPDATE_ADDRESS: {
      return produce(state, (draft) => {
        if (draft.result) {
          // has session;
          const session = draft.entities.sessions[draft.result];
          if (
            session?.type === SessionTypes.delivery &&
            session.state === SessionState.draft
          ) {
            const place = draft.entities?.places?.[session.placeId];

            const addressEligble = pointInPolygon(
              payload?.coordinates,
              place.deliveryArea as any
            );

            const included = draft.eligbleAddresses?.includes?.(payload.id);

            if (addressEligble) {
              if (!included) {
                draft.eligbleAddresses.push(payload.id);
              }
            } else {
              if (included) {
                draft.eligbleAddresses.filter((add) => add !== payload.id);
              }
            }
          }
        }
      });
    }

    case SESSION_ACTIONS.SESSION_LOADED: {
      return {
        ...state,
        ...payload,
      };
    }

    case SESSION_ACTIONS.ADD_OFFLINE_ORDER_ITEM: {
      const { orderItem } = payload;
      return produce(state, (draft) => {
        const session = draft.entities.sessions[draft.result];
        if (!session.orderItems) session.orderItems = [];
        session.orderItems.push(orderItem);

        applyAutoPromotionDiscounts({
          promotions: Object.values(state.entities.promotions || {}),
          session: session as any,
          items: state.entities.items,
        });

        session.check = calculateSessionCheck(session);
        session.updatedAt = new Date().toISOString();
      });
    }

    case SESSION_ACTIONS.UPDATE_LOCAL_META: {
      return produce(state, (draft) => {
        const session = draft.entities.sessions[draft.result];
        session.meta = { ...(session.meta || {}), ...payload };
        session.check = calculateSessionCheck(session);
        session.updatedAt = new Date().toISOString();
      });
    }

    case USER_ACTIONS.ON_LOGIN: {
      return produce(state, (draft) => {
        const session = draft.entities?.sessions?.[draft.result];
        const userId = payload.result;

        if (session && userId) {
          // we want to add the liability to each related oi & tip for once are missings.
          if (session.id) {
            for (const oi of session.orderItems) {
              if (!oi.liableId) {
                oi.liableId = userId;
              }
            }

            for (const tip of session.tips) {
              if (!tip.liableId) {
                tip.liableId = userId;
              }
            }

            // add user as consumer id
            if (!Array.isArray(session.consumerIds)) {
              session.consumerIds = [];
            }

            if (!session.consumerIds.includes(userId)) {
              session.consumerIds.push(userId);
            }

            // update check;
            session.check = calculateSessionCheck(session);
            // session.updatedAt = new Date().toISOString();
          }

          // update eligble addresses
          if (
            session?.type === SessionTypes.delivery &&
            session.state === SessionState.draft
          ) {
            draft.eligbleAddresses = getEligblesUserAddresses({
              session,
              user: payload.entities?.users?.[userId],
              addresses: payload.entities?.addresses || {},
              place: draft.entities?.places?.[session.placeId],
            });
          }
        }
      });
    }

    case SESSION_ACTIONS.RESET:
    case USER_ACTIONS.ON_LOGOUT: {
      // reset session data on logout;
      return { ...initialState };
    }

    default:
      return state;
  }
};

export const getEligblesUserAddresses = ({
  user,
  session,
  place,
  addresses,
}) => {
  const eligbles = [];
  if (user) {
    if (session.type === SessionTypes.delivery && place.deliveryArea) {
      if (user.addresses.length) {
        for (const addId of user.addresses as string[]) {
          const address: TUserAddress = addresses[addId];
          if (pointInPolygon(address.coordinates as any, place.deliveryArea)) {
            eligbles.push(addId);
          }
        }
      }
    }
  }

  return eligbles;
};

// actions

export const toggleSessionServiceFee = (reject) => ({
  type: SESSION_ACTIONS.TOGGLE_SERVICE_FEE,
  payload: { reject },
});

export const sessionLoaded = ({
  result,
  entities,
  eligbleAddresses = [],
  selectedPaymentMethodId = null,
}) => ({
  type: SESSION_ACTIONS.SESSION_LOADED,
  payload: { result, entities, eligbleAddresses, selectedPaymentMethodId },
});

export const sessionUpdated = ({ result, entities }) => ({
  type: SESSION_ACTIONS.SESSION_UPDATED,
  payload: { result, entities },
});

export const selectPaymentMethod = ({ id }) => ({
  type: SESSION_ACTIONS.SELECT_PAYMENT_METHOD,
  payload: { id },
});

export const addPromoCode = ({ promoId, by }) => {
  return {
    type: SESSION_ACTIONS.ADD_PROMO_CODE,
    payload: { promoId, by },
  };
};

export const resetSession = () => ({
  type: SESSION_ACTIONS.RESET,
});

export const selectDeliveryAddress = ({ id }) => ({
  type: SESSION_ACTIONS.SELECT_DELIVERY_ADDRESS,
  payload: { id },
});

export const addOrderItem =
  ({ orderItem }) =>
  async (dispatch) => {
    dispatch({
      type: SESSION_ACTIONS.ADD_OFFLINE_ORDER_ITEM,
      payload: { orderItem },
    });
  };
export const updateLocalTip =
  ({ tip }) =>
  async (dispatch) => {
    dispatch({
      type: SESSION_ACTIONS.UPDATE_LOCAL_PENDING_TIP,
      payload: { tip },
    });
  };

export const updateLocalSessionMeta =
  (meta: Partial<TSessionMeta>) => async (dispatch) => {
    dispatch({
      type: SESSION_ACTIONS.UPDATE_LOCAL_META,
      payload: meta,
    });
  };

export const updateOrderItem =
  ({ id, payload }) =>
  async (dispatch) => {
    dispatch({
      type: SESSION_ACTIONS.UPDATE_OFFLINE_ORDER_ITEM,
      payload: { payload, id },
    });
  };

// selectors
export const sessionIdSelector = (state: RootState) => {
  return state.session.result;
};
export const sessionEntitiesSelector = (state: RootState) => {
  return state.session.entities;
};

export const sessionSelector = createSelector(
  [sessionIdSelector, sessionEntitiesSelector],
  (id, entities) => (id ? (entities?.sessions?.[id] as TSession) : null)
);

export const sessionPlaceSelector = createSelector(
  [sessionSelector, sessionEntitiesSelector],
  (session, entities) => (session ? entities?.places?.[session.placeId] : null)
);

export const sessionUserSelector = createSelector(
  [sessionSelector, (state) => state.user.result],
  (session, userId) => {
    return session?.users?.find?.((su) => su.userId === userId);
  }
);

export const sessionBusinessSelector = createSelector(
  [sessionPlaceSelector, sessionEntitiesSelector],
  (place, entities) => (place ? entities?.businesses?.[place.businessId] : null)
);

export const sessionCurrencySelector = createSelector(
  [sessionBusinessSelector],
  (business) => business?.currency
);

export const sessionCollectionsSelector = createSelector(
  [sessionPlaceSelector, sessionEntitiesSelector],
  (place, entities) =>
    place?.cols?.map?.((id) => entities?.collections?.[id]) as TCollection[]
);

export default sessionReducer;
