import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";
import { Breakpoint, useBreakpoint } from "../utils/useBreakpoint";

const useBreakPointOrBoolean = (arg: Breakpoint | boolean) => {
  const isBreakpoint = typeof arg === "string";
  const hookBreakpoint = isBreakpoint ? arg : "sm";
  const minBreakpoint = useBreakpoint(hookBreakpoint);
  return typeof arg === "boolean" ? arg : minBreakpoint;
};

export const ModalTitle = ({ children }) => (
  <Dialog.Title
    as="h3"
    className="text-xl font-medium leading-6 rtl:text-right"
  >
    {children}
  </Dialog.Title>
);

export default function Modal({
  className = null,
  open,
  onClose = (e?: any) => {},
  maxWidth = "md",
  fullScreen = false,
  disablePadding = false,
  disableBodyScroll = false,
  scrollContent = true,
  children,
  style = null,
  ...props
}: {
  className?: string;
  open: boolean;
  onClose?: (e?: any) => void;
  maxWidth?: Breakpoint;
  fullScreen?: boolean | Breakpoint;
  disablePadding?: boolean;
  disableBodyScroll?: boolean;
  scrollContent?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  const isFullScreen = useBreakPointOrBoolean(fullScreen);

  return (
    <Transition appear show={Boolean(open)} as={Fragment}>
      <Dialog
        className="fixed inset-0 z-30"
        onClose={onClose}
        style={style}
        as="div"
        unmount
      >
        <div
          className={clsx(
            "text-center h-full",
            !disableBodyScroll && "overflow-y-scroll",
            !isFullScreen && "p-3"
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black bg-opacity-40"
              onClick={() => {
                if (typeof onClose === "function") onClose();
              }}
            />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            {...props}
            className={clsx(
              "relative inline-block w-full text-left bg-card",
              !disablePadding && "p-4",
              isFullScreen && "h-full",
              scrollContent ? "overflow-y-scroll" : "overflow-hidden",
              !isFullScreen &&
                clsx(
                  "h-fit max-h-[95vh] rounded-md shadow-xl align-middle", // rounded-2xl
                  maxWidth === "sm" && "max-w-sm",
                  maxWidth === "md" && "max-w-md",
                  maxWidth === "lg" && "max-w-lg",
                  maxWidth === "xl" && "max-w-xl",
                  maxWidth === "2xl" && "max-w-2xl",
                  maxWidth === "3xl" && "max-w-3xl"
                ),
              className
            )}
          >
            {children}
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export const ModalContent = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={clsx(
      "flex flex-col h h-full sm:h-fit sm:max-h-[70vh]",
      className
    )}
  >
    {children}
  </div>
);
