import { ErrorBoundary } from "@sentry/react";
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Alert from "../components/Alert";
import Btn from "../components/Btn";
import { useSessionCheckout } from "../contexts/SessionCheckoutContext";
import { useSession } from "../contexts/SessionContext";
import { useSessionStripeCheckout } from "../contexts/SessionStripeCheckout";
import { useTheme } from "../contexts/ThemeContext";
import { useUser } from "../contexts/UserContext";
import { RootState } from "../state/store";

const PayWithExistMethodButton = ({
  id,
  disabled = false,
  onClick = (...args: any): any => {},
}) => {
  const pm = useSelector(
    (s: RootState) => s.user.entities?.paymentMethods?.[id]
  );

  return null;

  // if (pm) {
  //   if ([paymentMethodTypes.CARD, "CREDIT_CARD"].includes(pm.type)) {
  //     const payload = pm.payload;
  //     const cardIconClass = getCreditCardIcon(pm.payload.details?.cardName);
  //     if (!payload?.exp) return null;
  //     const expYear = payload.exp - 2000;

  //     return (
  //       <Btn
  //         fullWidth
  //         size="base"
  //         variant="contained"
  //         color="primary"
  //         disabled={disabled}
  //         onClick={(e) => onClick(id)}
  //         StartIcon={() => <span className={clsx(cardIconClass)} />}
  //         EndIcon={() => (
  //           <span className="font-light">
  //             {payload.last4} {payload.expMonth}/{expYear}
  //           </span>
  //         )}
  //       >
  //         Pay with {capitalize(payload.brand)}
  //       </Btn>
  //     );
  //   }
  // }

  // return null;
};

const PayWithCard = () => {
  const { checkout } = useSessionCheckout();
  //savePaymentMethod, setSavePaymentMethod
  const { handleCardPay } = useSessionStripeCheckout();
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const disabled = Boolean(checkout.loading);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (elements?.getElement) {
      const card = elements.getElement(CardElement);
      if (card) {
        card.on("ready", () => card.focus());
        card.on("change", (e) => {
          setError(e.error ? e.error.message : null);
        });
      }
    }
  }, [elements?.getElement]);

  if (!stripe) return null;

  return (
    <form onSubmit={handleCardPay}>
      <ErrorBoundary
        fallback={<div>failed to load card component, please reload.</div>}
      >
        <CardElement
          className="h-6 my-5"
          options={{
            disabled,
            hidePostalCode: true,
            style: {
              base: {
                fontFamily: theme.fonts.body,
                fontSmoothing: "antialiased",
                iconColor: theme.colors["muted-foreground"],
                color: theme.colors.foreground,
                "::placeholder": {
                  color: theme.colors["muted-foreground"],
                },
              },
            },
          }}
        />
      </ErrorBoundary>
      {/* {user && user.type !== UserTypes.GUEST && (
        <div className="my-6 select-none">
          <input
            onChange={(e) => setSavePaymentMethod(e.target.checked)}
            disabled={checkout.loading}
            type="checkbox"
            checked={savePaymentMethod}
            id="savecard"
            className={clsx(
              "form-checkbox h-4 w-4 rounded mr-2 cursor-pointer",
              checkout.loading
                ? "focus:ring-disabled text-muted-foreground"
                : "focus:ring-secondary-500 text-secondary-600"
            )}
          />
          <label htmlFor="savecard" className="cursor-pointer w-full">
            Save this card for your future orders
          </label>
        </div>
      )} */}
      {/* <PaymentMethodSecurityLabel /> */}
      <Btn
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        disabled={!stripe || Boolean(disabled || error)}
        size="base"
      >
        Pay With Card
      </Btn>
    </form>
  );
};

export const ExistingPaymentMethods = () => {
  const { checkout } = useSessionCheckout();
  const { handleExistPay } = useSessionStripeCheckout();
  const disabled = Boolean(checkout.loading);

  const { user } = useUser();
  const paymentMethods: any = user?.paymentMethods;

  return (
    <React.Fragment>
      {paymentMethods?.map?.((id) => (
        <PayWithExistMethodButton
          key={id}
          disabled={disabled}
          onClick={handleExistPay}
          id={id}
        />
      ))}
    </React.Fragment>
  );
};

const SessionStripePayments = () => {
  const { status } = useSession();
  const { checkout, manualCard } = useSessionCheckout();
  const { paymentRequest } = useSessionStripeCheckout();

  const theme = useTheme();

  const hasPaymentMethods = false;
  // useSelector((s: RootState) => {
  //   const user = userSelector(s);
  //   return false; //user?.paymentMethods?.length > 0;
  // });

  const intl = useIntl();

  const disabled = Boolean(checkout.loading);

  if (status.ended) return null;

  return (
    <div className="grid grid-cols-1 gap-3">
      {!!checkout.error?.title && (
        <Alert type="danger">
          <span className="font-medium">
            {intl.formatMessage({
              id: checkout.error.title,
              defaultMessage: checkout.error.title,
            })}
          </span>
          {checkout.error?.description ? (
            <p className="font-regular">{checkout.error?.description}</p>
          ) : null}
        </Alert>
      )}

      {!manualCard.value && !!paymentRequest && (
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            classes: {
              base: "stripe-btn", //"mb-5",
            },
            style: {
              paymentRequestButton: {
                type: "default",
                theme: theme.type === "dark" ? "light" : "dark",
                height: "42px",
              },
            },
          }}
        />
      )}

      {!manualCard.value ? (
        hasPaymentMethods && <ExistingPaymentMethods />
      ) : (
        <div className="grid grid-cols-1 gap-3">
          <PayWithCard />
          {!checkout.loading && (
            <Btn
              fullWidth
              disabled={disabled}
              size="base"
              color="text"
              variant="light"
              // className="mb-5"
              onClick={() => manualCard.setFalse()}
            >
              Cancel
            </Btn>
          )}
        </div>
      )}

      {!manualCard.value && (
        <Btn
          fullWidth
          size="base"
          color={!hasPaymentMethods ? "primary" : "text"}
          variant={!hasPaymentMethods ? "contained" : "light"}
          onClick={() => manualCard.setTrue()}
          disabled={disabled}
        >
          {!hasPaymentMethods ? `Pay With Card` : `Pay With Another Card`}
        </Btn>
      )}
    </div>
  );
};

export default SessionStripePayments;
