import {
  HOURS_ALL_DAY_CLOSES,
  HOURS_ALL_DAY_OPEN,
  WEEKDAYS_INDEX,
} from "@gethere/common/settings";
import { TSchedule } from "@gethere/common/yup/Schedule";
import { ClockIcon, TrashIcon } from "@heroicons/react/24/outline";
import isEqual from "lodash/isEqual";
import { DateTime } from "luxon";
import { useState } from "react";
import { useIntl } from "react-intl";
import Alert from "../components/Alert";
import Btn from "../components/Btn";
import Collapsable from "../components/Collapsable";
import Input from "../components/Input";
import { Button } from "../components/ui/button";
import { Tabs, TabsList, TabsTrigger } from "../components/ui/tabs";

enum OpeningHoursTypes {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  CUSTOM = "CUSTOM",
}

const getHourTypes = (schedule: TSchedule) => {
  const { opens, closes } = schedule;
  if (isEqual(HOURS_ALL_DAY_CLOSES, { opens, closes }))
    return OpeningHoursTypes.CLOSED;
  if (isEqual(HOURS_ALL_DAY_OPEN, { opens, closes }))
    return OpeningHoursTypes.OPEN;
  return OpeningHoursTypes.CUSTOM;
};

function consecutiveRanges(days, intl) {
  let length = 1;
  const list = [];

  // If the array is empty,
  // return the list
  if (days.length === 0) {
    return list;
  }

  // Traverse the array from first position
  for (let day = 1; day <= days.length; day++) {
    // Check the difference between the
    // current and the previous elements
    // If the difference doesn't equal to 1
    // just increment the length variable.
    if (day === days.length || days[day] - days[day - 1] !== 1) {
      // If the range contains
      // only one element.
      // add it into the list.
      if (length === 1) {
        list.push(
          intl.formatMessage({ id: "short_day" }, { day: days[day - length] })
        );
      } else {
        // Build the range between the first
        // element of the range and the
        // current previous element as the
        // last range.

        list.push(
          [
            intl.formatMessage(
              { id: "short_day" },
              { day: days[day - length] }
            ),
            intl.formatMessage({ id: "from_to" }),
            intl.formatMessage({ id: "short_day" }, { day: days[day - 1] }),
          ].join(" ")
        );
      }

      // After finding the first range
      // initialize the length by 1 to
      // build the next range.
      length = 1;
    } else {
      length++;
    }
  }

  return list.join(", ");
}

const defaultScheduleTitleOptions = {
  showAllWeekdays: true,
  closeTimeMessage: "closes_at",
  openTimeMessage: "opens_at",
};

type ScheduleTitleOptions = Partial<typeof defaultScheduleTitleOptions>;

export const scheduleTitle = (
  s: TSchedule,
  intl,
  opts: ScheduleTitleOptions = defaultScheduleTitleOptions
) => {
  const options = { ...defaultScheduleTitleOptions, ...(opts || {}) };
  const hh = { opens: s.opens, closes: s.closes };

  const hours = isEqual(HOURS_ALL_DAY_CLOSES, hh)
    ? intl.formatMessage({ id: "closed_all_day" })
    : isEqual(HOURS_ALL_DAY_OPEN, hh)
    ? intl.formatMessage({ id: "opens_all_day" })
    : [
        intl.formatMessage({ id: options.openTimeMessage }, { at: hh.opens }),
        intl.formatMessage({ id: options.closeTimeMessage }, { at: hh.closes }),
      ].join(" ");

  let weekdays;

  const allWeekdays = !s.daysOfWeek;

  if (allWeekdays) {
    weekdays = intl.formatMessage({ id: "all_weekdays" });
  } else if (s.daysOfWeek?.length === 0) {
    weekdays = intl.formatMessage({ id: "choose_weekdays" });
  } else if (s.daysOfWeek?.length === 7) {
    weekdays = intl.formatMessage({ id: "all_weekdays" });
  } else {
    weekdays = [
      intl.formatMessage(
        { id: "plural_on_days" },
        { value: s.daysOfWeek?.length }
      ),
      consecutiveRanges(s.daysOfWeek, intl),
    ].join(" ");
  }

  const dates =
    !s.validThrough && !s.validFrom
      ? null
      : [
          s.validFrom
            ? intl.formatMessage({ id: "valid_from" }, { from: s.validFrom })
            : null,
          s.validThrough
            ? intl.formatMessage(
                { id: "valid_through" },
                { through: s.validThrough }
              )
            : null,
        ]
          .filter((x) => x?.length)
          .join(" ");

  return [
    dates,
    allWeekdays && !options.showAllWeekdays ? null : weekdays,
    hours,
  ]
    .filter((x) => x?.length)
    .join(", ");
};

const HOUR_FORMAT = "HH:mm";
const DATE_FORMAT = "MM/dd/yyyy";

export const Schedule = ({
  schedule,
  setSchedule,
  onDelete,
  context = "place",
}: {
  schedule: TSchedule;
  setSchedule: any;
  onDelete: any;
  context?: "place" | "bookings";
}) => {
  const intl = useIntl();

  const [state, setState] = useState({
    allDaysOfWeek: !schedule.daysOfWeek,
    openingHoursType: getHourTypes(schedule),
    hasValidFrom: !!schedule.validFrom,
    hasValidThrough: !!schedule.validThrough,
  });

  const handleDateChange = (n: string) => (e) => {
    if (n === "opens" || n === "closes") {
      setSchedule({
        ...schedule,
        [n]: e.target.value
          ? DateTime.fromISO(e.target.value).toFormat(HOUR_FORMAT)
          : null,
      });
    } else {
      setSchedule({
        ...schedule,
        [n]: e.target.value
          ? DateTime.fromISO(e.target.value).toFormat(DATE_FORMAT)
          : null,
      });
    }
  };

  const isClosedAllDay = OpeningHoursTypes.CLOSED === state.openingHoursType;
  const isOpenAllDay = OpeningHoursTypes.OPEN === state.openingHoursType;
  const isHoursCustom = OpeningHoursTypes.CUSTOM === state.openingHoursType;

  return (
    <Collapsable>
      <Collapsable.Header
        title={scheduleTitle(schedule, intl)}
        Icon={ClockIcon}
        onActionClick={onDelete}
        ActionIcon={TrashIcon}
      />
      <Collapsable.Body>
        <div className="grid grid-cols-1 gap-5 px-5 mb-5">
          <div className="grid grid-cols-1 gap-2">
            <div>
              {state.allDaysOfWeek ? (
                <Btn
                  size="sm"
                  className="w-full"
                  variant="contained"
                  color="text"
                  onClick={() => {
                    setSchedule({ ...schedule, daysOfWeek: [] });
                    setState((s) => ({ ...s, allDaysOfWeek: false }));
                  }}
                >
                  {intl.formatMessage({ id: "all_weekdays" })}
                </Btn>
              ) : (
                <div>
                  <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-2">
                    {WEEKDAYS_INDEX.map((day) => {
                      const checked = schedule.daysOfWeek?.indexOf(day) !== -1;
                      return (
                        <Btn
                          size="sm"
                          variant={checked ? "contained" : "light"}
                          color="text"
                          onClick={() => {
                            const next = !checked
                              ? [...schedule.daysOfWeek, day].sort()
                              : schedule.daysOfWeek.filter((x) => x !== day);

                            const allWeekdays = next.length === 7;
                            if (allWeekdays) {
                              setSchedule({ ...schedule, daysOfWeek: null });
                              setState((s) => ({ ...s, allDaysOfWeek: true }));
                            } else {
                              setSchedule({
                                ...schedule,
                                daysOfWeek: next,
                              });
                            }
                          }}
                        >
                          {intl.formatMessage({ id: "short_day" }, { day })}
                        </Btn>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            {schedule.daysOfWeek?.length === 0 && (
              <Alert type="warning">Choose at least one day of week</Alert>
            )}

            <Tabs
              value={
                isHoursCustom
                  ? "custom"
                  : isClosedAllDay
                  ? "closed_all_day"
                  : "open_all_day"
              }
              className="w-full"
              onValueChange={(value) => {
                if (value === "open_all_day") {
                  setSchedule({ ...schedule, ...HOURS_ALL_DAY_OPEN });
                  setState((s) => ({
                    ...s,
                    openingHoursType: OpeningHoursTypes.OPEN,
                  }));
                } else if (value === "closed_all_day") {
                  setSchedule({ ...schedule, ...HOURS_ALL_DAY_CLOSES });
                  setState((s) => ({
                    ...s,
                    openingHoursType: OpeningHoursTypes.CLOSED,
                  }));
                } else {
                  setState((s) => ({
                    ...s,
                    openingHoursType: OpeningHoursTypes.CUSTOM,
                  }));
                }
              }}
            >
              <TabsList className="grid w-full grid-cols-3">
                <TabsTrigger value="custom">
                  {intl.formatMessage({ id: "custom_schedule" })}
                </TabsTrigger>
                <TabsTrigger value="open_all_day">
                  {intl.formatMessage({ id: "opens_all_day" })}
                </TabsTrigger>
                <TabsTrigger value="closed_all_day">
                  {intl.formatMessage({ id: "closed_all_day" })}
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
          <div>
            {state.openingHoursType === OpeningHoursTypes.CUSTOM && (
              <div className="mt-5 grid grid-cols-2 gap-5">
                <Input
                  type="time"
                  label={intl.formatMessage({ id: "opens_at" }, { at: "" })}
                  value={schedule.opens}
                  onChange={handleDateChange("opens")}
                />
                <Input
                  type="time"
                  label={intl.formatMessage({ id: "closes_at" }, { at: "" })}
                  value={schedule.closes}
                  onChange={handleDateChange("closes")}
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-5">
            <Input
              type="date"
              label={intl.formatMessage({ id: "valid_from" }, { from: "" })}
              value={
                schedule.validFrom
                  ? DateTime.fromFormat(
                      schedule.validFrom,
                      DATE_FORMAT
                    ).toFormat("yyyy-MM-dd")
                  : ""
              }
              onChange={handleDateChange("validFrom")}
            />
            <Input
              type="date"
              label={intl.formatMessage(
                { id: "valid_through" },
                { through: "" }
              )}
              value={
                schedule.validThrough
                  ? DateTime.fromFormat(
                      schedule.validThrough,
                      DATE_FORMAT
                    ).toFormat("yyyy-MM-dd")
                  : ""
              }
              onChange={handleDateChange("validThrough")}
            />
          </div>
          {context === "bookings" && (
            <div>
              {schedule.maxGuests === null ? (
                <Button
                  type="button"
                  className="flex-1 w-full"
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setSchedule({ ...schedule, maxGuests: 25 });
                  }}
                >
                  Set Schedule Max Covers
                </Button>
              ) : (
                <Input
                  type="number"
                  value={schedule.maxGuests}
                  label="Schedule Max Covers"
                  clearValue={null}
                  clearable
                  className="flex-1"
                  onChange={(e) => {
                    setSchedule({
                      ...schedule,
                      maxGuests:
                        e.target.value === null ? null : Number(e.target.value),
                    });
                  }}
                />
              )}
            </div>
          )}
          {context === "bookings" && (
            <div>
              {schedule.maxSlotGuests === null ? (
                <Button
                  type="button"
                  className="flex-1 w-full"
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setSchedule({ ...schedule, maxSlotGuests: 25 });
                  }}
                >
                  Set Max Covers Per Slot
                </Button>
              ) : (
                <Input
                  type="number"
                  value={schedule.maxSlotGuests}
                  label="Max Covers Per Slot"
                  clearValue={null}
                  clearable
                  className="flex-1"
                  onChange={(e) => {
                    setSchedule({
                      ...schedule,
                      maxSlotGuests:
                        e.target.value === null ? null : Number(e.target.value),
                    });
                  }}
                />
              )}
            </div>
          )}
        </div>
        {/* <div>
          <pre>{JSON.stringify(schedule, null, 2)}</pre>
        </div> */}
      </Collapsable.Body>
    </Collapsable>
  );
};
