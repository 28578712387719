import * as yup from "yup";
import { BookingStatus } from "../enums";
import utcIsoDateString from "./Date";
import { yupMobileNumberField } from "./MobileInputForm";
import { gracefulNameSchema, nameSchema, requiredNameSchema } from "./User";
import { bookingOccasions } from "./BookingOccasion";

// regex for iso date string in any timezone
const iso =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})([+-]\d{2}:\d{2})$/;

export const notificationType = [
  "email",
  "sms",
  "push",
  "whatsapp",
  "call",
] as const;

export const bookingNotificationsMessages = {
  "booking.notify.sms.table_ready": {
    intl: {
      messages: {},
      defaultMessage:
        "Your table at {placeName} is now ready! Please come to the host stand within to be seated.",
      args: {
        placeName: "",
      },
    },
  },
  "booking.notify.sms.confirmEst": {
    intl: {
      messages: {},
      defaultMessage:
        "{placeName}: Wait time between {estMin}-{upperMin} min. {suffix}",
      args: {
        placeName: "",
        estMin: 10,
        upperMin: 15,
        suffix: "",
      },
    },
  },
  "booking.notify.sms.updateEst": {
    intl: {
      messages: {},
      defaultMessage:
        "{placeName}: Wait time updated! between {estMin}-{upperMin} min. {suffix}",
      args: {
        placeName: "",
        estMin: 10,
        upperMin: 15,
        suffix: "",
      },
    },
  },
};

export type BookingNotificationMessages =
  keyof typeof bookingNotificationsMessages;

export type NotificationType = (typeof notificationType)[number];

const bookingNotificationSchema = yup
  .object({
    via: yup.string().oneOf(notificationType).required(),
    msg: yup.string<BookingNotificationMessages>().required(),
    at: utcIsoDateString.required(),
    vars: yup.object().notRequired().default({}),
    by: yup.string().notRequired(),
  })
  .required();

export type TBookingNotification = yup.InferType<
  typeof bookingNotificationSchema
>;

const chatMessageSchema = yup.object({
  id: yup.string().required(),
  at: utcIsoDateString.required(),
  msg: yup.string().required(),
  uId: yup.string().required(),
});

export type TBookingChatMessage = yup.InferType<typeof chatMessageSchema>;

const bookingEventTypeSchema = yup.string().oneOf(["status", "note"]);

const bookingLogSchema = yup.object({
  by: yup.string().notRequired(),
  message: yup.string().notRequired(),
  at: utcIsoDateString.required(),
  type: bookingEventTypeSchema,
  args: yup.array().notRequired(),
});

export type TBookingLog = yup.InferType<typeof bookingLogSchema>;

const bookingMetaSchema = yup.object({
  // pmId: yup.string().optional(),
  // authAmount: yup.number().min(0).optional(),
  ref: yup.string().notRequired().default(undefined),
  cdId: yup.string().notRequired().default(undefined), // CONNECTED DEVICE id
  occasion: yup.string().notRequired().oneOf(bookingOccasions),
  staffNotes: yup.string().max(120).notRequired().default(undefined),
  locale: yup.string().notRequired().default(undefined),
  // waitlist specific
  estMin: yup.number().min(0).notRequired().default(undefined),
  estAt: utcIsoDateString.notRequired().default(undefined),
});

export const bookingCreateSchema = yup
  .object({
    email: yup.string().email().optional(),
    mobile: yupMobileNumberField.required(),
    name: requiredNameSchema.required(),
    placeId: yup.string().required(),
    guests: yup.number().optional().min(1).max(100).required().nullable(),
    startAt: yup.date().required(),
    notes: yup.string().optional().max(120).default(null).nullable(),
    occasion: yup.string().nullable().default(undefined),
    segment: yup
      .string()
      .notRequired()
      .default(null)
      .test({
        name: "is-valid-segment",
        message: "Invalid segment",
        test: (value: any) =>
          value === undefined ||
          value === null ||
          bookingSegments.includes(value),
      }),
  })
  .defined();

export const joinWaitlistSchema = yup
  .object({
    email: yup.string().email().optional(),
    mobile: yupMobileNumberField.required(),
    name: gracefulNameSchema.required(),
    placeId: yup.string().required(),
    guests: yup.number().min(1).max(100).required().nullable(),
    notes: yup.string().notRequired().max(120).default(null),
    occasion: yup.string().notRequired().default(undefined),
  })
  .defined();

export type TJoinWaitlistRequest = yup.InferType<typeof joinWaitlistSchema>;

export const bookingStatusSchema = yup
  .string<BookingStatus>()
  .oneOf(Object.values(BookingStatus));

export const bookingUpdateSchema = yup
  .object({
    sessionId: yup.string().notRequired(),
    email: yup.string().email().notRequired(),
    mobile: yupMobileNumberField.notRequired(),
    name: nameSchema.notRequired(),
    guests: yup.number().min(1).max(100).notRequired(),
    status: bookingStatusSchema.notRequired(),
    notes: yup.string().max(120).notRequired(),
  })
  .defined();

export type TBookingUpdate = yup.InferType<typeof bookingUpdateSchema>;

export const bookingSegments = [
  null /** reservations **/,
  "notify", // notify
  "waitlist",
] as const;

export type BookingSegment = (typeof bookingSegments)[number];

export const bookingSchema = yup
  .object({
    id: yup.string().required().defined(),

    segment: yup.string().notRequired().oneOf(bookingSegments),
    userId: yup.string().optional(),
    email: yup.string().optional(),
    mobile: yupMobileNumberField,
    name: nameSchema.required(),

    status: yup.string().oneOf(Object.values(BookingStatus)).nullable(),

    placeId: yup.string().required(),
    terminalIds: yup.array().of(yup.string()).nullable(),
    sessionId: yup.string().optional(),

    guests: yup.number().optional().default(null).min(1).max(100).nullable(),
    createdAt: yup.date().notRequired(),
    updatedAt: yup.date().notRequired(),

    startAt: yup.string().matches(iso).required(),
    endAt: yup.string().matches(iso).required(),

    // consumer notes
    notes: yup.string().optional().max(120),

    meta: bookingMetaSchema.optional().default({}),

    notifications: yup
      .array(bookingNotificationSchema)
      .notRequired()
      .default([]),

    chat: yup.array(chatMessageSchema).notRequired().default([]),

    log: yup.array(bookingLogSchema).notRequired().default([]),
  })
  .defined();

export const reserveRequestSchema = yup
  .object({
    email: yup.string().optional(),
    mobile: yupMobileNumberField,
    name: nameSchema.required(),
    placeId: yup.string().required(),
    guests: yup.number().optional().default(null).min(1).max(100).nullable(),
    startAt: yup.string().matches(iso).required(),
    endAt: yup.string().matches(iso).required(),
    notes: yup.string().optional().max(120),
  })
  .defined();

export const bookingSlotSearch = yup.object({
  placeId: yup.string().required(),
  startAt: utcIsoDateString.required(),
  guests: yup.number().required(),

  sections: yup.array(yup.string()).notRequired(),
  types: yup.array(yup.string()).notRequired(),
  minutes: yup.number().notRequired(),

  ignoreId: yup.string().notRequired(),
  withBlockedSlots: yup.boolean().notRequired().default(false),
});

export const bookingsPlaceSearchSchema = yup.object({
  placeId: yup.string().optional(),
  minAt: utcIsoDateString.required(),
  maxAt: utcIsoDateString.required(),
  status: yup
    .mixed<BookingStatus>()
    .oneOf(Object.values(BookingStatus))
    .nullable()
    .optional(),
});

export const bookingCalendarRequest = yup.object({
  minAt: utcIsoDateString.required(),
  maxAt: utcIsoDateString.required(),
});

export type TBookingCalendarRequest = yup.InferType<
  typeof bookingCalendarRequest
>;

export const bookingsUserSearchSchema = yup.object({
  userId: yup.string().optional(),
  placeId: yup.string().optional(),
  minAt: utcIsoDateString.required(),
  maxAt: utcIsoDateString.required(),
  status: yup
    .mixed<BookingStatus>()
    .oneOf(Object.values(BookingStatus))
    .nullable()
    .optional(),
});

export type TBookingMeta = yup.InferType<typeof bookingMetaSchema>;
export type TBooking = yup.InferType<typeof bookingSchema>;
export type TBookingCreate = yup.InferType<typeof bookingCreateSchema>;
export type TBookingSlotSearch = yup.InferType<typeof bookingSlotSearch>;
export type TBookingsPlaceSearch = yup.InferType<
  typeof bookingsPlaceSearchSchema
>;
export type TBookingsUserSearch = yup.InferType<
  typeof bookingsUserSearchSchema
>;

export type TBookingSlot = {
  startAt: string;
  endAt: string;
  segment?: BookingSegment;
  nearest?: boolean;
  minutes?: number;
  blocked?: boolean;
};
