import { useIntl } from "react-intl";
import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { MiniAppFooter } from "../components/AppFooter";
import { CompanyLogo } from "./LogoSymbol";

const Title = ({ children }) => (
  <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-medium max-w-prose">
    {children}
  </h2>
);

const Description = ({ children }) => (
  <h2 className="text-md md:text-lg lg:text-xl mt-3 text-muted-foreground max-w-prose">
    {children}
  </h2>
);

export default function JoinLayout({ children }) {
  const intl = useIntl();

  return (
    <div className="flex flex-col lg:flex-row justify-center min-h-screen z-0">
      <div className="lg:w-2/5">
        <div className="flex flex-col place-content-center h-auto lg:h-full px-6 lg:px-10">
          <div className="max-w-xl mx-auto py-10">
            <CompanyLogo
              className="h-4 md:h-5 lg:h-6 xl:h-7 mb-10"
              symbolClassName="fill-text opacity-60"
              labelClassName="fill-text opacity-60"
            />
            <Switch>
              <Route path="/join/login">
                <div>
                  <Title>{intl.formatMessage({ id: "wpa_join_login" })}</Title>
                  <Description>
                    {intl.formatMessage(
                      { id: "wpa_join_login_desc" },
                      {
                        contact: (
                          <Link className="font-medium" to="/pages/contact">
                            {intl.formatMessage({
                              id: "wpa_join_contact_our_team",
                            })}
                          </Link>
                        ),
                      }
                    )}
                  </Description>
                </div>
              </Route>
              <Route path="/join/business">
                <div>
                  <Title>
                    {intl.formatMessage({
                      id: "business_wizard_document_title",
                    })}
                  </Title>
                  <Description>
                    {intl.formatMessage(
                      { id: "wpa_join_welcom_desc" },
                      {
                        contact: (
                          <Link className="font-medium" to="/pages/contact">
                            {intl.formatMessage({
                              id: "wpa_join_contact_our_team",
                            })}
                          </Link>
                        ),
                      }
                    )}
                  </Description>
                </div>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between w-full lg:max-w-3/5 px-6">
        <div className="flex items-center mx-auto flex-grow mt-5 max-w-xl">
          {children}
        </div>
        <MiniAppFooter />
      </div>
      <div
        className="absolute w-full h-full bg-cover bg-center opacity-10 -z-10"
        style={{
          backgroundImage: "url(/pale-join.png)",
        }}
      />
    </div>
  );
}
