import { userAddressTypes } from "@gethere/common/enums";
import { pointInPolygon } from "@gethere/common/utils/geo";
import { userAddressSchema } from "@gethere/common/yup/UserAddress";
import {
  HomeIcon,
  MapPinIcon,
  BuildingOfficeIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useFormik } from "formik";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { batch, useDispatch, useSelector } from "react-redux";
import Alert from "../components/Alert";
import Btn from "../components/Btn";
import CloseIcon from "../components/CloseIcon";
import Input from "../components/Input";
import Section from "../components/Section";
import {
  onUserAddressUpdate,
  onUserUpdate,
  userSelector,
} from "../state/reducers/user";
import { RootState } from "../state/store";
import client from "../utils/client";
import {
  GoogleMapsContext,
  GoogleMapsProvider,
} from "../utils/GoogleMapsContext";
import useToggle from "../utils/useToggle";
import GoogleMapsAutocompleteInput from "./GoogleMapsAutocompleteInput";
import Modal from "./Modal";
import UserAddressButton from "./UserAddressButton";

const mapContainerStyle = {
  width: "100%",
  height: "200px",
  marginBottom: "8px",
  marginTop: "8px",
  borderRadius: "5px",
};

export const getAddressTypeIcon = (type: userAddressTypes) => {
  switch (type) {
    case userAddressTypes.HOME:
      return HomeIcon;
    case userAddressTypes.WORK:
      return BuildingOfficeIcon;
    case userAddressTypes.OTHER:
      return MapPinIcon;
  }
};

const UserAddressTypeButton = ({
  type,
  selected,
  onClick,
  disabled = false,
}) => {
  const intl = useIntl();
  const Icon = getAddressTypeIcon(type);
  return (
    <Btn
      disabled={disabled}
      onClick={() => onClick(type)}
      variant="contained"
      color={selected ? "secondary" : "default"}
      StartIcon={Icon}
    >
      {intl.formatMessage(
        {
          id: "user_address_type",
        },
        { type }
      )}
    </Btn>
  );
};

export const UserAddressForm = ({
  address = null,
  onSuccess,
  contextArea = null,
}) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    onSubmit: async (values, { setSubmitting, setValues }) => {
      try {
        setSubmitting(true);

        const isNew = !values.id;

        const response = await client(
          isNew
            ? { method: "POST", url: "/me/addresses", data: values }
            : { method: "PUT", url: `/me/addresses/${values.id}`, data: values }
        );

        const { result, entities, address } = response.data;

        if (isNew) {
          dispatch(((dispatch) => {
            batch(() => {
              onSuccess?.(address);
              dispatch(onUserUpdate({ result, entities }));
            });
          }) as any);
        } else {
          dispatch(((dispatch) => {
            batch(() => {
              onSuccess?.(result);
              dispatch(onUserAddressUpdate(result));
            });
          }) as any);
        }
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
    validationSchema: userAddressSchema,
    initialValues: userAddressSchema.cast(address || {}),
  });

  const intl = useIntl();

  const { trigger, googleMaps } = useContext(GoogleMapsContext);

  useEffect(() => {
    if (!googleMaps.triggered && formik.values.address) {
      // have address and not triggered
      trigger(); // trigger
    }
  }, [googleMaps.triggered, formik.values.address, trigger]);

  const [map, setMap] = useState(null);

  const onMapLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onMapUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleType = useCallback(
    function callback(type) {
      formik.setFieldValue("type", type);
    },
    [formik]
  );

  const handleAddress = useCallback(
    (result) => {
      if (result) {
        const { googleId, location, address, meta } = result;

        const coordinates = {
          type: "Point",
          coordinates: [location.lat, location.lng],
        };

        batch(() => {
          if (contextArea) {
            const eligble = pointInPolygon(coordinates as any, contextArea);
            formik.setStatus({
              eligble,
            });
          }

          formik.setValues((v) => ({
            ...v,
            address,
            coordinates,
            override: null,
            meta,
            googleId,
          }));
        });
      } else {
        batch(() => {
          formik.setValues(userAddressSchema.cast({}));
          formik.setStatus({ eligble: null });
        });
      }
    },
    [contextArea, formik]
  );

  const [lat, lng] = formik.values.coordinates?.coordinates || [];

  const coordinates = useMemo(() => ({ lat, lng }), [lat, lng]);

  useEffect(() => {
    if (map) {
      setTimeout(() => {
        batch(() => {
          map.setCenter(coordinates);
          map.setZoom(18);
        });
      }, 500);
    }
  }, [map, coordinates]);

  useEffect(() => {
    if (address?.id && formik.values.id !== address?.id) {
      // need reset.
      formik.setValues(userAddressSchema.cast(address));
    }
    // eslint-disable-next-line
  }, [address?.id, formik.values.id]);

  const canSubmit =
    !formik.isSubmitting && contextArea ? formik.status?.eligble : true;

  const eligble = formik.status?.eligble;

  return (
    <div>
      <GoogleMapsAutocompleteInput
        disabled={formik.isSubmitting}
        onChange={handleAddress}
        address={formik.values.address}
        label={intl.formatMessage({
          id: "session_delivery_address_title",
          defaultMessage: "Addrerss",
        })}
      />
      {!formik.values.address && (
        <Alert type="info">
          {intl.formatMessage({ id: "session_address_add_desc" })}
        </Alert>
      )}
      {!!contextArea && typeof eligble === "boolean" && (
        <>
          {eligble ? (
            <Alert type="success">
              {intl.formatMessage({
                id: "delivery_inside_service_area",
                defaultMessage: "Address inside service area",
              })}
            </Alert>
          ) : (
            <Alert type="warning">
              {intl.formatMessage({
                id: "delivery_out_of_service_area",
                defaultMessage: "Address out of service area",
              })}
            </Alert>
          )}
        </>
      )}

      {formik.values?.meta?.street_name &&
        !formik.values?.meta?.street_number &&
        eligble !== false && (
          <Alert type="info">
            {intl.formatMessage({
              id: "missing_street_number",
              defaultMessage: "Missing street number",
            })}
          </Alert>
        )}

      {formik.values.address && (
        <>
          {googleMaps.isLoaded && Boolean(window.google) && (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              onLoad={onMapLoad}
              onUnmount={onMapUnmount}
            >
              <Marker position={coordinates} />
            </GoogleMap>
          )}

          <Input
            disabled={formik.isSubmitting}
            error={formik.errors?.comments}
            label={intl.formatMessage({
              id: "delivery_instruction",
              defaultMessage: "delivery_instruction",
            })}
            helperText={
              (formik.errors?.comments as string) ||
              intl.formatMessage({
                id: "delivery_instruction_placeholder",
                defaultMessage: "delivery_instruction_placeholder",
              })
            }
            value={formik.values.comments}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="comments"
          />

          <div className="mb-5 grid grid-cols-3 gap-2">
            <UserAddressTypeButton
              disabled={formik.isSubmitting}
              type={userAddressTypes.HOME}
              selected={formik.values.type === userAddressTypes.HOME}
              onClick={handleType}
            />
            <UserAddressTypeButton
              disabled={formik.isSubmitting}
              type={userAddressTypes.WORK}
              selected={formik.values.type === userAddressTypes.WORK}
              onClick={handleType}
            />
            <UserAddressTypeButton
              disabled={formik.isSubmitting}
              type={userAddressTypes.OTHER}
              selected={formik.values.type === userAddressTypes.OTHER}
              onClick={handleType}
            />
          </div>

          <Input
            disabled={formik.isSubmitting}
            error={formik.errors?.label}
            label={intl.formatMessage({
              id: "name",
              defaultMessage: "Name",
            })}
            value={formik.values.label}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="label"
            helperText={
              (formik.errors?.label as string) ||
              intl.formatMessage({ id: "optional" })
            }
          />

          <Btn
            size="lg"
            color="primary"
            variant="contained"
            fullWidth
            onClick={formik.handleSubmit as any}
            disabled={!canSubmit}
          >
            {intl.formatMessage(
              {
                id: contextArea
                  ? "approve_what"
                  : !formik.values.id
                  ? "save_what"
                  : "update_what",
              },
              {
                what: intl.formatMessage({
                  id: "session_delivery_address_title",
                }),
              }
            )}
          </Btn>
        </>
      )}
    </div>
  );
};

const UserAddressDialog = ({ addressId, onClose, onSuccess }) => {
  const intl = useIntl();

  const address = useSelector((s: RootState) =>
    addressId ? s.user.entities?.addresses?.[addressId] : null
  );

  return (
    <Modal
      open={Boolean(addressId)}
      onClose={onClose}
      key={String(address?.id || "new")}
    >
      <Section.Header>
        <Section.Title>
          {intl.formatMessage(
            { id: address?.id ? "update_what" : "add_what" },
            {
              what: intl.formatMessage({
                id: "session_delivery_address_title",
              }),
            }
          )}
        </Section.Title>
        <Section.Actions>
          <CloseIcon onClick={onClose} />
        </Section.Actions>
      </Section.Header>
      <GoogleMapsProvider autoTrigger={Boolean(address)}>
        <UserAddressForm onSuccess={onSuccess} address={address} />
      </GoogleMapsProvider>
    </Modal>
  );
};

const UserAdresses = () => {
  const intl = useIntl();

  const { addresses = [] as any } = useSelector((s: RootState) => {
    const { defaultAddress, addresses } = userSelector(s);
    return {
      defaultAddress,
      addresses,
    };
  });

  const {
    isOpen: addressOpen,
    onOpen: onAddressOpen,
    onClose: onAddressClose,
  } = useToggle(false);

  const handleAddressSuccess = useCallback(
    (address) => {
      onAddressClose();
    },
    [onAddressClose]
  );

  return (
    <div>
      <div>
        {addresses?.map((id) => (
          <div key={id} onClick={() => onAddressOpen(null, id)}>
            <UserAddressButton addressId={id} />
          </div>
        ))}
      </div>
      <Btn
        color="primary"
        variant="contained"
        size="lg"
        fullWidth
        StartIcon={PlusIcon}
        onClick={onAddressOpen}
      >
        {intl.formatMessage(
          { id: "add_what", defaultMessage: "Add {what}" },
          {
            what: intl.formatMessage({
              id: "session_delivery_address_title",
              defaultMessage: "Address",
            }),
          }
        )}
      </Btn>
      <UserAddressDialog
        addressId={addressOpen}
        onClose={onAddressClose}
        onSuccess={handleAddressSuccess}
      />
    </div>
  );
};

export default UserAdresses;
