import { useOnValueChange } from "@shopify/react-hooks";
import Big from "big.js";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Cell, LabelList, Pie, PieChart, ResponsiveContainer } from "recharts";
import Amount from "../components/Amount";
import Btn from "../components/Btn";
import { useSessionCheckout } from "../contexts/SessionCheckoutContext";
import {
  DrawerScreenParams,
  useSessionDrawerNavigation,
} from "../contexts/SessionDrawerNavigationContext";
import { SubmitSessionPayment } from "../contexts/SubmitSessionPayment";
import { useTheme } from "../contexts/ThemeContext";
import { useAppSelector } from "../state/hooks";
import {
  sessionCurrencySelector,
  sessionSelector,
} from "../state/reducers/session";

type SplitMode = "custom" | "equal";

type SplitState = {
  mode: SplitMode;
  capacity: number;
  share: number;
};

const DescriptionAmount = ({
  value,
  onChange,
  max,
  min,
  startLabel,
  endLabel,
  disabled,
}: {
  value: number;
  onChange: (v: number) => void;
  max: number;
  min: number;
  startLabel: string;
  endLabel: string;
  disabled: boolean;
}) => {
  return (
    <div className="grid grid-cols-3 gap-2 items-center justify-between">
      <div className="text-base text-gray-500 text-right">{startLabel}</div>
      <div className="mx-auto">
        <Amount
          disabled={disabled}
          value={value}
          max={max}
          min={min}
          onChange={onChange}
        />
      </div>
      <div className="text-base text-gray-500">{endLabel}</div>
    </div>
  );
};

type ChartData = {
  name: string;
  value: number;
  fill: string;
  opacity: number;
  label: string;
};

const SplitPieChart = ({
  share,
  capcity,
  left,
  currency,
  paid,
  pay,
}: {
  currency: string;
  share: number;
  capcity: number;
  left: number;
  paid: number;
  pay: number;
}) => {
  const theme = useTheme();

  const splits: ChartData[] = [];

  const sharesCovered = pay > 0 && paid > 0 ? paid / pay : 0;

  let deducted = false;

  for (let i = 1; i <= capcity; i++) {
    const active = i === Math.ceil(sharesCovered) + 1;

    const covered = Math.ceil(sharesCovered) >= i;

    if (active) {
      splits.push({
        name: `split-${i}`,
        value: 1,
        fill: theme.colors.primary,
        opacity: 1,
        label: "Your share",
      });
    } else if (!covered) {
      splits.push({
        name: `split-${i}`,
        value: 1,
        fill: theme.colors["muted-foreground"],
        opacity: 0.2,
        label: "Other's Share",
      });
    } else {
      let value = 1;
      if (!deducted && sharesCovered < 1) {
        value = 1 - sharesCovered;
        deducted = true;
        splits.push({
          name: "covered",
          value: sharesCovered,
          fill: theme.colors["muted-foreground"],
          opacity: 0.2,
          label: "Other's Share",
        });
      }

      // covered already
      splits.push({
        value,
        name: "covered",
        fill: theme.colors.foreground,
        opacity: 1,
        label: "Paid Share",
      });
    }
  }

  return (
    <ResponsiveContainer height={250}>
      <PieChart height={250}>
        <Pie
          dur={10}
          data={splits}
          dataKey="value"
          cx="50%"
          cy="50%"
          paddingAngle={3}
          innerRadius={70}
          outerRadius={90}
        >
          <LabelList
            dataKey="label"
            position="outside"
            className="font-medium text-sm stroke-none"
            offset={10}
          />
          {splits.map((entry, index) => (
            <Cell
              focusable={false}
              stroke="none"
              key={`cell-${index}`}
              fill={entry.fill}
              opacity={entry.opacity}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default function SessionSplitPayment({
  params,
}: {
  params: DrawerScreenParams["split_equally"];
}) {
  const intl = useIntl();
  const { checkout } = useSessionCheckout();
  const nav = useSessionDrawerNavigation();
  const currency = useAppSelector(sessionCurrencySelector);
  const { capacity, check } = useAppSelector((s) => {
    const session = sessionSelector(s);
    return { capacity: session?.capacity, check: session?.check };
  });

  const [state, setState] = useState<SplitState>({
    mode: null,
    capacity: capacity || 2,
    share: 1,
  });

  const total = check ? check.total : 0;
  const balance = check ? check.balance : 0;
  const authorized = check ? check.authorized : 0;
  const balanceLeft = check && check.balance < 0 ? Math.abs(check.balance) : 0;
  const left = check && balance < 0 ? balanceLeft - authorized : 0;
  const canCalc = check && total && left;

  const minPayment = 3;
  const maxSplit = total > 0 ? Math.round(total / minPayment) : 0;

  const paid = check ? check.authorized + check.charged : 0;

  const value = canCalc
    ? Big(total).div(state.capacity).times(state.share).round(2).toNumber()
    : 0;

  let pay = value > left ? left : value;

  // if left less than 1$ then pay all left
  if (left - pay < 1) pay = left;

  useEffect(() => {
    if (state.share > state.capacity) {
      setState((s) => ({ ...s, share: state.capacity }));
    }
  }, [state.share, state.capacity]);

  useOnValueChange(left, (now, was) => {
    if (now === 0 && was > 0) {
      nav.setScreen({ name: "bill", params: null });
    }
  });

  return (
    <div className="flex flex-col gap-5 h-full">
      <h3 className="text-2xl font-medium">Split equally</h3>
      <div className="my-auto">
        <SplitPieChart
          paid={paid}
          share={state.share}
          capcity={state.capacity}
          left={left}
          currency={currency}
          pay={pay}
        />
      </div>
      <div className="flex flex-col gap-5">
        <DescriptionAmount
          value={state.capacity}
          onChange={(v) => setState((s) => ({ ...s, capacity: v }))}
          max={maxSplit}
          min={2}
          startLabel="Split the bill for"
          endLabel="people"
          disabled={checkout.loading}
        />
        <DescriptionAmount
          value={state.share}
          onChange={(v) => setState((s) => ({ ...s, share: v }))}
          max={capacity}
          min={1}
          startLabel="Pay for"
          endLabel="people"
          disabled={checkout.loading}
        />
      </div>
      <div className="grid grid-cols-1 gap-2">
        <div className="flex flex-row justify-between text-md">
          <div className="text-gray-500">Amount to share</div>
          <div className="text-gray-500">
            {intl.formatNumber(total, {
              style: "currency",
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        {paid > 0 && (
          <div className="flex flex-row justify-between text-md">
            <div className="text-gray-500">Already paid</div>
            <div className="text-gray-500">
              {intl.formatNumber(paid, {
                style: "currency",
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        )}
        <div className="flex flex-row justify-between text-lg font-medium">
          <div className="text-text">Amount to pay</div>
          <div className="text-text">
            {intl.formatNumber(pay, {
              style: "currency",
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-2">
        <SubmitSessionPayment value={pay} />
        <Btn
          color="text"
          variant="text"
          className="w-full"
          disabled={checkout.loading}
          onClick={() => {
            nav.setScreen({ name: "bill", params: {} });
          }}
        >
          Go Back
        </Btn>
      </div>
    </div>
  );
}
