import { useIntl } from "react-intl";
import Alert from "./Alert";

export default function NotFoundScreen() {
  const intl = useIntl();
  return (
    <div className="flex w-full h-full content-center justify-center items-center">
      <img
        src="/pale-fatal-error.png"
        style={{ maxWidth: "500px", width: "80vw" }}
        alt="page not found"
      />
      <Alert type="info">
        {intl.formatMessage({ id: "screen_not_found" })}
      </Alert>
    </div>
  );
}
