import * as yup from "yup";
import { userAddressTypes } from "../enums";
import { geoPointScheme } from "./Geo";

export const addressCommonSchema = yup
  .object({
    type: yup
      .mixed<userAddressTypes>()
      .oneOf(Object.values(userAddressTypes))
      .default(userAddressTypes.HOME),
    address: yup.string().required(),
    meta: yup
      .object({
        street_number: yup.string().optional(),
        street_name: yup.string().optional(),
        city: yup.string().optional(),
        state: yup.string().optional(),
        country: yup.string().optional(),
        postal_code: yup.string().optional(),
      })
      .required()
      .default({}),
    coordinates: geoPointScheme,
    googleId: yup.string().optional().nullable(),
    comments: yup.string().max(120).optional().nullable(),
  })
  .required();

export const userAddressSchema = addressCommonSchema.concat(
  yup
    .object({
      id: yup.string().nullable(),
      label: yup.string().nullable().default(null),
      override: geoPointScheme.nullable().default(null), // override
    })
    .required()
);

export type TUserAddress = yup.InferType<typeof userAddressSchema>;
