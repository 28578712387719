import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

/**
          active
            ? "ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60"
            : "" */
const SelectGroupItem = ({ value, name = "", children = null }) => {
  return (
    <RadioGroup.Option
      value={value}
      className={({ active, checked }) =>
        `${
          checked
            ? "bg-primary text-primary-foreground hover:text-primary-foreground/95 border-0"
            : "bg-card hover:bg-foreground/5 border-2 border-border"
        } group relative px-5 py-4 cursor-pointer flex focus:outline-none rounded-md`
      }
    >
      {({ active, checked }) => (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <div className="text-sm">
                <RadioGroup.Label as="p" className={`font-medium`}>
                  {name}
                </RadioGroup.Label>
                {Boolean(children) && (
                  <RadioGroup.Description
                    as="span"
                    className={`inline ${
                      checked
                        ? "text-primary-foreground/50"
                        : "text-card-foreground/50"
                    }`}
                  >
                    {children}
                  </RadioGroup.Description>
                )}
              </div>
            </div>
            {checked && (
              <div className="flex-shrink-0 text-primary-foreground">
                <CheckIcon className="w-6 h-6" />
              </div>
            )}
          </div>
        </>
      )}
    </RadioGroup.Option>
  );
};

function SelectGroup<T>({
  children,
  value,
  className = null,
  onChange = (v: T) => {},
}: {
  children: JSX.Element[] | JSX.Element;
  value: T;
  className?: string;
  onChange: (T) => void;
}) {
  return (
    <div className="w-full">
      <div className="w-full max-w-md mx-auto rtl:text-right">
        <RadioGroup value={value} onChange={onChange}>
          {/* <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label> */}
          <div className={clsx("grid gap-2", className)}>{children}</div>
        </RadioGroup>
      </div>
    </div>
  );
}

SelectGroup.Item = SelectGroupItem;

export default SelectGroup;
