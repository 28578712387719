import * as yup from "yup";

const validHebrew = new RegExp(/^[\u0590-\u05FF]{2,}$/);
const validEnglish = new RegExp(/^[A-Z,a-z]{2,}$/);

export const updateUserDetailsSchema = yup
  .object({
    name: yup
      .object({
        first: yup
          .string()
          .min(2)
          .test("name_first", "invalid_name", function (v) {
            try {
              return Boolean(
                v && (validHebrew.test(v) || validEnglish.test(v))
              );
            } catch (error) {
              return false;
            }
          }),
        last: yup
          .string()
          .min(2)
          .test("name_last", "invalid_name", function (v) {
            try {
              return Boolean(
                v && (validHebrew.test(v) || validEnglish.test(v))
              );
            } catch (error) {
              return false;
            }
          }),
      })
      .required(),
    email: yup
      .string()
      .email()
      .default(""),
  })
  .required();

export type TUpdateUserDetails = yup.InferType<typeof updateUserDetailsSchema>;
