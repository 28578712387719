import { PaymentIntentResponse } from "@gethere/common/types";
import { useToggle } from "@shopify/react-hooks";
import { createContext, useContext, useState, ElementType } from "react";
import { LoadingAnimation } from "../components/LoadingScreen";

type CheckoutState = {
  loading: boolean;
  error?: { title: string; description?: string };
  intent?: PaymentIntentResponse;
};

const SessionCheckoutContext = createContext<{
  checkout: CheckoutState;
  setCheckout: React.Dispatch<React.SetStateAction<CheckoutState>>;
  manualCard: {
    value: boolean;
    toggle: () => void;
    setTrue: () => void;
    setFalse: () => void;
  };
}>(null);

export const useSessionCheckout = () => useContext(SessionCheckoutContext);

export const SessionCheckoutProvider = ({ children }: { children: any }) => {
  const manualCard = useToggle(false);

  const [state, setstate] = useState<CheckoutState>({
    loading: false,
    error: null,
    intent: null,
  });

  return (
    <SessionCheckoutContext.Provider
      value={{
        checkout: state,
        setCheckout: setstate,
        manualCard,
      }}
    >
      {state.loading && (
        <div className="z-50 fixed bg-gray-100 bg-opacity-50 duration-300 h-full w-full inset-0 pointer-events-none">
          <LoadingAnimation className="flex flex-col items-center justify-center place-content-center justify-items-center h-full" />
        </div>
      )}
      {children}
    </SessionCheckoutContext.Provider>
  );
};

export default SessionCheckoutContext;
