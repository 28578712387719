import clsx from "clsx";

const PageWrapper = ({
  children,
  disableHorizonalPadding = false,
  disableVerticalMargin = false,
  className = null,
}) => {
  return (
    <div
      className={clsx(
        "w-full",
        !disableHorizonalPadding && "px-5",
        !disableVerticalMargin && "my-5",
        className
      )}
    >
      {children}
    </div>
  );
};

export default PageWrapper;
