import { UserTypes } from "@gethere/common/enums";
import * as RestAPI from "@gethere/common/RestAPI";
import sleep from "@gethere/common/utils/sleep";
import { addBreadcrumb, captureException, captureMessage } from "@sentry/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentIntent, PaymentRequest } from "@stripe/stripe-js";
import Big from "big.js";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
  sessionBusinessSelector,
  sessionSelector,
  sessionUpdated,
} from "../state/reducers/session";
import { userSelector } from "../state/reducers/user";
import store from "../state/store";
import client from "../utils/client";
import useAsyncEffect from "../utils/useAsyncEffect";
import { useSessionCheckout } from "./SessionCheckoutContext";
import { useSession } from "./SessionContext";
import { useUser } from "./UserContext";
import { useAnalytics } from "./AnalyticsContext";

const getExistPaymentMedhod = (pmId: string, state = store.getState()) => {
  return state.user.entities.paymentMethods?.[pmId];
};

const buildStripeReq = (
  { value }: { value: number },
  update: boolean = false
) => {
  const { currency, country } = sessionBusinessSelector(store.getState());

  const request: any = {
    currency: currency?.toLowerCase?.(),
    total: {
      label: "Order Subtotal",
      amount: Big(value).mul(100).round().toNumber(),
    },
  };

  if (!update) {
    request.country = !update ? country?.toUpperCase?.() || "GB" : undefined;
    request.requestPayerName = true;
    request.requestPayerEmail = true;
  }

  return request;
};

const SessionStripeCheckoutContext = createContext<{
  handleExistPay: (pmId: string) => Promise<any>;
  handleCardPay: (ev: any) => Promise<any>;
  paymentRequest?: PaymentRequest;
  savePaymentMethod: boolean;
  setSavePaymentMethod: (v: boolean) => void;
}>(null);

export const useSessionStripeCheckout = () =>
  useContext(SessionStripeCheckoutContext);

export const SessionStripeCheckoutProvider = ({
  children,
  value,
  payIntentSync,
  onSuccess,
}: {
  children: React.ReactNode;
  onSuccess?: () => void;
  value: number;
  payIntentSync: ({
    localValue,
  }: {
    localValue: number;
  }) => Promise<RestAPI.Session.Sync.Response>;
}) => {
  const { id: sessionId } = useSession();
  const { logEvent } = useAnalytics();
  const intl = useIntl();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useUser();

  const [savePaymentMethod, setSavePaymentMethod] = useState(
    () => user && user.type !== UserTypes.GUEST
  );

  const paymentRequestValue = useRef<number>(value);
  const paymentRequestRef = useRef<PaymentRequest>(null);

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>(null);

  const { setCheckout, manualCard } = useSessionCheckout();

  const updateSessionState = useCallback(
    ({ result, entities }: RestAPI.Session.Sync.Response) => {
      dispatch(sessionUpdated({ result, entities }));
    },
    [dispatch]
  );

  const localValue = useRef<number>(value);

  useEffect(() => {
    localValue.current = value;
  }, [value]);

  const onSyncStart = useCallback(() => {
    const session = sessionSelector(store.getState());

    logEvent("session_client_payment_submit", {
      placeId: session?.placeId,
      terminalId: session?.terminalId,
    });

    setCheckout((s) => ({ ...s, loading: true, error: null }));
  }, [setCheckout]);

  const onSyncError = useCallback(
    (error) => {
      addBreadcrumb({
        message: "syncing payment intent handler error",
        data: { error },
      });
      captureMessage("on stripe checkout error");

      let title: string, description: string;

      if (error.title) {
        title = error.title;
        if (error.description) {
          description = error.description;
        }
      } else if (error.code && error.message) {
        title = error.message;
        // description = error.message;
      } else if (error.message) {
        title = error.message;
      } else {
        title = "unknown error";
      }

      const session = sessionSelector(store.getState());

      logEvent("session_client_payment_failed", {
        placeId: session?.placeId,
        terminalId: session?.terminalId,
        error: title,
      });

      setCheckout((s) => ({
        ...s,
        loading: false,
        error: { title, description },
      }));
    },
    [setCheckout]
  );

  const onPaymentSuccess = useCallback(
    async (
      paymentIntent: PaymentIntent,
      props: { savePaymentMethod?: boolean }
    ) => {
      toast.success(
        intl.formatMessage({
          id: "payment_succeeded",
          defaultMessage: "Payment Succeeded",
        })
      );

      const response = await client.api.sessionPaymentIntentSuccessed(
        store.getState().session.result,
        { id: paymentIntent.id, provider: "STRIPE_CONNECT" }
      );

      updateSessionState(response.data);

      if (typeof onSuccess === "function") {
        onSuccess();
      }

      const session = sessionSelector(store.getState());

      logEvent("session_client_payment_success", {
        placeId: session?.placeId,
        terminalId: session?.terminalId,
        provider: "STRIPE_CONNECT",
        description: paymentIntent?.description,
        amount: paymentIntent?.amount,
        currency: paymentIntent?.currency,
      });

      manualCard.setFalse();

      setCheckout((s) => ({ ...s, loading: false, error: null }));
    },
    [setCheckout, updateSessionState, onSuccess]
  );

  const syncIntent = useCallback(
    async (localValue: number) => {
      addBreadcrumb({
        message: "syncing payment intent handler started",
        data: { localValue },
      });
      try {
        onSyncStart();

        const sync = await payIntentSync({
          localValue,
        });

        if ("result" in sync && "entities" in sync) {
          updateSessionState(sync);
        }

        if ("error" in sync && sync.error) {
          throw new Error(sync.error);
        }

        if (!("paymentIntent" in sync)) {
          throw new Error("Couldn't process payment intent");
        }

        if (sync.paymentIntent.error) {
          throw new Error(sync.paymentIntent.error);
        }

        setCheckout((s) => ({ ...s, intent: sync.paymentIntent }));

        return sync;
      } catch (error) {
        onSyncError(error);

        throw error;
      }
    },
    [onSyncStart, onSyncError, updateSessionState, payIntentSync]
  );

  useAsyncEffect(async () => {
    try {
      if (!stripe) {
        return () => {};
      }

      if (!sessionId) {
        return () => {};
      }

      if (!user?.id) {
        return () => {};
      }

      const amount = Big(value).mul(100).round().toNumber();

      if (value === paymentRequestValue.current && paymentRequestRef.current) {
        return () => {};
      }

      if (paymentRequest && typeof paymentRequest["update"] === "function") {
        const showing = paymentRequest.isShowing();

        addBreadcrumb({
          category: "stripe",
          message: `updating stripe wallet payment request ${amount}`,
        });

        if (showing) {
          paymentRequest.once("cancel", async function () {
            await sleep(500);
            paymentRequest.update({
              total: {
                label: "Your payment",
                amount,
              },
            });
            paymentRequest.show();
          });
          paymentRequest.abort();
        } else {
          paymentRequest.update({
            total: {
              label: "Your payment",
              amount,
            },
          });
        }

        return () => {};
      }

      addBreadcrumb({
        category: "stripe",
        message: `creating stripe wallet payment request ${amount}`,
      });

      const pr = stripe.paymentRequest(
        buildStripeReq({
          value,
        })
      );

      paymentRequestRef.current = pr;

      pr.on("paymentmethod", async (ev) => {
        setCheckout((s) => ({ ...s, loading: true, error: null }));

        const sync = await syncIntent(localValue.current);
        const pi = sync.paymentIntent;

        if (sync.error) {
          addBreadcrumb({
            category: "stripe",
            message: `sync error on('paymentmethod')`,
          });
          captureException(sync.error);
          ev.complete("fail");
          return () => {};
        }

        if (!pi) {
          addBreadcrumb({
            category: "stripe",
            message: `missing payment intent on sync on('paymentmethod')`,
          });
          captureException(
            new Error("stripecheckout - sync has no payment intent")
          );
          ev.complete("fail");
          return () => {};
        }

        if (pi.skip) {
          // not really an error, liable have enought balance
          ev.complete("fail");
          return () => {};
        }

        if (!pi.key) {
          captureException(
            new Error(
              "stripecheckout - missing payment intent key from sync request"
            )
          );
          ev.complete("fail");
          return () => {};
        }

        const intentSecretKey = pi.key;

        addBreadcrumb({ message: "got payment intent key" });

        const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            intentSecretKey,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );

        if (confirmError) {
          addBreadcrumb({
            category: "payment",
            message: "confirming card payment 1 error",
          });
          captureMessage("Payment confirm error", {
            extra: confirmError as any,
          });
          setCheckout((s) => ({
            ...s,
            error: {
              title: "payment_failed",
              description: confirmError.message,
            },
          }));
          ev.complete("fail");
          return () => {};
        }

        addBreadcrumb({ message: "confirming, before sending success" });

        try {
          ev.complete("success");
        } catch (error) {
          captureException(error);
        }

        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(intentSecretKey);

          if (error) {
            addBreadcrumb({
              category: "payment",
              message: "confirming card payment 2 error",
            });
            captureException(error);
            // The payment failed -- ask your customer for a new payment method.
            return setCheckout((s) => ({
              ...s,
              error: {
                title: "payment_failed",
                description: error.message,
              },
            }));
          }
        }

        // The payment has succeeded.
        onPaymentSuccess(paymentIntent, {});
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      paymentRequestValue.current = value;
    } catch (error) {
      // todo: remove console
      console.error(error);

      addBreadcrumb({
        category: "payment",
        message: "payment request error",
      });

      captureException(error);

      setCheckout((s) => ({
        ...s,
        error: null,
        loading: false,
      }));
    }
  }, [stripe, setPaymentRequest, sessionId, value, user?.id]);

  // update wallets requests when value changed
  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.update(buildStripeReq({ value }, true));
    }
  }, [value, paymentRequest]);

  const handleCardPay = useCallback(
    async (e) => {
      e?.preventDefault?.();
      if (!stripe || !elements?.getElement) return;

      try {
        const sync = await syncIntent(localValue.current);
        const card = elements.getElement(CardElement);

        if (!("paymentIntent" in sync))
          throw new Error("Couldn't process payment intent");

        const { paymentIntent, error } = await stripe.confirmCardPayment(
          sync.paymentIntent.key,
          {
            payment_method: {
              card,
            },
            save_payment_method: false, // savePaymentMethod,
          }
        );

        if (error) throw error;

        onPaymentSuccess(paymentIntent, { savePaymentMethod });
      } catch (error) {
        onSyncError(error);
      }
    },
    [
      stripe,
      savePaymentMethod,
      syncIntent,
      value,
      elements?.getElement,
      onPaymentSuccess,
      dispatch,
      onSyncError,
    ]
  );

  const handleExistPay = useCallback(
    async (pmId: string) => {
      try {
        const state = store.getState();
        const user = userSelector(state);

        const pm = getExistPaymentMedhod(pmId, state);
        if (
          !pm ||
          pm.payload.provider !== "STRIPE_CONNECT" ||
          !pm.payload?.token
        ) {
          throw new Error("invalid pm");
        }

        const sync = await syncIntent(localValue.current);

        const { paymentIntent, error } = await stripe.confirmCardPayment(
          sync.paymentIntent.key,
          {
            payment_method: pm.payload.token,
            receipt_email: user.email,
          }
        );

        if (error) {
          return onSyncError(error);
        }

        onPaymentSuccess(paymentIntent, {});
      } catch (error) {
        onSyncError(error);
      }
    },
    [syncIntent, onPaymentSuccess, onSyncError, value, stripe]
  );

  return (
    <SessionStripeCheckoutContext.Provider
      value={{
        handleExistPay,
        handleCardPay,
        paymentRequest,
        savePaymentMethod,
        setSavePaymentMethod,
      }}
    >
      {children}
    </SessionStripeCheckoutContext.Provider>
  );
};

export default SessionStripeCheckoutContext;
