import {
  HOURS_ALL_DAY_CLOSES,
  HOURS_ALL_DAY_OPEN,
} from "@gethere/common/settings";
import { TSchedule } from "@gethere/common/yup/Schedule";
import isEqual from "lodash/isEqual";
import { FormattedRelativeTime, IntlShape, useIntl } from "react-intl";
import Alert from "../components/Alert";
import { useSession } from "../contexts/SessionContext";
import PageContainer from "./PageContainer";
import PageWrapper from "./PageWrapper";
import { differenceInMinutes } from "date-fns";

// session_schedule_closed;
// session_schedule_closing_soon_at;

export const scheduleToString = (s: TSchedule, intl: IntlShape) => {
  const hh = { opens: s.opens, closes: s.closes };

  const hours = isEqual(HOURS_ALL_DAY_CLOSES, hh)
    ? intl.formatMessage({ id: "closed_all_day" })
    : isEqual(HOURS_ALL_DAY_OPEN, hh)
    ? intl.formatMessage({ id: "opens_all_day" })
    : [
        intl.formatMessage({ id: "opens_at" }, { at: hh.opens }),
        intl.formatMessage({ id: "closes_at" }, { at: hh.closes }),
      ].join(" ");

  const weekdays = !s.daysOfWeek
    ? intl.formatMessage({ id: "all_weekdays" })
    : s.daysOfWeek?.length === 0
    ? intl.formatMessage({ id: "choose_weekdays" })
    : intl.formatMessage(
        { id: "plural_on_days" },
        { value: s.daysOfWeek?.length }
      ) +
      " " +
      s.daysOfWeek
        ?.map((day) => intl.formatMessage({ id: "short_day" }, { day }))
        .join(", ");

  const dates =
    !s.validThrough && !s.validFrom
      ? null
      : [
          s.validFrom
            ? intl.formatMessage({ id: "valid_from" }, { from: s.validFrom })
            : null,
          s.validThrough
            ? intl.formatMessage(
                { id: "valid_through" },
                { through: s.validThrough }
              )
            : null,
        ]
          .filter((x) => x?.length)
          .join(" ");

  return [dates, weekdays, hours].filter((x) => x?.length).join(", ");
};

const soon = 20; // in minutes

const SessionMessages = () => {
  const { status } = useSession();
  const intl = useIntl();

  const now = new Date();

  const willCloseAt = status?.schedule?.closeAt
    ? differenceInMinutes(status.schedule.closeAt, now)
    : null;

  const closingSoon = willCloseAt ? willCloseAt <= soon : null;
  const closedNow = !status?.schedule?.status;

  if (!closingSoon || !closedNow) return null;

  return (
    <PageWrapper>
      <PageContainer className="grid grid-cols-1 gap-2">
        {closedNow ? (
          <Alert type="danger">
            {intl.formatMessage({
              id: "session_schedule_closed",
              defaultMessage: "Place currently closed for orders",
            })}
          </Alert>
        ) : closingSoon ? (
          <Alert type="warning">
            {intl.formatMessage(
              {
                id: "session_schedule_closing_soon_at",
                defaultMessage: "Place will close {at}",
              },
              {
                at: (
                  <FormattedRelativeTime
                    unit="minute"
                    value={willCloseAt}
                    updateIntervalInSeconds={10}
                  />
                ),
              }
            )}
          </Alert>
        ) : null}
      </PageContainer>
    </PageWrapper>
  );
};

export default SessionMessages;
