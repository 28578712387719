import { TPlace } from "@gethere/common/yup/Place";
import { TSession } from "@gethere/common/yup/Session";
import {
  ChevronRightIcon,
  ReceiptRefundIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Alert from "../components/Alert";
import Btn from "../components/Btn";
import { Surface } from "../components/Surface";
import client from "../utils/client";
import errorify from "../utils/errorify";
import mergeEntities from "../utils/mergeEntities";
import ProfileListItemSkeleton from "./ProfileListItemSkeleton";

const SessionButton = ({
  session,
  place,
}: {
  session: TSession;
  place: TPlace;
}) => {
  const intl = useIntl();

  if (!session) return null;

  const status = [
    intl.formatMessage(
      { id: "session_state_selector", defaultMessage: session.state },
      { state: session.state }
    ),
    intl.formatMessage(
      { id: "session_type", defaultMessage: session.type },
      { type: session.type }
    ),
  ].join(", ");

  const label = [
    intl.formatDate(session.createdAt),
    intl.formatTime(session.createdAt),
  ].join(", ");

  return (
    <Link
      to={`/order?sId${session.id}`}
      className="p-3 hover:bg-gray-200 dark:hover:bg-gray-800 flex flex-row items-center justify-between group"
    >
      <div className="flex flex-row items-center">
        <div className="flex w-6 h-6 mr-3 rounded-full items-center justify-center">
          <ReceiptRefundIcon className="mx-auto w-6 h-6 opacity-50 group-hover:opacity-100" />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <h6 className="font-bold text-sm">{label}</h6>
            <span className="ml-2 bg-gray-100 w-fit dark:bg-gray-700 p-1 text-[10px] font-medium rounded">
              {status}
            </span>
          </div>
          {!!place?.meta?.address && (
            <span className="text-muted-foreground text-xs">
              {place.meta.address}
            </span>
          )}
        </div>
      </div>
      <ChevronRightIcon className="w-6 h-6 opacity-0 group-hover:opacity-100" />
    </Link>
  );
};

const UserOrderHistory = () => {
  const intl = useIntl();
  //   const dispatch = useDispatch();

  const [state, setstate] = useState({
    loading: false,
    result: [],
    skip: 0,
    eof: false,
    entities: {} as any,
    error: null,
    query: {
      filter: {
        state: null,
      },
    },
  });

  const loadOrders = async () => {
    try {
      if (state.loading) return;
      setstate((s) => ({ ...s, loading: true, error: null }));
      const { data } = await client.post("/sessions/search", {
        filter: {},
        skip: state.skip + state.result.length,
      });

      const result = data.result || [];
      const entities = data.entities || {};

      setstate((s) => ({
        ...s,
        loading: false,
        error: null,
        result: [...s.result, ...result],
        entities: mergeEntities(s.entities, entities),
        skip: state.skip + result.length,
        eof: result.length === 0,
      }));
    } catch (error) {
      setstate((s) => ({
        ...s,
        loading: false,
        error: errorify(error)?.message,
      }));
    }
  };

  useEffect(() => {
    loadOrders();
    // eslint-disable-next-line
  }, []);

  if (!state.loading && state.result?.length === 0)
    return <Alert type="info">No reservations found</Alert>;

  return (
    <Surface>
      <Surface.Content disablePadding>
        <div className="divide-y dark:divide-gray-800">
          {state.loading
            ? Array(5)
                .fill(null)
                .map((x, i) => (
                  <ProfileListItemSkeleton
                    Icon={ReceiptRefundIcon}
                    key={String(i)}
                  />
                ))
            : state.result.map((id) => {
                const session = state.entities.sessions[id];
                const place = state.entities.places?.[session.placeId];
                return (
                  <SessionButton key={id} session={session} place={place} />
                );
              })}
        </div>
        {!state.eof && (
          <Btn
            variant="light"
            disabled={state.loading}
            loading={state.loading}
            onClick={() => loadOrders()}
          >
            {intl.formatMessage({ id: "load_more" })}
          </Btn>
        )}
      </Surface.Content>
    </Surface>
  );
};

export default UserOrderHistory;
