import { parseAddressComponents } from "@gethere/common/utils/geo";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { captureException } from "@sentry/react";
import autoSuggestParse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Input from "../components/Input";
import { GoogleMapsContext, mapServices } from "../utils/GoogleMapsContext";
import { useThrottleEffect } from "../utils/hooks";

// icon: {
//   color: theme.palette.text.secondary,
//   marginRight: theme.spacing(2),
// },
interface PlaceType {
  description: string;
  place_id?: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
}

const getDefaultPlaceType = (address: string | null): PlaceType | null =>
  address?.length > 0
    ? {
        description: address,
        structured_formatting: {
          main_text: address,
          secondary_text: "",
          main_text_matched_substrings: [
            {
              offset: 0,
              length: address.length,
            },
          ],
        },
      }
    : null;

const LocationOption = ({ option, parts, onClick }) => {
  return (
    <div
      className="rounded-md items-center flex flex-row px-3 py-2 gap-3 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
      onClick={onClick}
    >
      <MapPinIcon className="w-4 h-4 stroke-disabled" />
      <div className="flex-col flex">
        <div>
          <span>
            {parts.map((part, index) => (
              <span
                key={index}
                className={part.highlight ? "font-medium" : "font-normal"}
              >
                {part.text}
              </span>
            ))}
          </span>
        </div>
        <span className="text-muted-foreground text-sm">
          {option.structured_formatting.secondary_text}
        </span>
      </div>
    </div>
  );
};

function GoogleMapsAutocompleteInput({
  label = "Address",
  address,
  onChange,
  autoFocus = false,
  className = undefined,
  types = ["address"],
  disabled = false,
}) {
  const { googleMaps, trigger } = useContext(GoogleMapsContext);

  const [v, setValue] = useState<{
    value: PlaceType | null;
    loading: boolean;
  }>({
    value: getDefaultPlaceType(address),
    loading: false,
  });

  const [o, setOptions] = useState<{
    options: PlaceType[];
    loading: boolean;
  }>({ options: [], loading: false });

  const [inputValue, setInputValue] = useState("");

  const getPredictions = useMemo(
    () =>
      throttle(
        (request: { input }, callback: (results?: PlaceType[]) => void) => {
          mapServices.autoComplete.getPlacePredictions(
            {
              ...request,
              types,
              language: googleMaps.language,
            },
            callback
          );
        },
        300
      ),
    // eslint-disable-next-line
    [] //language, types
  );

  const getSelected = useCallback(
    (id: string) =>
      new Promise((resolve, reject) => {
        if (id) {
          if (typeof mapServices.geocode?.geocode !== "function") {
            return undefined;
          }

          // const fields = ["geometry", "formatted_address"];

          mapServices.geocode.geocode(
            {
              placeId: id,
              // fields,
            },
            (place, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                resolve(place?.[0]);
              } else reject(status);
            }
          );
        }
      }),
    []
  );

  useEffect(() => {
    if (v.value?.place_id) {
      getSelected(v.value?.place_id)
        .then((result: any) => {
          onChange({
            googleId: v.value?.place_id,
            location: JSON.parse(JSON.stringify(result.geometry.location)),
            address: result.formatted_address,
            types: result.types,
            meta: parseAddressComponents(result.address_components),
          });
        })
        .catch(captureException);
    }
  }, [v.value?.place_id]); //

  useThrottleEffect(
    () => {
      if (o.loading) return;

      if (!mapServices.autoComplete) {
        return undefined;
      }

      if (!inputValue || inputValue.length < 3) {
        setOptions((s) => ({
          ...s,
          loading: false,
          options: v.value ? [v.value] : [],
        }));
        return undefined;
      }

      setOptions((s) => ({ ...s, loading: true }));

      getPredictions({ input: inputValue }, (results?: PlaceType[]) => {
        let newOptions = [] as PlaceType[];

        if (v.value) {
          newOptions = [v.value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions((s) => ({ ...s, options: newOptions, loading: false }));
      });
    },
    [inputValue],
    { wait: 500, trailing: true, leading: false }
  );

  const onInputChange = useCallback(
    (event) => {
      setInputValue(event.target.value);
    },
    [setInputValue]
  );

  const onInputFocus = useCallback(
    (event) => {
      if (!googleMaps.triggered) trigger();
    },
    [googleMaps.triggered, trigger]
  );

  const handleChange = useCallback(
    (newValue: PlaceType | null) => {
      setValue((s) => ({ ...s, value: newValue }));
      if (
        newValue &&
        o.options &&
        !o.options.find((opt) => opt?.description == newValue.description)
      ) {
        setOptions((s) => ({
          ...s,
          options: [...s.options, newValue],
        }));
      }
    },
    [o.options]
  );

  const optionRenderer = useCallback((option, i, onClick) => {
    const matches = option.structured_formatting.main_text_matched_substrings;

    const parts = autoSuggestParse(
      option.structured_formatting.main_text,
      matches.map((match: any) => [match.offset, match.offset + match.length])
    );

    return (
      <LocationOption
        key={`o-${i}`}
        option={option}
        parts={parts}
        onClick={onClick}
      />
    );
  }, []);

  if (v.value)
    return optionRenderer(v.value, 0, () => {
      handleChange(null);
    });

  return (
    <div className={className}>
      <Input
        value={inputValue}
        onFocus={onInputFocus}
        onChange={onInputChange}
        autoFocus={autoFocus}
        label={label}
        disabled={disabled}
      />
      {o.options.map((opt, i) =>
        optionRenderer(opt, i, () => {
          handleChange(opt);
        })
      )}
    </div>
  );
}

export default GoogleMapsAutocompleteInput;
