export const bookingOccasions = [
  "romantic",
  "birthday",
  "business",
  "family",
  null,
] as const;

export type TBookingOccasion = (typeof bookingOccasions)[number];

export const bookingOccasionsOptions: {
  id: TBookingOccasion;
  label: string;
  emoji: any;
}[] = [
  {
    id: "romantic",
    label: "Date",
    emoji: "heart",
  },
  {
    id: "birthday",
    label: "Birthday",
    emoji: "cake",
  },
  {
    id: "family",
    label: "Family Gathering",
    emoji: "family",
  },
  {
    id: "business",
    label: "Business Meeting",
    emoji: "briefcase",
  },
  {
    id: null,
    label: "Just a good time",
    emoji: "man_dancing",
  },
];
