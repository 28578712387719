import {
  createContext,
  createRef,
  RefObject,
  useCallback,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { sessionCollectionsSelector } from "../state/reducers/session";
import { RootState } from "../state/store";

type SessionCollectionsSectionsContextType = {
  sections: RefObject<any>[];
  getSectionIndex: (id: string) => any;
  getSectionRef: (id: string) => RefObject<any>;
  getSectionRefById: (id: string) => RefObject<any>;
};

const SessionCollectionsSectionsContext = createContext(
  {} as SessionCollectionsSectionsContextType
);

export const SessionCollectionsSectionsProvider = ({ children }) => {
  const { collections }: { collections: any[] } = useSelector(
    (state: RootState) => ({
      collections: sessionCollectionsSelector(state),
    })
  );

  const { sectionIndex, sections } = useMemo(() => {
    const sectionIndex = new Map<string, number>();

    for (const c in collections) {
      sectionIndex.set(collections[c].id, sectionIndex.size);
      for (const g in collections[c].groups) {
        sectionIndex.set(collections[c].groups[g].id, sectionIndex.size);
      }
    }

    return {
      sectionIndex,
      sections: Array.from({ length: sectionIndex.size }, () => createRef()),
    };
    // eslint-disable-next-line
  }, []);

  const getSectionIndex = useCallback(
    (id) => sectionIndex.get(id),
    [sectionIndex]
  );

  const getSectionRef = useCallback((id) => sections[id], [sections]);

  const getSectionRefById = useCallback(
    (id: string) => sections[sectionIndex.get(id)],
    [sections, sectionIndex]
  );

  return (
    <SessionCollectionsSectionsContext.Provider
      value={{ getSectionIndex, getSectionRef, getSectionRefById, sections }}
    >
      {children}
    </SessionCollectionsSectionsContext.Provider>
  );
};

export default SessionCollectionsSectionsContext;
