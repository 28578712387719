import { useEffect, useLayoutEffect, useRef, useState } from "react";

export const screens = {
  sm: "640px",
  // => @media (min-width: 640px) { ... }

  md: "768px",
  // => @media (min-width: 768px) { ... }

  lg: "1024px",
  // => @media (min-width: 1024px) { ... }

  xl: "1280px",
  // => @media (min-width: 1280px) { ... }

  "2xl": "1536px",
  // => @media (min-width: 1536px) { ... }

  "3xl": "1920px",
  // => @media (min-width: 1920px) { ... }
};

export type Breakpoint = keyof typeof screens;

export const breakpoints = Object.keys(screens) as Array<Breakpoint>;

// https://github.com/pmndrs/zustand/blob/833f57ed131e94f3ed48627d4cfbf09cb9c7df03/src/ts#L20-L23
export const isSSR =
  typeof window === "undefined" ||
  !window.navigator ||
  /ServerSideRendering|^Deno\//.test(window.navigator.userAgent);

export const isBrowser = !isSSR;

export const useIsomorphicEffect = isBrowser ? useLayoutEffect : useEffect;

const isMinWidth = (breakpoint: Breakpoint) => {
  const value = (screens?.[breakpoint] as string) ?? "999999px";
  return !window.matchMedia(`(min-width: ${value})`).matches;
};

export function useBreakpoint(breakpoint: Breakpoint) {
  const [match, setMatch] = useState(() => isMinWidth(breakpoint));
  const matchRef = useRef(isMinWidth(breakpoint));

  useIsomorphicEffect(() => {
    if (!(isBrowser && "matchMedia" in window)) return undefined;

    function track() {
      matchRef.current = isMinWidth(breakpoint);
      if (matchRef.current !== match) {
        setMatch(matchRef.current);
      }
    }

    window.addEventListener("resize", track);
    return () => window.removeEventListener("resize", track);
  });

  return match;
}
