import { createContext, useContext, useEffect } from "react";
import SessionDrawer from "../containers/SessionDrawer";
import useToggle from "../utils/useToggle";
import { useSession } from "./SessionContext";

const SessionDrawerContext = createContext<ReturnType<typeof useToggle>>(null);

export const useSessionDrawer = () => useContext(SessionDrawerContext);

export const SessionDrawerProvider = ({ children }) => {
  const { status, total, id } = useSession();

  const toggle = useToggle(() =>
    Boolean(status?.pendings?.total > 0 || total > 0)
  );

  useEffect(() => {
    if (total > 0 && !toggle.isOpen) toggle.onOpen();
  }, [id]);

  return (
    <SessionDrawerContext.Provider value={toggle}>
      {children}
      <SessionDrawer isOpen={toggle.isOpen} onClose={toggle.onClose} />
    </SessionDrawerContext.Provider>
  );
};

export default SessionDrawerContext;
