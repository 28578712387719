import { createContext, useContext } from "react";
import useToggle from "../utils/useToggle";

const ToggleContext = createContext<ReturnType<typeof useToggle>>({
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  value: false,
});

export const useToggleContext = () => useContext(ToggleContext);

export const ToggleConsumer = ToggleContext.Consumer;

export const ToggleProvider = ({ children, defaultValue = false }) => {
  const state = useToggle<boolean>(defaultValue);
  return (
    <ToggleContext.Provider value={state}>{children}</ToggleContext.Provider>
  );
};

export default ToggleContext;
