import clsx from "clsx";
import env from "../utils/env";
import { CompanyLogo } from "./LogoSymbol";

const PoweredBy = ({ className = null }) => {
  return (
    <div
      className={clsx(
        "safe-area-bottom px-2 my-2 w-full mx-auto mt-auto pt-5",
        className
      )}
    >
      <a
        href={env.app}
        target="_blank"
        rel="noreferrer"
        className="flex flex-row no-underline rtl:flex-row-reverse items-center justify-items-center place-content-center gap-2"
      >
        <span className="text-tiny text-muted-foreground font-medium select-none whitespace-nowrap">
          Powered by
        </span>
        <CompanyLogo
          className="h-4"
          labelClassName="fill-muted-foreground"
          symbolClassName="fill-muted-foreground"
        />
      </a>
    </div>
  );
};

export default PoweredBy;
