import { SessionState, SocketActions } from "@gethere/common/enums";
import { useEffect } from "react";
import { useAppSelector } from "../state/hooks";
import { sessionSelector } from "../state/reducers/session";
import { RootState } from "../state/store";
import client from "../utils/client";
import { useSession } from "./SessionContext";
import { useSocket } from "./SocketProvider";
import { useAppEvent } from "../AppEvents";

export const SessionSocketProvider = ({ children }) => {
  const socket = useSocket();
  const { status: sst } = useSession();

  const { sessionId, sessionState } = useAppSelector((s: RootState) => {
    const session = sessionSelector(s);
    return {
      sessionId: s.session.result,
      sessionState: session?.state,
    };
  });

  const isDraft = sessionState === SessionState.draft;
  const forceConnect = !!sessionId && !isDraft && !sst?.ended;

  useEffect(() => {
    socket.setShouldConnect(forceConnect);
  }, [forceConnect]);

  useAppEvent("ws_status", (status) => {
    if (status && sessionId && !isDraft) {
      client.ws.emit(SocketActions.SUBSCRIBE_SESSION, {
        sessionId,
      });
    }
  });

  return children;
};
