import { ArchiveBoxArrowDownIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useIntl } from "react-intl";

const DefaultIcon = ({ className }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="8"
    width="8"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
  </svg>
);

export const ActiveIndicator = ({ value }) => {
  const intl = useIntl();
  return (
    <Indicator
      color={value ? "green" : "gray"}
      label={intl.formatMessage({ id: "active_value" }, { value })}
    />
  );
};

export const ArchivedIndicator = ({ active }) => {
  const intl = useIntl();
  if (active) return null;
  return (
    <Indicator
      Icon={ArchiveBoxArrowDownIcon}
      color={"gray"}
      label={intl.formatMessage({ id: "archived", defaultMessage: "Archived" })}
    />
  );
};

export default function Indicator({
  Icon = DefaultIcon,
  label,
  color = "gray",
  className = null,
  variant = "text",
}: {
  Icon?: any;
  label: string;
  color?: "gray" | "red" | "green" | "amber";
  variant?: "text" | "outline" | "contained";
  className?: string;
}) {
  const cs = clsx(
    "tracking-wide",
    color === "gray" && "text-gray-600 dark:text-gray-200",
    color === "gray" &&
      variant === "contained" &&
      "bg-gray-100 dark:bg-gray-700",

    color === "green" && "text-green-600 dark:text-green-200",
    color === "green" &&
      variant === "contained" &&
      "bg-green-100 dark:bg-green-900",

    color === "red" && "text-red-600 dark:text-red-200",
    color === "red" && variant === "contained" && "bg-red-100 dark:bg-red-900",

    color === "amber" && "text-amber-600 dark:text-amber-200",
    color === "amber" &&
      variant === "contained" &&
      "bg-amber-100 dark:bg-amber-900",

    "flex items-center px-1 py-1 text-tiny font-medium uppercase leading-none rounded",
    className
  );

  return (
    <div className={cs}>
      <span className="flex flex-col justify-center">
        <Icon className="stroke-current h-3 w-3 opacity-30" />
      </span>
      <span className="ltr:ml-1 rtl:mr-1">{label}</span>
    </div>
  );
}
