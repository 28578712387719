import * as yup from "yup";
import { locales, UserTypes as UserTypess } from "../enums";
import { imageSchema } from "./Image";
import { yupMobileNumberField } from "./MobileInputForm";
import { userPaymentMethodSchema } from "./PaymentMethod";
import { userAddressSchema } from "./UserAddress";
import { simpleDateSchema } from "./Date";

export const genders = ["male", "female"] as const;
export type Gender = (typeof genders)[number];

export const contractTypes = ["full", "irregular", "part"] as const;
export type ContractType = (typeof contractTypes)[number];

const namePartSchema = yup.string().min(1).max(25);

export const nameSchema = yup.object({
  first: namePartSchema,
  last: namePartSchema.optional(),
});

export const requiredNameSchema = yup
  .object({
    first: namePartSchema.required().default(""),
    last: namePartSchema.required().default(""),
  })
  .required();

export const gracefulNameSchema = yup.object({
  first: namePartSchema,
  last: yup.string().max(15).notRequired(),
});

export const controlPaySchema = yup.object({
  value: yup.number().nullable().default(0),
  type: yup.mixed<"hourly" | "monthly">().nullable().default("hourly"),
});

export const businessControlSchema = yup.object({
  id: yup.string(),
  userId: yup.string(),
  businessId: yup.string(),
  active: yup.boolean().default(true),
  roleId: yup.string().required().default(""),
  pin: yup
    .string()
    .required()
    .length(4)
    .default("")
    .transform((val) => (val === null ? "" : val)),
  meta: yup.object({
    placeIds: yup.array().of(yup.string()).notRequired(),
    name: gracefulNameSchema,
    employeeNumber: yup.string().notRequired(),
    tipable: yup.boolean(),
    emergency: yup.object({
      name: yup.string().notRequired(),
      mobile: yupMobileNumberField.notRequired(),
      relation: yup.string().notRequired(),
      email: yup.string().email().notRequired(),
    }),
    address: yup.string().notRequired(),
    city: yup.string().notRequired(),
    postcode: yup.string().notRequired(),
    email: yup.string().email().notRequired(),
    gender: yup.mixed<(typeof genders)[number]>().notRequired(),
    birthDate: simpleDateSchema.notRequired().default(null),
    hiredSince: simpleDateSchema.notRequired().default(null),
    pay: controlPaySchema.nullable().default(controlPaySchema.cast({})),
    contractType: yup.mixed<ContractType>().notRequired(),
    nationality: yup.string().notRequired(),
    eligbiltyType: yup.mixed<
      "nationalPassport" | "drivingLicence" | "workPermit"
    >(),
    _bank: yup.object({
      name: yup.string().notRequired(),
      accountNumber: yup.string().notRequired(),
      sortCode: yup.string().notRequired(),
    }),
  }),
  updatedAt: yup.string(),
  createdAt: yup.string(),
});

export const userBusinessControlSchema = yup.object({
  control: businessControlSchema,
  mobile: yupMobileNumberField.notRequired(),
});

export const userSchema = yup
  .object({
    id: yup.string(),
    email: yup.string().email().nullable().lowercase(),
    active: yup.boolean().default(true),
    locale: yup.string().nullable().default(locales.en_US),
    profileImg: imageSchema.notRequired(),
    ref: yup
      .object({
        sc: yup.mixed().optional(),
        userId: yup.string().nullable(),
        terminalId: yup.string().nullable(),
        placeId: yup.string().nullable(),
      })
      .optional(),
    pref: yup
      .object({
        allergies: yup.object().default({}),
        flavors: yup.object().default({}),
        diets: yup.object().default({}),
      })
      .optional(),
    type: yup.string().default(UserTypess.USER),
    name: nameSchema,
    mobileNumber: yupMobileNumberField.nullable(),
    mobileVerifiedAt: yup.date().nullable(),
    addresses: yup.array().of(userAddressSchema).optional(),
    paymentMethods: yup.array().of(userPaymentMethodSchema),
    defaultPaymentMethod: yup.string().nullable(),
    defaultAddress: yup.string().nullable(),
    controls: yup.array().of(businessControlSchema),
    createdAt: yup.date().nullable(),
    updatedAt: yup.date().nullable(),
  })
  .required();

const googleSignSelectBy = [
  "btn",
  "select_by",
  "auto",
  "btn_confirm_add_session",
  "btn_add_session",
  "btn_confirm",
  "user_1tap",
  "user_2tap",
  "user",
] as const;

export const signInWithGoogleSchema = yup.object({
  clientId: yup.string().required(),
  client_id: yup.string().required(),
  credential: yup.string().required(),
  select_by: yup
    .mixed<(typeof googleSignSelectBy)[number]>()
    .required()
    .oneOf(googleSignSelectBy as any),
});

export type TSignInWithGoogle = yup.InferType<typeof signInWithGoogleSchema>;
export type TUser = yup.InferType<typeof userSchema>;

export type TName = yup.InferType<typeof nameSchema>;

export type TBusinessControl = yup.InferType<typeof businessControlSchema>;

export type TUserBusinessControl = yup.InferType<
  typeof userBusinessControlSchema
>;
