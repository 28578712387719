import { NoSymbolIcon } from "@heroicons/react/24/outline";

export default function CenteredErrorContainer({
  Icon = NoSymbolIcon,
  title = null,
  subtitle = null,
  children = null,
}) {
  return (
    <div className="flex flex-col flex-grow place-items-center min-h-[80vh] items-center justify-center align-middle gap-10">
      <Icon width={100} height={100} className="stroke-disabled" />
      <div className="flex flex-col text-center w-max-xs gap-3">
        {!!title && (
          <h6 className="font-medium text-base  text-muted-foreground">
            {title}
          </h6>
        )}
        {!!subtitle && (
          <p className="font-medium text-sm text-muted-foreground">
            {subtitle}
          </p>
        )}
      </div>
      {children}
    </div>
  );
}
