import { DateTime } from "luxon";
import { dateRegex } from "../settings";
import * as yup from "yup";

export const utcIsoDateString = yup
  .string()
  .test("validated iso string", "invalid date", function (value) {
    if (value) return DateTime.fromISO(value).isValid;
    return true;
  })
  .transform(function (value) {
    if (value) {
      return DateTime.fromISO(value).toUTC().toISO({
        includeOffset: true,
      });
    }
    return value;
  });

export const timeStringSchema = yup
  .string()
  .test("time", "invalid time", (value) => {
    if (value) {
      const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
      return regex.test(value);
    }
    return true;
  });

export default utcIsoDateString;

export const simpleDateSchema = yup.string().test((value) => {
  if (!value) return true;
  return dateRegex.test(value);
});
