import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { createContext, useContext, useState } from "react";
import { Card } from "./ui/card";

const CollapsableContext = createContext({ opened: false, onToggle: () => {} });

const CollapsableProvider = ({
  children,
  defaultOpened = false,
}: {
  children?: any;
  defaultOpened?: boolean;
}) => {
  const [opened, setOpened] = useState(!!defaultOpened);
  const onToggle = () => setOpened(!opened);

  return (
    <CollapsableContext.Provider value={{ opened, onToggle }}>
      {children}
    </CollapsableContext.Provider>
  );
};

export default function Collapsable({
  defaultOpened = undefined,
  children = null,
}: {
  defaultOpened?: boolean;
  children?: any;
}) {
  return (
    <CollapsableProvider defaultOpened={defaultOpened}>
      <Card>{children}</Card>
    </CollapsableProvider>
  );
}

const CollapsableBody = ({ children }: { children?: any }) => {
  const { opened } = useContext(CollapsableContext);
  if (!opened) return null;
  return children;
};

const CollapsableHeader = ({
  title = null,
  desc = null,
  className = null,
  titleClassName = null,
  Icon = null,
  Action = null,
  ActionIcon = null,
  onActionClick = null,
  forceAction = false,
  ...props
}: {
  title?: any;
  desc?: any;
  className?: string;
  titleClassName?: string;
  Icon?: any;
  Action?: any;
  ActionIcon?: any;
  onActionClick?: () => void;
  forceAction?: boolean;
}) => {
  const { opened, onToggle } = useContext(CollapsableContext);
  const hasActionIcon = !!ActionIcon && !!onActionClick;
  const hasCustomAction = !hasActionIcon && !!Action;
  return (
    <div
      {...props}
      className={clsx(
        "flex flex-row items-center justify-between cursor-pointer px-4",
        className
      )}
      onClick={onToggle}
    >
      <div className="group flex flex-row items-center flex-grow py-3 select-none gap-2">
        {!!Icon && (
          <Icon className="w-5 h-5 stroke-disabled group-hover:hidden" />
        )}
        {!opened ? (
          <ChevronDownIcon className="w-5 h-5 hidden group-hover:stroke-current group-hover:block" />
        ) : (
          <XMarkIcon className="w-5 h-5 hidden group-hover:stroke-current group-hover:block" />
        )}
        <h6 className={clsx("font-medium text-base", titleClassName)}>
          {title}
        </h6>
        {!!desc && (
          <span className="text-muted-foreground font-normal">{desc}</span>
        )}{" "}
      </div>
      <div className="flex flex-row items-center py-3 relative flex-shrink">
        {(opened || forceAction) &&
          (hasActionIcon ? (
            <ActionIcon
              onClick={(e) => {
                e.stopPropagation();
                onActionClick?.();
              }}
              className="w-5 h-5 mx-2 stroke-disabled hover:stroke-current cursor-pointer"
            />
          ) : hasCustomAction ? (
            <div className="">{Action}</div>
          ) : null)}
      </div>
    </div>
  );
};

Collapsable.Header = CollapsableHeader;
Collapsable.Body = CollapsableBody;
