import * as yup from "yup";
import { scheduleScheme } from "./Schedule";
import cuid from "cuid";
import { sessionTypesArraySchema } from "./SessionTypes";

export const collectionGroupItemSchema = yup.object({
  id: yup.string().required(),
  soldOut: yup.boolean().default(false),
  schedule: yup.array().of(scheduleScheme).max(25).default(null).nullable(), // @todo: remove from item;
});

export const collectionGroupSchema = yup
  .object({
    id: yup.string().default(cuid),
    name: yup.string().default("").min(2),
    desc: yup.string().default("").nullable(),
    items: yup.array().of(collectionGroupItemSchema).defined().default([]),
    schedule: yup.array().of(scheduleScheme).max(25).default(null).nullable(), // @todo: remove from item;
    color: yup
      .string()
      .nullable()
      .optional()
      .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
  })
  .required();

export const collectionSchema = yup
  .object({
    id: yup.string().notRequired(),
    tempId: yup.string().default(cuid),
    name: yup.string().default("").min(2),
    displayName: yup.string().default("").notRequired(),
    sessionTypes: sessionTypesArraySchema,
    desc: yup.string().default("").notRequired(),
    notes: yup.string().default("").notRequired(),
    type: yup.string().default("menu").oneOf(["menu"]).notRequired(),
    active: yup.boolean().default(true),
    groups: yup.array().of(collectionGroupSchema),
    schedule: yup
      .array()
      .of(scheduleScheme)
      .max(25)
      .notRequired()
      .default(null),
    i: yup.number().notRequired(),
    createdAt: yup.date().notRequired(),
    updatedAt: yup.date().notRequired(),
  })
  .required();

export type TCollection = yup.InferType<typeof collectionSchema>;

export type TCollectionGroup = yup.InferType<typeof collectionGroupSchema>;

export type TCollectionGroupItem = yup.InferType<
  typeof collectionGroupItemSchema
>;
