import {
  discountType,
  OrderItemStatus,
  SessionState,
  SessionTypes,
  SessionUpdateActions,
  UnitType,
} from "@gethere/common/enums";
import {
  CONSUMER_ACTIVE_SESSION_STATES,
  SYNCED_SESSION_STATES,
} from "@gethere/common/settings";
import { CheckType } from "@gethere/common/types";
import sleep from "@gethere/common/utils/sleep";
import { TOrderItem } from "@gethere/common/yup/OrderItem";
import { TPromo } from "@gethere/common/yup/Promo";
import {
  TSession,
  TSessionDiscount,
  TSessionDocument,
} from "@gethere/common/yup/Session";
import {
  CheckIcon,
  QuestionMarkCircleIcon,
  ReceiptRefundIcon,
  SignalIcon,
  SparklesIcon,
  TagIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import loadable from "@loadable/component";
import clsx from "clsx";
import { toLower } from "lodash";
import { useCallback, useContext, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { FormattedTime, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import colors from "tailwindcss/colors";
import Alert from "../components/Alert";
import Btn from "../components/Btn";
import Indicator from "../components/Indicator";
import Input, { InputAdornment } from "../components/Input";
import LoadingScreen from "../components/LoadingScreen";
import { useSession } from "../contexts/SessionContext";
import { useSessionDrawer } from "../contexts/SessionDrawerContext";
import SessionItemModalContext from "../contexts/SessionItemModalContext";
import { useSocket } from "../contexts/SocketProvider";
import { SubmitOrderAndPay } from "../contexts/SubmitOrderAndPay";
import { useUser } from "../contexts/UserContext";
import { useAppSelector } from "../state/hooks";
import {
  addPromoCode,
  resetSession,
  sessionCurrencySelector,
  sessionPlaceSelector,
  sessionSelector,
  sessionUpdated,
  toggleSessionServiceFee,
} from "../state/reducers/session";
import store, { RootState } from "../state/store";
import client from "../utils/client";
import errorify from "../utils/errorify";
import useToggle from "../utils/useToggle";
import SessionMetaFields from "./SessionMetaFields";
import SessionTips from "./SessionTips";
import SessionTransactions from "./SessionTransactions";
import SubmitPaymentsOptions from "./SubmitPaymentsOptions";
import { BottomSheet } from "react-spring-bottom-sheet";

const SessionDelivery = loadable(
  () => import(/* webpackChunkName: "SessionDelivery" */ "./SessionDelivery"),
  {
    fallback: <LoadingScreen />,
  }
);

const ToggleServiceCharge = () => {
  const {
    isOpen: openedServiceFee,
    onClose: closeServiceFee,
    onOpen: openServiceFee,
  } = useToggle<boolean>(false);

  const dispatch = useDispatch();

  const handleReject = useCallback(() => {
    dispatch(toggleSessionServiceFee(true));
    closeServiceFee();
  }, [closeServiceFee, dispatch]);

  const handleAccept = useCallback(() => {
    dispatch(toggleSessionServiceFee(false));
    closeServiceFee();
  }, [closeServiceFee, dispatch]);

  return (
    <>
      <QuestionMarkCircleIcon
        onClick={openServiceFee}
        className="cursor-pointer w-5 h-5 place-self-center stroke-disabled"
      />
      <BottomSheet open={Boolean(openedServiceFee)} onDismiss={closeServiceFee}>
        <div className="p-5 max-w-lg mx-auto grid grid-cols-1 gap-5">
          <h5 className="font-medium text-2xl mb-2">
            We appreciate your support
          </h5>
          <p className="text-base mb-5">
            All service charges, tips, and gratitude are paid to our staff, who
            currently rely on them. Thank you for supporting them.
          </p>
          <div className="grid grid-cols-2 gap-5">
            <Btn onClick={handleReject} variant="light" color="text">
              Not today
            </Btn>
            <Btn
              onClick={handleAccept}
              autoFocus
              color="primary"
              variant="contained"
            >
              Sure! my pleasure 😊
            </Btn>
          </div>
        </div>
      </BottomSheet>
    </>
  );
};

const BFR = ({ children, className = null, ...props }) => {
  return (
    <div
      {...props}
      className={clsx("flex flex-row items-center justify-between", className)}
    >
      {children}
    </div>
  );
};

BFR.Label = ({ children, className = null }) => {
  return (
    <div
      className={clsx(
        "flex flex-row items-center justify-between text-sm",
        className
      )}
    >
      {children}
    </div>
  );
};

BFR.Value = ({ children, className = null }) => {
  return (
    <div
      className={clsx(
        "flex flex-row items-center justify-between text-sm pl-3",
        className
      )}
    >
      {children}
    </div>
  );
};

const ServiceFeeRow = ({
  toggleable = false,
  percent,
  rejected,
  currency,
  value,
}) => {
  const intl = useIntl();
  return (
    <BFR>
      <BFR.Label>
        {intl.formatMessage({
          id: "session_consumer_service_fee_title",
        })}
        {`${
          !isNaN(percent) && !rejected
            ? " " +
              intl.formatNumber(percent, {
                style: "percent",
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })
            : ""
        }`}
      </BFR.Label>
      <BFR.Value>
        {toggleable && (
          <span className="mr-2">
            <ToggleServiceCharge />
          </span>
        )}
        {intl.formatNumber(value, {
          style: "currency",
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}
      </BFR.Value>
    </BFR>
  );
};

const TipsRow = ({ value, currency }) => {
  const intl = useIntl();
  return (
    <BFR>
      <BFR.Label>
        {intl.formatMessage({
          id: "session_bill_tip",
        })}
      </BFR.Label>
      <BFR.Value>
        {intl.formatNumber(value, {
          style: "currency",
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}
      </BFR.Value>
    </BFR>
  );
};

const DeliveryCheckRow = ({
  check,
  minSubtotal,
  settings,
  state,
  currency,
}) => {
  const intl = useIntl();
  const diff =
    minSubtotal && minSubtotal > check.subtotal
      ? minSubtotal - check.subtotal
      : 0;

  return (
    <div>
      {diff > 0 && (
        <BFR>
          <BFR.Label>
            <div className="flex flex-col">
              <span>{intl.formatMessage({ id: "delivery_fee_extra" })}</span>
              {state === SessionState.draft && (
                <span className="text-muted-foreground">
                  {intl.formatMessage(
                    { id: "min_order_insentive_value" },
                    {
                      value: intl.formatNumber(settings?.minOrderSubtotal, {
                        style: "currency",
                        currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      }),
                    }
                  )}
                </span>
              )}
            </div>
          </BFR.Label>
          <BFR.Value>
            {intl.formatNumber(diff, {
              style: "currency",
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </BFR.Value>
        </BFR>
      )}
      <BFR>
        <BFR.Label>{intl.formatMessage({ id: "delivery_fee" })}</BFR.Label>
        <BFR.Value>
          {check.delivery === 0
            ? intl.formatMessage({
                id: "free",
                defaultMessage: "Free",
              })
            : intl.formatNumber(
                diff > 0 ? check.delivery - diff : check.delivery,
                {
                  style: "currency",
                  currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }
              )}
        </BFR.Value>
      </BFR>
    </div>
  );
};

const TaxCheckRow = ({ check, taxes, pendings, currency }) => {
  const intl = useIntl();
  return check.taxes?.map((t) => {
    const { name, value } = t;

    const tax = taxes?.find?.((t) => t?.name === name);

    if (pendings && value === 0) return null;

    return (
      <BFR key={name}>
        <BFR.Label className="gap-2">
          <span>
            {tax?.included
              ? intl.formatMessage({ id: "including_x" }, { x: name })
              : name}
          </span>

          {!!tax?.rate && (
            <span className="text-muted-foreground">
              {" " + intl.formatNumber(tax.rate, { style: "percent" })}
            </span>
          )}
        </BFR.Label>
        <BFR.Value>
          {intl.formatNumber(value, {
            style: "currency",
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </BFR.Value>
      </BFR>
    );
  });
};
const DiscountsCheckRow = ({ check, discounts, pendings, currency }) => {
  const intl = useIntl();
  const promotions = useAppSelector((s) => s.session?.entities?.promotions);

  return discounts?.map((x: TSessionDiscount) => {
    const promo = promotions?.[x.promoId];

    // if (pendings && value === 0) return null;

    const name = promo
      ? promo.displayName || promo.name
      : intl.formatMessage({
          id: "discount",
          defaultMessage: "Discount",
        });

    const value = check.discounts?.[x.id];
    if (!value) return null;

    return (
      <BFR key={x.id || x.promoId} className="text-rose-500 dark:text-rose-300">
        <BFR.Label className="gap-2">
          <span className="capitalize font-medium">{name}</span>
          {!promo && x.type === discountType.PERCENT && (
            <span className="text-muted-foreground">
              {" " + intl.formatNumber(x.amount, { style: "percent" })}
            </span>
          )}
        </BFR.Label>
        <BFR.Value className="font-medium">
          {intl.formatNumber(-value, {
            style: "currency",
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </BFR.Value>
      </BFR>
    );
  });
};

const SessionCheckFooter = ({
  session,
  check,
  currency,
  pendings = false,
  blockPayment = false,
}: {
  session: TSession;
  check: CheckType;
  currency: string;
  pendings?: boolean;
  blockPayment?: boolean;
}) => {
  const intl = useIntl();
  const { status } = useSession();

  if (!check) return null;

  const balance = check.balance + check.authorized;

  const left = balance < 0;

  const serviceCharge = !isNaN(session.settings?.serviceCharge?.percent);

  const show = {
    tipSum: check.total > 0 && (session.settings?.tips || check.tips > 0),
    tipController: pendings && session.settings?.tips && check.subtotal > 0,
    serviceCharge: check.total > 0 && serviceCharge,
    discounts:
      Array.isArray(session.discounts) &&
      session.discounts.length > 0 &&
      check.discounted > 0,
    tax: Array.isArray(check.taxes) && check.total > 0,
    total: check.amount > 0,
    delivery:
      session.type === SessionTypes.delivery &&
      ((pendings && session.state === SessionState.draft) ||
        (!pendings && session.state !== SessionState.draft)),
    balance: !pendings && balance !== 0 && session.state !== SessionState.draft,
    //check.balance !== 0 || session.state === SessionState.complete
  };

  return (
    <div className="grid grid-cols-1 gap-3">
      {pendings && <PromoCodeController />}
      {show.discounts && (
        <DiscountsCheckRow
          currency={currency}
          check={check}
          discounts={session.discounts}
          pendings={pendings}
        />
      )}
      {show.serviceCharge && (
        <ServiceFeeRow
          value={check.serviceCharge}
          currency={currency}
          percent={session.settings?.serviceCharge?.percent}
          rejected={session.meta.rejectedServiceCharge}
          toggleable={
            pendings && serviceCharge && session.state === SessionState.draft
          }
        />
      )}
      {show.tipSum && <TipsRow value={check.tips} currency={currency} />}
      {show.tipController && <SessionTips currency={currency} />}
      {show.delivery && (
        <DeliveryCheckRow
          state={session.state}
          currency={currency}
          settings={session.settings?.delivery}
          minSubtotal={session.settings?.delivery?.minOrderSubtotal}
          check={check.delivery}
        />
      )}
      {show.tax && (
        <TaxCheckRow
          currency={currency}
          check={check}
          taxes={session.settings.taxes}
          pendings={pendings}
        />
      )}
      {show.total && (
        <BFR>
          <BFR.Label className="text-xl font-medium capitalize">
            {intl.formatMessage({
              id: pendings
                ? "session_bill_pending_title"
                : "session_bill_total",
            })}
          </BFR.Label>
          <BFR.Value className="text-xl font-medium">
            {intl.formatNumber(
              pendings
                ? status?.leftPending >= 0
                  ? Math.abs(status?.leftPending)
                  : 0
                : check.total,
              {
                style: "currency",
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }
            )}
          </BFR.Value>
        </BFR>
      )}
      {!pendings && <SessionTransactions currency={currency} />}
      {!pendings && session.transactions?.length > 1 && check.charged > 0 && (
        <BFR>
          <BFR.Label>
            {intl.messages["session_bill_transactions_total"]}
          </BFR.Label>
          <BFR.Value>
            {intl.formatNumber(check.charged, {
              style: "currency",
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </BFR.Value>
        </BFR>
      )}
      {show.balance && (
        <BFR className="font-medium">
          <BFR.Label className="text-lg">
            {intl.formatMessage({
              id: left
                ? "session_bill_balance_left"
                : "session_bill_balance_change",
              defaultMessage: "Total",
            })}
          </BFR.Label>
          <BFR.Value className="text-lg">
            {intl.formatNumber(Math.abs(check.balance + check.authorized), {
              style: "currency",
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </BFR.Value>
        </BFR>
      )}
    </div>
  );
};

const BillableOrderItems = ({ currency, session, userId }) => {
  const billable = session.orderItems.filter(
    (oi) =>
      ![OrderItemStatus.REMOVED, OrderItemStatus.PENDING].includes(oi.status)
  );

  return billable.map((oi, oii) => (
    <BillOrderItem
      key={String(oii)}
      orderItem={oi}
      currency={currency}
      isLiable={userId === oi.liableId || !oi.liableId}
    />
  ));
};

const promoCodeSelector = (p: TPromo) => p?.requires?.code;

const InsertPromo = ({ promos }: { promos: TPromo[] }) => {
  const insert = useToggle(false);
  const { user } = useUser();
  const ref = useRef<HTMLInputElement>();
  const [value, setValue] = useState<string>("");
  const dispatch = useDispatch();

  const onApplyClick = (e) => {
    e.preventDefault();
    if (!value) {
      toast("No code been applied.");
      return insert.onClose();
    }

    toast.promise(
      (async () => {
        await sleep(700);

        ref.current?.focus?.();

        const promo = promos?.find?.(
          (p) => toLower(p.requires.code) === toLower(value)
        );

        if (!promo) {
          setValue("");
          throw new Error("not found");
        }

        dispatch(addPromoCode({ promoId: promo.id, by: user?.id }));
      })(),
      {
        loading: "Checking",
        success: "Code applied succefully!",
        error: "Code not exist",
      }
    );
  };

  if (!insert.isOpen)
    return (
      <div>
        <Btn
          size="xs"
          color="primary"
          variant="text"
          onClick={insert.onOpen}
          className="focus:outline-none focus:ring-0 active:outline-none active:ring-0 px-0 mx-0"
        >
          Have a promotion code?
        </Btn>
      </div>
    );

  return (
    <form onSubmit={onApplyClick}>
      <Input
        ref={ref}
        autoFocus={true}
        placeholder="Promo Code"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          if (!value) insert.onClose();
        }}
        end={
          <InputAdornment position="end" absolute className="mr-1">
            <Btn
              type="submit"
              size="xs"
              color="text"
              StartIcon={TagIcon}
              variant="contained"
              onClick={onApplyClick}
            >
              Apply Code
            </Btn>
          </InputAdornment>
        }
      />
    </form>
  );
};

const PromoCodeController = () => {
  const { status, submitable } = useSession();
  const enabled = useAppSelector((s) => sessionSelector(s)?.meta?.promos);
  const promoIndex = useAppSelector((s) => s.session.entities.promotions);
  const promos = Object.values(promoIndex || {});
  const codesExist = promos.filter(promoCodeSelector);

  const codesEnabled = Array.isArray(enabled)
    ? enabled.filter((sp) => !!promoCodeSelector(promoIndex?.[sp.promoId]))
    : [];

  // promo code enabled.
  if (codesEnabled.length > 0) {
    return null;
  }

  // have promo codes, but not enabled
  if (status.orderable && submitable && codesExist?.length > 0) {
    return <InsertPromo promos={codesExist} />;
  }

  // no promo code require codes exists, return null;
  return null;
};

const PendingsOrderItems = ({ currency, session, userId }) => {
  const { status, submitable } = useSession();
  const { isSigned } = useUser();
  const intl = useIntl();

  const pendings = session.orderItems.filter(
    (oi) => OrderItemStatus.PENDING === oi.status
  );

  return (
    <>
      <SessionMetaFields />
      {pendings.map((oi, oii) => (
        <BillOrderItem
          key={String(oii)}
          orderItem={oi}
          isLiable={userId === oi.liableId}
          currency={currency}
        />
      ))}
      <SessionCheckFooter
        pendings
        session={session}
        check={status.pendings}
        currency={currency}
      />
      {!status.hasPaymentSettings && (
        <Alert type="warning">
          {intl.formatMessage({
            id: "no_acceptance_available",
            defaultMessage:
              "The business does not accept payments at the moment.",
          })}
        </Alert>
      )}
      {submitable && isSigned && <SubmitOrderAndPay currency={currency} />}
    </>
  );
};

const SocketIcon = () => {
  const { connected } = useSocket();
  switch (connected) {
    case true:
      return <SignalIcon className="w-5 h-5 stroke-blue-500" />;
    case false:
      return null;
    case null:
      return <SignalIcon className="w-5 h-5 stroke-gray-500" />;
    default:
      return null;
  }
};

const SessionDocuments = ({ documents }) => {
  const intl = useIntl();
  if (Array.isArray(documents) && documents.length > 0) {
    return (
      <div className="space-y-2">
        {documents.map((doc: TSessionDocument) => {
          const link = (doc.attachments as any)?.[0] || "#";
          const today =
            doc.issuedAt &&
            new Date(doc.issuedAt).getDate() === new Date().getDate();
          return (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              key={doc.id}
              className="group flex flex-row items-center gap-5"
            >
              <ReceiptRefundIcon className="w-[20px] h-[20px] stroke-disabled" />
              <span className="w-full flex flex-row items-center justify-between">
                <span className="text-sm group-hover:underline duration-200">
                  {intl.formatMessage(
                    { id: "session_document_label" },
                    { type: doc.type, number: doc.identifier }
                  )}
                </span>
                <span className="text-muted-foreground text-xs opacity-0 group-hover:opacity-100 duration-200">
                  {[
                    !today && intl.formatDate(doc.issuedAt),
                    intl.formatTime(doc.issuedAt),
                  ]
                    .filter(Boolean)
                    .join(" ")}
                </span>
              </span>
            </a>
          );
        })}
      </div>
    );
  } else return null;
};

const EndSession = () => {
  const dispatch = useDispatch();
  const [{ loading, error }, setState] = useState({
    loading: false,
    error: null,
  });

  const { status } = useSession();

  const { ordered, balanced, temp } = useAppSelector((s) => {
    const session: TSession = sessionSelector(s);

    return {
      temp: session?.id.indexOf("@") !== 1,
      ordered: session?.check.total > 0,
      balanced: status.completable,
    };
  });

  const handleReset = useCallback(() => {
    const terminalId = sessionSelector(store.getState())?.terminalId;
    dispatch(resetSession());
    setTimeout(() => {
      window.location.href = String(document.location.href).replace(
        document.location.search,
        `?tId=${terminalId}&cb=${new Date().getTime()}`
      );
    }, 500);
  }, [dispatch]);

  const handleComplete = useCallback(async () => {
    try {
      setState({ loading: true, error: null });
      const id = store.getState().session.result;
      const response = await client.put(`/sessions/${id}`, {
        action: SessionUpdateActions.SESSION_USER_LEAVE,
      });
      dispatch(sessionUpdated(response.data));
    } catch (error) {
      setState({ loading: false, error: errorify(error).message });
    }
  }, [dispatch, setState]);

  // disabled
  const showCompleteOrder =
    false && ordered && balanced && !status.handoff && !temp;

  const showResetOrder =
    !showCompleteOrder && temp && !status.handoff && status.pendings.amount > 0;

  return (
    <>
      {!!error && (
        <Alert className="mb-5" type="danger">
          {error}
        </Alert>
      )}
      {showCompleteOrder && (
        <Btn
          size="base"
          fullWidth
          disabled={loading}
          onClick={handleComplete}
          color="text"
        >
          Complete Order
        </Btn>
      )}
      {showResetOrder && (
        <Btn
          size="sm"
          fullWidth
          variant="text"
          disabled={loading}
          color="text"
          onClick={handleReset}
        >
          Reset Order
        </Btn>
      )}
    </>
  );
};

const PlaceMessages = () => {
  const messages = useAppSelector(
    (s) => sessionPlaceSelector(s)?.settings?.messages
  );

  if (!messages || messages.length === 0) return null;

  return (
    <div className="grid grid-cols-1 gap-2 mb-5">
      {messages.map((m) => (
        <Alert type={m.typ || "info"}>{m.txt}</Alert>
      ))}
    </div>
  );
};

const SessionBill = () => {
  const intl = useIntl();
  const { type, status } = useSession();
  const drawer = useSessionDrawer();
  const { isSigned } = useUser();

  const { session, userId, currency, terminalName } = useSelector(
    (s: RootState) => {
      const session = sessionSelector(s) as TSession;
      return {
        session,
        terminalName:
          s.session.entities?.terminals?.[session?.terminalId]?.name,
        currency: sessionCurrencySelector(s),
        userId: s.user.result,
      };
    }
  );

  if (!session || !status) return null;

  const isActiveSession = CONSUMER_ACTIVE_SESSION_STATES.includes(
    session?.state as SessionState
  );

  const socketShouldSynced =
    SYNCED_SESSION_STATES.includes(session?.state as SessionState) ||
    session.meta.shouldSocket;

  const hasNonRemovedItem = Boolean(
    session?.orderItems.find((oi) => oi.status !== OrderItemStatus.REMOVED)
  );

  const isActiveOrderEmpty = !hasNonRemovedItem && isActiveSession;

  let title = session.orderId
    ? `Order ${session.orderId}, ${terminalName}`
    : terminalName;

  // ? intl.formatMessage(
  //     { id: "order_with_id", defaultMessage: "Order #{orderId}" },
  //     { orderId: session.orderId }
  //   )
  // : intl.formatMessage({ id: "session_bill_title" });

  let description = null;

  if (isActiveOrderEmpty) {
    title = intl.formatMessage({
      id: "session_bill_empty_title",
      defaultMessage: "Empty Order",
    });
    description = intl.messages["session_bill_empty_description"];
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center justify-between mb-5 w-full">
        <div className="flex flex-row items-center w-full">
          <div>
            <h5 className="font-medium text-xl">{title}</h5>
          </div>
        </div>
        <div className="flex items-center flex-row">
          {socketShouldSynced && <SocketIcon />}
          {!isActiveOrderEmpty && session.state !== SessionState.draft && (
            <Indicator
              Icon={() => null}
              label={intl.formatMessage(
                { id: "session_state_selector" },
                { state: session.state }
              )}
            />
          )}
          {!status.handoff && drawer.isOpen && (
            <div
              className="h-10 w-10 flex relative cursor-pointer self-center justify-end items-center"
              onClick={drawer.onClose}
            >
              <XMarkIcon className="stroke-gray-600 dark:stroke-gray-300 hover:stroke-gray-800 dark:hover:stroke-gray-100 h-5 w-5" />
            </div>
          )}
        </div>
      </div>
      <PlaceMessages />

      {status.handoff && session.type === SessionTypes.pickup && (
        <div className="text-center my-14">
          <h4 className="text-2xl font-medium">Thank you for your order!</h4>
          {session.state === SessionState.accepted && (
            <p>We accepted your order and it will be ready soon.</p>
          )}
          {session.state === SessionState.active && (
            <p>We are currently preparing your order.</p>
          )}
          {session.state === SessionState.ready && (
            <p>Your order is ready to collect!</p>
          )}
          {session.state === SessionState.complete && (
            <p>
              We hope you liked it, and looking forward to serving you again!
            </p>
          )}
          {session.meta.targetAt &&
            [
              SessionState.approved,
              SessionState.accepted,
              SessionState.active,
            ].includes(session.state) && (
              <p className="font-medium text-primary-700 dark:text-primary-300">
                Your order expected to be ready to collect at{" "}
                <FormattedTime value={session.meta.targetAt} />
              </p>
            )}
        </div>
      )}
      {type === SessionTypes.delivery && <SessionDelivery />}

      <div className="grid grid-cols-1 gap-3">
        {Boolean(description) && (
          <p className="mt-2 text-sm text-muted-foreground grid grid-cols-1 gap-3">
            {description}
          </p>
        )}
        <PendingsOrderItems
          session={session}
          currency={currency}
          userId={userId}
        />
        <BillableOrderItems
          userId={userId}
          session={session}
          currency={currency}
        />
        <SessionCheckFooter
          session={session}
          check={session.check}
          currency={currency}
        />
        {isSigned ? (
          status?.leftPending ? null : (
            <SubmitPaymentsOptions />
          )
        ) : (
          <Btn>Pay</Btn>
        )}
        <SessionDocuments documents={session.documents} />
        <EndSession />
      </div>
    </div>
  );
};

const getOrderItemStatusIcon = (status) => {
  let Icon,
    color = "stroke-disabled";

  switch (status) {
    case OrderItemStatus.CANCELED:
      Icon = XMarkIcon;
      color = "stroke-red-700";
      break;
    case OrderItemStatus.REMOVED: {
      Icon = XMarkIcon;
      break;
    }
    case OrderItemStatus.APPROVED: {
      Icon = CheckIcon;
      break;
    }
    case OrderItemStatus.ACCEPTED: {
      Icon = CheckIcon;
      color = "stroke-red-700";
      break;
    }
    case OrderItemStatus.SUPPLIED: {
      Icon = CheckIcon;
      color = colors.blue[700];
      break;
    }
    case OrderItemStatus.PENDING: {
      Icon = SparklesIcon;
      color = colors.orange[600];
      break;
    }
    case OrderItemStatus.REJECTED: {
      Icon = XCircleIcon;
      color = colors.slate[300];
      break;
    }
    default:
      Icon = QuestionMarkCircleIcon;
  }
  return { Icon, color };
};

const BillOrderItem = ({
  orderItem,
  currency,
  isLiable = false,
}: {
  orderItem: TOrderItem;
  currency: string;
  isLiable: boolean;
}) => {
  const intl = useIntl();
  const { openOrderItemModal } = useContext(SessionItemModalContext);
  const { item } = useSelector((s: RootState) => ({
    item: s.session.entities.items[orderItem.itemId],
  }));

  const { Icon, color } = getOrderItemStatusIcon(orderItem.status);

  const id = orderItem.id || orderItem?.meta?.localId;

  const handleSelect = useCallback(() => {
    openOrderItemModal(id);
    // eslint-disable-next-line
  }, [id]);

  return (
    <BFR
      onClick={handleSelect}
      className={clsx(!isLiable && "opacity-70", "group cursor-pointer")}
    >
      <BFR.Label className="text-base">
        <div className="relative">
          <Icon className={clsx("w-4 h-4 mr-3", color)} />
        </div>
        <div className="font-medium flex flex-row items-center">
          {item?.name || "Unkown Item"}
          {UnitType.unit && orderItem.amount > 1 && (
            <span className="text-[12px] ml-3 text-muted-foreground">
              ✕{orderItem.amount}
            </span>
          )}
        </div>
      </BFR.Label>
      <BFR.Value className="text-base">
        {intl.formatNumber(orderItem.cost, {
          style: "currency",
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}
      </BFR.Value>
    </BFR>
  );
};

export default SessionBill;
