import clsx from "clsx";

const OptionalLabel = () => (
  <span className="text-xs text-gray-400 dark:text-gray-300 ltr:ml-2 rtl:mr-2">
    Optional
  </span>
);

const InputLabel = ({
  name,
  error = false,
  children,
  optional = false,
  disabled = false,
  className = null,
}) => {
  return (
    <label
      htmlFor={name}
      className={clsx(
        !disabled && "cursor-pointer",
        "flex flex-row items-center text-sm font-medium capitalize",
        error
          ? "text-red-700 dark:text-red-200"
          : "text-gray-700 dark:text-gray-200",
        className
      )}
    >
      {children}
      {optional && <OptionalLabel />}
    </label>
  );
};

export default InputLabel;
