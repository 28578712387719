import localeToGoogleLang from "@gethere/common/utils/localeToGoogleLang";
import { LoadScript } from "@react-google-maps/api";
import { captureException } from "@sentry/react";
import { createContext, useCallback, useMemo, useState } from "react";
import env from "./env";
import store from "../state/store";

const googleMapsApiKey = env.development
  ? "AIzaSyDNY7b5gjM9VVU0T2d_anOW1s1BwXIWxYM"
  : "AIzaSyDE4cww-Pv7xAE5-IlyTTQmOdAB6bod5D0";

const EmptyComonent: JSX.Element = null;

export const mapServices = {
  geocode: null,
  autoComplete: null,
};

const googleLibs: any[] = ["places", "geometry"];

type GoogleMapsState = {
  triggered: boolean;
  isLoaded: boolean;
  loadError?: string;
  language: string;
};

export const GoogleMapsContext = createContext<{
  googleMaps: GoogleMapsState;
  trigger: () => void;
}>({
  googleMaps: {
    triggered: false,
    isLoaded: false,
    loadError: null,
    language: "en",
  },
  trigger: () => {},
});

export const GoogleMapsProvider = ({
  children,
  libs = googleLibs,
  autoTrigger = false,
}) => {
  const language = useMemo(() => {
    const state = store.getState();
    return localeToGoogleLang(state.system.locale);
  }, []);

  const [state, setstate] = useState<GoogleMapsState>({
    isLoaded: Boolean(mapServices.geocode),
    triggered: Boolean(autoTrigger),
    loadError: null,
    language,
  });

  const onLoad = useCallback(() => {
    if (!mapServices.autoComplete) {
      mapServices.autoComplete = new google.maps.places.AutocompleteService();
    }

    // load place details service
    if (typeof mapServices.geocode !== "function") {
      mapServices.geocode = new google.maps.Geocoder();
    }

    setstate((s) => ({ ...s, loadError: null, isLoaded: true }));
  }, [setstate]);

  const onError = useCallback(
    (error) => {
      captureException(error);
      if (error?.message === "google api is already presented") {
        onLoad();
      } else {
        setstate((s) => ({ ...s, loadError: error, isLoaded: false }));
      }
    },
    [setstate, onLoad]
  );

  const trigger = useCallback(() => {
    setstate((s) => (s.triggered ? s : { ...s, triggered: true }));
  }, [setstate]);

  return (
    <GoogleMapsContext.Provider
      value={{
        googleMaps: state,
        trigger,
      }}
    >
      {state.triggered && (
        <LoadScript
          loadingElement={EmptyComonent}
          googleMapsApiKey={googleMapsApiKey}
          libraries={libs}
          language={language}
          onLoad={onLoad}
          onError={onError}
        />
      )}
      {children}
    </GoogleMapsContext.Provider>
  );
};
