import { TBooking } from "@gethere/common/yup/Booking";
import { TPlace } from "@gethere/common/yup/Place";
import { CalendarIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Alert from "../components/Alert";
import { Surface } from "../components/Surface";
import client from "../utils/client";
import errorify from "../utils/errorify";
import ProfileListItemSkeleton from "./ProfileListItemSkeleton";

const BookingListItem = ({
  booking,
  place,
}: {
  booking: TBooking;
  place: TPlace;
}) => {
  const intl = useIntl();

  if (!booking) return null;

  const { startAt, endAt, status } = booking;

  const times = {
    startAt: DateTime.fromISO(startAt),
    endAt: DateTime.fromISO(endAt),
  };

  const diff = times
    ? Math.round(times.startAt.toJSDate().getTime() / 1000 / 60 / 60 / 24)
    : null;

  const relative =
    diff && Math.abs(diff) <= 1
      ? intl.formatRelativeTime(diff, "day", { numeric: "auto" })
      : null;

  const label = (
    relative
      ? [
          place?.name,
          relative,
          intl.formatDate(times.startAt.toJSDate(), { dateStyle: "short" }),
          intl.formatTime(times.startAt.toJSDate(), { timeStyle: "short" }),
        ]
      : [
          place?.name,
          intl.formatDate(times.startAt.toJSDate(), { dateStyle: "short" }),
          intl.formatTime(times.startAt.toJSDate(), { timeStyle: "short" }),
        ]
  ).join(", ");

  return (
    <Link
      to={`/b/${booking.id}`}
      className="p-3 hover:bg-gray-200 dark:hover:bg-gray-800 flex flex-row items-center justify-between group"
    >
      <div className="flex flex-row items-center">
        <div className="flex w-6 h-6 mr-3 rounded-full items-center justify-center">
          <CalendarIcon className="mx-auto w-6 h-6 opacity-50 group-hover:opacity-100" />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <h6 className="font-bold text-sm">{label}</h6>
            <span className="ml-2 bg-gray-100 w-fit dark:bg-gray-700 p-1 text-[10px] font-medium rounded">
              {status}
            </span>
          </div>
          {!!place?.meta?.address && (
            <span className="text-muted-foreground text-xs">
              {place?.meta?.address}
            </span>
          )}
        </div>
      </div>
      <ChevronRightIcon className="w-6 h-6 opacity-0 group-hover:opacity-100" />
    </Link>
  );
};

const UserBookings = () => {
  const [state, setstate] = useState<{
    loading: boolean;
    result: string[];
    error: string;
    entities: {
      places: Record<string, TPlace>;
      bookings: Record<string, TBooking>;
    };
  }>({
    loading: false,
    result: [],
    entities: { bookings: {}, places: {} },
    error: null,
  });

  const load = async () => {
    try {
      if (state.loading) return;
      setstate((s) => ({ ...s, loading: true, error: null }));
      const { data } = await client.get("/me/bookings");
      const { result, entities } = data;

      setstate((s) => ({
        ...s,
        loading: false,
        error: null,
        entities,
        result,
      }));
    } catch (error) {
      setstate((s) => ({
        ...s,
        loading: false,
        error: errorify(error)?.message,
      }));
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (!state.loading && state.result?.length === 0)
    return <Alert type="info">No reservations found</Alert>;

  return (
    <Surface>
      <Surface.Content disablePadding>
        <div className="divide-y dark:divide-gray-800">
          {state.loading
            ? Array(5)
                .fill(null)
                .map((x, i) => (
                  <ProfileListItemSkeleton
                    Icon={CalendarIcon}
                    key={String(i)}
                  />
                ))
            : state.result.map((id) => {
                const booking = state.entities.bookings[id];
                const place = state.entities.places[booking.placeId];
                return (
                  <BookingListItem key={id} booking={booking} place={place} />
                );
              })}
        </div>
      </Surface.Content>
    </Surface>
  );
};

export default UserBookings;
