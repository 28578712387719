import { staticImageBuilder } from "@gethere/common/utilities";
import { TImage } from "@gethere/common/yup/Image";
import { ImgHTMLAttributes, useMemo } from "react";
import LazyLoad from "react-lazy-load";

export type PictureProps = {
  srcset: TImage;
  sourceHeight?: number;
  sourceWidth?: number;
} & ImgHTMLAttributes<any>;

export const Picture = ({
  srcset,
  sourceHeight,
  sourceWidth,
  ...props
}: PictureProps) => {
  const res = useMemo(() => {
    try {
      if (typeof srcset === "string" && srcset.length > 0) {
        return {
          original: staticImageBuilder(srcset, {
            width: sourceWidth,
            height: sourceHeight,
          }),
        };
      }

      if (typeof srcset === "object" && srcset.src?.length > 0) {
        return {
          original: staticImageBuilder(srcset.src, {
            width: sourceWidth,
            height: sourceHeight,
          }),
          webp:
            srcset.webp?.length > 0
              ? staticImageBuilder(srcset.webp, {
                  width: sourceWidth,
                  height: sourceHeight,
                })
              : null,
        };
      }

      if (srcset) return {};
    } catch (error) {
      console.log(error);
      return {};
    }
  }, [
    typeof srcset === "string" ? srcset : srcset?.src,
    sourceHeight,
    sourceWidth,
  ]);

  const { webp, original } = res || {};

  if (!original) return null;

  const { width, height, className, alt, style } = props;

  const imgProps = { width, height, alt, className, style };

  return (
    <picture>
      {!!webp && <source srcSet={webp} type="image/webp" {...imgProps} />}
      <img src={original} {...imgProps} />
    </picture>
  );
};

type LazyPictureProps = Omit<LazyLoad["props"], "children"> & PictureProps;

export function LazyPicture({ offset, ...props }: LazyPictureProps) {
  return (
    <LazyLoad height={props.height} offset={offset}>
      <Picture {...props} />
    </LazyLoad>
  );
}
