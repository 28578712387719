import { createContext, useContext, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { RawIntlProvider } from "react-intl";
import { useAppSelector } from "../state/hooks";
import { getIntl } from "../utils/intl.web";
import { localeLang } from "../utils/langUtil";
import { DEFAULT_TIMEZONE } from "@gethere/common/settings";

const AppIntlContext = createContext<{
  dir: "rtl" | "ltr";
  lang: string;
  locale: string;
  setTimezone: (timeZone: string) => void;
  timezone: string;
}>({
  setTimezone: () => {},
  dir: "ltr",
  lang: "en",
  locale: "en_GB",
  timezone: DEFAULT_TIMEZONE,
});

export const useAppIntl = () => useContext(AppIntlContext);

export const AppIntlProvider = ({ children }) => {
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  const rtl = useAppSelector((s) => s.system.rtl);
  const locale = useAppSelector((s) => s.system.locale);

  const lang = localeLang(locale);
  const dir = rtl ? "rtl" : "ltr";

  const intl = useMemo(() => getIntl(locale, timezone), [locale, timezone]);

  return (
    <AppIntlContext.Provider
      value={{
        dir,
        lang,
        locale,
        timezone,
        setTimezone,
      }}
    >
      <Helmet>
        <html lang={lang} dir={dir} />
      </Helmet>
      <RawIntlProvider value={intl} key="intl">
        {children}
      </RawIntlProvider>
    </AppIntlContext.Provider>
  );
};

export default AppIntlContext;
