import { createContext, useContext, useEffect, useState } from "react";
import { UserFields } from "./UserContext";

type DrawerScreens = "sign_in" | "bill" | "pay_custom" | "split_equally";

type SessionDrawerNavigationState<T extends DrawerScreens> = {
  name: T;
  params: DrawerScreenParams[T];
};

export type DrawerScreenParams = {
  bill: any;
  split_equally: any;
  pay_custom: any;
  sign_in: {
    fields?: UserFields[];
    next?: SessionDrawerNavigationState<any>;
  };
};

const SessionDrawerNavigationContext = createContext<
  SessionDrawerNavigationState<any> & {
    setScreen: <T extends DrawerScreens>(next: {
      name: T;
      params: DrawerScreenParams[T];
    }) => void;
  }
>({
  name: "bill",
  params: {} as any,
  setScreen: () => {},
});

export const useSessionDrawerNavigation = () =>
  useContext(SessionDrawerNavigationContext);

export const SessionDrawerNavigationProvider = ({ children }) => {
  const [{ name, params }, setState] = useState<
    SessionDrawerNavigationState<any>
  >({
    name: "bill",
    params: {},
  });

  const setScreen = <T extends DrawerScreens>(next: {
    name: T;
    params: DrawerScreenParams[T];
  }) => {
    setState(next);
  };

  return (
    <SessionDrawerNavigationContext.Provider
      value={{ name, params, setScreen }}
    >
      {children}
    </SessionDrawerNavigationContext.Provider>
  );
};

export const DrawerRedirect = ({
  screen,
}: {
  screen: SessionDrawerNavigationState<any>;
}) => {
  const nav = useSessionDrawerNavigation();
  useEffect(() => {
    nav.setScreen(screen);
  }, []);
  return null;
};

export default SessionDrawerNavigationContext;
