import { AxiosResponse } from "axios";
import { SharedClient } from "./Client";
import * as RestAPI from "./RestAPI";
import { NormalizedControlsEntity } from "./entities";
import { BookingUpdateActions, SessionUpdateActions } from "./enums";
import { HTTP_RECAPTCHA_HEADER } from "./settings";
import {
  BusinessEntitiesResponse,
  CreateMobileChallengeResult,
  DeviceAuthResponseBody,
  DeviceUserAuthResponseBody,
  MobileChallengeVerifyResult,
  PaymentIntentResponse,
  TClientConfiguration,
  UserAuthResponseBody,
} from "./types";
import {
  TBooking,
  TBookingCalendarRequest,
  TBookingCreate,
  TBookingUpdate,
  TBookingsUserSearch,
  TJoinWaitlistRequest,
} from "./yup/Booking";
import { PayloadForBookingAction } from "./yup/BookingUpdateActions";
import { TBusiness } from "./yup/Business";
import { THouseAccount } from "./yup/HouseAccount";
import { TImageObject } from "./yup/Image";
import { TItemBulkRequest } from "./yup/Item";
import {
  TAddPaymentRequestBody,
  TDeviceActionRequestBody,
} from "./yup/Requests";
import { TSession } from "./yup/Session";
import { TBusinessControl, TUserBusinessControl } from "./yup/User";
import {
  TMember,
  TMemberJoinForm,
  TMembershipSearchQuery,
  TRewardStep,
} from "./yup/Membership";
import { TReview, TReviewSearchQuery, TSubmitReviewBody } from "./yup/Review";
import { TPlace } from "./yup/Place";

export type BaseResponse = {
  status: boolean;
  error?: string;
};

type BookingResult = BaseResponse & { booking: TBooking };

class Api {
  private client: SharedClient;

  constructor(client: SharedClient) {
    this.client = client;
  }

  public me = {
    memberships: () => {
      return this.client.get<
        BaseResponse & { memberships: TMember[]; businesses: TBusiness[] }
      >("/me/memberships");
    },

    membership: (businessId: string) => {
      return this.client.get<
        BaseResponse & {
          business: TBusiness;
          membership: TMember;
          rewards: TRewardStep[];
        }
      >(`/me/memberships/${businessId}`);
    },

    membershipGooglePass: (businessId: string) => {
      return this.client.get<BaseResponse & { url: string }>(
        `/me/memberships/${businessId}/pass/google`
      );
    },
    membershipApplePass: (businessId: string) => {
      return this.client.get<any>(`/me/memberships/${businessId}/pass/apple`, {
        responseType: "arraybuffer",
      });
    },
  };

  public reviews = {
    create: ({
      values,
      reCapToken,
    }: {
      values: TSubmitReviewBody;
      reCapToken: string;
    }) => {
      const config = reCapToken
        ? { headers: { [HTTP_RECAPTCHA_HEADER]: reCapToken } }
        : {};

      return this.client.post<BaseResponse & { result: TReview }>(
        `/businesses/${values.businessId}/reviews`,
        values,
        config
      );
    },

    get: (businessId: string, reviewId: string) => {
      return this.client.get<BaseResponse & { result: TReview }>(
        `/businesses/${businessId}/reviews/${reviewId}`
      );
    },

    update: (
      businessId: string,
      reviewId: string,
      payload: Partial<TReview>
    ) => {
      return this.client.put<BaseResponse & { result: TReview }>(
        `/businesses/${businessId}/reviews/${reviewId}`,
        payload
      );
    },

    search: (businessId: string, query: Partial<TReviewSearchQuery>) => {
      return this.client.post<BaseResponse & { result: TReview[] }>(
        `/businesses/${businessId}/reviews/search`,
        query
      );
    },
  };

  public members = {
    policy: (businessId: string) => {
      return this.client.get<
        BaseResponse & {
          retentionDays: number;
          content: string;
          updatedAt: string;
        }
      >(`/businesses/${businessId}/members-policy`);
    },

    search: (businessId: string, query: Partial<TMembershipSearchQuery>) => {
      return this.client.post<BaseResponse & { result: TMember[] }>(
        `/businesses/${businessId}/members/search`,
        query
      );
    },

    create: ({
      values,
      reCapToken,
    }: {
      values: TMemberJoinForm;
      reCapToken: string;
    }) => {
      const config = reCapToken
        ? { headers: { [HTTP_RECAPTCHA_HEADER]: reCapToken } }
        : {};

      return this.client.post<BaseResponse & { result: TMember }>(
        `/businesses/${values.businessId}/members`,
        values,
        config
      );
    },

    get: (businessId: string, memberId: string) => {
      return this.client.get<
        BaseResponse & { result: TMember; rewards: TRewardStep }
      >(`/businesses/${businessId}/members/${memberId}`);
    },

    update: (
      businessId: string,
      memberId: string,
      payload: Partial<TMember>
    ) => {
      return this.client.put<BaseResponse & { result: TMember }>(
        `/businesses/${businessId}/members/${memberId}`,
        payload
      );
    },
  };

  public items = {
    getAll: ({ businessId }: { businessId: string }) =>
      this.client.get<
        any,
        AxiosResponse<RestAPI.Businesses.Items.GetAll.Response>
      >(`/businesses/${businessId}/items`),

    getById: ({ businessId, itemId }: { businessId: string; itemId: string }) =>
      this.client.get<
        any,
        AxiosResponse<RestAPI.Businesses.Items.GetById.Response>
      >(`/businesses/${businessId}/items/${itemId}`),

    updateById: ({
      businessId,
      item,
    }: {
      businessId: string;
      item: RestAPI.Businesses.Items.UpdateById.Request;
    }) =>
      this.client.put<
        any,
        AxiosResponse<RestAPI.Businesses.Items.GetById.Response>
      >(`/businesses/${businessId}/items/${item.id}`, item),

    create: ({
      businessId,
      item,
    }: {
      businessId: string;
      item: RestAPI.Businesses.Items.Create.Request;
    }) =>
      this.client.post<
        any,
        AxiosResponse<RestAPI.Businesses.Items.Create.Response>
      >(`/businesses/${businessId}/items`, item),
  };

  public modifiers = {
    getAll: ({ businessId }: { businessId: string }) =>
      this.client.get<
        any,
        AxiosResponse<RestAPI.Businesses.Modifiers.GetAll.Response>
      >(`/businesses/${businessId}/modifiers`),

    getById: ({ businessId, modId }: { businessId: string; modId: string }) =>
      this.client.get<
        any,
        AxiosResponse<RestAPI.Businesses.Modifiers.GetById.Response>
      >(`/businesses/${businessId}/modifiers/${modId}`),

    updateById: ({
      businessId,
      modifier,
    }: {
      businessId: string;
      modifier: RestAPI.Businesses.Modifiers.UpdateById.Request;
    }) =>
      this.client.put<
        any,
        AxiosResponse<RestAPI.Businesses.Modifiers.GetById.Response>
      >(`/businesses/${businessId}/modifiers/${modifier.id}`, modifier),

    create: ({
      businessId,
      modifier,
    }: {
      businessId: string;
      modifier: RestAPI.Businesses.Modifiers.Create.Request;
    }) =>
      this.client.post<
        any,
        AxiosResponse<RestAPI.Businesses.Modifiers.Create.Response>
      >(`/businesses/${businessId}/modifiers`, modifier),
  };

  public places = {
    get: ({ businessId, placeId }: { businessId: string; placeId: string }) =>
      this.client.get<RestAPI.Businesses.Places.Get.Response>(
        `/businesses/${businessId}/places/${placeId}`
      ),
    daily: {
      get: ({
        businessId,
        placeId,
        id,
      }: RestAPI.Businesses.Places.Daily.Get.Request) =>
        this.client.get<RestAPI.Businesses.Places.Daily.Get.Response>(
          `/businesses/${businessId}/places/${placeId}/dailys/${id}`
        ),
      search: ({
        businessId,
        placeId,
        lastId = null,
      }: RestAPI.Businesses.Places.Daily.Search.Request) => {
        return this.client.post<RestAPI.Businesses.Places.Daily.Search.Response>(
          `/businesses/${businessId}/places/${placeId}/dailys/search`,
          { lastId }
        );
      },
    },
  };

  public houseAccounts = {
    getAll: ({ businessId }: { businessId: string }) => {
      return this.client.get<RestAPI.Businesses.HouseAccounts.GetAll.Response>(
        `/businesses/${businessId}/house-accounts`
      );
    },
    getById: ({
      businessId,
      accountId,
    }: {
      businessId: string;
      accountId: string;
    }) => {
      return this.client.get<RestAPI.Businesses.HouseAccounts.GetById.Response>(
        `/businesses/${businessId}/house-accounts/${accountId}`
      );
    },
    create: ({
      businessId,
      payload,
    }: {
      businessId: string;
      payload: Partial<THouseAccount>;
    }) => {
      return this.client.post<RestAPI.Businesses.HouseAccounts.Create.Response>(
        `/businesses/${businessId}/house-accounts`,
        payload
      );
    },
    update: ({
      businessId,
      accountId,
      payload,
    }: {
      businessId: string;
      accountId: string;
      payload: Partial<THouseAccount>;
    }) => {
      return this.client.put<RestAPI.Businesses.HouseAccounts.UpdateById.Response>(
        `/businesses/${businessId}/house-accounts/${accountId}`,
        payload
      );
    },
  };

  public bookings = {
    getCalendar: ({
      placeId,
      businessId,
      ...query
    }: {
      placeId: string;
      businessId: string;
    } & TBookingCalendarRequest) => {
      return this.client.post<RestAPI.Businesses.Places.Bookings.Calendar.Response>(
        `/businesses/${businessId}/places/${placeId}/bookings/calendar`,
        query
      );
    },

    search: ({
      placeId,
      businessId,
      ...query
    }: {
      placeId: string;
      businessId: string;
    } & TBookingsUserSearch) => {
      return this.client.post<RestAPI.Businesses.Places.Bookings.Search.Response>(
        `/businesses/${businessId}/places/${placeId}/bookings/search`,
        query
      );
    },

    update: ({
      bookingId,
      ...body
    }: {
      bookingId: string;
    } & TBookingUpdate) => {
      return this.client.put<BookingResult>(`/bookings/${bookingId}`, body);
    },

    action: <T extends BookingUpdateActions>({
      bookingId,
      action,
      payload,
    }: {
      bookingId: string;
      action: T;
      payload: PayloadForBookingAction<T>;
    }) => {
      return this.client.post<BookingResult>(`/bookings/${bookingId}`, {
        action,
        payload,
      });
    },

    create: ({
      values,
      reCapToken,
    }: {
      values: TBookingCreate;
      reCapToken?: string;
    }) => {
      const config = reCapToken
        ? { headers: { [HTTP_RECAPTCHA_HEADER]: reCapToken } }
        : {};

      return this.client.post<BookingResult>("/bookings", values, config);
    },

    getById: (bookingId: string) => {
      return this.client.get<
        BookingResult & { place: TPlace & { business: TBusiness } }
      >(`/bookings/${bookingId}`);
    },
  };

  public waitlist = {
    join: ({
      values,
      reCapToken,
    }: {
      values: TJoinWaitlistRequest;
      reCapToken?: string;
    }) => {
      const config = reCapToken
        ? { headers: { [HTTP_RECAPTCHA_HEADER]: reCapToken } }
        : {};

      return this.client.post<BaseResponse & { booking: TBooking }>(
        "/bookings/waitlist",
        values,
        config
      );
    },
  };

  // business
  public getBusiness(businessId: string) {
    return this.client.get<BaseResponse & { business?: TBusiness }>(
      `/businesses/${businessId}`
    );
  }

  public updateBusiness(businessId: string, payload: Partial<TBusiness>) {
    return this.client.put<BaseResponse & { business?: TBusiness }>(
      `/businesses/${businessId}`,
      payload
    );
  }

  // controls
  public getControls(businessId: string) {
    return this.client.get<BaseResponse & NormalizedControlsEntity>(
      `/businesses/${businessId}/controls`
    );
  }

  public getControl(businessId: string, controlId: string) {
    return this.client.get<BaseResponse & { control?: TBusinessControl }>(
      `/businesses/${businessId}/controls/${controlId}`
    );
  }

  public createControl(businessId: string, payload: TUserBusinessControl) {
    return this.client.post<BaseResponse & { control?: TBusinessControl }>(
      `/businesses/${businessId}/controls`,
      payload
    );
  }

  public updateControl(
    businessId: string,
    controlId: string,
    payload: TUserBusinessControl
  ) {
    return this.client.put<BaseResponse & { control?: TBusinessControl }>(
      `/businesses/${businessId}/controls/${controlId}`,
      payload
    );
  }

  // auth
  public authGuest({ recaptcha }: { recaptcha: string }) {
    return this.client.post<UserAuthResponseBody>("/auth/consumer/guest", {
      recaptcha,
    });
  }

  public getConfiguration() {
    return this.client.get<TClientConfiguration>("/configuration");
  }

  public getBusinessEntities(businessId: string) {
    return this.client.get<BusinessEntitiesResponse>(
      `/businesses/${businessId}/entities`
    );
  }

  public authDeviceUser({ pin, userId }: { pin: string; userId: string }) {
    return this.client.post<DeviceUserAuthResponseBody>(`/auth/device/user`, {
      pin,
      userId,
    });
  }

  public authDeviceToken({ token }: { token: string }) {
    return this.client.post<DeviceAuthResponseBody>(`/auth/device/token`, {
      token,
    });
  }

  public createUserDeviceChallenge({ inputMobile }: { inputMobile: string }) {
    return this.client.post<
      BaseResponse & { result: CreateMobileChallengeResult }
    >(`/auth/device/user/mobile`, {
      inputMobile,
    });
  }

  public verifyUserDeviceChallenge({
    challengeId,
    code,
  }: {
    challengeId: string;
    code: string;
  }) {
    return this.client.post<
      BaseResponse & { result: MobileChallengeVerifyResult }
    >(`/auth/device/user/mobile/${challengeId}`, {
      code,
    });
  }

  public operatorStartTimeTrack({
    placeId,
    businessId,
  }: {
    placeId: string;
    businessId: string;
  }) {
    return this.client.post(
      `/businesses/${businessId}/places/${placeId}/timetracks/start`
    );
  }

  public operatorEndTimeTrack({
    placeId,
    businessId,
    trackId,
  }: {
    placeId: string;
    businessId: string;
    trackId: string;
  }) {
    return this.client.post(
      `/businesses/${businessId}/places/${placeId}/timetracks/${trackId}/end`
    );
  }

  public deviceAction(
    params: { businessId: string; placeId: string; deviceId: string },
    payload: TDeviceActionRequestBody
  ) {
    return this.client.post(
      `/businesses/${params.businessId}/places/${params.placeId}/devices/${params.deviceId}/actions`,
      payload
    );
  }

  public uploadImages(images: any[]) {
    return this.client.post<TImageObject[]>("/uploads/images", images);
  }

  public getSession(id: string) {
    return this.client.get<TSession>("/sessions/" + id);
  }

  public addPaymentMethod(params: TAddPaymentRequestBody) {
    return this.client.post<RestAPI.Me.PaymentMethods.Post.Response>(
      "/me/paymentmethods",
      params
    );
  }

  public getPlaceSessions(params: { businessId: string; placeId: string }) {
    return this.client.get<RestAPI.Businesses.Places.Sessions.Get.Response>(
      `/businesses/${params.businessId}/places/${params.placeId}/sessions`
    );
  }

  public getPlaceBooking(params: { businessId: string; placeId: string }) {
    return this.client.get<RestAPI.Businesses.Places.Bookings.Get.Response>(
      `/businesses/${params.businessId}/places/${params.placeId}/bookings`
    );
  }

  public getPlacesSwitchList(params: { businessId: string }) {
    return this.client.get<RestAPI.Businesses.Places.SwitchList.Response>(
      `/businesses/${params.businessId}/places/switchlist`
    );
  }

  public switchDevice(params: { deviceId: string; token?: string }) {
    return this.client.post<DeviceAuthResponseBody>(
      "/auth/device/switch/" + params.deviceId,
      { token: params.token }
    );
  }

  public getDeviceLoginToken(params: {
    businessId: string;
    placeId: string;
    deviceId: string;
  }) {
    return this.client.get<RestAPI.Businesses.Places.Device.LoginToken.Get.Response>(
      `/businesses/${params.businessId}/places/${params.placeId}/devices/${params.deviceId}/token`
    );
  }

  public getPlacePrintingQueue(params: {
    businessId: string;
    placeId: string;
    deviceId: string;
  }) {
    return this.client.get<RestAPI.Businesses.Places.PrintingQueue.Get.Response>(
      `/businesses/${params.businessId}/places/${params.placeId}/devices/${params.deviceId}/printingjobs`
    );
  }

  public bulkCreateItems(params: TItemBulkRequest) {
    return this.client.post<boolean>(
      `/businesses/${params.businessId}/items/bulk`,
      params
    );
  }

  public updateSession<Success, Body = RestAPI.Session.Generic.Request>(
    sessionId: string,
    payload: Body
  ) {
    return this.client.put<any, AxiosResponse<Success>, Body>(
      `/sessions/${sessionId}`,
      payload
    );
  }

  public sessionReplaceTerminals(
    sessionId: string,
    payload: RestAPI.Session.ReplaceTerminal.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.TERMINAL_REPLACE,
      payload,
    });
  }

  public sessionMetaUpdate(
    sessionId: string,
    payload: RestAPI.Session.UpdateMeta.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.SESSION_UPDATE,
      payload,
    });
  }

  public sessionSendOrderLink(
    sessionId: string,
    payload: RestAPI.Session.SendOrderLink.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.SEND_ORDER_LINK,
      payload,
    });
  }

  public sessionSync(payload: Partial<RestAPI.Session.Sync.Payload>) {
    return this.client.post<RestAPI.Session.Sync.Response>(
      "/sessions",
      payload
    );
  }

  public sessionUpdateDiscount(
    sessionId: string,
    payload: RestAPI.Session.Discount.Update.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.DISCOUNT_UPDATE,
      payload,
    });
  }

  public sessionAddDiscount(
    sessionId: string,
    payload: RestAPI.Session.Discount.Add.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.DISCOUNT_ADD,
      payload,
    });
  }

  public sessionUpdateTransaction(
    sessionId: string,
    payload: RestAPI.Session.Transaction.Update.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.TRANSACTION_UPDATE,
      payload,
    });
  }

  public sessionSyncTransaction(
    sessionId: string,
    payload: RestAPI.Session.Transaction.Update.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.TRANSACTION_SYNC,
      payload,
    });
  }

  public sessionCancelTransaction(
    sessionId: string,
    payload: RestAPI.Session.Transaction.Update.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.TRANSACTION_CANCEL,
      payload,
    });
  }

  public sessionAddTransaction(
    sessionId: string,
    payload: RestAPI.Session.Transaction.Add.Payload
  ) {
    return this.updateSession<RestAPI.Session.Transaction.Add.Response>(
      sessionId,
      {
        action: SessionUpdateActions.TRANSACTION_ADD,
        payload,
      }
    );
  }

  public sessionEventUpdate(
    sessionId: string,
    payload: RestAPI.Session.EventUpdate.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.EVENT_UPDATE,
      payload,
    });
  }

  public sessionUpdateTip(
    sessionId: string,
    payload: RestAPI.Session.Tip.Update.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.TIP_UPDATE,
      payload,
    });
  }

  public sessionAddTip(
    sessionId: string,
    payload: RestAPI.Session.Tip.Add.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.TIP_ADD,
      payload,
    });
  }

  public sessionVoid(
    sessionId: string,
    payload: RestAPI.Session.VoidSession.Payload
  ) {
    return this.updateSession<RestAPI.Session.VoidSession.Response>(sessionId, {
      action: SessionUpdateActions.VOID_SESSION,
      payload,
    });
  }

  public sessionTransferOrderItems(
    sessionId: string,
    payload: RestAPI.Session.OrderItems.Transfer.Payload
  ) {
    return this.updateSession<RestAPI.Sessions.Generic.Response>(sessionId, {
      action: SessionUpdateActions.SESSION_TRANSFER_ORDER_ITEMS,
      payload,
    });
  }

  public sessionUserFeedback(
    sessionId: string,
    payload: RestAPI.Session.UserFeedback.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.USER_FEEDBACK,
      payload,
    });
  }

  public sessionOperatorCreatePaymentIntent(
    sessionId: string,
    payload: RestAPI.Session.PaymentIntent.Create.Payload
  ) {
    return this.updateSession<{ paymentIntent: PaymentIntentResponse }>(
      sessionId,
      {
        action: SessionUpdateActions.OPERATOR_CREATE_PAYMENT_INTENT,
        payload,
      }
    );
  }

  public sessionUpdateOrderItem(
    sessionId: string,
    payload: RestAPI.Session.OrderItem.Update.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.ORDER_ITEM_UPDATE,
      payload,
    });
  }

  public sessionPaymentIntentSuccessed(
    sessionId: string,
    payload: RestAPI.Session.PaymentIntent.Success.Payload
  ) {
    return this.updateSession<RestAPI.Session.PaymentIntent.Success.Response>(
      sessionId,
      {
        action: SessionUpdateActions.PAYMENT_INTENT_SUCCESSED,
        payload,
      }
    );
  }

  public sessionCancelOrderItems(
    sessionId: string,
    payload: RestAPI.Session.OrderItem.Cancel.Payload
  ) {
    return this.updateSession<RestAPI.Session.OrderItem.Cancel.Response>(
      sessionId,
      {
        action: SessionUpdateActions.ORDER_ITEM_CANCEL,
        payload,
      }
    );
  }

  public sessionReprintOrderItems(
    sessionId: string,
    payload: RestAPI.Session.OrderItem.Reprint.Payload
  ) {
    return this.updateSession<{ status: boolean }>(sessionId, {
      action: SessionUpdateActions.ORDER_ITEMS_REPRINT,
      payload,
    });
  }

  public sessionRemoveOrderItems(
    sessionId: string,
    payload: RestAPI.Session.OrderItem.Remove.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.ORDER_ITEM_REMOVE,
      payload,
    });
  }

  public sessionAddOrderItem(
    sessionId: string,
    payload: RestAPI.Session.OrderItem.Add.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.ORDER_ITEM_ADD,
      payload,
    });
  }

  public getSessionInviteKey(sessionId: string) {
    return this.updateSession<RestAPI.Session.InviteKey.Response>(sessionId, {
      action: SessionUpdateActions.GET_INVITE_KEY,
      payload: null,
    });
  }

  public sessionReopen(sessionId: string) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.SESSION_REOPEN,
      payload: null,
    });
  }

  public sessionComplete(sessionId: string) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.SESSION_COMPLETE,
      payload: null,
    });
  }

  public sessionUserLeave(sessionId: string) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.SESSION_USER_LEAVE,
      payload: null,
    });
  }

  public replaceTerminals(
    sessionId: string,
    payload: RestAPI.Session.ReplaceTerminal.Payload
  ) {
    return this.updateSession<RestAPI.Session.Generic.Response>(sessionId, {
      action: SessionUpdateActions.TERMINAL_REPLACE,
      payload: payload,
    });
  }
}

export default Api;
