export const development = "dev";
export const staging = "staging";
export const production = "prod";

export enum ENV {
  development = "dev",
  staging = "staging",
  production = "prod",
}

// "100.114.238.26" google cafe
// "10.193.0.166" wework
// "192.168.31.41" home
// "192.168.43.27" mobile
// "10.132.91.223" visa
const local = "localhost";

/** non secret data */
export default class CommonEnviroment {
  readonly e: ENV;
  readonly development: boolean = false;
  readonly staging: boolean = false;
  readonly production: boolean = false;
  readonly static_cloudfront = "https://static.tableport.io";

  readonly api: string;
  readonly ws: string;
  readonly recaptcha_public = "6LcXci4eAAAAAHzESnGFevhUib8_xpTW6miGiRmX";

  readonly app: string;
  readonly origin: RegExp[] | RegExp | string | string[];
  readonly domain: string;

  /** stripe public key, not secret, can be exposed :) */
  readonly stripe_pk: {
    default: string;
    test: string;
  };

  readonly googleClientId: string;

  constructor(node_env: string) {
    if (node_env === ENV.production || node_env?.startsWith?.(ENV.production)) {
      this.e = ENV.production;
      this.production = true;
      this.googleClientId =
        "688214905411-4g6buqm962ai9497j58f8f6mf6p5pl06.apps.googleusercontent.com";
      this.api = `https://api.tableport.io/${ENV.production}`;
      this.ws = `wss://ws.tableport.io/${ENV.production}`;
      this.app = `https://tableport.io`;
      this.origin = [/https:\/\/([^\.]+\.)?tableport\.io/];
      this.domain = `.tableport.io`;
      this.stripe_pk = {
        // prod
        // default:
        //   "pk_live_51JyzkMJwHbbHsXtoV5xZGY2qmkyFMvAEj9e1rOzuBmNqDb8sQVMyn3TUVt8cTGgf4K6MLxr11wUjHHkMyUYN7pgV00OWtANeI3",
        // test: "pk_test_51JyzkMJwHbbHsXtoiuaHzd255BNySlVaifNTqRXTjoeeiCRuqMkdc2r4BjCKE2IpW2QTJqrQoTqrx1itVGcit4jQ00VAeWyJzf",
        default:
          "pk_test_51E1BuwD8jcP4TRV45UsLTxXqv9sixeAtxXNQrOKHAsHJKQnuNE7BBBptegXIwISXNzc7twFl3ARTOnP6pTzUfaLb00JubkMX4Z",
        test: "pk_test_51E1BuwD8jcP4TRV45UsLTxXqv9sixeAtxXNQrOKHAsHJKQnuNE7BBBptegXIwISXNzc7twFl3ARTOnP6pTzUfaLb00JubkMX4Z",
      };
    } else if (node_env === ENV.staging) {
      this.e = ENV.staging;
      this.staging = true;
      this.googleClientId =
        "6462973500-6cngma58c3v1att8emh8fm4r4v24n2oh.apps.googleusercontent.com";
      this.api = `https://api.tableport.io/${ENV.staging}`;
      this.ws = `wss://ws.tableport.io/${ENV.staging}`;
      this.app = `https://tableport.io`;
      this.origin = [/https:\/\/([^\.]+\.)?tableport\.io/];
      this.domain = `.tableport.io`;
      this.stripe_pk = {
        default:
          "pk_test_51E1BuwD8jcP4TRV45UsLTxXqv9sixeAtxXNQrOKHAsHJKQnuNE7BBBptegXIwISXNzc7twFl3ARTOnP6pTzUfaLb00JubkMX4Z",
        test: "pk_test_51E1BuwD8jcP4TRV45UsLTxXqv9sixeAtxXNQrOKHAsHJKQnuNE7BBBptegXIwISXNzc7twFl3ARTOnP6pTzUfaLb00JubkMX4Z",
      };
    } else {
      this.e = ENV.development;
      this.googleClientId =
        "6462973500-6cngma58c3v1att8emh8fm4r4v24n2oh.apps.googleusercontent.com";
      this.development = true;
      // this.api = `https://api.tableport.io/${ENV.production}`; // debug prod
      // this.ws = `wss://ws.tableport.io/${ENV.production}`; // debug prod
      this.api = `http://${local}:3000/${ENV.development}`;
      this.ws = `ws://${local}:3001`;
      this.app = `https://tableport.io`;
      this.origin = [`http://${local}:3003`];
      this.domain = `${local}`;
      this.stripe_pk = {
        default:
          "pk_test_51E1BuwD8jcP4TRV45UsLTxXqv9sixeAtxXNQrOKHAsHJKQnuNE7BBBptegXIwISXNzc7twFl3ARTOnP6pTzUfaLb00JubkMX4Z",
        test: "pk_test_51E1BuwD8jcP4TRV45UsLTxXqv9sixeAtxXNQrOKHAsHJKQnuNE7BBBptegXIwISXNzc7twFl3ARTOnP6pTzUfaLb00JubkMX4Z",
      };
    }
  }
}
