import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React from "react";

export default function Alert({
  Icon = ExclamationCircleIcon,
  children,
  type = "danger",
  variant = "light",
  className = null,
  iconSize = "base",
  Action = null,
  rounded = true,
  ...props
}: {
  Icon?: any;
  className?: string;
  rounded?: boolean;
  children: any;
  iconSize?: "base" | "lg" | "sm" | "xl";
  variant?: "light" | "text";
  Action?: any;
  type?: "danger" | "info" | "success" | "warning" | "default";
} & React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={clsx(
        "flex flex-row items-center p-4 text-sm font-medium gap-2",

        rounded && "rounded-md",

        type === "danger" &&
          clsx(
            variant === "light" && "bg-red-100 dark:bg-red-800",
            "text-red-700 dark:text-red-100"
          ),

        type === "info" &&
          clsx(
            variant === "light" && "bg-blue-100 dark:bg-blue-800",
            "text-blue-700  dark:text-blue-100"
          ),

        type === "success" &&
          clsx(
            variant === "light" && "bg-green-100 dark:bg-green-800",
            "text-green-700  dark:text-green-100"
          ),

        type === "warning" &&
          clsx(
            variant === "light" && "bg-yellow-100 dark:bg-yellow-800",
            "text-yellow-700  dark:text-yellow-100"
          ),

        className
      )}
      {...props}
    >
      <Icon
        className={clsx("flex-none", {
          "w-4 h-4": iconSize === "sm",
          "w-6 h-6": iconSize === "base",
          "w-8 h-8": iconSize === "lg",
          "w-12 h-12": iconSize === "xl",
        })}
      />
      <span className="text-current grow ltr:text-left rtl:text-right">
        {children}
      </span>
      {Action}
    </div>
  );
}
