import cuid from "cuid";
import * as yup from "yup";
import {
  discountType,
  documentTypes,
  integrationProviders,
  OrderItemStatus,
  paymentMethodTypes,
  SessionEventTypes,
  SessionState,
  SessionTypes,
  TipStatus,
  transactionAction,
  transactionStatus,
} from "../enums";
import { CheckType, TransactionPayload } from "../types";
import { taxSchema } from "./Business";
import utcIsoDateString from "./Date";
import { orderItemSchema } from "./OrderItem";
import { SeatingServingStyle, seatingServingStyles } from "./Place";
import { nameSchema } from "./User";
import { addressCommonSchema } from "./UserAddress";

export const sessionNotificationsMessages = {
  "session.notify.sms.order_link": {
    intl: {
      messages: {},
      defaultMessage: "Here is your {placeName} order link: {link}",
      args: {
        link: "",
        placeName: "",
      },
    },
  },
};

export type SessionNotificationMessages =
  keyof typeof sessionNotificationsMessages;

export const sessionMetaDeliverySchema = addressCommonSchema
  .concat(
    yup
      .object({
        refAddressId: yup.string().optional().nullable(),
        refUserId: yup.string().optional().nullable(),
        estAt: utcIsoDateString.optional().nullable(),
      })
      .required()
  )
  .nullable();

export const sessionMetaLiableSchema = yup.object({
  name: nameSchema.required(),
  mobile: yup.string(),
  userId: yup.string(),
});

export type SnapshotMetaLiableType = yup.InferType<
  typeof sessionMetaLiableSchema
>;

export const sessionRoundSchema = yup
  .object({
    id: yup.number().required().min(0),
    // fireAt: utcIsoDateString.nullable().default(null),
    approvedAt: utcIsoDateString.nullable().default(null),

    style: yup
      .mixed<SeatingServingStyle>()
      .oneOf(seatingServingStyles as any)
      .nullable(),
  })
  .required();

export const sessionPromoSchema = yup.object({
  promoId: yup.string(),
  userId: yup.string().nullable(), // no user == auto.
  at: utcIsoDateString.required(),
});

export const sessionMetaSchema = yup.object({
  terminalIds: yup.array().of(yup.string()).min(0).notRequired(),
  reviewId: yup.string().notRequired(),
  bookingId: yup.string().notRequired(), // custom name for reciepts and documents
  documentClient: yup.string().notRequired(), // custom name for reciepts and documents
  futureOrder: yup.boolean().default(false).nullable(),
  targetAt: utcIsoDateString.optional().nullable().default(null), // when order is for -> null == ASAP
  delivery: sessionMetaDeliverySchema.default(null).defined().optional(),
  liables: yup.array().of(sessionMetaLiableSchema).defined().default([]),
  rejectedServiceCharge: yup.boolean().notRequired(),
  shouldSocket: yup.boolean().notRequired(),
  rounds: yup.array().of(sessionRoundSchema).notRequired(),
  promos: yup.array().of(sessionPromoSchema).notRequired(),
  estCompleteAt: utcIsoDateString.notRequired(),
});

export const stripeConnectPaymentSettingsSchema = yup.object({
  publicKey: yup.string().required(),
  accountId: yup.string().required(),
  platformId: yup.string().required(),
  livemode: yup.boolean(),
});

export type TStripeConnectPaymentSettingsPayload = yup.InferType<
  typeof stripeConnectPaymentSettingsSchema
>;

export const sessionPaymentsSettingsSchema = yup.object({
  id: yup.string(),
  provider: yup
    .mixed<integrationProviders>()
    .oneOf(Object.values(integrationProviders)),
  payload: yup.lazy(function (value, options) {
    if (options.parent.provider === integrationProviders.STRIPE_CONNECT) {
      return stripeConnectPaymentSettingsSchema;
    }
    return yup.mixed().nullable().default(null);
  }),
});

export const sessionSettingsSchema = yup
  .object({
    taxes: yup.array().of(taxSchema).notRequired(),

    payments: sessionPaymentsSettingsSchema.notRequired().default(null),

    tips: yup.object({}).notRequired().default(null),

    serviceCharge: yup
      .object({
        percent: yup.number().min(0).max(1).nullable().optional(),
      })
      .nullable()
      .default(null),

    // delivery
    delivery: yup.object({
      taxIds: yup.array().of(yup.string()).nullable().optional().default(null),
      baseFee: yup.number().optional(),
      freeMin: yup.number().optional(),
      minOrderSubtotal: yup.number().optional(),
    }),
  })
  .required();

export const sessionTipSchema = yup.object({
  id: yup.string(),
  sessionId: yup.string(),
  localId: yup.string().default(cuid),
  value: yup.number().min(0).default(0),
  liableId: yup.string().required(),
  status: yup
    .mixed<TipStatus>()
    .oneOf(Object.values(TipStatus))
    .optional()
    .default(TipStatus.PENDING),
  meta: yup.object({
    mode: yup.string(),
    base: yup.number().default(0),
    scope: yup.string(),
  }),
  createdAt: utcIsoDateString.optional(),
  updatedAt: utcIsoDateString.optional(),
});

export const snapshotOrderItemSchema = yup
  .object({
    id: yup.string(),
    amount: yup.number().min(0),
    cost: yup.number(),
    status: yup
      .mixed<OrderItemStatus>()
      .oneOf(Object.keys(OrderItemStatus) as any),
  })
  .defined();

export type SnapshotOrderItemType = yup.InferType<
  typeof snapshotOrderItemSchema
>;

export const snapshotTransactionSchema = yup
  .object({
    id: yup.string(),
    value: yup.number(),
    status: yup.string().oneOf(Object.keys(transactionStatus)),
  })
  .defined();

export const sessionEventTypes = Object.values(SessionEventTypes);

export const sessionEventTypeSchema = yup
  .mixed<SessionEventTypes>()
  .oneOf(sessionEventTypes)
  .required();

export const sessionLogSchema = yup
  .object({
    by: yup.string().notRequired(),
    message: yup.string().notRequired(),
    at: utcIsoDateString.required(),
    ids: yup.array().of(yup.string()).notRequired(),
    type: sessionEventTypeSchema,
  })
  .defined();

export type SnapshotTransactionType = yup.InferType<
  typeof snapshotTransactionSchema
>;

export const sessionSnapshotSchema = yup
  .object({
    orderItems: yup.array().of(snapshotOrderItemSchema).defined().default([]),
    transactions: yup
      .array()
      .of(snapshotTransactionSchema)
      .defined()
      .default([]),
    delivery: yup.number().optional(),
    discounts: yup.number().defined(),
    tips: yup.number().defined(),
    serviceCharge: yup.number().defined(),
    tax: yup.number().defined(),
    taxes: yup.array().of(
      yup.object({
        name: yup.string().defined().required(),
        value: yup.number().defined().required(),
      })
    ),
    charged: yup.number().optional(),
    total: yup.number(),
  })
  .defined();

export type DocumentSnapshotType = yup.InferType<typeof sessionSnapshotSchema>;

export const sessionDocumentSchema = yup
  .object({
    issuedAt: utcIsoDateString,
    type: yup.mixed<documentTypes>().oneOf(Object.values(documentTypes)),
    id: yup.string(), // unique document id
    identifier: yup.string(), // tax document id
    refId: yup.string().optional().nullable(), // other doc ref
    taxId: yup.string(), // other doc ref
    accountId: yup.string(),
    sessionId: yup.string(),
    integrationId: yup.string().optional().nullable(),
    attachments: yup.array().of(yup.string().defined()).default([]).defined(),
    snapshot: sessionSnapshotSchema, // SessionSnapshot;
  })
  .defined();

export const transactionMethodSchema = yup.mixed<paymentMethodTypes>();
export const transactionActionSchema = yup.mixed<transactionAction>();
export const transactionStatusSchema = yup.mixed<transactionStatus>();

export const sessionTransactionSchema = yup.object({
  id: yup.string(),
  method: transactionMethodSchema.required(),
  action: transactionActionSchema.required(),
  status: transactionStatusSchema.required(),
  acceptId: yup.string().required(),
  integrationId: yup.string().nullable().notRequired(),

  value: yup.number().required(),

  byId: yup.string(),
  currency: yup.string(),

  payload: yup.mixed<TransactionPayload>(),

  refs: yup
    .object({
      printerId: yup.string().optional(),
      authTransId: yup.string().optional(),
      chargeId: yup.string().optional(),
      intId: yup.string().optional(),
      customerId: yup.string().optional(),
      pmId: yup.string().optional(),
      authNumber: yup.string().optional(),
    })
    .optional(),

  dailyId: yup.number().notRequired(),
  placeId: yup.string().notRequired(),

  createdAt: utcIsoDateString,
  updatedAt: utcIsoDateString,
});

export const sessionDiscountSchema = yup
  .object({
    id: yup.string().notRequired(),

    amount: yup
      .number()
      .min(0)
      .test(
        "valid percent if type percent",
        "invalid percentage value",
        function (value) {
          if (value === undefined) return true;

          if (this.parent.type === discountType.PERCENT) {
            if (isNaN(value) || value > 1 || value < 0) return false;
          }

          return true;
        }
      ),

    type: yup.mixed<discountType>().oneOf(Object.values(discountType)),

    limit: yup.number().nullable().default(null),

    auto: yup.boolean().notRequired(),

    settings: yup.object({
      // items: yup.array(yup.string().min(5).max(50)).nullable().default(null),
      orderItems: yup
        .array(yup.string().min(0).max(50))
        .nullable()
        .default(null),
    }),

    // meta
    promoId: yup.string().notRequired().nullable().default(undefined),
    createdAt: yup.string().notRequired(),
    byId: yup.string().notRequired(),

    note: yup
      .string()
      .notRequired()
      .min(0)
      .max(120)
      .transform((x) => (x ? x : undefined)),

    canceled: yup.boolean().notRequired().default(false),
  })
  .defined();

export const sessionUserSchema = yup.object({
  id: yup.string(),
  state: yup.string(),
  role: yup.string(),
  sessionId: yup.string(),
  userId: yup.string(),
  pmIds: yup.string(),
  feedbackRate: yup.number().min(0).max(5).notRequired().default(null),
  feedbackTags: yup.array().of(yup.string()).notRequired().default(null),
  feedbackNote: yup.string().notRequired().default(null),
  feedbackAt: yup.string().notRequired(),
  createdAt: yup.string().notRequired(),
  updatedAt: yup.string().notRequired(),
});

export const sessionSchema = yup
  .object({
    id: yup.string(),

    type: yup
      .mixed<SessionTypes>()
      .defined()
      .oneOf(Object.keys(SessionTypes) as SessionTypes[]),

    state: yup
      .mixed<SessionState>()
      .defined()
      .oneOf(Object.values(SessionState))
      .required(),

    houseAccountId: yup.number().notRequired(),
    memberId: yup.number().notRequired(),
    dailyId: yup.number().notRequired(),
    terminalId: yup.string().required(),
    placeId: yup.string().required(),
    country: yup.string().required(),
    orderId: yup.string().nullable().default(null),
    capacity: yup.number().required().min(1).max(100),
    meta: sessionMetaSchema,
    settings: sessionSettingsSchema,
    orderItems: yup.array().of(orderItemSchema).defined().default([]),
    users: yup.array().of(sessionUserSchema).defined().default([]),
    tips: yup.array().of(sessionTipSchema).defined().default([]),
    consumerIds: yup.array().of(yup.string()).defined().default([]),
    // users: yup.array().of(?).defined().default([]),
    transactions: yup.array().of(sessionTransactionSchema).default([]),
    discounts: yup.array().of(sessionDiscountSchema).defined().default([]),
    documents: yup.array().of(sessionDocumentSchema).default([]),
    logs: yup.array().of(sessionLogSchema).default([]).notRequired(),
    check: yup.mixed<CheckType>().notRequired(),
    _v: yup.number(),
    completedAt: utcIsoDateString.notRequired(),
    createdAt: utcIsoDateString.notRequired(),
    updatedAt: utcIsoDateString.notRequired(),
  })
  .required()
  .defined();

export type TSession = yup.InferType<typeof sessionSchema>;

export type TSessionUser = yup.InferType<typeof sessionUserSchema>;

export type TSessionDiscount = yup.InferType<typeof sessionDiscountSchema>;

export type TSessionTransaction = yup.InferType<
  typeof sessionTransactionSchema
>;

export type TSessionMeta = yup.InferType<typeof sessionMetaSchema>;

export type TSessionMetaDelivery = yup.InferType<
  typeof sessionMetaDeliverySchema
>;
export type TSessionSettings = yup.InferType<typeof sessionSettingsSchema>;

export type TSessionTip = yup.InferType<typeof sessionTipSchema>;

export type TSessionPaymentsSettings<T = any> = yup.InferType<
  typeof sessionPaymentsSettingsSchema
> & { payload: T };

export type TSessionRound = yup.InferType<typeof sessionRoundSchema>;

export type TSessionDocument = yup.InferType<typeof sessionDocumentSchema>;

export type TSessionLog = yup.InferType<typeof sessionLogSchema>;
