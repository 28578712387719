import { useMemo } from "react";
import { useAppSelector } from "../state/hooks";
import { sessionPlaceSelector } from "../state/reducers/session";
import store from "../state/store";
import { useAppIntl } from "./AppIntlContext";
import { createTheme, ThemeProvider } from "./ThemeContext";

export const SessionThemeProvider = ({ children }) => {
  const { setTimezone } = useAppIntl();
  const { placeId, locale, rtl } = useAppSelector((state) => ({
    rtl: state.system.rtl,
    locale: state.system.locale,
    placeId: sessionPlaceSelector(state)?.id,
  }));

  const theme = useMemo(() => {
    const place = sessionPlaceSelector(store.getState());

    if (place?.settings?.timeZone) setTimezone(place.settings.timeZone);

    return createTheme(
      {
        direction: rtl ? "rtl" : "ltr",
        type: place?.theme?.colors?.palette,
        colors: place?.theme?.colors as any,
        fonts: {
          title: place?.theme?.fontTitle,
          body: place?.theme?.fontBody,
        },
      },
      locale
    );
  }, [placeId, locale, rtl]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default SessionThemeProvider;
