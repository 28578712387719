import { LOCALES_TRANSLATIONS } from "@gethere/common/settings";
import { LanguageIcon } from "@heroicons/react/24/outline";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { CompanyLogo } from "../containers/LogoSymbol";
import { useLanguageDialog } from "../contexts/LanguageContext";
import { useAppSelector } from "../state/hooks";
import Btn from "./Btn";
import { FacebookIcon, InstagramIcon, LinkedinIcon } from "./SocialIcons";

const FooterLinkItem = ({ to = null, label, href = null, ...props }) => {
  if (to) {
    return (
      <li {...props}>
        <Link to={to} className="hover:underline text-xs">
          {label}
        </Link>
      </li>
    );
  } else {
    return (
      <li>
        <a href={href} className="hover:underline text-xs" {...props}>
          {label}
        </a>
      </li>
    );
  }
};

const FooterLinksGroup = ({ title, children }) => (
  <div>
    <h2 className="mb-5 text-xs font-medium text-gray-900 dark:text-white">
      {title}
    </h2>
    <ul className="text-gray-600 dark:text-gray-400">{children}</ul>
  </div>
);

const LocaleFooterButton = () => {
  const country = useAppSelector((s) => s.system.country);
  const locale = useAppSelector((s) => s.system.locale);

  const { onOpen } = useLanguageDialog();
  const intl = useIntl();
  return (
    <Btn
      onClick={() => onOpen(null, "region")}
      size="xs"
      variant="light"
      color="text"
      StartIcon={LanguageIcon}
    >
      {country
        ? intl.formatMessage({ id: "countries_by_value" }, { value: country }) +
          ", "
        : null}
      {LOCALES_TRANSLATIONS[locale] || locale}
    </Btn>
  );
};

const FooterButtons = () => (
  <span className="flex mt-4 flex-row items-center space-x-6 rtl:space-x-reverse sm:justify-center sm:mt-0 gap-3">
    <a
      target="_blank"
      aria-label="facebook"
      rel="noreferrer"
      href="https://www.facebook.com/tableport"
    >
      <FacebookIcon className="w-5 h-5 text-gray-400 dark:hover:text-gray-300 hover:text-gray-700" />
    </a>
    <a
      target="_blank"
      aria-label="instagram"
      rel="noreferrer"
      href="https://www.instagram.com/tableport.io"
    >
      <InstagramIcon className="w-5 h-5 text-gray-400 dark:hover:text-gray-300 hover:text-gray-700" />
    </a>
    <a
      target="_blank"
      aria-label="linkedin"
      rel="noreferrer"
      href="https://www.linkedin.com/company/tableport"
    >
      <LinkedinIcon className="w-5 h-5 text-gray-400 dark:hover:text-gray-300 hover:text-gray-700" />
    </a>
  </span>
);

export const AllRightsReserved = () => {
  const intl = useIntl();
  return (
    <span className="text-xs text-muted-foreground sm:text-center">
      © {new Date().getFullYear()}{" "}
      <a href="https://tableport.io" className="hover:underline font-medium">
        TablePort™
      </a>
      .{" "}
      {intl.formatMessage({
        id: "all_rights_reserved",
        defaultMessage: "All Rights Reserved",
      })}
      .
    </span>
  );
};

export const MiniAppFooter = () => {
  const intl = useIntl();

  return (
    <div className="sm:flex sm:items-center sm:justify-between my-5">
      <AllRightsReserved />
      <ul className="list-none flex-row flex gap-3">
        <FooterLinkItem
          to="/legal/privacy"
          label={intl.formatMessage({
            id: "settings_items_privacy_policy_title",
            defaultMessage: "Privacy Policy",
          })}
        />
        <FooterLinkItem
          to="/legal/tos"
          label={intl.formatMessage({
            id: "wpa_link_tos",
            defaultMessage: "Terms of Use",
          })}
        />
        <FooterLinkItem
          to="/legal/license"
          label={intl.formatMessage({
            id: "license",
            defaultMessage: "License Agreement",
          })}
        />
        <FooterLinkItem
          to="/legal/accessibility"
          label="Accessibility Statement"
        />
        <FooterLinkItem
          to="/legal/copyright"
          label="Copyright and Trademark Notice"
        />
      </ul>
    </div>
  );
};

const AppFooter = () => {
  const intl = useIntl();
  return (
    <footer className="p-5 bg-gray-100 dark:bg-zinc-900 divide-y divide-opacity-10 divide-gray-500">
      <div className="md:flex md:justify-between max-w-screen-xl mx-auto">
        <div className="mb-6 md:mb-0 flex flex-col gap-5">
          <CompanyLogo
            labelClassName="fill-muted-foreground"
            symbolClassName="fill-muted-foreground"
            className="h-6"
          />
          <div>
            <LocaleFooterButton />
          </div>
        </div>
        <div className="grid gap-10 grid-cols-2">
          <FooterLinksGroup
            title={intl.formatMessage({
              id: "wpa_link_for_businesses",
              defaultMessage: "For Businesses",
            })}
          >
            <FooterLinkItem
              to="/businesses"
              label={intl.formatMessage({
                id: "manage",
                defaultMessage: "Manage",
              })}
            />
            <FooterLinkItem
              to="/join"
              label={intl.formatMessage({
                id: "wpa_business_join_link",
                defaultMessage: "Join",
              })}
            />
            <FooterLinkItem
              href="https://docs.tableport.io"
              label={intl.formatMessage({
                id: "wpa_link_docs",
                defaultMessage: "Docs",
              })}
              target="_blank"
            />
            <FooterLinkItem
              to="/pages/download-os"
              label={intl.formatMessage({
                id: "wpa_link_download_os",
                defaultMessage: "Download Tableport OS",
              })}
            />
          </FooterLinksGroup>
          <FooterLinksGroup
            title={intl.formatMessage({
              id: "wpa_link_legal",
              defaultMessage: "Legal",
            })}
          >
            <FooterLinkItem
              to="/legal/privacy"
              label={intl.formatMessage({
                id: "settings_items_privacy_policy_title",
                defaultMessage: "Privacy Policy",
              })}
            />
            <FooterLinkItem
              to="/legal/tos"
              label={intl.formatMessage({
                id: "wpa_link_tos",
                defaultMessage: "Terms of Use",
              })}
            />
            <FooterLinkItem
              to="/legal/license"
              label={intl.formatMessage({
                id: "license",
                defaultMessage: "License Agreement",
              })}
            />
            <FooterLinkItem
              to="/legal/accessibility"
              label="Accessibility Statement"
            />
            <FooterLinkItem
              to="/legal/copyright"
              label="Copyright and Trademark Notice"
            />
          </FooterLinksGroup>
        </div>
      </div>
      <div className="sm:flex sm:items-center sm:justify-between max-w-screen-xl mx-auto mt-5 pt-5">
        <AllRightsReserved />
        <FooterButtons />
      </div>
    </footer>
  );
};

export default AppFooter;
