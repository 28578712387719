import { SessionSubmitIntents } from "@gethere/common/enums";
import { TSession } from "@gethere/common/yup/Session";
import { sessionSyncDraftSchema } from "@gethere/common/yup/SessionUpdateActions";
import { captureException, ErrorBoundary } from "@sentry/react";
import {
  PayIntentSync,
  SessionPaymentsComponent,
} from "../containers/SessionPayments";
import { sessionSelector } from "../state/reducers/session";
import store from "../state/store";
import client from "../utils/client";
import errorify from "../utils/errorify";

const payIntentSync: PayIntentSync = async ({ localValue }) => {
  try {
    const state = store.getState();

    const session: TSession = JSON.parse(
      JSON.stringify(sessionSelector(state))
    );

    const response = await client.api.sessionSync({
      intent: SessionSubmitIntents.PAYMENT,
      sId: session.id,
      draft: sessionSyncDraftSchema.cast({
        paymentIntent: { value: localValue },
      }),
    });

    const { result, entities, paymentIntent } = response.data;
    return { result, entities, paymentIntent };
  } catch (error) {
    captureException(error);
    return { error: errorify(error)?.message };
  }
};

export const SubmitSessionPayment = ({
  value,
  onSuccess,
}: {
  value: number;
  onSuccess?: () => void;
}) => {
  return (
    <ErrorBoundary>
      <SessionPaymentsComponent
        value={value}
        payIntentSync={payIntentSync}
        onSuccess={onSuccess}
      />
    </ErrorBoundary>
  );
};
