const defaultModifiers = (mods: any, pre: any = {}) => {
  const result = {};

  if (Array.isArray(mods)) {
    for (let mod of mods) {
      result[mod.id] = {};
      for (let opt of mod.options?.values) {
        if (
          pre[mod.id] &&
          opt.id in pre?.[mod.id] &&
          "type" in pre[mod.id][opt.id]
        ) {
          result[mod.id][opt.id] = pre[mod.id][opt.id].type;
        } else {
          result[mod.id][opt.id] = opt.default;
        }
      }
    }
  }

  return result;
};

export default defaultModifiers;
