import * as yup from "yup";
// import { multiPolygon } from "@turf/turf";

const pointCoordinates = yup
  .array()
  .of(yup.number().required())
  .max(2)
  .min(2)
  .default([0, 0]);

export const geoPointScheme = yup
  .object({
    type: yup.mixed<"Point" | string>().default("Point").oneOf(["Point"]),
    coordinates: pointCoordinates,
  })
  .defined();

export const geoPolygonScheme = yup.object({
  type: yup.string().default("Polygon").oneOf(["Polygon"]).required(),
  coordinates: yup
    .array()
    .of(yup.array().of(pointCoordinates.required()))
    .test(
      "first and last points are equal",
      "first and last not equal",
      (value) => {
        try {
          // multiPolygon(value as any);
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      }
    )
    .max(20)
    .min(0)
    .default([])
    .required(),
});

export type TGeoPoint = { type: "Point"; coordinates: number[] };
export type TGeoPolygon = { type: "Polygon"; coordinates: number[][] };
