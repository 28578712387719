import { type ClassValue, clsx } from "clsx";
import { get } from "http";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const isHexColor = (color: string) =>
  /^#([0-9A-F]{3,4}|[0-9A-F]{6}|[0-9A-F]{8})$/i.test(color);

export const hexToHsl = (hex: string, flat: boolean = true): string => {
  // Ensure hex is immutable
  const hexImmutable = hex;

  // Remove the hash symbol if present and convert to full 6-digit hex if needed
  let normalizedHex = hexImmutable.replace("#", "");
  if (normalizedHex.length === 3) {
    normalizedHex = normalizedHex
      .split("")
      .map((c) => c + c)
      .join("");
  }

  // Check if hex is valid
  const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(normalizedHex);
  if (!result) {
    return hex;
  }

  // Convert to RGB
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);

  r /= 255;
  g /= 255;
  b /= 255;

  // Calculate HSL
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // Achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  // Format HSL
  h = Math.round(360 * h);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return flat ? `${h} ${s}% ${l}%` : `hsl(${h}, ${s}%, ${l}%)`;
};

export const isFlatHsl = (hsl: string) => {
  return (
    hsl.match(/\d{1,3}\s\d{1,3}%\s\d{1,3}%/) &&
    !hsl.includes("(") &&
    !hsl.includes(")")
  );
};

export const isHsl = (hsl: string) => {
  const [h, s, l] = hsl.split(" ");

  return (
    h?.includes("deg") ||
    s?.includes("%") ||
    l?.includes("%") ||
    l?.includes(")")
  );
};

export const flatHslToHsl = (flatHsl: string) => {
  const [h, s, l] = flatHsl.split(" ");
  return `hsl(${h}, ${s}, ${l})`;
};

export const hexToRgb = (hex: string) => {
  try {
    if (hex.length === 4 && hex.startsWith("#")) {
      hex =
        "#" +
        hex
          .replace("#", "")
          .split("")
          .map(function (hex) {
            return hex + hex;
          })
          .join("");
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) return hex;

    return [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ].join(" ");
  } catch (error) {
    return hex;
  }
};

export const isRgba = (rgba: string) =>
  /^rgba?\((\d{1,3}),(\d{1,3}),(\d{1,3}),?([01]?\.?\d*?)?\)$/i.test(rgba);

export const isRgb = (rgb: string) =>
  /^rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)$/i.test(rgb);

export const rgbaToRgb = (rgba: string) =>
  rgba.replace("rgba", "rgb").replace(/,[^,]+?\)/, ")");

export const rgbaToHex = (rgba: string) => {
  const [r, g, b, a] = rgba.replace("rgba(", "").replace(")", "").split(",");
  return `#${parseInt(r).toString(16)}${parseInt(g).toString(16)}${parseInt(
    b
  ).toString(16)}${a ? parseInt(a).toString(16) : ""}`;
};

export const rgbToHex = (rgb: string) => {
  const [r, g, b] = rgb.replace("rgb(", "").replace(")", "").split(",");
  return `#${parseInt(r).toString(16)}${parseInt(g).toString(16)}${parseInt(
    b
  ).toString(16)}`;
};

export const hslToRgb = (hsl: string) => {
  let r: number, g: number, b: number;

  const [h, s, l] = getHslValues(hsl);

  const hValue = h / 360;
  const sValue = s / 100;
  const lValue = l / 100;

  if (sValue === 0) {
    r = g = b = lValue; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;

      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;

      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;

      return p;
    };

    const q =
      lValue < 0.5 ? lValue * (1 + sValue) : lValue + sValue - lValue * sValue;
    const p = 2 * lValue - q;

    r = hue2rgb(p, q, hValue + 1 / 3);
    g = hue2rgb(p, q, hValue);
    b = hue2rgb(p, q, hValue - 1 / 3);
  }

  return (
    Math.round(r * 255) + " " + Math.round(g * 255) + " " + Math.round(b * 255)
  );
};

const getHslValues = (hsl: string) => {
  const [h, s, l] = hsl
    .replace("hsl(", "")
    .replace(")", "")
    .split(",")
    .map((item) => item.replace("%", "").trim());

  const hValue = parseInt(h);
  const sValue = parseInt(s);
  const lValue = parseInt(l);

  return [hValue, sValue, lValue];
};

export const hslToHex = (hsl: string) => {
  const rgb = hslToRgb(hsl);
  return rgbToHex(rgb);
};

const hue2rgb = (p: number, q: number, t: number) => {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;

  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;

  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;

  return p;
};

export const hslToRgba = (hsl: string) => {
  const [h, s, l] = getHslValues(hsl);

  const hValue = h / 360;
  const sValue = s / 100;
  const lValue = l / 100;

  let r: number, g: number, b: number;

  if (sValue === 0) {
    r = g = b = lValue; // achromatic
  } else {
    const q =
      lValue < 0.5 ? lValue * (1 + sValue) : lValue + sValue - lValue * sValue;
    const p = 2 * lValue - q;

    r = hue2rgb(p, q, hValue + 1 / 3);
    g = hue2rgb(p, q, hValue);
    b = hue2rgb(p, q, hValue - 1 / 3);
  }

  return `rgba(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(
    b * 255
  )}, 1)`;
};

export const convertColor = (
  from: string,
  to: "rgb" | "rgba" | "hsl" | "hex",
  options: { opacity?: number; flat?: boolean } = { opacity: 1, flat: false }
) => {
  if (!from) return from;

  if (isHexColor(from)) {
    if (to === "rgb") {
      return hexToRgb(from);
    } else if (to === "rgba") {
      return hexToRgb(from) + " " + options.opacity;
    } else if (to === "hsl") {
      return hexToHsl(from, options.flat);
    } else if (to === "hex") {
      return from;
    }
  } else if (isRgba(from)) {
    if (to === "rgba") return from;
    if (to === "rgb") return rgbaToRgb(from);
    if (to === "hex") return rgbaToHex(from);
  } else if (isRgb(from)) {
    if (to === "rgb") return from;
    if (to === "hex") return rgbToHex(from);
  } else {
    let hsl: string;

    if (isFlatHsl(from)) {
      hsl = flatHslToHsl(from);
    } else if (isHsl(from)) {
      hsl = from;
    } else {
      console.warn("Invalid color format", from);
      return from;
    }

    if (to === "hsl") {
      if (options?.flat) {
        return hsl;
      } else {
        return hsl
          .replace("hsl(", "")
          .replace(")", "")
          .replaceAll(/\,[\s]/, " ");
      }
    }

    if (to === "hex") return hslToHex(hsl);
    if (to === "rgb") return hslToRgb(hsl);
    if (to === "rgba") return hslToRgba(hsl);
  }
};
