import cuid from "cuid";
import slugify from "../utils/slugify";
import * as yup from "yup";
import {
  CountryCodes,
  Currencies,
  FeeStrategies,
  LicenseSegmentType,
  hardwareTypes,
  locales,
  monthlySubscriptionTypes,
  paymentMethodTypes,
} from "../enums";
import { controlRoleSchema } from "./ControlRole";
import utcIsoDateString from "./Date";
import { itemCategorySchema } from "./ItemCategory";
import { yupMobileNumberField } from "./MobileInputForm";
import { sessionTypesArraySchema } from "./SessionTypes";
import { businessMembershipSettingsSchema } from "./Membership";
import { imageSchema } from "./Image";

const PaymentMethodTypes = Object.values(paymentMethodTypes);

export const taxRuleSchema = yup.object({
  sessionTypes: sessionTypesArraySchema.notRequired().default(undefined),
  placeIds: yup.array().of(yup.string()).notRequired().default(undefined),
  categoryIds: yup.array().of(yup.string()).notRequired().default(undefined),
  orderItem: yup.boolean().notRequired().default(undefined),
  tip: yup.boolean().notRequired().default(undefined),
  serviceCharge: yup.boolean().notRequired().default(undefined),
  deliveryFee: yup.boolean().notRequired().default(undefined),
});

export type TaxRule = yup.InferType<typeof taxRuleSchema>;

export const taxSchema = yup.object({
  id: yup.string().required().transform(slugify).default(""),
  name: yup.string().required().default(""),
  rate: yup.number().required().min(0).max(1).default(0),
  included: yup.boolean().required(),
  active: yup.boolean().default(true),
  rules: yup.array().of(taxRuleSchema).nullable().default(null),
});

export const acceptSchema = yup
  .object({
    id: yup.string().default(cuid),
    method: yup
      .mixed<paymentMethodTypes>()
      .oneOf(PaymentMethodTypes)
      .transform((v) =>
        PaymentMethodTypes.includes(v) ? v : paymentMethodTypes.OTHER
      ),
    integrationId: yup.string().nullable(),
    operator: yup.object({ only: yup.boolean().default(false) }),
    name: yup.string().min(2).nullable(),
    active: yup.boolean().default(true),
    pinned: yup.boolean().default(false),
    parameters: yup.object({
      brands: yup.array().of(yup.string().min(1)).nullable().default(null),
    }),
  })
  .required();

export const FixAndPercentSchema = yup.object({
  fixed: yup.number().min(0),
  percent: yup.number().min(0).max(1),
});

export const oneTierSchema = yup.object({
  fee: FixAndPercentSchema.required(),
});

export const twoTierSchema = yup.object({
  low: FixAndPercentSchema.required(),
  high: FixAndPercentSchema.required(),
});

export const monthlySubscriptionSchema = yup.object({
  fee: yup.number().min(0), // per unit
  amount: yup.number().min(1).max(100000).notRequired().default(1), // for ex. 2 locations, 5 readers etc.
});

export const hardwareStructSchema = yup.object({
  fee: yup.number().min(0),
  deliveryFee: yup.number().min(0),
});

export type OneTierStrategy = yup.InferType<typeof oneTierSchema>;

export type TwoTierStrategy = yup.InferType<typeof twoTierSchema>;

export type MonthlySubscription = yup.InferType<
  typeof monthlySubscriptionSchema
>;

export type HardwareStruct = yup.InferType<typeof hardwareStructSchema>;

export const segmentTermsSchema = yup
  .object({
    minMonthlyFee: yup.number().min(0),
    strategy: yup
      .mixed<FeeStrategies>()
      .oneOf(Object.values(FeeStrategies))
      .default(FeeStrategies.TWO_TIERS)
      .required()
      .default(FeeStrategies.TWO_TIERS),
    struct: yup
      .mixed<
        OneTierStrategy | TwoTierStrategy | MonthlySubscription | HardwareStruct
      >()
      .default(
        twoTierSchema.cast({
          low: { fixed: 0.2, percent: 0.014 },
          high: { fixed: 0.2, percent: 0.025 },
        })
      ),
  })
  .test("validate struct against strategy", function (value) {
    switch (value.strategy) {
      case FeeStrategies.ONE_TIER:
        return oneTierSchema.isValidSync(value);
      case FeeStrategies.TWO_TIERS:
        return twoTierSchema.isValidSync(value);
      case FeeStrategies.MONTHLY_SUBSCRIPTION:
        return monthlySubscriptionSchema.isValidSync(value);
      case FeeStrategies.HARDWARE:
        return hardwareStructSchema.isValidSync(value);
      default:
        return this.createError({
          path: "strategy",
          message: "invalid strategy for given struct",
        });
    }
  });

export const licenseSegmentSchema = yup
  .object({
    id: yup.string().default(cuid),

    // @new
    type: yup
      .mixed<LicenseSegmentType>()
      .oneOf(Object.values(LicenseSegmentType))
      .required()
      .default(LicenseSegmentType.card),

    // // @deprecated
    // types: yup
    //   .array()
    //   .of(
    //     yup.mixed<LicenseSegmentType>().oneOf(Object.values(LicenseSegmentType))
    //   )
    //   .min(1)
    //   .required()
    //   .default([]),

    base: segmentTermsSchema.required(),

    // gradual: yup
    //   .array()
    //   .of(
    //     segmentTermsSchema.concat(
    //       yup.object({
    //         minMonthlyVolume: yup.number(),
    //       })
    //     )
    //   )
    //   .optional(),
  })
  .test(
    "validate_type_base_struct",
    function validateTypeBaseStruct(value, context) {
      const type = value.type;
      if (!type) return true; // type is required, not checking this here

      const base = value.base;
      const strategy = base.strategy;

      if (!strategy) return true; // strategy is required, not checking this here

      if (monthlySubscriptionTypes.includes(type)) {
        if (strategy !== FeeStrategies.MONTHLY_SUBSCRIPTION) {
          return context.createError({
            path: "base.strategy",
            message: "invalid strategy for given type",
          });
        } else {
          return true;
        }
      }

      if (hardwareTypes.includes(type)) {
        if (strategy !== FeeStrategies.HARDWARE) {
          return context.createError({
            path: "base.strategy",
            message: "invalid strategy for given type",
          });
        } else {
          return true;
        }
      }

      // we left with processing, so it need to be either
      if (
        ![FeeStrategies.TWO_TIERS, FeeStrategies.ONE_TIER].includes(strategy)
      ) {
        return context.createError({
          path: "base.strategy",
          message: "invalid strategy for given type",
        });
      } else {
        return true;
      }
    }
  );

export const licenseSchema = yup.object({
  id: yup.string().nullable(),
  active: yup.boolean().default(true),
  notes: yup.string().optional().nullable(), //
  segments: yup.array().of(licenseSegmentSchema).min(1),
  validFrom: utcIsoDateString
    .required()
    .default(() => new Date().toISOString()),
  validThrough: utcIsoDateString.nullable(),
});

export const accountSchema = yup.object({
  id: yup.string().notRequired(), //
  name: yup.string().required().default(""),
  companyType: yup.string().required().default(""),
  companyId: yup.string().required().default(""),
  taxId: yup.string().required().default(""),
  meta: yup
    .object({
      state: yup.string().nullable(),
      city: yup.string().nullable(),
      zip: yup.string().nullable(),
    })
    .nullable(),
});

export const businessBrandSchema = yup.object({
  theme: yup.object({
    bgColor: yup.string().notRequired(),
    fgColor: yup.string().notRequired(),
    mutedColor: yup.string().notRequired(),
    destructiveColor: yup.string().notRequired(),
    primaryColor: yup.string().notRequired(),
    secondaryColor: yup.string().notRequired(),
    logoImage: imageSchema.notRequired(),
    logoSvg: yup.string().url().notRequired(),
    coverImage: imageSchema.notRequired(),
    titleFont: yup.string().notRequired(),
    bodyFont: yup.string().notRequired(),
  }),

  qr: yup.object({
    text: yup.string().notRequired(),
    background: yup.string().notRequired(),
    code: yup.string().notRequired(),
    font: yup.string().notRequired(),
    logo: imageSchema.notRequired().default(null),
    frame: yup.string().notRequired(),
    corners: yup.string().oneOf(["r", "s"]).notRequired(),
    rounded: yup.boolean().notRequired(),
    promptWeight: yup.number().min(100).max(900).notRequired(),
    nameWeight: yup.number().min(100).max(900).notRequired(),
    promptSize: yup.number().min(0).max(500).notRequired(),
    lineHeight: yup.number().min(0).max(500).notRequired(),
    nameSize: yup.number().min(0).max(500).notRequired(),
    width: yup.number().min(1).max(1000).notRequired().default(55),
    height: yup.number().min(1).max(1000).notRequired().default(85),
    unit: yup.string().oneOf(["mm", "in"]).notRequired().default("mm"),
    prompt: yup.lazy((v) => {
      if (typeof v === "undefined") {
        return yup.mixed().default(undefined).optional();
      }

      if (typeof v === "boolean") {
        return yup.boolean().default(true).optional();
      }

      return yup.string().notRequired().max(60);
    }),
  }),

  pass: yup
    .object()
    .shape({
      bgColor: yup.string().notRequired(),
      fgColor: yup.string().notRequired(),

      // icon
      x1Icon: yup.string().url().notRequired(), // 29 x 29 pixels
      x2Icon: yup.string().url().notRequired(), // 58 x 58 pixels
      x3Icon: yup.string().url().notRequired(), // 87 x 87 pixels

      // logo
      x1Logo: yup.string().url().notRequired(), // 160 x 50 pixels
      x2Logo: yup.string().url().notRequired(), // 320 x 100 pixels
      x3Logo: yup.string().url().notRequired(), // 480 x 150 pixels
      gLogo: yup.string().notRequired(), // 80 x 80 pixels

      // cover
      x1Cover: yup.string().url().notRequired(), // 624 x 240 pixels
      x2Cover: yup.string().url().notRequired(), // 1248 x 480 pixels
      x3Cover: yup.string().url().notRequired(), // 1872 x 720 pixels
      gCover: yup.string().url().notRequired(), // 375 x 123 pixels (aspect ratio 3:1)
    })
    .notRequired(),
});

export const businessSchema = yup
  .object({
    id: yup.string(),
    name: yup.string().default("").min(2),
    currency: yup
      .string()
      .oneOf(Object.values(Currencies))
      .default(Currencies.ILS),
    active: yup.boolean().default(true),
    locale: yup.string().nullable().default(locales.en_US),
    country: yup
      .string()
      .default(CountryCodes.UnitedKingdom)
      .oneOf(Object.values(CountryCodes)),
    email: yup.string().email().notRequired(),
    website: yup.string().url().nullable(),
    mobile: yupMobileNumberField.notRequired(),
    roles: yup.array().of(controlRoleSchema),
    accounts: yup.array().of(accountSchema).min(1).defined().default([]),
    taxes: yup
      .array()
      .of(taxSchema)
      .min(0)
      .defined()
      .default([])
      .nullable()
      .test(function (value) {
        if (value && value.length) {
          const ids = value.map((tax) => tax.id);
          const set = new Set(ids);
          if (ids.length !== set.size) {
            for (let i in ids) {
              if (ids.filter((id) => id === ids[i])?.length > 1) {
                return this.createError({
                  path: `taxes[${i}].id`,
                  message: "tax id must be unique",
                });
              }
            }
          }
        }
        return true;
      }),
    accepts: yup.array().of(acceptSchema).defined().default([]),
    itemCategories: yup.array().of(itemCategorySchema),
    licenses: yup
      .array()
      .of(licenseSchema)
      .defined()
      .default([])
      .test("no_active_and_expired_licenses", function (value) {
        for (let license of value) {
          if (!license.active) continue;
          if (
            license.validThrough &&
            new Date(license.validThrough) < new Date()
          ) {
            return this.createError({
              path: "licenses",
              message: "Active licenses should not be expired",
            });
          }
        }
        return true;
      })
      .test("only_one_active_license", function (value) {
        const activeLicenses = value.filter((license) => license.active);

        if (activeLicenses.length !== 1) {
          return this.createError({
            path: "licenses",
            message: "Single license should be active at a time",
          });
        }

        return true;
      }),
    membership: businessMembershipSettingsSchema.notRequired(),
    brand: businessBrandSchema.notRequired(),
    createdAt: yup.date().nullable(),
    updatedAt: yup.date().nullable(),
  })
  .required();

export const businessWizardSchema = yup
  .object({
    roleName: yup.string().min(2).required(),
    pin: yup.string().min(4).max(4).required(),
    agree: yup.boolean().oneOf([true]).required().default(false),
  })
  .concat(businessSchema);

export type TBusinessBrand = yup.InferType<typeof businessBrandSchema>;
export type TBusiness = yup.InferType<typeof businessSchema>;
export type TBusinessWizard = yup.InferType<typeof businessWizardSchema>;
export type TBusinessAccept = yup.InferType<typeof acceptSchema>;
export type TBusinessTax = yup.InferType<typeof taxSchema>;
export type TBusinessTaxRule = yup.InferType<typeof taxRuleSchema>;
export type TBusinessAccount = yup.InferType<typeof accountSchema>;
export type TLicense = yup.InferType<typeof licenseSchema>;
export type TLicenseSegment = yup.InferType<typeof licenseSegmentSchema>;
export type TLicenseSegmentTerms = yup.InferType<typeof segmentTermsSchema>;
