import clsx from "clsx";

export default function Section({ children, className = null }) {
  return (
    <div className={clsx("grid grid-cols-1 gap-5", className)}>{children}</div>
  );
}

Section.Header = ({ children, className = null }) => (
  <div
    className={clsx(
      "flex flex-row flex-wrap justify-between items-center",
      className
    )}
  >
    {children}
  </div>
);

Section.Info = ({ children, className = null }) => {
  return <div className={clsx("flex flex-col", className)}>{children}</div>;
};

Section.Title = ({ children, className = "text-lg" }) => (
  <h4 className={clsx("font-medium", className)}>{children}</h4>
);

Section.Subtitle = ({ children, className = null }) => (
  <h4 className={clsx("text-sm text-muted-foreground", className)}>
    {children}
  </h4>
);

Section.Actions = ({ children, className = null }) => (
  <div
    className={clsx("flex flex-row flex-wrap gap-2 items-center", className)}
  >
    {children}
  </div>
);

Section.Head = ({ title = null, subtitle = null, actions = null }) => {
  return (
    <Section.Header>
      <Section.Info>
        {title && <Section.Title>{title}</Section.Title>}
        {subtitle && <Section.Subtitle>{subtitle}</Section.Subtitle>}
      </Section.Info>
      {actions && <Section.Actions>{actions}</Section.Actions>}
    </Section.Header>
  );
};
