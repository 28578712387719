import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { Fragment, ReactElement, useRef } from "react";
import {
  ToggleConsumer,
  ToggleProvider,
  useToggleContext,
} from "../contexts/ToggleContext";
import OutsideClick from "./OutsideClick";
import Popover from "./Popper";

const DropdownItem = ({ children, className = null, onClick, ...props }) => {
  const { onClose } = useToggleContext();
  return (
    <div
      {...props}
      onClick={(e) => {
        onClose?.();
        onClick?.(e);
      }}
      className={clsx(
        "hover:bg-gray-100 hover:dark:bg-zinc-800 hover:text-gray-900 hover:dark:text-white",
        "text-gray-700 dark:text-zinc-200",
        "flex flex-row items-center cursor-pointer content-center px-4 py-2 text-sm",
        className
      )}
    >
      {children}
    </div>
  );
};

Dropdown.Group = ({ children }) => {
  return <div className="py-1">{children}</div>;
};

function Dropdown({
  usePortal = true,
  children = [] as ReactElement[],
  disabled = false,
  disableBorder = false,
  disableFocus = false,
  disableBackground = false,
  label,
  divide = true,
  className = null,
}) {
  const button = useRef<HTMLElement>(null);
  return (
    <ToggleProvider>
      <ToggleConsumer>
        {({ isOpen, onOpen, onClose }) => (
          <div className={clsx("relative text-left", className)}>
            <button
              type="button"
              disabled={disabled}
              ref={button as any}
              onClick={() => {
                if (isOpen) {
                  onClose();
                } else {
                  onOpen();
                }
              }}
              className={clsx(
                !disableBorder &&
                  "border border-gray-300 shadow-sm hover:bg-gray-50",
                !disableBackground && "bg-card",
                !disableFocus &&
                  "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary",
                "rounded-md inline-flex justify-center w-full h-full px-4 py-2 text-sm font-medium text-gray-700 dark:text-zinc-300"
              )}
            >
              {label}
              {!disabled && (
                <ChevronDownIcon
                  className="ltr:-mr-1 ltr:ml-1 rtl:-ml-1 rtl:mr-1 h-5 w-5"
                  aria-hidden="true"
                />
              )}
            </button>
            <Popover referenceElement={button?.current} portal={usePortal}>
              <Transition appear show={!!isOpen} as={Fragment}>
                <Transition.Child
                  as="div"
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <OutsideClick onOutsideClick={onClose} enabled={isOpen}>
                    <div
                      className={clsx(
                        divide && "divide-y divide-gray-100",
                        "z-50 rounded-md w-56 mt-2 bg-card divide-y divide-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      )}
                    >
                      {children}
                    </div>
                  </OutsideClick>
                </Transition.Child>
              </Transition>
            </Popover>
          </div>
        )}
      </ToggleConsumer>
    </ToggleProvider>
  );
}

Dropdown.Item = DropdownItem;

export default Dropdown;
