import { OUTGOING_SESSION_TYPES } from "@gethere/common/settings";
import { sessionUserFeedbackSchema } from "@gethere/common/yup/SessionUpdateActions";
import { StarIcon } from "@heroicons/react/24/solid";
import { captureException } from "@sentry/react";
import { useOnValueChange } from "@shopify/react-hooks";
import clsx from "clsx";
import { useFormik } from "formik";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import Btn, { BtnLink, HtmlAnchor } from "../components/Btn";
import Input from "../components/Input";
import BottomPageContainer from "../components/SafeArea";
import { useSession } from "../contexts/SessionContext";
import { useSessionDrawer } from "../contexts/SessionDrawerContext";
import {
  DrawerScreenParams,
  useSessionDrawerNavigation,
} from "../contexts/SessionDrawerNavigationContext";
import { UserFields } from "../contexts/UserContext";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import {
  sessionPlaceSelector,
  sessionSelector,
  sessionUpdated,
  sessionUserSelector,
  updateLocalSessionMeta,
} from "../state/reducers/session";
import { RootState } from "../state/store";
import client from "../utils/client";
import errorify from "../utils/errorify";
import useAsync from "../utils/useAsync";
import SessionBill from "./SessionBill";
import SessionPaymentsProvider from "./SessionPayments";
import SessionPayRemaing from "./SessionPayRemaining";
import PoweredBy from "./SessionPoweredBy";
import SessionSplitPayment from "./SessionSplitRemaining";
import SignInContainer from "./SignInContainer";
import EnsureUserSignedWhenNeeded from "./EnsureUserSignedWhenNeeded";
import ReviewComponent from "./ReviewComponent";
import { Card, CardContent } from "../components/ui/card";

export const OpenOrderDrawerButton = () => {
  const intl = useIntl();

  const { onOpen, isOpen } = useSessionDrawer();

  const { status } = useSession();

  const hasItems = useSelector(
    (s: RootState) => sessionSelector(s)?.check?.total > 0
  );

  const havePendings = status?.pendings?.amount > 0;

  const shown = isOpen
    ? false
    : hasItems || havePendings || status?.pendings.balance !== 0;

  if (!shown) return null;

  return (
    <BottomPageContainer className="w-full bg-gradient-to-t from-bg fixed bottom-0 pb-2">
      <div className="px-5 w-full">
        <Btn
          fullWidth
          color="primary"
          onClick={onOpen}
          size="2xl"
          className="shadow-xl max-w-screen-xl mx-auto"
        >
          {intl.formatMessage({
            id: "view_order",
            defaultMessage: "View Order",
          })}
        </Btn>
      </div>
    </BottomPageContainer>
  );
};

const SessionDrawer = ({ isOpen, onClose }) => {
  const { status } = useSession();
  if (status?.handoff) return null;

  return (
    <BottomSheet
      expandOnContentDrag
      key="item_dialog"
      open={Boolean(isOpen)}
      onDismiss={onClose}
      snapPoints={({ minHeight }) => [minHeight]}
    >
      <DrawerContent />
    </BottomSheet>
  );
};

const OrderAgainButton = () => {
  const intl = useIntl();
  const { load } = useSession();
  const { onClose } = useSessionDrawer();

  const haveBookings = useAppSelector(
    (s) => sessionPlaceSelector(s)?.bookings?.enabled
  );

  const { terminalId, placeId } = useAppSelector((state) => {
    const session = sessionSelector(state);
    return {
      terminalId: session?.terminalId,
      placeId: session?.placeId,
    };
  });

  const reload = useAsync(async ({ tId }: { tId: string }) => {
    // todo: add analytics re order.
    try {
      await load({ tId });
      onClose();
    } catch (error) {
      captureException(error);
    }
  });

  if (!terminalId) return null;

  return (
    <div className="flex flex-col gap-5">
      <Btn
        fullWidth
        variant="contained"
        color="text"
        className="mt-auto"
        onClick={() => reload.execute({ tId: terminalId })}
        loading={reload.loading}
        disabled={reload.loading}
        size="lg"
      >
        {intl.formatMessage({
          id: "order_again",
          defaultMessage: "Order Again",
        })}
      </Btn>
      {haveBookings && (
        <BtnLink
          Component={HtmlAnchor}
          variant="outline"
          color="text"
          size="lg"
          href={`https://tableport.io/p/${placeId}/booking`}
        >
          Book your next visit
        </BtnLink>
      )}
    </div>
  );
};

const Rating = ({ value, onChange, disabled }) => {
  return (
    <div className="flex items-center gap-1 flex-row">
      {[1, 2, 3, 4, 5].map((i) => {
        if (value >= i)
          return (
            <StarIcon
              key={`star-${i}`}
              className={clsx(
                "duration-300 w-7 h-7",
                value === 1 && "text-red-400",
                value === 2 && "text-orange-400",
                value === 3 && "text-yellow-400",
                value === 4 && "text-green-400",
                value === 5 && "text-blue-500",
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              )}
              onClick={() => !disabled && onChange(i)}
            />
          );
        return (
          <StarIcon
            key={`star-${i}`}
            className={clsx(
              "duration-300 w-7 h-7 text-gray-300 dark:text-gray-500",
              disabled ? "cursor-not-allowed" : "cursor-pointer"
            )}
            onClick={() => !disabled && onChange(i)}
          />
        );
      })}
    </div>
  );
};
export const SessionFeedback = () => {
  const dispatch = useAppDispatch();
  const session = useSelector((s: RootState) => sessionSelector(s));
  const place = useSelector((s: RootState) => sessionPlaceSelector(s));

  return (
    <Card>
      <CardContent>
        <ReviewComponent
          place={place}
          session={session}
          reviewId={session?.meta?.reviewId}
          onSuccess={(review) => {
            dispatch(updateLocalSessionMeta({ reviewId: review.id }));
          }}
        />
      </CardContent>
    </Card>
  );
};

export const DrawerBill = ({
  params,
}: {
  params: DrawerScreenParams["bill"];
}) => {
  const drawer = useSessionDrawer();
  const { status } = useSession();
  const check = useAppSelector((s) => sessionSelector(s)?.check);

  const hasAny = !!status?.pendings?.amount || !!check?.total;

  useOnValueChange(hasAny, (now, before) => {
    if (before && !now) {
      drawer.onClose();
    }
  });

  return (
    <>
      <SessionBill />
      {status?.ended && <SessionFeedback />}
      {status?.ended && <OrderAgainButton />}
    </>
  );
};

export const DrawerSignIn = ({
  params,
}: {
  params: DrawerScreenParams["sign_in"];
}) => {
  const nav = useSessionDrawerNavigation();

  return (
    <SignInContainer
      fields={params.fields}
      allowGuest
      forceDetails
      onSuccess={() => {
        nav.setScreen(params.next || { name: "bill", params: {} });
      }}
    />
  );
};

const EnsureUserSignedWhenNeededSession = ({ children }) => {
  const sessionType = useAppSelector((s) => sessionSelector(s)?.type);

  const requiredUserFields = useMemo((): UserFields[] => {
    const fields: UserFields[] = [];

    if (OUTGOING_SESSION_TYPES.includes(sessionType)) {
      return ["name.first", "name.last"];
    }

    return fields;
  }, [sessionType]);

  return (
    <EnsureUserSignedWhenNeeded requiredUserFields={requiredUserFields}>
      {children}
    </EnsureUserSignedWhenNeeded>
  );
};

export const DrawerContent = () => {
  const nav = useSessionDrawerNavigation();

  return (
    <SessionPaymentsProvider>
      <div className="flex-auto flex flex-col mx-auto w-full h-full max-w-xl">
        <EnsureUserSignedWhenNeededSession>
          <div className="flex flex-col gap-5 w-full h-full px-5 pt-5">
            {nav.name === "sign_in" && <DrawerSignIn params={nav.params} />}
            {nav.name === "bill" && <DrawerBill params={nav.params} />}
            {nav.name === "pay_custom" && (
              <SessionPayRemaing params={nav.params} />
            )}
            {nav.name === "split_equally" && (
              <SessionSplitPayment params={nav.params} />
            )}
          </div>
        </EnsureUserSignedWhenNeededSession>
        <PoweredBy className="mb-5" />
      </div>
    </SessionPaymentsProvider>
  );
};

export default SessionDrawer;
