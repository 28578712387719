import * as yup from "yup";

export const itemCategoryTypes = ["f", "b", "r"] as const; // food, beverage, retail

export type ItemCategoryType = (typeof itemCategoryTypes)[number];

export enum ItemCategoryTypes {
  food = "f",
  beverage = "b",
  retail = "r",
}

export const itemCategorySchema = yup
  .object({
    id: yup.string(),
    name: yup.string().default("").min(2).max(25),
    businessId: yup.string().nullable(),
    type: yup.mixed<ItemCategoryType>().oneOf(itemCategoryTypes).notRequired(),
    createdAt: yup.date().nullable(),
    updatedAt: yup.date().nullable(),
  })
  .required();

export type TItemCategory = yup.InferType<typeof itemCategorySchema>;
