import createClient, { ClientUserInfo } from "@gethere/common/Client";
import { CLIENT_ID_LOCAL_KEY } from "@gethere/common/settings";
import { UserAuthResponseBody } from "@gethere/common/types";
import cuid from "cuid";
import {
  addBreadcrumb,
  captureException,
  captureMessage,
  setTag,
} from "@sentry/react";
import env, { appName, appVersion } from "./env";
import waitFor from "@gethere/common/utils/waitFor";
import store, { RootState } from "../state/store";
import { onLogout, onUserUpdate } from "../state/reducers/user";

const variant = `${appName}@${appVersion}`;

const getState = (): Promise<RootState> =>
  waitFor(
    () => {
      const state = store?.getState?.();
      if (state._persist?.rehydrated) return state;
    },
    { ms: 300, maxRetries: 10 }
  );

const getUserInfo = async (): Promise<ClientUserInfo> => {
  const state = await getState();
  return {
    userId: state.user.result,
    locale: state.system.locale,
  };
};

let channel: BroadcastChannel;

try {
  if (BroadcastChannel) {
    channel = new BroadcastChannel("tableport_bc");
  }
} catch (error) {
  captureException(error);
}

type TokenRefreshBroadcastMessage = {
  message: "token_refreshed";
} & Partial<UserAuthResponseBody> &
  Pick<UserAuthResponseBody, "access">;

type BroadcastMessages =
  | { message: "new_tab" }
  | TokenRefreshBroadcastMessage
  | { message: "logout" };

export function postChannelMessage(data: BroadcastMessages) {
  try {
    if (channel && BroadcastChannel && channel.postMessage) {
      channel.postMessage(data);
    }
  } catch (error) {
    captureException(error);
  }
}

postChannelMessage({ message: "new_tab" });

const client = createClient({
  baseUrl: env.api,
  variant,
  getUserInfo,
  ws: { uri: env.ws },
  clientIdentifier: {
    receive: () => localStorage.getItem(CLIENT_ID_LOCAL_KEY),
    create: () => cuid(),
    set: (id) => localStorage.setItem(CLIENT_ID_LOCAL_KEY, id),
  },
  onRefreshSuccess: async ({
    access,
    accessExpAt,
    refresh,
    result,
    entities,
  }) => {
    postChannelMessage({
      message: "token_refreshed",
      refresh,
      access,
      accessExpAt,
      result,
      entities,
    });
    store.dispatch(onUserUpdate({ result, entities }));
  },
  onLogout: () => {
    store.dispatch(onLogout());
    postChannelMessage({ message: "logout" });
  },
  captureException: captureException,
  addBreadcrumb: addBreadcrumb,
  captureMessage: captureMessage,
  setTag: setTag,
});

try {
  if (channel) {
    channel.onmessage = (e) => {
      try {
        const payload = e.data as BroadcastMessages;
        const message = payload.message;
        switch (message) {
          case "logout": {
            return store.dispatch(onLogout());
          }

          case "new_tab": {
            const token = client.authenticator.accessToken;
            if (token) {
              postChannelMessage({
                message: "token_refreshed",
                access: token,
              });
            }
            return;
          }

          case "token_refreshed": {
            const { access, result, entities, accessExpAt } = payload;

            if (access) {
              client.authenticator.setSecureTokens({ access, accessExpAt });
            }

            if (result && entities) {
              store.dispatch(onUserUpdate({ result, entities }));
            }
            return;
          }

          default:
            break;
        }
      } catch (error) {
        captureException(error);
      }
    };
  }
} catch (error) {
  captureException(error);
}

export default client;
