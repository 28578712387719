import clsx from "clsx";

export function Surface({ children, className = null, ...props }) {
  return (
    <div
      {...props}
      className={clsx("shadow h-min rounded-md overflow-hidden", className)}
    >
      {children}
    </div>
  );
}

Surface.Content = ({ children, className = null, disablePadding = false }) => (
  <div
    className={clsx(
      "bg-card h-min",
      !disablePadding && "p-5 bg-card sm:p-6 ",
      className
    )}
  >
    {children}
  </div>
);

Surface.Footer = ({ children, className = null }) => {
  return (
    <div
      className={clsx(
        "px-4 py-3 bg-gray-50 dark:bg-zinc-800 text-right sm:px-6 flex flex-row justify-between",
        className
      )}
    >
      {children}
    </div>
  );
};
