import { useIntl } from "react-intl";
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";

import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { TImage } from "@gethere/common/yup/Image";
import { Picture } from "../components/Picture";
import clsx from "clsx";

type Review = {
  name: string;
  personTitle: string;
  review: string;
  srcset?: TImage;
};

const suda = {
  srcset: {
    type: "image/png",
    src: "https://static.tableport.io/iljijs8j6000008m6anzf8vxw.png",
    webp: "https://static.tableport.io/iljijs8j6000008m6anzf8vxw.webp",
  },
  name: "Suda Sharma",
  personTitle: "Owner of Zhug and Suda Delicatessen, Nottingham",
  review: `I've seen a massive improvement in our operations since introducing TablePort at Zhug and Suda Delicatessen.The platform is so friendly that our team picked it up in no time, and the support team is always there for us, adding to the overall great service experience.
    Our customers are absolutely thrilled with the new convenience of ordering and paying directly from their tables. What's more, they can add more items to their tab as they wish. This new level of engagement with TablePort has not only streamlined their dining experience but also noticeably increased our revenue per bill.
    The bill-splitting feature is another winner, particularly for large groups. It's fast, easy, and works like a charm! I highly recommend TablePort to anyone looking to improve their restaurant operations.`,
};

const yossi = {
  name: "Yossi Edri",
  srcset: {
    type: "image/png",
    src: "https://static.tableport.io/iljijtcub000108m65xf91lk1.png",
    webp: "https://static.tableport.io/iljijtcub000108m65xf91lk1.webp",
  },
  personTitle: "Owner & Chef of The Gate Restaurants, London",
  review: `Over my twenty years in the restaurant business, I've come across countless tools claiming to be 'the one', But TablePort has genuinely lived up to that promise.Managing 3 restaurants has never been easier, thanks to TablePort, it seamlessly unify every aspect of our operations.
 It's a unique platform that lets me keep my finger on the pulse of the operations, even from my own mobile, whether I'm on site or not.\n
 What's more, our customers have truly embraced TablePort's online payment solution. The ease, security and convenience it offers have led to outstanding feedback, significantly enhancing our service experience.Their support team is also always on hand to help - truly one of a kind in terms of industry standards, and the onboarding was surprisingly fast and simple.\n
 In essence, TablePort has been a game-changer for us. It's provided us with real-time data, helped us tackle labour shortages, and made managing multi-location operations so much easier for us. To any fellow restaurateurs grappling with similar challenges, I cannot recommend TablePort highly enough.`,
};

const reviews: Review[] = [yossi, suda, yossi, suda];

const Slide = ({ name, personTitle, review, srcset }: Review) => {
  const slide = useSwiperSlide();
  return (
    <blockquote
      className={
        "select-none overflow-hidden m-2 cursor-grab hover:scale-[1.01] flex h-full flex-col md:flex-row justify-between bg-black rounded-lg hover:shadow-xl shadow-lg duration-200 px-5 md:px-10 lg:px-16 relative"
      }
    >
      <div className="w-full md:w-2/3 py-12">
        <div className="flex flex-col">
          <p className="mt-4 leading-relaxed text-white">{review}</p>
          <footer className="mt-8 text-md text-slate-300">
            &mdash; <b>{name}</b> {personTitle}
          </footer>
        </div>
      </div>
      <div
        className={clsx(
          "w-full md:w-1/3 relative h-full mt-auto duration-300",
          !slide.isActive && "grayscale-100 blur-2xl",
          slide.isActive && "grayscale-0 blur-0 fade-in scale-105"
        )}
      >
        <Picture
          className="object-cover hidden lg:flex flex-1"
          srcset={srcset}
          alt={name}
          sourceHeight={400}
          sourceWidth={400}
        />
        <Picture
          className="w-full h-18 object-contain md:hidden"
          srcset={srcset}
          alt={name}
          sourceHeight={400}
          sourceWidth={400}
        />
      </div>
    </blockquote>
  );
};

const TestemonialsNav = () => {
  const swiper = useSwiper();
  return (
    <div className="lg:mt-8 flex gap-4 px-10 pt-5">
      <button
        onClick={() => swiper.slidePrev()}
        className="prev-button rounded-full border border-primary p-3 text-primary hover:bg-primary-600 hover:text-white"
      >
        <span className="sr-only">Previous Slide</span>
        <ArrowLeftIcon className="h-5 w-5 rtl:rotate-180 stroke-current" />
      </button>
      <button
        onClick={() => swiper.slideNext()}
        className="next-button rounded-full border border-primary p-3 text-primary hover:bg-primary-600 hover:text-white"
      >
        <span className="sr-only">Next Slide</span>
        <ArrowRightIcon className="h-5 w-5 rtl:rotate-180 stroke-current" />
      </button>
    </div>
  );
};

const Testimonials = () => {
  return (
    <div className="w-full">
      <h2 className="text-4xl font-bold tracking-tight sm:text-5xl px-10 pb-10 text-center">
        We 💙 Our Customers
      </h2>
      <Swiper
        centeredSlides
        keyboard={{ enabled: true, onlyInViewport: true }}
        slidesPerView={1.1}
        loop={true}
        pagination={{ clickable: true }}
        wrapperClass="items-center"
      >
        {reviews.map((review, index) => (
          <SwiperSlide key={index}>
            <Slide
              review={review.review}
              name={review.name}
              personTitle={review.personTitle}
              srcset={review.srcset}
            />
          </SwiperSlide>
        ))}
        <TestemonialsNav />
      </Swiper>
    </div>
  );
};

const TestemonialsBlock = () => {
  const intl = useIntl();
  return (
    <div className="max-w-screen-xl self-center w-full px-5 xl:px-0">
      <div className="rounded-xl flex flex-col items-center justify-center ring-1 hover:shadow-lg overflow-hidden ring-zinc-200 dark:ring-zinc-700 shadow-md md:flex-4 duration-500 bg-zinc-100 dark:bg-zinc-800 group py-10">
        <Testimonials />
      </div>
    </div>
  );
};

export default TestemonialsBlock;
