import { selectUnit } from "@formatjs/intl-utils";
import { captureException } from "@sentry/react";
import { useOnValueChange } from "@shopify/react-hooks";
import { useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import useInterval from "../utils/useInterval";

const calc = (
  value: Date | string,
  intl: IntlShape,
  defaultInterval?: any,
  options = {}
) => {
  const selected = selectUnit(new Date(value), Date.now());
  let intr = defaultInterval;

  if (!intr) {
    if (selected.unit === "second") intr = 1;
    if (selected.unit === "minute") intr = 10;
  }

  if (["day", "week", "month", "quarter", "year"].includes(selected.unit))
    intr = undefined;

  return {
    value: intl.formatRelativeTime(selected.value, selected.unit),
    interval: intr,
    past: Date.now() - new Date(value).getTime() > 100,
  };
};

const FormattedRelative = ({
  value,
  updateIntervalInSeconds,
  onValuePast = null as () => void,
  ...props
}) => {
  const intl = useIntl();

  const [state, setState] = useState(() =>
    calc(value, intl, updateIntervalInSeconds)
  );

  useOnValueChange(state.past && !!onValuePast, (value, old) => {
    if (!old && value) onValuePast?.();
  });

  try {
    useInterval(() => {
      if (state.interval) {
        setState(calc(value, intl, updateIntervalInSeconds));
      }
    }, state.interval * 1000);

    return <span {...props}>{state.value}</span>;
  } catch (error) {
    captureException(error);
    return null;
  }
};

export default FormattedRelative;
