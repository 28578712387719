export interface ClientIdentifierOptions {
  receive: () => Promise<string> | string;
  create: () => Promise<string> | string;
  set: (id: string) => Promise<void> | void;
}

class ClientIdentifier {
  private _id?: string;
  public promise: Promise<void>;

  constructor(options: ClientIdentifierOptions) {
    this.promise = this.init(options);
  }

  public get id() {
    return this._id; // this is client id
  }

  private async init(options: ClientIdentifierOptions): Promise<void> {
    try {
      const receivedId = await options.receive();
      if (receivedId) {
        this._id = receivedId;
      } else {
        const uniqueId = await options.create();
        await options.set(uniqueId);
        this._id = uniqueId;
      }
    } catch (error) {
      console.error("Error initializing client identifier:", error);
    }
  }
}

export default ClientIdentifier;
