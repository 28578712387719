import clsx from "clsx";

function FormSection({
  children,
  vertical = false,
  className = null,
}: {
  children: any | any[];
  vertical?: boolean;
  className?: string;
}) {
  return (
    <div
      className={clsx(
        !vertical && "md:grid md:grid-cols-3 md:gap-6",
        className
      )}
    >
      {children}
    </div>
  );
}

FormSection.Header = ({
  children = null,
  className = null,
  disablePadding = false,
}) => (
  <div className="md:col-span-1">
    <div className={clsx(!disablePadding && "px-4 sm:px-0", className)}>
      {children}
    </div>
  </div>
);

FormSection.Title = ({ children = null, className = null }) => (
  <h3
    className={clsx(
      "text-lg font-medium leading-6 dark:text-gray-100 text-gray-900",
      className
    )}
  >
    {children}
  </h3>
);

FormSection.Desc = ({ children = null }) => (
  <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">{children}</p>
);

FormSection.Body = ({
  children,
  vertical = false,
  className = null,
}: {
  children: any | any[];
  vertical?: boolean;
  className?: string;
}) => (
  <div
    className={clsx("mt-5", !vertical && "md:mt-0 md:col-span-2", className)}
  >
    {children}
  </div>
);

export default FormSection;
