import { TSession } from "@gethere/common/yup/Session";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMemo } from "react";

const fonts = [
  {
    cssSrc: "https://fonts.googleapis.com/css?family=Albert+Sans",
  },
];

export const StripeElementsProvider = ({
  children,
  settings,
}: {
  children: any;
  settings: TSession["settings"]["payments"];
}) => {
  const promise = useMemo(() => {
    return loadStripe(settings?.payload?.["publicKey"]);
  }, [settings?.id]);

  return (
    <Elements options={{ fonts }} stripe={promise}>
      {children}
    </Elements>
  );
};
