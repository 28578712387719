import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig,
} from "axios";
import { ClientOptions, ClientUserInfo, SharedClient } from "./Client";
import {
  HTTP_ACCESS_HEADER,
  HTTP_CLIENT_APP_HEADER,
  HTTP_CLIENT_ID_HEADER,
  HTTP_ERROR_EVENT_ID,
  HTTP_LOCALE_HEADER,
  HTTP_REFRESH_HEADER,
  HTTP_TOKEN_EXPIRE_CODE,
  REFRESH_TOKEN_ROUTE,
} from "./settings";
import sleep from "./utils/sleep";
import withTimeout from "./utils/withTimeout";
import * as RestAPI from "./RestAPI";

class TokenManager {
  private client: SharedClient;
  private options: ClientOptions;
  private _refreshing: boolean;
  private _subscribers: (() => void)[];
  private _access: string | null;
  private _accessExp: number | null;
  private userInfo: ClientUserInfo | null;
  private clientId: string | null;

  constructor(client: SharedClient, options: ClientOptions) {
    this._subscribers = [];
    this._refreshing = false;
    this._access = null;
    this._accessExp = null;
    this.client = client;
    this.options = options;
    this.userInfo = null;
    this.clientId = null;

    // if (!options.secureStorage?.getItem) {
    // console.warn("No secure storage getItem provided");
    // options.captureMessage?.("No secure storage getItem provided");
    // }

    // if (!options.secureStorage?.setItem) {
    // console.warn("No secure storage setItem provided");
    // options.captureMessage?.("No secure storage setItem provided");
    // }
  }

  private async getRefreshToken(retry = 0): Promise<string | null> {
    const getItem = this.options.secureStorage?.getItem;

    if (typeof getItem === "function") {
      try {
        const stored = await getItem(HTTP_REFRESH_HEADER);

        if (!stored) {
          throw new Error("refresh token not found on storage");
        }

        return stored;
      } catch (error) {
        if (retry < 3) {
          await sleep(250);
          return this.getRefreshToken(retry + 1);
        } else {
          this.options.captureException?.(error);
          return null;
        }
      }
    } else {
      return null;
    }
  }

  private isAccessPast(): boolean {
    return this._accessExp ? this._accessExp < Date.now() : false;
  }

  public get accessToken(): string | null {
    if (this._access) {
      if (this.isAccessPast()) return null;
      return String(this._access);
    }

    return null;
  }

  public async setSecureTokens({
    refresh,
    access,
    accessExpAt,
  }: {
    refresh?: string;
    access: string;
    accessExpAt: number | null;
  }) {
    this.userInfo = null; // clear user info cache
    this._access = String(access);
    this._accessExp = Number(accessExpAt);

    const setItem = this.options.secureStorage?.setItem;

    if (typeof setItem === "function" && typeof refresh === "string") {
      try {
        await setItem(HTTP_REFRESH_HEADER, refresh);
      } catch (error) {
        this.options.addBreadcrumb?.({
          message: "Error setting refresh token",
        });

        this.options.captureException?.(error);
      }
    }
  }

  private isRefreshRequest(
    config: InternalAxiosRequestConfig<any> | undefined
  ): boolean {
    return config?.url?.includes(REFRESH_TOKEN_ROUTE.path) ?? false;
  }

  public get hasAccessToken(): boolean {
    return Boolean(this._access) && !this.isAccessPast();
  }

  public clear() {
    this._access = null;
    this.userInfo = null;
    this.setSecureTokens({ access: "", refresh: "", accessExpAt: null }).catch(
      this.options.captureException || console.error || ((e) => console.log(e))
    );
  }

  private handleLogout() {
    this.client.logout?.();
    this.userInfo = null;
    this.clear();
  }

  public async refreshAccess(): Promise<void> {
    if (this._refreshing) {
      await this.waitForTokenRefreshed();
      return;
    }

    // console.log("refreshing access token");

    this._refreshing = true;

    const refreshToken = await this.getRefreshToken();

    try {
      const options: AxiosRequestConfig = {
        method: REFRESH_TOKEN_ROUTE.method,
      };

      if (refreshToken) {
        if (!options.headers) options.headers = {};
        options.headers[HTTP_REFRESH_HEADER] = refreshToken;
      }

      const res = await this.client<RestAPI.Auth.Refresh.Response>(
        REFRESH_TOKEN_ROUTE.path,
        options
      );

      const { access, refresh, accessExpAt } = res.data;

      this.setSecureTokens({ refresh, access, accessExpAt });
      this.options.onRefreshSuccess?.(res.data);
      this._refreshing = false;
      this.onRefreshed();
    } catch (error) {
      console.log(error);
      this.options?.captureException?.(error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // we have a response from server
          const status = error.response.status;
          if (status > 399 && status < 499) {
            this.options.addBreadcrumb?.({
              message: "Authenticator: failed refreshing token",
              data: {
                status,
                error: error.response.data,
              },
            });

            this.options.captureMessage?.("Logging out on failed refresh");
            this.handleLogout();
            this._refreshing = false;
          }
        }
      } else {
        this._refreshing = false;
      }
    }
  }

  public async ensureUserInfo() {
    try {
      if (this.userInfo) {
        return;
      }

      this.userInfo = await this.options.getUserInfo();
    } catch (error) {
      console.error("Error getting user info:", error);
      this.client.addBreadcrumb?.({
        message: "SharedClient: Error getting user info",
      });
      this.client.captureException?.(error);
    }
  }

  public async ensureClientId() {
    try {
      if (this.clientId) return;

      if (this.client.clientIdentifier.promise) {
        await this.client.clientIdentifier.promise;
      }

      this.clientId = this.client.clientIdentifier.id || null;
    } catch (error) {
      console.error("Error getting client identifier:", error);
      this.client.addBreadcrumb?.({
        message: "SharedClient: Error getting client identifier",
      });
      this.client.captureException?.(error);
    }
  }

  public get isRefreshing(): boolean {
    return Boolean(this._refreshing);
  }

  public isLogoutRequest(config: AxiosRequestConfig): boolean {
    return Boolean(config.url?.includes("/logout"));
  }

  public async requestInterceptor(
    config: InternalAxiosRequestConfig<any>
  ): Promise<InternalAxiosRequestConfig<any>> {
    const isRefresh = this.isRefreshRequest(config);

    // if we are refreshing, wait for it to finish
    if (this._refreshing && !isRefresh) {
      await this.waitForTokenRefreshed();
    }

    await Promise.all([this.ensureClientId(), this.ensureUserInfo()]);

    const hasDeviceId = Boolean(this.userInfo?.deviceId);
    const hasUserId = Boolean(this.userInfo?.userId);
    const shouldAccessToken = hasUserId || hasDeviceId;

    if (!isRefresh && shouldAccessToken) {
      if (!this.hasAccessToken && !this.isLogoutRequest(config)) {
        await this.refreshAccess();
      }
    }

    if (this.hasAccessToken && !isRefresh) this.applyReqAccessToken(config);

    if (!config.headers) config.headers = {} as AxiosRequestHeaders;

    config.headers[HTTP_CLIENT_APP_HEADER] = this.options.variant;

    if (this.userInfo?.locale) {
      config.headers[HTTP_LOCALE_HEADER] = this.userInfo.locale;
    }

    if (this.clientId) {
      config.headers[HTTP_CLIENT_ID_HEADER] = this.clientId;
    }

    return config;
  }

  public subscribeTokenRefresh(cb: () => void): void {
    if (typeof cb === "function") this._subscribers.push(cb);
  }

  public waitForTokenRefreshed(): Promise<void> {
    if (!this._refreshing) return Promise.resolve();

    return withTimeout(
      new Promise((resolve) => {
        this.subscribeTokenRefresh(resolve);
      }),
      60000,
      "Timeout waiting for refresh"
    );
  }

  private onRefreshed(): void {
    for (let cb of this._subscribers) {
      if (typeof cb === "function") cb();
    }

    this._subscribers = [];
  }

  private applyReqAccessToken(
    config: InternalAxiosRequestConfig<any> | undefined
  ) {
    if (!config) return;
    if (!config.headers) config.headers = {} as AxiosRequestHeaders;
    config.headers[HTTP_ACCESS_HEADER] = `Bearer ${this.accessToken}`;
  }

  private setRetryFlag(config: InternalAxiosRequestConfig<any> | undefined) {
    if (!config) return;
    config["_retry"] = true;
  }

  private isRetryRequest(config: InternalAxiosRequestConfig<any> | undefined) {
    return Boolean(config?.["_retry"]);
  }

  private isAccessTokenExpired(status: number | undefined) {
    return status === HTTP_TOKEN_EXPIRE_CODE;
  }

  public logError(error: any) {
    this.options?.addBreadcrumb?.({
      category: "SharedClient",
      message: "error occured in response interceptor",
      data: {
        error: error?.response?.data,
        status: error?.response?.status,
        eventId: error?.response?.headers?.[HTTP_ERROR_EVENT_ID],
      },
    });

    this.options?.captureException?.(error);
  }

  public async responseInterceptor(error: any) {
    if (axios.isAxiosError(error)) {
      const originalRequest = error.config;

      if (!this.isAccessTokenExpired(error?.response?.status)) {
        this.logError(error); // we don't need to log access expired error
        return Promise.reject(error);
      }

      // if we are here, it means it's an access token expired error

      if (this.isRefreshRequest(originalRequest)) {
        return Promise.reject(error); // we manage it seperately
      }

      if (this.isRetryRequest(originalRequest)) {
        return Promise.reject(error);
      }

      if (!originalRequest) return Promise.reject(error); // early return if no original request
      // not coming from a retry req or refresh req, and have a config.

      // therefore, we need to refresh.
      await this.refreshAccess();

      this.applyReqAccessToken(originalRequest);

      this.setRetryFlag(originalRequest);

      return this.client(originalRequest);
    } else {
      return Promise.reject(error);
    }
  }
}

export default TokenManager;
