import React, { useState, useCallback, useEffect } from "react";
import parsePhoneNumber, {
  getAsYouType,
} from "@gethere/common/utils/parsePhoneNumber";
import countryToFlag from "../utils/countryToFlag";
import { useIntl } from "react-intl";
import Input, { InputAdornment } from "./Input";
import Dropdown from "./Dropdown";
import { CheckIcon } from "@heroicons/react/24/outline";
import countries from "@gethere/common/countries";
import clsx from "clsx";

const getDefaultRegion = () =>
  window.navigator.language.indexOf("GB") ? "GB" : "US";

export const MobileInput = ({
  value = "",
  onChange,
  autoComplete = "tel",
  className = null,
  defaultRegion = null,
  name,
  touched = false,
  disabled = false,
  helperText = null,
  clearValue = null,
  clearable = false,
  error = null,
  ...props
}: Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> & {
  value: string;
  label?: string;
  defaultRegion?: string;
  clearValue?: any;
  clearable?: boolean;
  helperText?: string;
  error?: string;
  onChange: (v: string) => any;
  touched?: boolean;
}) => {
  const intl = useIntl();
  const [touchedState, setTouch] = useState(Boolean(touched));
  const [focused, setFocus] = useState(false);
  const parentBlur = props?.onBlur;

  const onBlur = useCallback(
    (e) => {
      parentBlur?.(e);
      if (!touchedState) setTouch(true);
      setFocus(false);
    },
    [touchedState, setTouch, parentBlur]
  );

  const onFocus = useCallback(() => {
    setFocus(true);
  }, [setFocus]);

  const [region, setRegion] = useState(() => {
    try {
      return (
        parsePhoneNumber(value).regionCode ||
        defaultRegion ||
        getDefaultRegion()
      );
    } catch (error) {
      return defaultRegion || getDefaultRegion();
    }
  });

  useEffect(() => {
    try {
      const nr = parsePhoneNumber(value).regionCode;
      if (nr && nr !== region) setRegion(nr);
    } catch (error) {}
  }, [region, value, setRegion]);

  const ayt = getAsYouType(region);
  ayt.reset(value);
  const p = ayt.getPhoneNumber();

  const valid = Boolean(p?.valid);
  const possible = Boolean(p?.possible);

  const errorState = p
    ? focused
      ? !possible && value?.trim?.().length > 5
      : (touchedState && !valid) || !!error
    : error;

  const message = errorState
    ? intl.formatMessage({
        id: "mobile_challenge_invalid_number",
      })
    : helperText;

  const messageProps = { [errorState ? "error" : "helperText"]: message };

  const inputValue = valid ? p?.number?.national : ayt?.number?.();

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const empty = event.target.value?.length === 0;
    if (empty) return onChange("");
    ayt.reset(event.target.value);
    const number = ayt.getPhoneNumber();
    if (number.valid) {
      const r = number.regionCode;
      if (r !== region) setRegion(r);
      onChange(number.number?.e164);
    } else {
      onChange(event.target.value);
    }
  };

  const onCountrySelect = (code, pre) => {
    setRegion(code);
    onChange(p.number?.e164?.replace?.("+" + p.countryCode, "+" + pre) ?? "");
  };

  const countryCode = p?.countryCode;

  return (
    <Input
      {...props}
      {...messageProps}
      className={clsx("ltr:pl-24 rtl:pr-24 overflow-hidden", className)}
      name={name}
      disabled={disabled}
      type="tel"
      autoComplete={autoComplete}
      value={inputValue}
      onBlur={onBlur}
      onFocus={onFocus}
      clearValue={clearValue}
      clearable={clearable}
      onChange={onInputChange}
      end={
        p?.valid && !error ? (
          <InputAdornment position="end" absolute>
            <CheckIcon className="relative ltr:-left-4 rtl:-right-4 w-4 h-4 text-gray-400" />
          </InputAdornment>
        ) : null
      }
      start={
        <InputAdornment absolute position="start">
          <Dropdown
            disabled={disabled}
            disableBorder
            disableBackground
            disableFocus
            label={
              <span className="relative w-fit flex h-5 flex-row items-center justify-between gap-1">
                <span className="text-lg">{countryToFlag(region)}</span>
                <span className="text-sm text-muted-foreground">
                  {countryCode ? "+" + countryCode : ""}
                </span>
              </span>
            }
          >
            {Object.keys(countries).map((cc) => {
              const country = countries[cc];
              if (!country) return null;
              return (
                <Dropdown.Item
                  key={cc}
                  className="justify-between"
                  onClick={() => {
                    onCountrySelect(cc, country.phoneCode);
                  }}
                >
                  <span>
                    <span className="mr-2">{countryToFlag(cc)}</span>
                    {country.name}
                  </span>
                  <span className="text-gray-400">+{country.phoneCode}</span>
                </Dropdown.Item>
              );
            })}
          </Dropdown>
        </InputAdornment>
      }
    />
  );
};
