import { TImageObject } from "@gethere/common/yup/Image";
import clsx from "clsx";
import { Picture, PictureProps } from "./Picture";

export const IphoneMockImage = ({
  image,
  className = null,
  alt = "",
}: {
  image: TImageObject;
  className?: string;
  alt?: string;
}) => {
  return (
    <div
      className={clsx(
        "flex relative w-fit flex-col items-center justify-center",
        className
      )}
    >
      <div className="absolute z-0 max-w-[74.6%] max-h-full">
        <Picture
          alt={alt}
          className="rounded-lg bg-card aspect-[9/19.5]"
          srcset={image}
        />
      </div>
      <Picture
        alt="iphone"
        className="w-auto max-h-full flex z-10 relative"
        srcset={{
          src: "https://static.tableport.io/images/il7rstht7000009l25e5w5dnt.png",
          webp: "https://static.tableport.io/images/il7rstht7000009l25e5w5dnt.webp",
          type: "image/png",
        }}
      />
    </div>
  );
};

export const IphoneMockVideo = ({
  src,
  className = null,
  alt = "",
}: {
  src: string;
  className?: string;
  alt?: string;
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center pointer-events-auto"
      )}
    >
      <video
        src={src}
        className="absolute z-0 w-[74.8%] rounded-xl bg-card aspect-[9/19.5] pointer-events-auto"
        playsInline
        autoPlay
        loop
        muted
        aria-label={alt}
        controls={false}
      />
      <Picture
        alt="iphone"
        className={clsx("z-10 relative pointer-events-none", className)}
        srcset={{
          src: "https://static.tableport.io/images/il7rstht7000009l25e5w5dnt.png",
          webp: "https://static.tableport.io/images/il7rstht7000009l25e5w5dnt.webp",
          type: "image/png",
        }}
      />
    </div>
  );
};

export const IpadMockImage = ({
  image,
  className = null,
  alt = "",
}: {
  image: TImageObject;
  className?: string;
  alt?: string;
}) => {
  return (
    <div
      className={clsx(
        "relative flex flex-col items-center justify-center",
        className
      )}
    >
      <div className="absolute z-0 w-auto rounded-3xl">
        <Picture
          className="h-auto w-[89%] mx-auto aspect-[1.439/1] bg-card"
          srcset={image}
          alt={alt}
        />
      </div>
      <Picture
        alt="ipad"
        className="w-auto max-h-[50vh] flex z-10 relative"
        srcset={{
          src: "https://static.tableport.io/images/il7t0rcy00000emul97hj0n42.png",
          webp: "https://static.tableport.io/images/il7t0rcy00000emul97hj0n42.webp",
          type: "image/png",
        }}
      />
    </div>
  );
};

export const IpadMockVideo = ({
  src,
  className = null,
  alt = "",
}: {
  src: string;
  className?: string;
  alt?: string;
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center pointer-events-auto"
      )}
    >
      <video
        src={src}
        className="absolute z-0 w-[88.8%] h-auto pointer-events-auto aspect-[1.439/1] bg-card"
        playsInline
        autoPlay
        loop
        muted
        controls={false}
        aria-label={alt}
      />
      <Picture
        alt="ipad"
        className={clsx("z-10 relative pointer-events-none", className)}
        srcset={{
          src: "https://static.tableport.io/images/il7t0rcy00000emul97hj0n42.png",
          webp: "https://static.tableport.io/images/il7t0rcy00000emul97hj0n42.webp",
          type: "image/png",
        }}
      />
    </div>
  );
};
