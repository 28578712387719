import { throttle } from "lodash";
import React, { useCallback } from "react";

export const SessionCollectionsScrollSpyContext = React.createContext<{
  activeSection: number;
  updateActiveSession: (activeSection: number) => void;
}>({ activeSection: 0, updateActiveSession: () => {} });

export const SessionCollectionsScrollSpyProvider = ({ children }) => {
  const [activeSection, setActiveSection] = React.useState(0);

  const updateActiveSession = useCallback(
    throttle(
      (activeSection: number) => {
        if (activeSection >= 0) setActiveSection(activeSection);
      },
      500,
      {
        leading: true,
        trailing: true,
      }
    ),
    [setActiveSection]
  );

  return (
    <SessionCollectionsScrollSpyContext.Provider
      value={{ activeSection, updateActiveSession }}
    >
      {children}
    </SessionCollectionsScrollSpyContext.Provider>
  );
};
