import {
  Currencies,
  OrderItemStatus,
  SessionSubmitIntents,
  SessionTypes,
} from "@gethere/common/enums";
import * as RestAPI from "@gethere/common/RestAPI";
import { TSession } from "@gethere/common/yup/Session";
import { sessionSyncDraftSchema } from "@gethere/common/yup/SessionUpdateActions";
import { captureException, ErrorBoundary } from "@sentry/react";
import {
  PayIntentSync,
  SessionPaymentsComponent,
} from "../containers/SessionPayments";
import { sessionSelector } from "../state/reducers/session";
import store from "../state/store";
import client from "../utils/client";
import errorify from "../utils/errorify";
import { useSession } from "./SessionContext";
import { useState } from "react";
import Btn from "../components/Btn";
import { useIntl } from "react-intl";

const payIntentSync: PayIntentSync = async ({ localValue }) => {
  try {
    const state = store.getState();
    const session: TSession = JSON.parse(
      JSON.stringify(sessionSelector(state))
    );

    const isDelivery = session.type === SessionTypes.delivery;
    const selectedAddressId = session?.meta?.delivery?.refAddressId;
    const addresses = state.user?.entities?.addresses;
    const userId = state.user.result;
    const address = selectedAddressId ? addresses?.[selectedAddressId] : null;

    if (isDelivery && !address) {
      // snack bar choose pm;
      return {
        error: "session_address_add_desc",
      };
    }

    const filteredOrderItems = session.orderItems.filter(
      (oi: any) =>
        [OrderItemStatus.PENDING].includes(oi.status) &&
        (userId && oi.liableId ? oi.liableId === userId : true)
    );

    const draft = sessionSyncDraftSchema.cast({
      //SessionDraftSubmitionType
      paymentIntent: { value: localValue },
      localId: session["localId"],
      meta: session.meta,
      tips: session.tips.filter((tip: any) => !tip.id),
      type: session.type,
      orderItems: filteredOrderItems,
    });

    if (isDelivery) {
      draft.meta.delivery = {
        refAddressId: selectedAddressId,
        ...addresses[selectedAddressId],
      };
    }

    let payload: Partial<RestAPI.Session.Sync.Payload>;

    if (!session.id || session.id.includes("@")) {
      payload = {
        pId: session.placeId,
        tId: session.terminalId,
        draft,
        intent: SessionSubmitIntents.ORDER_AND_PAY,
      };
    } else {
      payload = {
        intent: SessionSubmitIntents.ORDER_AND_PAY,
        draft,
        sId: session.id,
      };
    }

    const response = await client.api.sessionSync(
      payload as RestAPI.Session.Sync.Payload
    );

    const { result, entities, paymentIntent } = response.data;
    return { result, entities, paymentIntent };
  } catch (error) {
    captureException(error);
    return { error: errorify(error)?.message };
  }
};

export const SubmitOrderAndPay = ({
  currency = Currencies.GBP,
}: {
  currency: string;
}) => {
  const { status } = useSession();
  const intl = useIntl();
  const [pending, setPending] = useState(null);

  if (pending === null && status?.leftOrder > 0) {
    return (
      <div className="grid grid-cols-1 gap-2">
        <Btn
          onClick={() => {
            setPending(false);
          }}
        >
          Order & Clear Balance{" "}
          <span className="font-normal text-xs p-1 rounded-md border-current border ms-1">
            {intl.formatNumber(status?.leftCombined, {
              currency,
              style: "currency",
            })}
          </span>
        </Btn>
        <Btn
          color="text"
          onClick={() => {
            setPending(true);
          }}
        >
          Pay for Current Items Only{" "}
          <span className="font-normal text-xs p-1 rounded-md border-current border ms-1">
            {intl.formatNumber(status?.leftPending, {
              currency,
              style: "currency",
            })}
          </span>
        </Btn>
      </div>
    );
  }

  const value = pending ? status?.leftPending : status?.leftCombined;

  return (
    <ErrorBoundary>
      <SessionPaymentsComponent payIntentSync={payIntentSync} value={value} />
    </ErrorBoundary>
  );
};
