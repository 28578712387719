import { XMarkIcon } from "@heroicons/react/24/outline";
import { Big } from "big.js";
import clsx from "clsx";
import { forwardRef } from "react";
import { useIntl } from "react-intl";
import InputLabel from "./InputLabel";

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  start?: JSX.Element;
  optional?: boolean;
  end?: JSX.Element;
  error?: string;
  helperText?: string;
  label?: string;
  clearable?: boolean;
  clearValue?: any;
  containerClassName?: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      start = null,
      name,
      disabled,
      type = "text",
      end = null,
      label = null,
      value,
      className = null,
      clearValue = null,
      containerClassName = null,
      placeholder = "",
      error = null,
      helperText = null,
      optional = false,
      clearable = false,
      ...props
    },
    ref
  ) => {
    const text = error || helperText;
    return (
      <div className={containerClassName}>
        {label && (
          <InputLabel optional={optional} name={name} error={!!error}>
            {label}
          </InputLabel>
        )}
        <div
          className={clsx("flex flex-row mt-1 relative shadow-sm rounded-md")}
        >
          {start}
          <input
            {...props}
            ref={ref}
            type={type}
            disabled={disabled}
            name={name}
            id={name}
            onBlur={props.onBlur}
            value={value ?? ""}
            className={clsx(
              type === "number" ? "text-right" : "text-left",

              disabled &&
                "cursor-not-allowed border-gray-300 bg-gray-200 dark:bg-zinc-800 text-gray-500 dark:text-zinc-300",

              !disabled &&
                !error &&
                "border-gray-300 dark:border-zinc-700 focus:border-primary focus:ring-2 focus:ring-primary-200 text-base outline-none text-gray-700 dark:text-zinc-100",

              !disabled &&
                error &&
                "border-red-700 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-red-700 dark:text-red-200",
              !disabled && "bg-card",

              "rounded-md flex flex-1 border py-1 px-3 leading-8 transition-colors duration-200 ease-in-out",
              className
            )}
            placeholder={placeholder}
          />
          {end}
          {clearable && value !== clearValue && (
            <span
              className={clsx(
                "flex items-center absolute inset-y-0 cursor-pointer px-5",
                type === "number"
                  ? end
                    ? "left-5"
                    : "left-0"
                  : end
                  ? "right-5"
                  : "right-0"
              )}
              onClick={() =>
                props.onChange?.({ target: { value: clearValue, name } } as any)
              }
            >
              <XMarkIcon className="w-4 h-4 stroke-disabled" />
            </span>
          )}
        </div>
        {!!text && (
          <p className="text-xs mt-1 text-gray-400 dark:text-zinc-300">
            {text.replace(name + " is ", "").replace(name + " ", "")}
          </p>
        )}
      </div>
    );
  }
);

export const InputArea = ({
  name,
  label = null,
  value,
  disabled,
  className = null,
  placeholder = "",
  error = null,
  helperText = null,
  ...props
}: React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  error?: string;
  helperText?: string;
  label?: string;
}) => {
  const text = error || helperText;
  return (
    <div>
      {label && (
        <InputLabel name={name} error={!!error}>
          {label}
        </InputLabel>
      )}
      <div className="flex flex-row mt-1 relative shadow-sm rounded-md">
        <textarea
          {...props}
          name={name}
          id={name}
          disabled={disabled}
          onBlur={props.onBlur}
          value={value ?? ""}
          className={clsx(
            disabled &&
              "cursor-not-allowed bg-gray-200 dark:bg-zinc-800 text-gray-500 dark:text-zinc-300",

            !disabled &&
              !error &&
              "border-gray-300 dark:border-zinc-700 focus:border-primary focus:ring-2 focus:ring-primary-200 text-base outline-none text-gray-700 dark:text-zinc-100",

            !disabled &&
              error &&
              "border-red-700 focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-red-700 dark:text-red-200",
            !disabled && "bg-card",

            "rounded-md flex flex-1 border py-1 px-3 leading-8 transition-colors duration-200 ease-in-out",
            className
          )}
          placeholder={placeholder}
        />
      </div>
      {!!text && (
        <p className="text-xs mt-1 text-gray-400 dark:text-zinc-300">
          {text.replace(name + " is ", "").replace(name + " ", "")}
        </p>
      )}
    </div>
  );
};

export const InputAdornment = ({
  children = null,
  position = "start",
  className = null,
  absolute = null,
}: {
  children?: any;
  position?: "start" | "end";
  className?: string;
  absolute?: boolean;
}) => (
  <div
    className={clsx(
      "text-gray-500 sm:text-sm flex items-center",
      absolute
        ? "absolute inset-y-0 " +
            (position === "start"
              ? "rtl:right-0 ltr:left-0"
              : "rtl:left-0 ltr:right-0")
        : null,
      className
    )}
  >
    {children}
  </div>
);

export default Input;

const stringifyNumber = (value: number, maxFractions: number) => {
  let text = String(value);

  // remove leading zeros
  text = text.replace(/^0+/, "");
  // add leading zero if necessary
  if (text.length === 0) {
    text = "0";
  }
  // remove decimal point
  text = text.replace(/\./g, "");
  // add decimal point and trailing zeros if necessary

  if (maxFractions > 0) {
    if (text.length > maxFractions) {
      text =
        text.substring(0, text.length - maxFractions) +
        "." +
        text.substring(text.length - maxFractions);
    } else if (text.length === maxFractions) {
      text = "0." + text;
    } else {
      text = "0.0" + text;
    }
  }

  return text;
};

export const NumericInput = ({
  value,
  currency,
  unit,
  numberStyle = "decimal",
  onChange,
  max = 1000,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0,
  helperText,
  ...props
}: {
  value: number;
  currency?: string;
  helperText?: string;
  unit?: string;
  onChange: any;
  max?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  numberStyle?: "currency" | "percent" | "decimal" | "unit";
} & InputProps) => {
  const intl = useIntl();

  let start = null;
  let end = null;

  if (numberStyle === "currency" && currency) {
    start = (
      <InputAdornment absolute position="start" className="px-3">
        {intl
          .formatNumber(0, { style: "currency", currency })
          ?.replace(/[0.]/g, "")}
      </InputAdornment>
    );
  }

  if (numberStyle === "unit" && unit) {
    end = (
      <InputAdornment absolute position="end" className="px-3">
        {intl.formatNumber(0, { style: "unit", unit })?.replace(/[0.]/g, "")}
      </InputAdornment>
    );
  }

  const isPercent = numberStyle === "percent";

  if (isPercent) {
    start = (
      <InputAdornment absolute position="start" className="px-3">
        %
      </InputAdornment>
    );
  }

  return (
    <Input
      {...props}
      pattern={`\d{0,5}([,.]\d{1,2})?`}
      onWheel={(event) => event.target?.["blur"]?.()}
      type="number"
      value={
        isPercent
          ? Big(value || 0)
              .mul(100)
              .toString()
          : value
      }
      onChange={(e) => {
        if (isPercent) {
          let next = Big(e.target.value || 0).toNumber();
          next = !next
            ? 0
            : Big(next || 0)
                .div(100)
                .round(4)
                .toNumber();
          onChange({
            ...e,
            target: { ...e.target, value: next, name: props.name },
          });
        } else {
          const num = Number(e.target.value || 0);

          const big = Big(e.target.value || 0)
            .round(2)
            .toNumber();

          let next = e.target.value || 0;

          if (num !== big) {
            next = big as any;
          }

          onChange({
            ...e,
            target: { ...e.target, value: next, name: props.name },
          });
        }
      }}
      className={clsx(
        props.className,
        !!start && "pl-7",
        !!end && "pr-7",
        "text-right"
      )}
      start={start}
      end={end}
    />
  );
};
