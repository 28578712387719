import { ALL_SESSION_TYPES } from "../settings";
import { SessionTypes } from "../enums";
import * as yup from "yup";

export const sessionTypeSchema = yup
  .string<SessionTypes>()
  .oneOf(ALL_SESSION_TYPES);

export const sessionTypesArraySchema = yup
  .array()
  .of(sessionTypeSchema.required().defined())
  .nullable()
  .default(null);
