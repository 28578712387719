import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import { useBeforeUnload } from "./useBeforeUnload"; // Custom hook to handle before unload event
import { TReview, submitReviewSchema } from "@gethere/common/yup/Review"; // Importing the yup schema for review validation
import { Button } from "../components/ui/button";
import { cn } from "../utils/cn";
import { TPlace } from "@gethere/common/yup/Place";
import client from "../utils/client";
import { getReCaptchaToken, useRecaptcha } from "../contexts/ReCaptcha";
import toast from "react-hot-toast";
import errorify from "../utils/errorify";
import { captureException } from "@sentry/react";
import { TSession } from "@gethere/common/yup/Session";

interface EmojiRatingProps {
  rating: number;
  onRating: (name: string, value: number) => void;
  name: string;
  emojis: string[];
}

const EmojiRating: React.FC<EmojiRatingProps> = ({
  rating,
  onRating,
  name,
  emojis,
}) => {
  const getRateBackground = (index: number, selected: boolean) => {
    if (index === 0) return "bg-gradient-to-r from-red-500 to-orange-500";
    if (index === 1) return `bg-gradient-to-r from-orange-200 to-amber-400`;
    if (index === 2) return `bg-gradient-to-r from-amber-200 to-yellow-400`;
    if (index === 3) return `bg-gradient-to-r from-emerald-400 to-cyan-400`;
    if (index === 4) return `bg-gradient-to-r from-indigo-400 to-cyan-400`;
  };

  return (
    <div className="flex flex-row gap-2 w-full justify-center">
      {emojis.map((emoji, index) => {
        const somethingSelected = rating !== 0;
        const isSelected = rating === index + 1;
        return (
          <motion.div
            key={index}
            onClick={() => onRating(name, index + 1)}
            className={cn(
              "relative cursor-pointer shadow-sm items-center ring-2 justify-center rounded-lg overflow-hidden flex flex-1 px-5 py-3 hover:ring-2 h-16",
              isSelected ? "transform shadow-lg" : "ring-transparent",
              somethingSelected
            )}
            whileHover={{ zIndex: 1 }}
          >
            <span
              className={cn(
                "z-10 flex flex-row gap-2 items-center",
                isSelected ? "text-4xl" : "text-2xl"
              )}
            >
              {emoji}{" "}
            </span>
            <div
              className={cn(
                "absolute inset-0 overflow-hidden transition duration-300",
                isSelected ? "opacity-60" : "opacity-20",
                getRateBackground(index, isSelected),
                !isSelected && somethingSelected && "grayscale"
              )}
            />
          </motion.div>
        );
      })}
    </div>
  );
};

const GoogleLogo = ({
  height = 168,
  ...props
}: {
  width?: number;
  height?: number;
}) => {
  let width = 512;

  if (height !== 168) {
    width = (width / 168) * height;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 168"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <path
        fill="#FF302F"
        d="m496.052 102.672 14.204 9.469c-4.61 6.79-15.636 18.44-34.699 18.44-23.672 0-41.301-18.315-41.301-41.614 0-24.793 17.816-41.613 39.308-41.613 21.616 0 32.206 17.193 35.633 26.475l1.869 4.735-55.692 23.049c4.236 8.348 10.84 12.584 20.183 12.584 9.345 0 15.823-4.61 20.495-11.525ZM452.384 87.66l37.19-15.45c-2.056-5.17-8.16-8.845-15.45-8.845-9.281 0-22.176 8.223-21.74 24.295Z"
      />
      <path fill="#20B15A" d="M407.407 4.931h17.94v121.85h-17.94V4.93Z" />
      <path
        fill="#3686F7"
        d="M379.125 50.593h17.318V124.6c0 30.711-18.128 43.357-39.558 43.357-20.183 0-32.33-13.58-36.878-24.606l15.885-6.604c2.865 6.79 9.78 14.827 20.993 14.827 13.767 0 22.24-8.535 22.24-24.482v-5.98h-.623c-4.112 4.983-11.961 9.468-21.928 9.468-20.807 0-39.87-18.128-39.87-41.488 0-23.486 19.063-41.8 39.87-41.8 9.905 0 17.816 4.423 21.928 9.282h.623v-5.98Zm1.245 38.499c0-14.702-9.78-25.417-22.239-25.417-12.584 0-23.174 10.715-23.174 25.417 0 14.514 10.59 25.042 23.174 25.042 12.46.063 22.24-10.528 22.24-25.042Z"
      />
      <path
        fill="#FF302F"
        d="M218.216 88.78c0 23.984-18.688 41.613-41.613 41.613-22.924 0-41.613-17.691-41.613-41.613 0-24.108 18.689-41.675 41.613-41.675 22.925 0 41.613 17.567 41.613 41.675Zm-18.19 0c0-14.95-10.84-25.23-23.423-25.23-12.583 0-23.423 10.28-23.423 25.23 0 14.826 10.84 25.23 23.423 25.23 12.584 0 23.423-10.404 23.423-25.23Z"
      />
      <path
        fill="#FFBA40"
        d="M309.105 88.967c0 23.984-18.689 41.613-41.613 41.613-22.925 0-41.613-17.63-41.613-41.613 0-24.108 18.688-41.613 41.613-41.613 22.924 0 41.613 17.443 41.613 41.613Zm-18.253 0c0-14.95-10.839-25.23-23.423-25.23-12.583 0-23.423 10.28-23.423 25.23 0 14.826 10.84 25.23 23.423 25.23 12.646 0 23.423-10.466 23.423-25.23Z"
      />
      <path
        fill="#3686F7"
        d="M66.59 112.328c-26.102 0-46.534-21.056-46.534-47.158 0-26.101 20.432-47.157 46.534-47.157 14.079 0 24.357 5.544 31.957 12.646l12.522-12.521C100.479 7.984 86.338.258 66.59.258 30.833.259.744 29.414.744 65.17c0 35.758 30.089 64.912 65.846 64.912 19.312 0 33.889-6.354 45.289-18.19 11.711-11.712 15.324-28.158 15.324-41.489 0-4.174-.498-8.472-1.059-11.649H66.59v17.318h42.423c-1.246 10.84-4.672 18.253-9.718 23.298-6.105 6.168-15.76 12.958-32.705 12.958Z"
      />
    </svg>
  );
};

const GoogleReviewButton = ({ placeId }) => {
  return (
    <Button
      asChild
      size="lg"
      variant="outline"
      className=" flex-row items-center gap-2 py-8"
    >
      <a
        href={`https://search.google.com/local/writereview?placeid=${placeId}`}
        target="_blank"
        rel="noreferrer"
      >
        <span className="text-base">Leave a review on</span>
        <GoogleLogo height={20} />
      </a>
    </Button>
  );
};

interface ReviewComponentProps {
  reviewId: string | null;
  onSuccess?: (review: TReview) => void;
  place: TPlace;
  session?: TSession;
}

const ReviewComponent: React.FC<ReviewComponentProps> = ({
  reviewId,
  place,
  session,
  onSuccess,
}) => {
  useRecaptcha();
  const [currentStep, setCurrentStep] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);

  const questions = useMemo(
    () => [
      {
        name: "rate",
        label: "How was Your Overall Experience?",
        emojis: ["😠", "😟", "😐", "😊", "😍"],
      },
      {
        name: "svc",
        label: "How was the Service",
        emojis: ["😡", "🙁", "😐", "🙂", "😁"],
      },
      {
        name: "fod",
        label: "How did you enjoyed the food?",
        emojis: ["🤢", "😕", "😐", "😋", "🤤"],
      },
      {
        name: "vfm",
        label: "What about the value for your money?",
        emojis: ["👎", "🙁", "😐", "🙂", "👍"],
      },
    ],
    []
  );

  const handleRating = useCallback((name: string, value: number) => {
    const key = name === "rate" ? "rate" : "segments" + "." + name;
    formik.setFieldValue(key, value);
    setTimeout(() => {
      setCurrentStep((prev) => prev + 1);
    }, 500);
  }, []);

  const formik = useFormik({
    initialValues: {
      businessId: "",
      placeId: "",
      rate: 0,
      text: "",
      segments: questions.reduce((acc, q) => {
        if (q.name !== "rate") {
          acc[q.name] = 0;
        }
        return acc;
      }, {}),
    },
    validationSchema: submitReviewSchema,
    onSubmit: async (values) => {
      try {
        const reCapToken = await getReCaptchaToken();
        const response = await client.api.reviews.create({
          values,
          reCapToken,
        });
        onSuccess?.(response?.data?.result);
        toast.success("Review submitted successfully!");
        setIsSuccess(true);
      } catch (error) {
        captureException(error);
        toast.error(errorify(error)?.message);
      }
    },
  });

  useBeforeUnload(() => {
    if (formik.values.rate > 0 && !reviewId) {
      formik.handleSubmit();
    }
  }, [formik.values, reviewId]);

  useEffect(() => {
    if (place?.id && place?.id !== formik.values.placeId) {
      formik.setValues((s) => ({
        ...s,
        placeId: place?.id,
        businessId: place?.businessId,
        sessionId: session?.id,
        sessionType: session?.type,
        bookingId: session?.meta?.bookingId,
      }));
    }
  }, [place?.id]);

  const googlePlaceId = place?.settings?.gPlaceId;
  const minGoogleRate = place?.settings?.review?.gRateRef;

  const showGoogleButton =
    reviewId &&
    googlePlaceId &&
    minGoogleRate > 0 &&
    formik.values.rate > minGoogleRate;

  const thankYouText = showGoogleButton
    ? "Would you be able to help us for another 1 minute and leave a review on Google?"
    : "Your feedback means a lot to us and helps us improve our services, and our staff will review it shortly.";

  const question = questions[currentStep];

  const rating =
    currentStep === 0
      ? formik.values.rate
      : formik.values.segments[question?.name];

  const alreadyRated = reviewId || isSuccess;

  return (
    <div className="px-6 max-w-lg mx-auto">
      {alreadyRated ? (
        <div className="text-center text-primary flex-col flex gap-2">
          <span className="text-6xl">🫶</span>
          <span className="text-2xl font-medium">Thanks for your review!</span>
          <span className="text-md text-muted-foreground">{thankYouText}</span>
          {showGoogleButton && <GoogleReviewButton placeId={googlePlaceId} />}
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          {currentStep < questions.length ? (
            <motion.div
              key={question.name}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.5 }}
            >
              <div className="mb-4">
                <label className="block text-xl mb-5 py-5 text-center">
                  {question.label}
                </label>
                <EmojiRating
                  name={question.name}
                  rating={rating}
                  onRating={handleRating}
                  emojis={question.emojis}
                />
              </div>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.5 }}
            >
              <div className="mb-4">
                <label className="block text-xl mb-5 py-5 text-center">
                  Any Additional Feedback?
                </label>
                <textarea
                  name="text"
                  className="w-full p-2 border rounded"
                  value={formik.values.text}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={360}
                  autoFocus
                  disabled={formik.isSubmitting}
                />
                {formik.errors.text && formik.touched.text ? (
                  <div className="text-red-500">{formik.errors.text}</div>
                ) : null}
              </div>
              <Button
                type="submit"
                size="lg"
                className="w-full text-lg py-8"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                Submit Review
              </Button>
            </motion.div>
          )}
        </form>
      )}
    </div>
  );
};

export default ReviewComponent;
