import axios, { AxiosInstance } from "axios";
import TokenManager from "./TokenManager";
import ClientIdentifier, {
  ClientIdentifierOptions as ClientIdentifierOptions,
} from "./ClientIdentifier";
import WebSocketClient, { WebSocketClientOptions } from "./WebSocketClient";

import Api from "./Api";

export type ClientUserInfo = {
  deviceId?: string;
  userId?: string;
  locale?: string;
};

export interface ClientOptions {
  ws: WebSocketClientOptions;
  getUserInfo: () => Promise<ClientUserInfo>;
  baseUrl: string;
  variant: string;
  onRefreshSuccess: (data: any) => any;
  onLogout: () => any;
  clientIdentifier: ClientIdentifierOptions;
  secureStorage?: {
    getItem: (key: string) => Promise<string>;
    setItem: (key: string, value: string) => Promise<void>;
  };

  // optional
  captureMessage?: (message: string) => void;
  captureException?: (error: Error) => void;
  addBreadcrumb?: (args: {
    category?: string;
    message: string;
    data?: Record<string, any>;
  }) => void;
  setTag?: (
    key: string,
    value: string | number | null | undefined | boolean
  ) => any;
}

export type SharedClient = AxiosInstance & {
  authenticator: TokenManager;
  clientIdentifier: ClientIdentifier;
  ws: WebSocketClient;
  api: Api;
  logout: () => void;
  captureException?: (error: Error) => void;
  captureMessage?: (message: string) => void;
  addBreadcrumb?: (args: {
    category?: string;
    message: string;
    data?: Record<string, any>;
  }) => void;
  setTag?: (
    key: string,
    value: string | number | null | undefined | boolean
  ) => any;
};

function createClient(options: ClientOptions): SharedClient {
  const sc = axios.create({
    baseURL: options.baseUrl,
    withCredentials: true,
  }) as SharedClient;

  // order of declarations is important:

  if (options.captureException) {
    sc.captureException = options.captureException;
  }

  if (options.addBreadcrumb) {
    sc.addBreadcrumb = options.addBreadcrumb;
  }

  if (options.captureMessage) {
    sc.captureMessage = options.captureMessage;
  }

  // 1. connected device is required for authenticator;
  sc["clientIdentifier"] = new ClientIdentifier(options.clientIdentifier);

  // 2. authenticator is required for ws;
  const tm = new TokenManager(sc, options);
  sc["authenticator"] = tm;

  // 3. register websocket client
  sc["ws"] = new WebSocketClient(sc, options);

  // 2. authenticator is required for ws;
  sc["api"] = new Api(sc);

  sc["logout"] = () => {
    sc.authenticator.clear();
    options?.onLogout?.();
  };

  sc.interceptors.request.use(
    async (config) => tm.requestInterceptor(config),
    (error) => Promise.reject(error)
  );

  sc.interceptors.response.use(
    (response) => response,
    (error) => tm.responseInterceptor(error)
  );
  return sc;
}

export default createClient;
