type ParsedQuery = Record<string, string | number | boolean>;

export const parse = (search: string) => {
  return search
    .substring(1)
    .split("&")
    .reduce(function (qs, query) {
      const chunks = query.split("=");
      const key = chunks[0];
      let value: string | number | boolean = decodeURIComponent(
        chunks[1] || ""
      );
      if (key) {
        const valueLower = value.trim().toLowerCase();
        if (valueLower === "true" || value === "false") {
          value = Boolean(value);
        } else if (!isNaN(Number(value))) {
          value = Number(value);
        }
        qs[key] = value;
      }
      return qs;
    }, {} as ParsedQuery);
};

export const stringify = (search: ParsedQuery) => {
  return Object.keys(search)
    .map((key) => {
      const value = search[key];
      if (value === undefined) return "";
      if (value === null) return `${key}`;
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join("&");
};
