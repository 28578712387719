import { SocketActions } from "@gethere/common/enums";
import {
  useInterval,
  useMountedRef,
  useOnValueChange,
} from "@shopify/react-hooks";
import { DateTime } from "luxon";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { emitAppEvent, useAppEvent } from "../AppEvents";
import client from "../utils/client";
import { useUser } from "./UserContext";

type SocketProviderState = boolean | null;

const SocketProviderContext = createContext<{
  connected: SocketProviderState;
  shouldConnect: boolean;
  setShouldConnect: React.Dispatch<React.SetStateAction<boolean>>;
}>(null);

export const useSocket = () => useContext(SocketProviderContext);

export const wsStateToStatus = (
  state: WebSocket["readyState"]
): SocketProviderState => {
  if (state === WebSocket.OPEN) return true;
  if (state === WebSocket.CONNECTING) return null;
  return false;
};

export const SocketProvider = ({ children }) => {
  const [shouldConnect, setShouldConnect] = useState(false);
  const [connected, setConnected] = useState<boolean | null>(false);
  const lastConnectedAt = useRef<DateTime>(null);
  const mounted = useMountedRef();
  const dispatch = useDispatch();
  const { isSigned, userId } = useUser();

  const eligbility = isSigned && shouldConnect;

  const close = () => {
    if (client.ws.state === WebSocket.OPEN) {
      client.ws.close();
    }
  };

  useEffect(() => {
    if (!eligbility) return;

    client.ws.on(SocketActions.SESSION_UPDATED, (payload) => {
      emitAppEvent("session_updated", payload);
    });

    client.ws.on(SocketActions.CLIENT_BOOKING_UPDATE, (update) => {
      emitAppEvent("updated_booking", update.booking);
    });

    client.ws.onSocketClose = () => {
      emitAppEvent("ws_status", false);
    };

    client.ws.onSocketConnect = () => {
      emitAppEvent("ws_status", null);
    };

    client.ws.onSocketConnected = () => {
      emitAppEvent("ws_status", true);
      if (mounted.current) {
        lastConnectedAt.current = DateTime.local();
      }
    };

    return () => {
      try {
        close();
      } catch (error) {
        // unmount, do nothing
      }
    };
  }, [eligbility, dispatch]);

  useOnValueChange(userId, () => {
    // disconnect so we will be connected again once we have device id changed
    if (connected) close();
  });

  useAppEvent("ws_status", (next) => {
    if (mounted.current) setConnected(next);
  });

  const connect = async () => {
    try {
      await client.ws.connect();
    } catch (e) {
      console.error("failed to connect socket", e);
    }
  };

  useInterval(
    () => {
      if (eligbility && connected === false) connect();
    },
    connected === true ? null : 3000
  );

  return (
    <SocketProviderContext.Provider
      value={{
        connected,
        shouldConnect,
        setShouldConnect,
      }}
    >
      {children}
    </SocketProviderContext.Provider>
  );
};
