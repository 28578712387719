import clsx from "clsx";
import { motion } from "framer-motion";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SessionCollectionsSectionsContext from "../contexts/SessionCollectionsSectionsContext";
import { useAppSelector } from "../state/hooks";
import { sessionCollectionsSelector } from "../state/reducers/session";
import PageWrapper from "./PageWrapper";
import { SessionCollectionsScrollSpyContext } from "./SessionCollectionsScrollSpy";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useDebouncedValue, useToggle } from "@shopify/react-hooks";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Input, { InputAdornment } from "../components/Input";
import Fuse from "fuse.js";
import store from "../state/store";
import { LazyPicture } from "../components/Picture";
import SessionItemModalContext from "../contexts/SessionItemModalContext";
import Btn from "../components/Btn";
import { useSession } from "../contexts/SessionContext";
import { checkSchedule } from "@gethere/common/utilities";
import { Card } from "../components/ui/card";
import { TItem } from "@gethere/common/yup/Item";

const thumb = { width: 90, height: 90 };

const navId = (id: string) => `nav-${id}`;

const CollectionsGallery = ({
  onSelect,
  onClose,
}: {
  onSelect: (id: string) => void;
  onClose: () => void;
}) => {
  const { collections } = useAppSelector((state) => ({
    collections: sessionCollectionsSelector(state),
  }));

  return (
    <div className="grid grid-cols-1 gap-5">
      {collections.map((collection) => (
        <div className="">
          <div
            className="font-bold text-lg mb-2 cursor-pointer"
            onClick={() => {
              onSelect(collection.id);
              onClose();
            }}
          >
            {collection.name}
          </div>
          <div className="grid grid-cols-2 gap-2">
            {collection.groups.map((group) => (
              <div
                className="relative cursor-pointer rounded-md p-2 h-20 flex flex-col items-end justify-end text-right shadow-lg overflow-hidden"
                style={{ backgroundColor: group.color }}
                onClick={() => {
                  onSelect(group.id);
                  onClose();
                }}
              >
                <div
                  className="absolute inset-0"
                  style={{
                    backgroundImage:
                      "linear-gradient(170deg, transparent, black)",
                    opacity: 0.6,
                    // mixBlendMode: "multiply",
                  }}
                />

                <span className="relative z-10 font-medium mt-auto drop-shadow text-white">
                  {group.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

type ResultItem = TItem & {
  soldOut: boolean;
  collection: string;
  group: string;
  eligble: boolean;
};

const SearchResults = ({
  search,
  clearSearch,
}: {
  search: string;
  clearSearch: () => void;
}) => {
  const { type } = useSession();
  const { openCollectionItemModal } = useContext(SessionItemModalContext);

  const items = useMemo(() => {
    const state = store.getState();
    const collections = sessionCollectionsSelector(state);

    return Object.values(state.session.entities.items)
      .map((item) => {
        for (const col of collections) {
          if (col.schedule) {
            const sc = checkSchedule(col.schedule, new Date());
            if (!sc.status) continue;
          }

          for (const group of col.groups) {
            if (group.schedule) {
              const sc = checkSchedule(group.schedule, new Date());
              if (!sc.status) continue;
            }

            const gi = group.items.find((gii) => gii.id === item.id);

            if (!gi) continue;

            const eligble =
              item?.active && Array.isArray(item?.sessionTypes)
                ? item.sessionTypes.includes(type)
                : true;

            if (!eligble) return false;
            if (gi.soldOut) return false;

            return {
              ...item,
              collection: col.name,
              group: group.name,
            };
          }
        }
      })
      .filter(Boolean) as ResultItem[];
  }, []);

  const results = useMemo(() => {
    const fuse = new Fuse(items, {
      keys: [
        {
          name: "name",
          weight: 0.7,
        },
        {
          name: "desc",
          weight: 0.3,
        },
      ],
    });

    const relevant = fuse.search(search);

    return relevant;
  }, [search, items]);

  return (
    <div className="min-h-[50vh]">
      <div className="grid grid-cols-1 gap-2">
        {results.length === 0 && (
          <div className="text-center text-muted-foreground">
            No results for <span className="font-bold">{search}</span>
            <Btn
              onClick={clearSearch}
              variant="light"
              color="primary"
              size="sm"
              className="mx-auto mt-5"
            >
              Clear Search
            </Btn>
          </div>
        )}
        {results.map((result) => {
          const item = result.item;
          return (
            <Card
              onClick={() => {
                openCollectionItemModal(item.id);
              }}
              className={clsx(
                "flex flex-row justify-between h-[90] overflow-hidden",
                // disabled
                //   ? "opacity-60 pointer-events-none cursor-not-allowed"
                //   :

                "cursor-pointer"
              )}
            >
              <div className="px-5 py-2 flex flex-col justify-center">
                <div className="flex flex-row gap-2 items-center w-full flex-nowrap">
                  <span className="font-bold line-clamp-1 shrink flex">
                    {item.name}
                  </span>
                </div>
                <div className="text-ellipsis overflow-hidden -mr-5 pr-5 w-auto max-h-10">
                  <p className="text-muted-foreground leading-5 text-xs max-w-xs">
                    {item.desc}
                  </p>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <span className="text-xs text-muted-foreground">
                    # {item.group}
                  </span>
                </div>
              </div>

              <div
                className="flex flex-none relative"
                style={{
                  height: "100%",
                  minHeight: thumb.height,
                  width: thumb.width,
                }}
              >
                {Boolean(item.images[0]) && (
                  <div className="p-2">
                    <LazyPicture
                      height={thumb.height}
                      width={thumb.width}
                      offset={1000}
                      className="w-full h-full object-center object-cover rounded-md"
                      srcset={item.images[0]}
                      sourceHeight={600}
                      sourceWidth={600}
                      alt={item.name}
                    />
                  </div>
                )}
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

const MenuNavigationModal = ({
  onSelect,
  onClose,
}: {
  onSelect: (value: string) => void;
  onClose: () => void;
}) => {
  const [text, setText] = useState("");
  const search = useDebouncedValue(text, { timeoutMs: 500 });
  const clearSearch = useCallback(() => {
    setText("");
  }, [setText]);

  return (
    <div className="p-5 grid grid-cols-1 gap-5 max-h-[95vh]">
      <Input
        value={text}
        defaultValue={""}
        autoFocus={false}
        placeholder="Search"
        className="pl-8"
        clearable
        onChange={(e) => {
          setText(e.target.value);
        }}
        clearValue=""
        start={
          <InputAdornment absolute>
            <MagnifyingGlassIcon className="w-5 h-5 mx-2" />
          </InputAdornment>
        }
      />
      {search ? (
        <SearchResults search={search} clearSearch={clearSearch} />
      ) : (
        <CollectionsGallery onSelect={onSelect} onClose={onClose} />
      )}
    </div>
  );
};

const SessionCollectionsTabs = () => {
  const { collections } = useAppSelector((state) => ({
    collections: sessionCollectionsSelector(state),
  }));

  const modal = useToggle(false);
  const navScrollRef = useRef<HTMLUListElement>(null);
  const { activeSection } = useContext(SessionCollectionsScrollSpyContext);
  const { getSectionRefById } = useContext(SessionCollectionsSectionsContext);

  const handleSelect = useCallback(
    (value: string) => {
      setTimeout(() => {
        const offsetTop = getSectionRefById(value)?.current?.offsetTop;
        window?.scrollTo?.({
          top: Math.max(offsetTop ? offsetTop - 100 : 0, 0),
          behavior: "smooth",
        });
      }, 250);
    },
    [getSectionRefById]
  );

  // collections and groups of menu
  const sections = useMemo(() => {
    return collections?.flatMap?.(({ groups, ...col }) =>
      groups.length > 1 ? [col, ...groups] : [...groups]
    );
  }, []);

  const activeGroup = sections?.[activeSection || 0];

  useEffect(() => {
    if (typeof activeGroup === "object" && activeGroup.id) {
      const element = document.getElementById(navId(activeGroup.id));
      if (!element) return;

      const scrollOffset =
        element.offsetLeft - element.getBoundingClientRect().width / 2;

      navScrollRef.current.scrollTo({
        left: scrollOffset,
        behavior: "smooth",
      });
      // add code here to scroll the scrollRef to the element
    }
  }, [activeGroup]);

  // const scrollY = useMotionValue(scrollYProgress);

  return (
    <PageWrapper
      disableVerticalMargin
      disableHorizonalPadding
      className="flex bg-card shadow-sm w-full sticky top-0 z-10 navbar h-14"
    >
      <div
        className="md:hidden absolute right-0 z-30 h-full flex-row flex items-center"
        onClick={modal.setTrue}
      >
        <div className="bg-background shadow z-30 cursor-pointer ml-auto mr-2 rounded-md">
          <MagnifyingGlassCircleIcon className="w-8 h-8 py-1 fill-primary block" />
        </div>
      </div>
      <div
        className={clsx(
          // scrollY < 5 && "opacity-0",
          "flex flex-row items-center md:opacity-0 absolute opacity-100 duration-150 right-0 w-14 h-full bg-gradient-to-l from-card to-transparent z-20 pointer-events-none"
        )}
      ></div>
      <div
        className={clsx(
          // scrollY > 95 && "opacity-0",
          "md:opacity-o absolute opacity-100 duration-150 left-0 w-14 h-full bg-gradient-to-l from-transparent to-card z-10 pointer-events-none"
        )}
      />

      <motion.ul
        className="relative overflow-x-auto max-w-screen-xl pl-5 pr-12 md:px-0 mx-auto w-full overflow-y-hidden scrollbar-hide whitespace-nowrap flex items-center gap-1 md:gap-2 flex-row snap-y snap-mandatory scroll-smooth"
        ref={navScrollRef}
        initial={{ x: 0 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 200, damping: 30 }}
      >
        {sections?.map?.((section) => {
          const selected = activeGroup?.id === section.id;
          return (
            <li
              key={section.id}
              id={navId(section.id)}
              onClick={() => handleSelect(section.id)}
              className={clsx(
                selected ? "font-medium bg-background" : "",
                "text-sm cursor-pointer rounded-md md:hover:bg-background w-fit py-2 px-2 md:px-3 active:bg-background focus:bg-background"
              )}
            >
              {section.name}
            </li>
          );
        })}
      </motion.ul>
      <BottomSheet
        open={modal.value}
        onDismiss={modal.setFalse}
        autoFocus={false}
      >
        <MenuNavigationModal onSelect={handleSelect} onClose={modal.setFalse} />
      </BottomSheet>
    </PageWrapper>
  );
};
export default SessionCollectionsTabs;
