import { locales } from "@gethere/common/enums";
import { createContext, useContext } from "react";
import colors from "tailwindcss/colors";
import Values from "values.js";
import { hexToHsl, isHexColor } from "../lib/utils";
import { TPlaceTheme } from "@gethere/common/yup/Place";
const defaultTheme = require("tailwindcss/defaultTheme");

/**
 * @param {number} steps
 * @param {string} color
 */
function generatePalette(steps, color) {
  const generator = new Values();
  if (!generator.setColor(color)) {
    return null;
  }

  const palette = generator
    .all(steps)
    .filter(({ weight }) => weight !== 100)
    .reduce(
      (ac, c, index) => ({
        ...ac,
        [(index + 1) * 100]: c.hexString(),
      }),
      {}
    );

  return palette;
}

type Theme = {
  direction: "ltr" | "rtl";
  type: "light" | "dark";
  fonts: {
    title: string;
    body: string;
  };
  radius: string;
  colors?: {
    primary?: string;
    "primary-foreground"?: string;

    secondary?: string;
    "secondary-foreground"?: string;

    foreground?: string;
    background?: string;

    popover?: string;
    "popover-foreground"?: string;

    muted?: string;
    "muted-foreground"?: string;

    border?: string;
    input?: string;

    card?: string;
    "card-foreground"?: string;

    accent?: string;
    "accent-foreground"?: string;

    destructive?: string;
    "destructive-foreground"?: string;

    ring?: string;
  };
  embedded?: boolean;
  transparent?: boolean;
};

const DEFAULT_RADIUS = "0.5rem";

export const DEFAULT_FONTS_BY_LOCALE = {
  default: "Golos Text", //"Figtree",
}; //"Lexend Deca";

export const DEFAULT_TITLE_FONT_BY_LOCALE = {
  default: null,
};

export const DEFAULT_COLORS: { light: Theme["colors"]; dark: Theme["colors"] } =
  {
    light: {
      background: colors.white,
      foreground: colors.slate[900], // or coolGray[900]

      muted: colors.stone[100], // or blue[100]
      "muted-foreground": colors.slate[500],

      popover: colors.white,
      "popover-foreground": colors.slate[900], // or coolGray[900]

      border: colors.stone[200],
      input: colors.stone[200],

      card: colors.white,
      "card-foreground": colors.slate[900], // or coolGray[900]

      primary: colors.slate[900], // or coolGray[900]
      "primary-foreground": colors.stone[100], // or blue[100]

      secondary: colors.stone[100], // or blue[100]
      "secondary-foreground": colors.slate[900], // or coolGray[900]

      accent: colors.stone[100], // or blue[100]
      "accent-foreground": colors.slate[900], // or coolGray[900]

      destructive: colors.red[500],
      "destructive-foreground": colors.stone[100], // or blue[100]

      ring: colors.slate[400], // or coolGray[400]
    },
    dark: {
      background: colors.zinc[900], // Dark blue-gray
      foreground: colors.zinc[100], // Light blue-gray

      muted: colors.zinc[800], // Darker blue-gray
      "muted-foreground": colors.zinc[600], // Medium blue-gray

      accent: colors.zinc[700], // Dark cool-gray
      "accent-foreground": colors.zinc[100], // Very light blue

      popover: colors.zinc[900], // Same as background
      "popover-foreground": colors.zinc[400], // Medium-light blue-gray

      border: colors.zinc[700], // Dark cool-gray
      input: colors.zinc[700], // Same as border

      card: colors.black, // Same as background
      "card-foreground": colors.zinc[50], // Same as foreground

      primary: colors.zinc[100], // Very light blue
      "primary-foreground": colors.zinc[900], // Very dark blue-gray

      secondary: colors.zinc[800], // Dark blue-gray
      "secondary-foreground": colors.zinc[100], // Very light blue

      destructive: colors.red[700], // Darker red
      "destructive-foreground": colors.zinc[100], // Very light blue

      ring: colors.zinc[700], // Dark cool-gray
    },
  };

const FONTS_ATTR_ID = "theme-fonts-link";
const EX_FONTS_ATTR_ID = "external-fonts-link";

const defaultWeights = [400, 500, 700, 800];

export const loadFonts = ({
  fonts,
  external = false,
  weights = defaultWeights,
}: {
  fonts: string[];
  external?: boolean;
  weights?: number[];
}) => {
  const id = external ? EX_FONTS_ATTR_ID : FONTS_ATTR_ID;
  document.getElementById(id)?.remove?.(); // remove if already exist

  const wght = Array.from(
    new Set(weights.filter(isNaN).map(Number).filter(Boolean))
  );

  const w = (wght.length ? wght : defaultWeights).join(";");

  const names = Array.from(new Set(fonts))
    .filter(Boolean)
    .map((font) => `family=${font.replace(/\s/g, "+")}:wght@${w}`)
    .join("&");

  const link = document.createElement("link");
  link.id = id;
  link.rel = "stylesheet";
  link.href = `https://fonts.googleapis.com/css2?${names}&display=swap`;

  document.head.appendChild(link);
};

export function shadeColor(hexColor: string, magnitude: number) {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
}

const DEFAULT_THEME: Theme = {
  direction: "ltr",
  type: "light",
  colors: DEFAULT_COLORS.light,
  radius: DEFAULT_RADIUS,
  fonts: {
    title: DEFAULT_TITLE_FONT_BY_LOCALE.default,
    body: DEFAULT_FONTS_BY_LOCALE.default,
  },
};

const ThemeContext = createContext<Theme>(DEFAULT_THEME);

export const useTheme = () => useContext(ThemeContext);

export const createTheme = (
  theme: Partial<Theme>,
  locale: locales | string
): Theme => {
  const result = { ...DEFAULT_THEME } as Theme;

  if (!["light", "dark"].includes(theme.type)) {
    result.type = "light";
  } else {
    result.type = theme.type;
  }

  if (theme.embedded) {
    result.embedded = true;
  }

  if (theme.transparent) {
    result.transparent = true;
  }

  result.fonts.title =
    theme.fonts?.title ||
    DEFAULT_TITLE_FONT_BY_LOCALE[locale] ||
    DEFAULT_TITLE_FONT_BY_LOCALE.default;

  result.fonts.body =
    theme.fonts?.body ||
    DEFAULT_FONTS_BY_LOCALE[locale] ||
    DEFAULT_FONTS_BY_LOCALE.default;

  loadFonts({ fonts: [result.fonts.title, result.fonts.body] });

  const defaultPallete = DEFAULT_COLORS[result.type] as Theme["colors"];

  const customPallete = (theme.colors || {}) as TPlaceTheme["colors"];

  const background = customPallete.background || defaultPallete.background;

  result.colors = {
    ...defaultPallete,
    background: background,
    foreground: customPallete.text || defaultPallete.foreground,
  };

  const colors = result.colors;

  document.body.parentElement.className = result.type;

  const vars = [];

  for (const key in colors) {
    let value: string = colors[key];

    if (isHexColor(value)) {
      value = hexToHsl(value);
    }

    vars.push(`--${key}:${value};`);
  }

  // radius
  vars.push(`--radius:${theme.radius || DEFAULT_RADIUS};`);

  // fonts
  vars.push(
    `--font-family-title:"${result.fonts.title || result.fonts.body}";`
  );

  vars.push(`--font-family:"${result.fonts.body}";`);

  // safe area
  vars.push(`--sat:env(safe-area-inset-top);`);
  vars.push(`--sar:env(safe-area-inset-right);`);
  vars.push(`--sab:env(safe-area-inset-bottom);`);
  vars.push(`--sal:env(safe-area-inset-left);`);

  const prev = document.head.querySelector("#tableport-theme-vars");
  if (prev) document.head.removeChild(prev);

  const style = document.createElement("style");
  style.innerHTML = `:root{${vars.join("")}}${
    result.transparent ? `body{background:transparent!important;}` : ""
  }}`;
  style.id = "tableport-theme-vars";
  document.head.appendChild(style);

  result.fonts.body = [result.fonts.body, ...defaultTheme.fontFamily.sans].join(
    ", "
  );

  result.fonts.title = [
    result.fonts.title,
    ...defaultTheme.fontFamily.sans,
  ].join(", ");

  return result;
};

const getPropertyValue = getComputedStyle(
  document.documentElement
).getPropertyValue;

export const getSafeArea = () => {
  try {
    return {
      bottom: getPropertyValue("--sab"),
      top: getPropertyValue("--sat"),
      left: getPropertyValue("--sal"),
      right: getPropertyValue("--sar"),
    };
  } catch (error) {
    return {
      bottom: "0px",
      right: "0px",
      left: "0px",
      top: "0px",
    };
  }
};

export const ThemeProvider = ({
  theme,
  children,
}: {
  theme: Theme;
  children: any;
}) => {
  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
};

export default ThemeContext;
