import * as yup from "yup";
import { gracefulNameSchema } from "./User";
import utcIsoDateString from "./Date";

export const reviewSegmentsSchema = yup.lazy((value) =>
  yup
    .object()
    .shape(
      Object.keys(value || {}).reduce(
        (acc, key) => {
          acc[key] = yup.number().required();
          return acc;
        },
        {} as Record<string, yup.NumberSchema<number>>
      )
    )
    .notRequired()
);

export const reviewMetaSchema = yup.object().shape({
  name: gracefulNameSchema.notRequired(),
  mobile: yup.string().notRequired(),
  email: yup.string().email().notRequired(),
});

export const reviewSchema = yup.object().shape({
  id: yup.string().required(),
  userId: yup.string().required(),
  businessId: yup.string().required(),
  placeId: yup.string().notRequired(),
  bookingId: yup.string().notRequired(),
  sessionId: yup.string().notRequired(),
  sessionType: yup.string().notRequired(),
  segments: reviewSegmentsSchema,
  meta: reviewMetaSchema.notRequired(),
  rate: yup.number().required(),
  text: yup.string().notRequired().max(360),
  createdAt: utcIsoDateString.notRequired(),
  viewedAt: utcIsoDateString.notRequired(),
});

export const submitReviewSchema = yup.object().shape({
  sessionId: yup.string().notRequired(),
  sessionType: yup.string().notRequired(),
  businessId: yup.string().required(),
  placeId: yup.string().required(),
  rate: yup.number().required(),
  text: yup.string().notRequired().max(360),
  segments: reviewSegmentsSchema,
  meta: reviewMetaSchema.notRequired(),
});

export const reviewSearchSchema = yup.object().shape({
  businessId: yup.string().required("Business ID is required"),

  skip: yup
    .number()
    .min(0, "Skip must be greater than or equal to 0")
    .default(0),

  limit: yup
    .number()
    .min(1, "Limit must be greater than 0")
    .max(100, "Limit must be less than or equal to 100")
    .default(50),

  sort: yup.object().shape({
    key: yup
      .string()
      .oneOf(["createdAt", "rate"])
      .default("createdAt")
      .required("Sort key is required"),

    value: yup
      .string()
      .oneOf(["ASC", "DESC"], "Sort value must be either ASC or DESC")
      .default("DESC")
      .required("Sort value is required"),
  }),
  userId: yup.string().notRequired().default(null),
});

export type TReview = yup.InferType<typeof reviewSchema>;
export type TSubmitReviewBody = yup.InferType<typeof submitReviewSchema>;
export type TReviewSearchQuery = yup.InferType<typeof reviewSearchSchema>;
