import { useOnValueChange } from "@shopify/react-hooks";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Alert from "../components/Alert";
import Btn from "../components/Btn";
import { NumericInput } from "../components/Input";
import {
  DrawerScreenParams,
  useSessionDrawerNavigation,
} from "../contexts/SessionDrawerNavigationContext";
import { SubmitSessionPayment } from "../contexts/SubmitSessionPayment";
import { useAppSelector } from "../state/hooks";
import {
  sessionCurrencySelector,
  sessionSelector,
} from "../state/reducers/session";
import { useSession } from "../contexts/SessionContext";

export default function SessionPayCustom({
  params,
}: {
  params: DrawerScreenParams["pay_custom"];
}) {
  const { status } = useSession();
  const intl = useIntl();
  const nav = useSessionDrawerNavigation();
  const currency = useAppSelector(sessionCurrencySelector);

  const check = useAppSelector((s) => {
    const session = sessionSelector(s);
    return session?.check;
  });

  const [value, setValue] = useState(null);
  const [confirmed, setConfirmed] = useState(false);

  const total = check ? check.total : 0;
  const left = status.leftOrder;
  const paid = check ? check.authorized + check.charged : 0;

  useOnValueChange(left, (now, was) => {
    if ((now = 0 && was > 0)) {
      nav.setScreen({ name: "bill", params: null });
    }
  });

  const minPayment = left < 1 ? left : 1;

  const validAmount = value >= minPayment && value > 0 && value <= left;

  useEffect(() => {
    if (!status?.leftOrder) nav.setScreen({ name: "bill", params: {} });
  }, [status?.leftOrder]);

  const warning =
    !validAmount &&
    [
      "Payment must be greater than",
      intl.formatNumber(minPayment, {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }),
      "and less than or equal to",
      intl.formatNumber(left, {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }),
    ]
      .filter(Boolean)
      .join(" ");

  return (
    <div className="flex flex-col gap-5 h-full">
      <h3 className="text-2xl font-medium">Custom Payment</h3>
      <NumericInput
        autoFocus
        disabled={confirmed}
        label="Your custom payment"
        value={value}
        min={0}
        numberStyle="currency"
        currency={currency}
        minimumFractionDigits={0}
        maximumFractionDigits={2}
        className="text-2xl py-5"
        enterKeyHint="done"
        inputMode="decimal"
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <div className="grid grid-cols-1 gap-2 mt-auto">
        {total > 0 && (
          <div className="flex flex-row justify-between text-md">
            <div className="text-gray-500">Total amount</div>
            <div className="text-gray-500">
              {intl.formatNumber(total, {
                style: "currency",
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        )}
        {paid > 0 && (
          <div className="flex flex-row justify-between text-md">
            <div className="text-gray-500">Already paid</div>
            <div className="text-gray-500">
              {intl.formatNumber(paid, {
                style: "currency",
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        )}
        <div className="flex flex-row justify-between text-lg font-medium">
          <div className="text-text">Amount to pay</div>
          <div className="text-text">
            {intl.formatNumber(left, {
              style: "currency",
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
      </div>
      {!validAmount && (
        <Alert type="danger" className="mt-5">
          {warning}
        </Alert>
      )}
      <div className={clsx("grid grid-cols-1 gap-2")}>
        {confirmed && validAmount ? (
          <SubmitSessionPayment value={value} />
        ) : (
          <Btn disabled={!validAmount} onClick={() => setConfirmed(true)}>
            Confirm Payment{" "}
            {intl.formatNumber(value, { style: "currency", currency })}
          </Btn>
        )}
        <Btn
          color="text"
          variant="text"
          className="w-full"
          onClick={() => {
            nav.setScreen({ name: "bill", params: {} });
          }}
        >
          Go Back
        </Btn>
      </div>
    </div>
  );
}
