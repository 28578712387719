import { OUTGOING_SESSION_TYPES } from "@gethere/common/settings";
import { getDate } from "@gethere/common/utilities";
import parsePhoneNumber from "@gethere/common/utils/parsePhoneNumber";
import { useDebouncedValue, useOnValueChange } from "@shopify/react-hooks";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import Alert from "../components/Alert";
import Btn from "../components/Btn";
import Input from "../components/Input";
import { MobileInput } from "../components/MobileField";
import { useSession } from "../contexts/SessionContext";
import { useUser } from "../contexts/UserContext";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import {
  sessionBusinessSelector,
  sessionSelector,
  updateLocalSessionMeta,
} from "../state/reducers/session";
import { format, isValid } from "date-fns";

const LiableMobileNumber = () => {
  const { user } = useUser();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [mobileNumber, setMobileNumber] = useState(user?.mobileNumber || "");
  const business = useAppSelector((state) => sessionBusinessSelector(state));

  const liabels = useAppSelector(
    (state) => sessionSelector(state)?.meta?.liables || []
  );

  const debouncedMobileNumber = useDebouncedValue(mobileNumber, {
    timeoutMs: 500,
  });

  useOnValueChange(debouncedMobileNumber, (mobile) => {
    if (!mobile || !parsePhoneNumber(mobile)?.valid) return;

    const next = [...liabels].filter((l) => l.userId !== user?.id);

    next.push({
      name: {
        first: user?.name?.first || null,
        last: user?.name?.last || null,
      },
      userId: user?.id,
      mobile,
    });

    dispatch(
      updateLocalSessionMeta({
        liables: next,
      })
    );
  });

  return (
    <div className="mb-5 grid grid-cols-1 gap-5">
      <Alert type="info">
        To continue the order please insert you mobile number
      </Alert>
      <MobileInput
        defaultRegion={business?.country as string}
        label={intl.formatMessage({ id: "mobile_number" })}
        value={mobileNumber}
        onChange={(value: string) => {
          setMobileNumber(value);
        }}
      />
    </div>
  );
};

const TargetAt = ({ targetAt }: { targetAt: string | null }) => {
  const intl = useIntl();
  const { status, type } = useSession();
  const dispatch = useAppDispatch();
  const [focused, setFocus] = useState(false);

  const setTargetAt = (targetAt: string | null) => {
    dispatch(updateLocalSessionMeta({ targetAt }));
  };

  const minTime = useMemo(
    () =>
      DateTime.now().plus({ minutes: 30 }).startOf("minute").toFormat("HH:mm"),
    []
  );

  const time = getDate(targetAt);

  if (status.handoff) return null;

  return (
    <div className="grid grid-cols-1">
      <div className="grid grid-cols-2 gap-2 bg-background mb-5">
        <Btn
          size="xs"
          variant="outline"
          color={targetAt === null ? "primary" : "default"}
          onClick={() => {
            setTargetAt(null);
          }}
        >
          {intl.formatMessage({ id: "session_schedule_asap" })}
        </Btn>
        <Btn
          size="xs"
          variant="outline"
          color={targetAt !== null ? "primary" : "default"}
          onClick={() => {
            focused ? setFocus(false) : setFocus(true);
            setTargetAt(
              DateTime.now().plus({ hour: 1 }).startOf("minute").toISO()
            );
          }}
        >
          {!isValid(time) || focused
            ? intl.formatMessage({ id: "session_schedule_set" })
            : intl.formatTime(targetAt)}
        </Btn>
      </div>
      {targetAt === null && (
        <Alert type="warning">
          Once you place your order, we'll need about 15 minutes to carefully
          prepeare it with love. 😊
        </Alert>
      )}
      {!!time && focused && (
        <div className="mb-5">
          <Input
            type="time"
            min={minTime}
            autoFocus={true}
            label={intl.formatMessage({ id: "session_schedule" })}
            value={format(time, "HH:mm")}
            onBlur={() => setFocus(false)}
            onChange={(e) => {
              const next = DateTime.now()
                .set({
                  hour: Number(e.target.value.split(":")[0]),
                  minute: Number(e.target.value.split(":")[1]),
                })
                .startOf("minute");
              setTargetAt(next.toISO());
            }}
          />
        </div>
      )}
      {(!status.schedule || !status.schedule.status) && (
        <Alert type="warning">Please choose another time for {type}</Alert>
      )}
    </div>
  );
};

const SessionMetaFields = () => {
  const { missing, type } = useSession();
  const session = useAppSelector((state) => sessionSelector(state));
  const outgoing = OUTGOING_SESSION_TYPES.includes(type);
  const targetAt = session?.meta?.targetAt;

  return (
    <>
      {outgoing && <TargetAt targetAt={targetAt} />}
      {missing && missing.includes("mobileNumber") && <LiableMobileNumber />}
    </>
  );
};

export default SessionMetaFields;
