import { SUPPORTED_COUNTRIES } from "@gethere/common/countries";
import { locales, CountryCodes } from "@gethere/common/enums";
import { DEFAULT_LANGUAGES } from "@gethere/common/settings";
import { createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist/lib/constants";
import { isLangRTL, localeLang } from "../../utils/langUtil";
import { RootState } from "../store";
import { USER_ACTIONS } from "../actions";

const getDefaultLocale = () => {
  const browser = window.navigator.language.replace("-", "_");
  if (browser in locales) {
    return browser;
  }
  return locales.en_GB;
};

const getDefaultCountry = () => {
  const path = String(window.location.pathname).replace("/", "").toUpperCase();
  if (path?.length > 0 && SUPPORTED_COUNTRIES.includes(path as any))
    return path;
  return CountryCodes.UnitedKingdom;
};

const defaultCountry = getDefaultCountry();
const defaultLocale = DEFAULT_LANGUAGES[defaultCountry] || getDefaultLocale();

type SystemSlice = {
  theme: "light" | "dark";
  locale: string;
  rtl: boolean;
  country: string;
};

const initialState: SystemSlice = {
  theme: "light",
  locale: defaultLocale,
  rtl: isLangRTL(localeLang(defaultLocale)),
  country: defaultCountry,
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
      state.rtl = isLangRTL(localeLang(state.locale));
    },
    setTheme: (state, action: { payload: "light" | "dark" }) => {
      state.theme = action.payload;
    },
    setCountry: (
      state,
      { payload }: { payload: { country: string; locale?: string } }
    ) => {
      if (state.country !== payload.country) state.country = payload.country;

      const locale =
        payload.locale || DEFAULT_LANGUAGES[payload.country] || locales.en_US;

      if (state.locale !== locale) state.locale = locale;

      const rtl = isLangRTL(localeLang(state.locale));
      if (state.rtl !== rtl) state.rtl = rtl;
    },
  },
  extraReducers: (builder) => {
    builder.addCase<any>(USER_ACTIONS.ON_LOGOUT, () => {
      return { ...initialState };
    });
    builder.addCase<any>(REHYDRATE, (state, { payload }) => {
      const country = payload?.system?.country;

      if (!SUPPORTED_COUNTRIES.includes(country as any)) {
        state.country = defaultCountry;
      }

      let locale = payload?.system?.locale;

      if (!locale || !locales[locale])
        locale = DEFAULT_LANGUAGES[state.country] || locales.en_US;

      state.locale = locale;

      state.rtl = isLangRTL(localeLang(state.locale));

      state.theme = payload?.system?.theme || "light";
    });
  },
});

export const { setLocale, setTheme, setCountry } = systemSlice.actions;
export default systemSlice.reducer;

export const localeSelector = (state: RootState) => state.system.locale;
export const rtlSelector = (state: RootState) => state.system.rtl;
export const themeSelector = (state: RootState) => state.system.theme;
