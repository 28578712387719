import { createContext, useCallback, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import SessionItemModal from "../containers/SessionItemModal";

export type SessionItemModalContextType = {
  openCollectionItemModal: (ciId: string) => void;
  openOrderItemModal: (oiId: string) => void;
  closeItemModal: (...arg: any) => void;
};

const SessionItemModalContext = createContext(
  {} as SessionItemModalContextType
);

export const SessionItemModalProvider = ({ children }) => {
  const [opened, setItemOpen] = useState({
    ciId: null, // collection item
    oiId: null, // order item
  });

  const openCollectionItemModal = useCallback(
    (ciId = null) => setItemOpen((s) => ({ ...s, ciId })),
    [setItemOpen]
  );

  const openOrderItemModal = useCallback(
    (oiId = null) => setItemOpen((s) => ({ ...s, oiId })),
    [setItemOpen]
  );

  const closeItemModal = useCallback(
    () => setItemOpen((s) => ({ oiId: null, ciId: null })),
    [setItemOpen]
  );

  const isOpen = Boolean(opened.ciId || opened.oiId);

  return (
    <SessionItemModalContext.Provider
      value={{ openCollectionItemModal, openOrderItemModal, closeItemModal }}
    >
      {children}
      <BottomSheet
        expandOnContentDrag
        key="item_dialog"
        open={isOpen}
        onDismiss={closeItemModal}
        snapPoints={({ minHeight }) => [minHeight]}
      >
        <SessionItemModal
          itemId={opened.ciId}
          orderItemId={opened.oiId}
          onClose={closeItemModal}
        />
      </BottomSheet>
    </SessionItemModalContext.Provider>
  );
};

export default SessionItemModalContext;
