import { Messages } from "@gethere/common/SocketAPI";
import { TBooking } from "@gethere/common/yup/Booking";
import EventEmitter from "eventemitter3";
import { useEffect } from "react";

export type AppEvents = "ws_status" | "session_updated" | "updated_booking";

export const AppEmitter = new EventEmitter<AppEvents>();

type EventTypeByName = {
  // upsert_prints: UpsertPrintsEvent;
  // screen_changed: NavigationChangedEvent;
  ws_status: boolean | null;
  session_updated: Messages.SessionUpdate;
  updated_booking: TBooking;
};

export const useAppEvent = <T extends AppEvents>(
  eventName: T,
  callback: (event: EventTypeByName[T]) => void
) => {
  useEffect(() => {
    AppEmitter.addListener(eventName, callback);
    return () => {
      AppEmitter.removeListener(eventName, callback);
    };
  }, [callback]);
};

export const emitAppEvent = <T extends AppEvents>(
  eventName: T,
  payload: EventTypeByName[T]
) => {
  AppEmitter.emit(eventName, payload);
};
