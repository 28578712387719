import { UserTypes } from "@gethere/common/enums";
import { updateUserDetailsSchema } from "@gethere/common/yup/UpdateUserDetails";
import { userSchema } from "@gethere/common/yup/User";
import { captureException } from "@sentry/react";
import { useFormik } from "formik";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch, useHistory } from "react-router";
import Alert from "../components/Alert";
import Avatar from "../components/Avatar";
import Btn from "../components/Btn";
import FormSection from "../components/FormSection";
import Input from "../components/Input";
import InputLabel from "../components/InputLabel";
import { MobileInput } from "../components/MobileField";
import { Surface } from "../components/Surface";
import { UserFields, useUser } from "../contexts/UserContext";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { onUserUpdate } from "../state/reducers/user";
import client from "../utils/client";
import errorify from "../utils/errorify";
import { handleUploadImages } from "../utils/uploads";
import UserAdresses from "./UserAddresses";
import UserBookings from "./UserBookings";
import UserOrderHistory from "./UserOrderHistory";
import { UserPaymentMethods } from "./UserPaymentMethods";
import { MeMembershipsList } from "./me/memberships/list";
import { MeMembership } from "./me/memberships/membership";

export const BasicUserDetailsForm = ({
  fields,
  onSuccess,
}: {
  fields: UserFields[];
  onSuccess?: any;
}) => {
  const intl = useIntl();
  const { user } = useUser();
  const dispatch = useAppDispatch();
  const {
    values,
    touched,
    errors,
    isSubmitting,
    submitCount,
    status,
    handleChange,
    handleSubmit,
    handleBlur,
    setSubmitting,
    setStatus,
  } = useFormik({
    initialStatus: null,
    validationSchema: updateUserDetailsSchema,
    initialValues: updateUserDetailsSchema.cast({
      name: user?.name,
      email: user?.email || "",
    }),
    onSubmit: async (values) => {
      try {
        setSubmitting(true);
        const response = await client.put("/me", values);
        dispatch(onUserUpdate(response.data));
        onSuccess?.(response.data);
      } catch (error) {
        captureException(error);
        setStatus(errorify(error)?.message);
        setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={handleSubmit} className="h-full">
      <div className="w-full flex flex-col gap-10 h-full">
        <h3 className="font-medium text-xl">
          {intl.formatMessage({ id: "consumer_personal_details" })}
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          {fields.includes("name.first") && (
            <Input
              error={
                (touched?.name?.first || submitCount > 0) &&
                errors?.name?.first &&
                intl.formatMessage({
                  id: errors.name?.first,
                  defaultMessage: errors?.name?.first,
                })
              }
              name="name.first"
              id="outlined-basic"
              disabled={isSubmitting}
              label={intl.formatMessage({ id: "user_name_first" })}
              value={values.name?.first}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
          {fields.includes("name.last") && (
            <Input
              name="name.last"
              disabled={isSubmitting}
              label={intl.formatMessage({ id: "user_name_last" })}
              value={values.name?.last}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                (touched?.name?.last || submitCount > 0) &&
                errors?.name?.last &&
                intl.formatMessage({
                  id: errors.name?.last,
                  defaultMessage: errors?.name?.last,
                })
              }
            />
          )}
        </div>
        <Input
          optional={!fields.includes("email")}
          name="email"
          disabled={isSubmitting}
          label={intl.formatMessage({ id: "email" })}
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          error={
            touched?.email &&
            errors?.email &&
            intl.formatMessage({
              id: errors.email,
              defaultMessage: errors?.email,
            })
          }
        />
        {status && <Alert type="danger">{status}</Alert>}
        <div className="flex flex-grow" />
        <Btn
          variant="contained"
          color="primary"
          size="lg"
          fullWidth
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        >
          {intl.formatMessage({ id: "confirm" })}
        </Btn>
      </div>
    </form>
  );
};

const AddFile = ({
  onFiles,
  label = "Change",
  id = "uploadbtn",
  accept = "image/jpeg, image/png",
  multiple = false,
}) => {
  const [uploading, setstate] = useState(false);

  const onDrop = async (files) => {
    try {
      setstate(true);
      await onFiles(files);
    } catch (error) {
      captureException(error);
    } finally {
      setstate(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept,
  } as any);

  const lbl = !uploading
    ? isDragActive
      ? "Drop Here :)"
      : label
    : "Uploading...";

  return (
    <div {...getRootProps()}>
      <input
        className="hidden"
        id={id}
        type="file"
        {...getInputProps()}
        disabled={uploading}
      />
      <label htmlFor={id}>
        <Btn
          className="ml-2 border-dashed"
          size="sm"
          color="text"
          variant={!isDragActive ? "outline" : "contained"}
          disabled={uploading}
          onClick={(e) => e.preventDefault()}
        >
          {lbl}
        </Btn>
      </label>
    </div>
  );
};

const UserDetailsForm = () => {
  const intl = useIntl();
  const { user } = useUser();
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialStatus: null,
    validationSchema: userSchema,
    initialValues: userSchema.cast({
      name: user?.name,
      email: user?.email || "",
      mobileNumber: user?.mobileNumber || "",
      profileImg: user?.profileImg || "",
    }),
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true);
        const response = await client.put("/me", values);
        dispatch(onUserUpdate(response.data));
      } catch (error) {
        formik.setStatus(errorify(error)?.message);
        formik.setSubmitting(false);
      }
    },
  });

  const handleFiles = async (files) => {
    try {
      const result = await handleUploadImages(files);
      formik.setFieldValue("profileImg", result[0]);
    } catch (error) {
      captureException(error);
      const message = errorify(error)?.message;
      toast.error(message);
    }
  };

  return (
    <FormSection>
      <FormSection.Header>
        <FormSection.Title>Personal Details</FormSection.Title>
        <FormSection.Desc>
          Some basic information about you so we can get to know one another
          better.
        </FormSection.Desc>
      </FormSection.Header>
      <FormSection.Body>
        <form onSubmit={formik.handleSubmit}>
          <Surface className="mb-10">
            <Surface.Content>
              <div className="grid grid-cols-1 gap-10">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                  <Input
                    error={
                      formik.errors?.name?.first &&
                      intl.formatMessage({
                        id: formik.errors.name?.first,
                        defaultMessage: formik.errors?.name?.first,
                      })
                    }
                    name="name.first"
                    id="outlined-basic"
                    disabled={formik.isSubmitting}
                    label={intl.formatMessage({ id: "user_name_first" })}
                    value={formik.values.name?.first}
                    onChange={formik.handleChange}
                  />
                  <Input
                    name="name.last"
                    disabled={formik.isSubmitting}
                    label={intl.formatMessage({ id: "user_name_last" })}
                    value={formik.values.name?.last}
                    onChange={formik.handleChange}
                    error={
                      formik.errors?.name?.last &&
                      intl.formatMessage({
                        id: formik.errors.name?.last,
                        defaultMessage: formik.errors?.name?.last,
                      })
                    }
                  />
                  <Input
                    optional
                    name="email"
                    disabled={formik.isSubmitting}
                    label={intl.formatMessage({ id: "email" })}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={
                      formik.errors?.email &&
                      intl.formatMessage({
                        id: formik.errors.email,
                        defaultMessage: formik.errors?.email,
                      })
                    }
                  />
                  <MobileInput
                    label={intl.formatMessage({ id: "mobile_number" })}
                    value={formik.values.mobileNumber}
                    onChange={() => {}}
                    disabled
                  />
                  <div>
                    <InputLabel name="profileImg">Photo</InputLabel>
                    <div className="mt-1 flex items-center">
                      <Avatar
                        size="md"
                        name={formik.values.name}
                        image={formik.values.profileImg}
                      />
                      <AddFile onFiles={handleFiles} />
                    </div>
                  </div>
                </div>
                {formik.status && <Alert type="danger">{formik.status}</Alert>}
              </div>
            </Surface.Content>
            <Surface.Footer>
              <div className="md:ml-auto">
                <Btn
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="w-full md:w-fit"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({ id: "save" })}
                </Btn>
              </div>
            </Surface.Footer>
          </Surface>
        </form>
      </FormSection.Body>
    </FormSection>
  );
};

const ProfileDelete = () => {
  const [state, setState] = useState({ loading: false, error: null });
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleDelete = useCallback(
    async (e) => {
      const deleteText = "delete me";
      e.preventDefault();
      const confirm = prompt(
        `Are you sure you want to remove your account & data? if so, please type '${deleteText}'.`
      );
      if (confirm !== deleteText) {
        alert(
          "Invalid input, try again if you sure you want to delete your data."
        );
      } else {
        setState((s) => ({ ...s, loading: true }));
        try {
          await client.delete("/me", { data: { confirm: true } });
          client.logout();
          alert("Your data been removed.");
          history.push("/");
        } catch (error) {
          toast.error(errorify(error).message);
        }
      }
    },
    [dispatch, history]
  );

  return (
    <FormSection>
      <FormSection.Header>
        <FormSection.Title>Danger zone</FormSection.Title>
      </FormSection.Header>
      <FormSection.Body>
        <Surface>
          <Surface.Content>
            <div>
              <InputLabel name="delete-account">
                Permanently delete your account
              </InputLabel>
              <p className="text-muted-foreground text-xs my-5">
                When you delete your TablePort account, you won't be able to
                retrieve the content or information you've shared on TablePort.
                Be careful, This cannot be undone.
              </p>
              <Btn
                color="danger"
                size="base"
                variant="light"
                disabled={state.loading}
                onClick={handleDelete}
              >
                Delete My Account
              </Btn>
            </div>
          </Surface.Content>
        </Surface>
      </FormSection.Body>
    </FormSection>
  );
};

const ScreenTitle = ({ children }) => (
  <h1 className="text-3xl font-bold mb-10">{children}</h1>
);

const ProfileScreen = () => {
  return (
    <div>
      <ScreenTitle>Your account</ScreenTitle>
      <UserDetailsForm />
      <ProfileDelete />
    </div>
  );
};

const UserProfileScreen = () => {
  const { user } = useUser();
  const rehydrated = useAppSelector((s) => s._persist.rehydrated);

  if (rehydrated && (!user || user.type === UserTypes.GUEST)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="my-5 px-5 mx-auto max-w-screen-lg w-full">
      <Switch>
        <Route path="/me/methods">
          <ScreenTitle>Your payment methods</ScreenTitle>
          <UserPaymentMethods />
        </Route>
        <Route path="/me/addresses">
          <ScreenTitle>Delivery addresses</ScreenTitle>
          <UserAdresses />
        </Route>
        <Route path="/me/orders">
          <ScreenTitle>Your order history</ScreenTitle>
          <UserOrderHistory />
        </Route>
        <Route path="/me/reservations">
          <ScreenTitle>Your reservations</ScreenTitle>
          <UserBookings />
        </Route>
        <Route path="/me/memberships" exact>
          <MeMembershipsList />
        </Route>
        <Route path="/me/memberships/:businessId" exact>
          <MeMembership />
        </Route>
        <Route path="/me" exact>
          <ProfileScreen />
        </Route>
        <Redirect to="/me" />
      </Switch>
    </div>
  );
};

export default UserProfileScreen;
