import { NormalizedUserEntity } from "@gethere/common/entities";
import { UserTypes } from "@gethere/common/enums";
import { TUser } from "@gethere/common/yup/User";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { produce } from "immer";
import client, { postChannelMessage } from "../../utils/client";
import mergeEntities from "../../utils/mergeEntities";
import type { RootState } from "../store";
import { TUserAddress } from "@gethere/common/yup/UserAddress";
import { TMember } from "@gethere/common/yup/Membership";

// Define the initial state using a type
interface UserState {
  result: string | null;
  entities: NormalizedUserEntity["entities"];
}

interface LoginPayload {
  access: string;
  accessExpAt: number;
  refresh: string;
  result: string;
  entities: NormalizedUserEntity["entities"];
}

interface UserUpdatePayload {
  result: string;
  entities: NormalizedUserEntity["entities"];
}

interface AddressUpdatePayload {
  [key: string]: TUserAddress;
}

const initialState: UserState = {
  result: null,
  entities: {
    users: {},
    addresses: {},
    paymentMethods: {},
    memberships: {},
    controls: {},
  },
};

// Thunks
export const onLogin = createAsyncThunk(
  "user/onLogin",
  async ({ access, accessExpAt, refresh, result, entities }: LoginPayload) => {
    // set access token;
    client.authenticator.setSecureTokens({ refresh, access, accessExpAt });

    postChannelMessage({
      message: "token_refreshed",
      access,
      accessExpAt,
      refresh,
      result,
      entities,
    });

    return { result, entities };
  }
);

export const onUserUpdate = createAsyncThunk(
  "user/onUserUpdate",
  async ({ result, entities }: UserUpdatePayload) => {
    return { result, entities };
  }
);

export const onUserAddressUpdate = createAsyncThunk(
  "user/onUserAddressUpdate",
  async (address: AddressUpdatePayload) => {
    return address;
  }
);

export const onUserJoinedMembership = createAsyncThunk(
  "user/onUserJoinedMembership",
  async ({ membership }: { membership: TMember }) => {
    return { membership };
  }
);

export const onLogout = createAsyncThunk("user/onLogout", async () => {
  return initialState;
});

// Slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        onLogin.fulfilled,
        (
          state,
          action: PayloadAction<{
            result: string;
            entities: NormalizedUserEntity["entities"];
          }>
        ) => {
          const { entities, result } = action.payload;
          return {
            ...state,
            result,
            entities,
          };
        }
      )
      .addCase(
        onUserUpdate.fulfilled,
        (state, action: PayloadAction<UserUpdatePayload>) => {
          return produce(state, (draft) => {
            if (action.payload.result) {
              draft.result = action.payload.result;
            }

            if (action.payload.entities) {
              draft.entities = mergeEntities(
                state.entities,
                action.payload.entities
              );
            }
          });
        }
      )
      .addCase(
        onUserJoinedMembership.fulfilled,
        (state, action: PayloadAction<{ membership: TMember }>) => {
          return produce(state, (draft) => {
            if (!draft.entities.memberships) draft.entities.memberships = {};
            draft.entities.memberships[action.payload.membership.id] =
              action.payload.membership;
          });
        }
      )
      .addCase(
        onUserAddressUpdate.fulfilled,
        (state, action: PayloadAction<AddressUpdatePayload>) => {
          return produce(state, (draft) => {
            if (!draft.entities.addresses) draft.entities.addresses = {};
            draft.entities.addresses = {
              ...draft.entities.addresses,
              ...action.payload,
            };
          });
        }
      )
      .addCase(onLogout.fulfilled, (state) => {
        return initialState;
      });
  },
});

// Selectors
export const isUserSelector = (state: RootState) => !!state.user.result;

export const userSelector = (state: RootState): TUser =>
  state.user.entities.users?.[state.user.result];

export const isAdminUserSelector = (user: TUser) =>
  user?.type === UserTypes.ADMIN;

export const isAdminSelector = (state: RootState) =>
  isAdminUserSelector(userSelector(state));

export const userNameSelector = (state: RootState) =>
  state.user.entities.users?.[state.user.result]?.name;

export const isUserHasDetails = (state: RootState) => {
  const u = state.user.entities.users?.[state.user.result];
  return u?.name?.first && u?.name?.last;
};

export const userShortName = (name: any) => {
  return name?.first
    ? name?.first + " " + name?.last?.substring(0, 1) + "."
    : "";
};

export const userInitials = (name: any) => {
  return name?.first
    ? name?.first?.substring(0, 1) + name?.last?.substring(0, 1)
    : "";
};

export default userSlice.reducer;
