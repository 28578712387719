import { TBusiness } from "@gethere/common/yup/Business";
import { TMember } from "@gethere/common/yup/Membership";
import { Badge } from "@gethere/web/components/ui/badge";
import { Button } from "@gethere/web/components/ui/button";
import { Card, CardContent } from "@gethere/web/components/ui/card";
import { Skeleton } from "@gethere/web/components/ui/skeleton";
import client from "@gethere/web/utils/client";
import useAsync from "@gethere/web/utils/useAsync";
import { CalendarCheckIcon, GiftIcon } from "lucide-react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const MembershipListItem = ({
  membership,
  business,
}: {
  membership: TMember;
  business: TBusiness;
}) => {
  const intl = useIntl();
  const program = business?.membership;
  return (
    <Card>
      <CardContent className="grid grid-cols-1 md:grid-cols-[1fr_auto] gap-4 md:gap-8 items-center">
        <div className="grid gap-2 pt-4">
          <div className="flex items-center gap-2">
            <h3 className="text-lg font-semibold">{program.name}</h3>
            <Badge
              className="px-2 py-1 text-xs font-medium"
              variant={membership.active ? "secondary" : "destructive"}
            >
              {membership.active ? "Active" : "Expired"}
            </Badge>
          </div>
          <div className="flex items-center gap-4 text-sm">
            <div className="flex items-center gap-1.5">
              <GiftIcon className="w-4 h-4 text-gray-500 dark:text-gray-400" />
              <span>
                {intl.formatNumber(membership.points, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}{" "}
                Points
              </span>
            </div>
            <div className="flex items-center gap-1.5">
              <CalendarCheckIcon className="w-4 h-4 text-gray-500 dark:text-gray-400" />
              <span>{membership.orders} Visits</span>
            </div>
            <div className="text-gray-500 dark:text-gray-400 text-sm">
              Joined:
              <time dateTime={membership.createdAt}>
                {intl.formatDate(membership.createdAt)}
              </time>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <Button asChild size="sm" variant="outline">
            <Link to={`/me/memberships/${membership.businessId}`}>
              View Details
            </Link>
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

const MembershipListItemSkeleton = () => {
  return (
    <Card className="py-3 flex flex-col gap-2">
      <CardContent className="grid grid-cols-1 md:grid-cols-[1fr_auto] gap-4 md:gap-8 items-center">
        <Skeleton className="max-w-1/2 w-40 h-5" />
        <Skeleton className="max-w-1/4 w-28 h-3" />
      </CardContent>
    </Card>
  );
};

const skeletons = Array.from({ length: 5 }).map((_, i) => String(i));

export function MeMembershipsList() {
  const data = useAsync(() => client.api.me.memberships(), { immediate: true });

  return (
    <div>
      {data.loading ? (
        <div className="pt-5 flex flex-col gap-3">
          {skeletons.map((skeleton) => (
            <MembershipListItemSkeleton key={skeleton} />
          ))}
        </div>
      ) : data.error ? (
        <div>Error: {data.error.message}</div>
      ) : data.successed && data.value.data.memberships.length > 0 ? (
        <div className="pt-5 flex flex-col gap-3">
          {data.value.data.memberships.map((membership) => (
            <MembershipListItem
              key={membership.id}
              membership={membership}
              business={data.value.data.businesses.find(
                (b) => b.id === membership.businessId
              )}
            />
          ))}
        </div>
      ) : (
        <div>No memberships found</div>
      )}
    </div>
  );
}
