import { IntlShape } from "react-intl";

const modifierSelectDescription = (
  intl: IntlShape,
  { min, max }: { min: number; max: number }
) => {
  if (min === max && max > 0) {
    return intl.formatMessage(
      { id: "modifiers_select_exactly_instructions" },
      { min, max }
    );
  } else if (min >= 1 && max > 1 && min < max) {
    return intl.formatMessage(
      { id: "modifiers_select_min_to_max_instructions" },
      { min, max }
    );
  } else if (min === 0 && max > 0) {
    return intl.formatMessage(
      { id: "modifiers_select_upto_to_max_instructions" },
      { min, max }
    );
  }
};

export default modifierSelectDescription;
