import client from "./client";

export function readFile(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();

    fr.onload = () => {
      try {
        const result = fr.result.toString().split(",");
        resolve(result.slice(1, result.length).join(","));
      } catch (error) {
        reject(error);
      }
    };

    fr.readAsDataURL(file); // or readAsText(file) to get raw content
  });
}

export const handleUploadImages = async (files: any[]) => {
  const data = [];

  if (files.length === 0) return;

  for (const file of files) {
    data.push(await readFile(file));
  }

  const response = await client.api.uploadImages(data);
  return response.data;
};
