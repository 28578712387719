import * as yup from "yup";
import parsePhoneNumber from "../utils/parsePhoneNumber";

const invalidRegions: any[] = [];

export const yupMobileNumberField = yup.string().test({
  name: "mobile",
  test: function (value) {
    try {
      // this check is not checking if required

      if (!value) return true;

      const pn = parsePhoneNumber(value as string);

      if (!pn?.valid) {
        if (value?.indexOf?.("+") === -1 && value?.length > 5)
          return this.createError({ message: "mobile_cc_missing" });

        return this.createError({
          message: "mobile_challenge_invalid_number",
        });
      }

      const region = pn.regionCode;

      if (invalidRegions.includes(region)) {
        return this.createError({
          message: `Sorry, we are still not active in the ${region}.`,
        });
      }

      return true;
    } catch (error) {
      console.log(error);
      return this.createError({
        message: "mobile_challenge_invalid_number",
      });
    }
  },
});

export const mobileInputFormSchema = yup
  .object({
    mobileNumber: yupMobileNumberField.required(),
  })
  .required();

export type TMobileInputForm = yup.InferType<typeof mobileInputFormSchema>;
