import {
  SelectInputOption,
  BusinessEntitiesResponse,
} from "@gethere/common/types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import client from "../utils/client";
import errorify from "../utils/errorify";

const defaultState = {
  loading: false,
  result: null,
  error: null,
  entities: {} as BusinessEntitiesResponse["entities"],
  options: {
    categories: [] as SelectInputOption[],
    roles: [] as SelectInputOption[],
    places: [] as SelectInputOption[],
  },
};

const BusinessContext = createContext<{
  state: typeof defaultState;
  load: () => Promise<void>;
  business: BusinessEntitiesResponse["entities"]["businesses"][string];
}>({
  state: defaultState,
  load: async () => {},
  business: {} as any,
});

export const useBusiness = () => useContext(BusinessContext);

export const BusinessProvider = ({ businessId, children }) => {
  const [state, setState] = useState({ ...defaultState });

  const load = useCallback(async () => {
    try {
      setState((s) => ({ ...s, loading: true }));
      const res = await client.api.getBusinessEntities(businessId);
      const { result, entities } = res.data;
      setState((s) => ({
        ...s,
        result,
        entities,
        loading: false,
        options: {
          roles: Object.keys(entities.roles).map((id) => ({
            value: id,
            label: entities.roles?.[id]?.name,
          })),
          categories: Object.keys(entities.categories).map((id) => ({
            value: id,
            label: entities.categories[id]?.name,
          })),
          places: Object.keys(entities.places).map((id) => ({
            value: id,
            label: entities.places[id]?.name,
          })),
        },
      }));
    } catch (error) {
      setState((s) => ({
        ...s,
        result: null,
        error: errorify(error)?.message,
        loading: false,
      }));
    }
  }, [setState, businessId]);

  useEffect(() => {
    setState({ ...defaultState });
    if (businessId) {
      load();
    }
  }, [businessId, setState, load]);

  return (
    <BusinessContext.Provider
      value={{
        state,
        load,
        business: state.entities?.businesses?.[state.result],
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};

export default BusinessContext;
