import { CountryCodes } from "@gethere/common/enums";
import { MOBILE_CHALLENGE } from "@gethere/common/settings";
import { mobileInputFormSchema } from "@gethere/common/yup/MobileInputForm";
import { TSignInWithGoogle } from "@gethere/common/yup/User";
import {
  ArrowPathIcon,
  ChatBubbleLeftEllipsisIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { captureException } from "@sentry/react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../components/Alert";
import Btn from "../components/Btn";
import FormattedRelative from "../components/FormattedRelative";
import Input from "../components/Input";
import { LoadingAnimation } from "../components/LoadingScreen";
import { MobileInput } from "../components/MobileField";
import { useAnalytics } from "../contexts/AnalyticsContext";
import { getReCaptchaToken, useRecaptcha } from "../contexts/ReCaptcha";
import { UserFields, useUser } from "../contexts/UserContext";
import { onLogin } from "../state/reducers/user";
import { RootState } from "../state/store";
import client from "../utils/client";
import errorify from "../utils/errorify";
import SignInWithGoogle from "./SignInWithGoogle";
import { BasicUserDetailsForm } from "./UserProfileScreen";
import { useAppDispatch } from "../state/hooks";
import openNewTab from "../utils/openNewTab";
import { Button } from "../components/ui/button";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../components/ui/input-otp";

let timeout: any;

const InsertMobile = ({ onSuccess, defaultCountry, loading, setLoading }) => {
  const intl = useIntl();
  const { logEvent } = useAnalytics();

  const formik = useFormik({
    initialStatus: null,
    validationSchema: mobileInputFormSchema,
    initialValues: mobileInputFormSchema.cast({
      mobileNumber: "",
    }),
    onSubmit: async ({ mobileNumber }) => {
      try {
        console.log("onSubmit");
        formik.setSubmitting(true);
        setLoading(true);
        logEvent("auth_login_mobile_number_submit");
        const token = await getReCaptchaToken();

        const response = await client.post("/auth/consumer/mobile", {
          mobileNumber,
          token,
        });
        onSuccess?.(response.data.result);
      } catch (error) {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.message === "Already signed in as a user"
        ) {
          client.logout();
        }

        formik.setStatus(errorify(error)?.message);
        formik.setSubmitting(false);
        captureException(error);
        logEvent("auth_login_mobile_number_failed", { error: error?.message });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form className="grid grid-cols-1 gap-3">
      <MobileInput
        autoFocus={true}
        autoComplete="tel"
        defaultRegion={defaultCountry}
        error={formik.errors.mobileNumber}
        name="mobileNumber"
        touched={formik.touched.mobileNumber}
        id="outlined-basic"
        disabled={formik.isSubmitting || loading}
        label={intl.formatMessage({ id: "mobile_number" })}
        onBlur={formik.handleBlur}
        value={formik.values.mobileNumber as string}
        helperText={intl.formatMessage({
          id: "guest_mobile_input_description",
        })}
        onChange={(v) => formik.setFieldValue("mobileNumber", v)}
      />
      {formik.status && <Alert type="danger">{formik.status}</Alert>}
      <Button
        size="lg"
        variant="default"
        type="submit"
        disabled={formik.isSubmitting || loading}
        onClick={formik.handleSubmit as any}
      >
        {intl.formatMessage({ id: "send_validation_code" })}
      </Button>
    </form>
  );
};

const ValidateMobileVerification = ({
  onSuccess,
  onReset,
  challengeId,
  expireAt,
  expired,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [state, setstate] = useState({ validating: false, error: null });
  const { logEvent } = useAnalytics();
  const [code, setOTP] = useState("");

  const validateCode = useCallback(
    async (code) => {
      if (code?.length === MOBILE_CHALLENGE.CODE_LENGTH) {
        try {
          setstate((s) => ({ ...s, validating: true, error: null }));

          logEvent("auth_login_mobile_verify_submit");

          const { data } = await client.post(
            `/auth/consumer/mobile/${challengeId}`,
            {
              code,
            }
          );

          logEvent(
            data.created ? "auth_signup_success" : "auth_login_success",
            {
              method: "mobile",
            }
          );

          await dispatch(onLogin(data) as any);
          onSuccess?.(data);
        } catch (error) {
          const message = errorify(error)?.message;
          captureException(error);
          toast.error(message);
          setOTP("");
          setstate((s) => ({
            ...s,
            validating: false,
            error: message,
          }));
          logEvent("auth_login_mobile_verify_failed", { error: message });
        }
      }
    },
    [dispatch, onSuccess, setstate]
  );

  useEffect(() => {
    validateCode(code);
  }, [code, validateCode]);

  const Icon = expired
    ? ClockIcon
    : state.error
    ? ExclamationCircleIcon
    : ChatBubbleLeftEllipsisIcon;

  return (
    <form className="mx-auto w-full grid-cols-1 gap-5">
      <div className="flex flex-row mb-10 text-center items-center justify-center">
        <div className="flex flex-col">
          <div className="flex self-center text-center">
            {state.validating ? (
              <LoadingAnimation size={150} />
            ) : (
              <Icon className="w-20 h-20 text-gray-300 dark:text-gray-700 mb-5" />
            )}
          </div>
          <h5 className={clsx("text-2xl font-medium")}>
            {intl.formatMessage({
              id: expired
                ? "verification_code_expired"
                : state.error
                ? state.error
                : "verificattion_code_screen_title",
            })}
          </h5>
        </div>
      </div>

      {!expired && (
        <div className="text-center flex flex-col items-center">
          <div className="mb-5">
            <p className="text-gray-500 mb-2">Please enter the code below</p>

            <InputOTP
              maxLength={5}
              value={code}
              onChange={(value) => setOTP(value)}
              autoFocus
              disabled={state.validating}
            >
              <InputOTPGroup>
                <InputOTPSlot index={0} />
                <InputOTPSlot index={1} />
                <InputOTPSlot index={2} />
                <InputOTPSlot index={3} />
                <InputOTPSlot index={4} />
              </InputOTPGroup>
            </InputOTP>

            {/* <Input
              value={code}
              autoFocus={true}
              className="text-center text-xl tracking-widest"
              onChange={(e) => setOTP(e.target.value)}
              maxLength={MOBILE_CHALLENGE.CODE_LENGTH}
              type="tel"
              disabled={state.validating}
            /> */}
          </div>
          <p className="text-sm text-gray-500">
            {intl.formatMessage(
              { id: "verification_code_will_expire_at" },
              {
                at: (
                  <FormattedRelative
                    value={expireAt}
                    updateIntervalInSeconds={1}
                  />
                ),
              }
            )}
          </p>
        </div>
      )}

      {expired && (
        <Btn
          variant="contained"
          color="primary"
          size="lg"
          className="w-full"
          StartIcon={ArrowPathIcon}
          onClick={onReset}
        >
          {intl.formatMessage({ id: "try_again" })}
        </Btn>
      )}
    </form>
  );
};

const SignInAsGuestBtn = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled: boolean;
}) => {
  const intl = useIntl();
  return (
    <Button
      size="lg"
      variant="secondary"
      disabled={disabled}
      onClick={onClick}
      type="button"
      className="w-full"
    >
      {intl.formatMessage({
        id: "continue_as_a_guest",
        defaultMessage: "Continue as a Guest",
      })}
    </Button>
  );
};

const SignInHeader = ({
  loading,
  titleIntlMessage = "session_guest_user_required_title",
}: {
  loading: boolean;
  titleIntlMessage: string;
}) => {
  const intl = useIntl();
  return (
    <div className="mb-5 flex-col flex gap-5">
      <h1 className="text-2xl font-semibold tracking-tight text-center">
        {intl.formatMessage({ id: titleIntlMessage })}
      </h1>
      <p className="text-sm text-muted-foreground text-center">
        {intl.formatMessage(
          { id: "welcom_privacy_text" },
          {
            link: (
              <span
                className="underline underline-offset-4 hover:text-primary cursor-pointer"
                onClick={() => {
                  openNewTab("/privacypolicy");
                }}
              >
                {intl.formatMessage({ id: "welcom_privacy_link" })}
              </span>
            ),
          }
        )}
      </p>
    </div>
  );
};

const SignInContainer = ({
  onSuccess = (...args: any) => {},
  allowGuest = false,
  allowGoogle = true,
  googleOneTap = false,
  forceDetails = false,
  fields = ["name.first", "name.last", "email"],
  titleIntlMessage,
}: {
  onSuccess?: (...args: any) => void;
  allowGuest?: boolean;
  forceDetails?: boolean;
  googleOneTap?: boolean;
  allowGoogle?: boolean;
  fields?: UserFields[];
  titleIntlMessage?: string;
}) => {
  useRecaptcha();
  const { user, hasBasicFields, isSigned, isGuest } = useUser();
  const [loading, setLoading] = useState(false);
  const { logEvent } = useAnalytics();
  const dispatch = useDispatch();

  const requireds = useMemo(
    () => (user ? hasBasicFields(fields) : { status: false, missing: fields }),
    [
      user?.id,
      user?.mobileNumber,
      user?.email,
      user?.name?.first,
      user?.name?.last,
    ]
  );

  const { userId, defaultCountry } = useSelector((s: RootState) => ({
    userId: s.user.result,
    defaultCountry: s.system.country || CountryCodes.UnitedKingdom,
  }));

  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (userId && typeof onSuccess === "function") {
      if (!forceDetails) {
        onSuccess();
      } else if (forceDetails && requireds.status) {
        onSuccess();
      }
    }
  }, [onSuccess, userId, requireds.status]);

  const [state, setState] = useState({
    challenge: null,
    expired: false,
    validated: false,
  });

  const challenged = Boolean(state.challenge);

  const handleMobileSuccess = ({ id, expireAt }) => {
    const left = new Date(expireAt).getTime() - Date.now();
    timeout = setTimeout(() => {
      setState((s) => ({
        ...s,
        expired: true,
      }));
    }, left); // time left

    setState({ ...state, expired: false, challenge: { id, expireAt } });
  };

  const handleReset = () =>
    setState((s) => ({
      ...s,
      expired: false,
      challenge: null,
      validated: false,
    }));

  const handleSignInWithGoogle = async (payload: TSignInWithGoogle) => {
    try {
      setLoading(true);
      logEvent("auth_login_google_submit", {
        method: "google",
        selectBy: payload.select_by,
      });

      const { data } = await client.post("/auth/consumer/google", payload);

      logEvent(data.created ? "auth_signup_success" : "auth_login_success", {
        method: "google",
        selectBy: payload.select_by,
      });

      await dispatch(onLogin(data) as any);
    } catch (e) {
      if (
        e?.response?.status === 401 &&
        e?.response?.data?.message === "Already signed in as a user"
      ) {
        client.logout();
      }

      logEvent("auth_login_google_failed", {
        method: "google",
        selectBy: payload.select_by,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationSuccess = () => {
    setState((s) => ({
      ...s,
      challenge: null,
      expired: false,
      validated: true,
    }));
  };

  const onContinueAsGuest = async () => {
    try {
      if (isGuest && allowGuest) {
        if (requireds.status) {
          onSuccess?.();
        }
      }

      setLoading(true);
      const recaptcha = await getReCaptchaToken();
      const req = await client.api.authGuest({ recaptcha });
      logEvent("auth_guest");
      dispatch(onLogin(req.data) as any);
    } catch (error) {
      toast.error(errorify(error).message);
    } finally {
      setLoading(false);
    }
  };

  if (!isSigned || (isGuest && !allowGuest)) {
    if (!challenged) {
      return (
        <div className="mx-auto max-w-sm">
          <SignInHeader loading={loading} titleIntlMessage={titleIntlMessage} />
          <InsertMobile
            loading={loading}
            setLoading={setLoading}
            onSuccess={handleMobileSuccess}
            defaultCountry={defaultCountry}
          />
          {!!(allowGoogle || allowGuest) && (
            <div className="relative my-5">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-background px-2 text-muted-foreground">
                  Or continue with
                </span>
              </div>
            </div>
          )}
          {allowGoogle && (
            <SignInWithGoogle
              allowOneTap={googleOneTap}
              loading={loading}
              onSuccess={handleSignInWithGoogle}
            />
          )}
          {allowGuest && (
            <>
              <SignInAsGuestBtn
                disabled={loading}
                onClick={onContinueAsGuest}
              />
              <p className="text-muted-foreground text-xs text-center mt-3">
                As a guest, you won't be able to save your preferences,
                reservations, or previous orders.
              </p>
            </>
          )}
        </div>
      );
    } else {
      return (
        <ValidateMobileVerification
          expired={state.expired}
          onSuccess={handleVerificationSuccess}
          challengeId={state.challenge?.id}
          expireAt={state.challenge?.expireAt}
          onReset={handleReset}
        />
      );
    }
  } else if (!requireds.status) {
    return <BasicUserDetailsForm onSuccess={onSuccess} fields={fields} />;
  } else {
    return null;
  }
};

export default SignInContainer;
