import { ItemTypes, OrderItemStatus } from "@gethere/common/enums";
import { ALLERGIES } from "@gethere/common/settings";
import {
  applyOrderItemModifiersByState,
  calcModifierChange,
  calcOrderItemCost,
  checkSchedule,
  filterItemPromotions,
  orderItemCan,
  orderItemIs,
  previewOrderItemCost,
} from "@gethere/common/utilities";
import defaultModifiers from "@gethere/common/utils/defaultModifiers";
import { TItem } from "@gethere/common/yup/Item";
import {
  orderItemMetaSchema,
  orderItemNotesSchema,
  orderItemSubGroupSchema,
  orderItemUnitSchema,
  TOrderItem,
} from "@gethere/common/yup/OrderItem";
import { sessionUpdateOrderItemSchema } from "@gethere/common/yup/SessionUpdateActions";
import {
  ChatBubbleBottomCenterTextIcon,
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
import { captureException } from "@sentry/react";
import cuid from "cuid";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../components/Alert";
import Amount from "../components/Amount";
import Avatar from "../components/Avatar";
import Btn from "../components/Btn";
import ItemModifier from "../components/ItemModifier";
import { Picture } from "../components/Picture";
import BottomPageContainer from "../components/SafeArea";
import Stepper from "../components/Stepper";
import { useSession } from "../contexts/SessionContext";
import {
  addOrderItem,
  sessionCurrencySelector,
  sessionSelector,
  updateOrderItem,
} from "../state/reducers/session";
import { RootState } from "../state/store";
import { useAnalytics } from "../contexts/AnalyticsContext";
import clsx from "clsx";

const SessionItemModal = ({ onClose, itemId, orderItemId }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { logEvent } = useAnalytics();

  const { status, promotions } = useSession();
  const orderable = status?.orderable;

  const { items, modifiers, session, userId, currency } = useSelector(
    (state: RootState) => ({
      items: state.session.entities?.items,
      modifiers: state.session.entities?.modifiers,
      session: sessionSelector(state),
      currency: sessionCurrencySelector(state),
      userId: state.user.result,
    })
  );

  const orderItem: TOrderItem | null = orderItemId
    ? session.orderItems.find(
        (oi) => orderItemId === (oi.id || oi.meta?.localId)
      )
    : null;

  const iId = orderItem ? orderItem?.itemId : itemId;

  const item: TItem | null = iId ? items?.[iId] : null;

  const [state, setState] = useState(null);

  useEffect(() => {
    if (!iId) {
      setState(null);
    } else {
      if (!orderItemId && itemId) {
        // opend item
        logEvent("session_client_item_open", {
          itemId: itemId,
          placeId: session?.placeId,
          terminalId: session?.terminalId,
        });
      } else {
        // opened order item
        logEvent("session_client_oi_open", {
          itemId: itemId,
          placeId: session?.placeId,
          terminalId: session?.terminalId,
        });
      }

      const mods = defaultModifiers(
        (item as any).modifierIds.map((mId) => modifiers[mId]),
        orderItem?.modifiers || {}
      );

      const subItemGroups = item?.subItemGroups?.reduce((groups, sig) => {
        const si: any = {};

        const selectedItemId = orderItem
          ? orderItem.subItemGroups?.[sig.id]?.itemId
          : sig.defaultSubItem;

        si.itemId = selectedItemId;

        if (selectedItemId && items[selectedItemId]) {
          if (orderItem) {
            si.notes = orderItem.subItemGroups.notes;
          } else {
            si.itemId = selectedItemId;
            si.notes = {
              liable: "",
            };
          }

          const mods = defaultModifiers(
            items[selectedItemId].modifierIds?.map((m) => modifiers[m]),
            orderItem?.subItemGroups?.[sig.id] || {}
          );
          si.modifiers = mods;
          si.defaultModifiers = mods;
        }

        groups[sig.id] = si;
        return groups;
      }, {});

      if (orderItem) {
        // updating
        setState({
          ppu: orderItem.ppu,
          amount: orderItem.amount,
          notes: orderItem.notes,
          defaultModifiers: mods,
          modifiers: mods,
          subItemGroups,
          activeSubItemGroup: null, // not open by default
        });
      } else {
        // new
        setState({
          ppu: item.price,
          amount: item.amount.min,
          notes: { liable: "" },
          defaultModifiers: mods,
          modifiers: mods,
          subItemGroups,
          activeSubItemGroup: item?.subItemGroups?.[0]?.id, // first selection
        });
      }
    }

    return () => {
      // cleanup ?
    };
    // eslint-disable-next-line
  }, [itemId, orderItemId]);

  const handleSubItemModifier = React.useCallback(
    (sig: string) => (modId: string, optId: string, value: string | number) => {
      const modifier = modifiers[modId];

      if (!modifier) return null;

      setState((s) => ({
        ...s,
        subItemGroups: {
          ...s.subItemGroups,
          [sig]: {
            ...s.subItemGroups[sig],
            modifiers: {
              ...s.subItemGroups[sig].modifiers,
              [modId]: calcModifierChange(
                modifier,
                optId,
                value,
                s.subItemGroups[sig].modifiers[modId]
              ),
            },
          },
        },
      }));
    },
    [modifiers]
  );

  const discounts = useMemo(() => session?.discounts, [session?.updatedAt]);

  const promos = useMemo(() => {
    return filterItemPromotions({
      item,
      promotions,
      at: new Date().toISOString(),
      promoCodeIds: status.promoCodeIds,
      discounts,
    });
  }, [promotions, status.promoCodeIds, discounts]);

  const handleItemModifier = React.useCallback(
    (modId: string, optId: string, value: string | number) => {
      const modifier = modifiers[modId];

      if (!modifier) return null;

      setState((s) => ({
        ...s,
        modifiers: {
          ...s.modifiers,
          [modId]: calcModifierChange(
            modifier,
            optId,
            value,
            s.modifiers[modId]
          ),
        },
      }));
    },
    [modifiers]
  );

  const handleAmount = useCallback(
    (amount) => setState((s) => ({ ...s, amount })),
    [setState]
  );

  const handleNoteChange = useCallback(
    (note: string) =>
      setState((s) => ({
        ...s,
        notes: {
          liable: note,
        },
      })),
    [setState]
  );

  const selectSubItem = useCallback(
    (sig: string, sit: string) => {
      const mods =
        sit !== null && items[sit]
          ? defaultModifiers(items[sit].modifierIds?.map((m) => modifiers[m]))
          : {};

      const subItemGroup = item.subItemGroups.find(({ id }) => id === sig);

      const subItemSelected =
        sit && subItemGroup
          ? subItemGroup.subItems.find((si) => si.itemId === sit)
          : null;

      setState((s) => ({
        ...s,
        subItemGroups: {
          ...s.subItemGroups,
          [sig]: {
            ...s.subItemGroups[sig],
            itemId: sit,
            cost: subItemSelected?.price || 0,
            defaultModifiers: mods,
            modifiers: mods,
            notes: {},
          },
        },
      }));
    },
    [items, item?.subItemGroups, modifiers]
  );

  const handleOrderItemRemove = useCallback(async () => {
    await dispatch(
      updateOrderItem({
        id: orderItemId,
        payload: { ...orderItem, status: OrderItemStatus.REMOVED },
      }) as any
    );
    logEvent("session_client_oi_removed", {
      itemId: iId,
      placeId: session?.placeId,
      terminalId: session?.terminalId,
    });
    onClose?.();
  }, [dispatch, orderItem, onClose]);

  const handleOrderItemSubmit = useCallback(async () => {
    try {
      // add for new, update for oi.

      const oi = sessionUpdateOrderItemSchema.cast({
        ...(orderItem || {}),
        unit: orderItemUnitSchema.cast({
          type: orderItem?.unit?.type || item?.unit?.type,
        }),
        amount: state.amount || 1,
        notes: orderItemNotesSchema.cast({
          ...(orderItem?.notes || {}),
          liable: state.notes.liable || "",
        }),
        status: OrderItemStatus.PENDING,
        liableId: userId || "",
        itemId: iId,
        modifiers: {},
        ppu: item.price,
        meta: orderItemMetaSchema.cast({
          localId: cuid(),
        }),
      });

      //modifiers
      const invalidModifiers = applyOrderItemModifiersByState(
        modifiers,
        state,
        oi
      );

      if (invalidModifiers.length > 0) {
        const names = invalidModifiers
          .map((mid) => modifiers?.[mid]?.displayName || modifiers?.[mid]?.name)
          .join(", ");

        toast(`Please check ${names}`);
        return;
      }

      if (item.type === ItemTypes.BUNDLE) {
        oi.subItemGroups = {};
        for (const sig in state.subItemGroups) {
          // sub item state

          const subItemGroupState = state.subItemGroups[sig];

          const selectedSubItemGroup = item.subItemGroups.find(
            (si) => si.id === sig
          );

          const selectedSubItem = selectedSubItemGroup.subItems.find(
            (si) => si.itemId === subItemGroupState.itemId
          );

          if (!selectedSubItem) {
            toast(
              `Please choose ${
                selectedSubItemGroup.displayName || selectedSubItemGroup.name
              }`
            );
            return;
          }

          const subItemGroup = orderItemSubGroupSchema.cast({
            itemId: subItemGroupState?.itemId,
            modifiers: {},
            cost: selectedSubItem ? selectedSubItem.price : 0,
            notes: { liable: subItemGroupState?.notes?.liable },
          });

          const invalids = applyOrderItemModifiersByState(
            modifiers,
            subItemGroupState,
            subItemGroup
          );

          if (invalids.length > 0) {
            const names = invalids
              .map(
                (mid) => modifiers?.[mid]?.displayName || modifiers?.[mid]?.name
              )
              .join(", ");

            toast(`Please check ${selectedSubItemGroup.name} ${names}`);
            return;
          }

          oi.subItemGroups[sig] = subItemGroup;
        }
      }

      oi.cost = calcOrderItemCost(oi);

      if (orderItem) {
        logEvent("session_client_oi_updated", {
          itemId: iId,
          placeId: session?.placeId,
          terminalId: session?.terminalId,
          amount: oi.amount,
          cost: oi.cost,
        });
        await dispatch(
          updateOrderItem({
            id: orderItemId,
            payload: oi,
          }) as any
        );
      } else {
        logEvent("session_client_item_added", {
          itemId: iId,
          placeId: session?.placeId,
          terminalId: session?.terminalId,
          amount: oi.amount,
          cost: oi.cost,
        });
        await dispatch(addOrderItem({ orderItem: oi }) as any);
      }
      onClose();
    } catch (error) {
      captureException(error);
    }
    // eslint-disable-next-line
  }, [dispatch, addOrderItem, onClose, state, iId]);

  const cost = useMemo(
    () => previewOrderItemCost(state, modifiers),
    [state, modifiers]
  );

  const isChanged = useMemo(() => {
    if (!orderItem || !state) return false;

    if (state?.amount !== orderItem.amount) {
      return true;
    }

    if (
      state.notes?.liable &&
      state.notes?.liable !== orderItem.notes?.liable
    ) {
      return true;
    }

    if (!isEqual(state.modifiers, state.defaultModifiers)) {
      return true;
    }

    for (const sg in state.subItemGroups) {
      const subGroup = state.subItemGroups[sg];
      if (subGroup.itemId !== orderItem.subItemGroups[sg]?.itemId) {
        return true;
      } else if (!isEqual(subGroup.modifiers, subGroup.defaultModifiers)) {
        return true;
      } else if (
        subGroup.notes?.liable &&
        subGroup.notes?.liable !== orderItem.subItemGroups[sg].notes?.liable
      ) {
        return true;
      }

      return false;
    }
  }, [state, orderItem]);

  // if (!item || !state) {
  //   return null;
  // }

  const disabled = !orderable;

  const isLiable = !orderItemId || (userId && orderItem?.liableId === userId);

  const is = useMemo(
    () =>
      orderItemIs({
        orderable,
        status: orderItem?.status,
        id: orderItemId,
        state: session?.state,
        type: session?.type,
        liable: isLiable,
        operator: false,
      }),
    [
      orderable,
      orderItem?.status,
      orderItemId,
      session?.state,
      session?.type,
      isLiable,
    ]
  );

  const can = orderItemCan({ changed: isChanged, is });

  const formattedCost = cost
    ? intl.formatNumber(cost, {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
    : null;

  if (!state || !item) {
    return null;
  }

  const name = item.displayName || item.name;
  let diets = Object.entries(item.meta?.diets || {});

  if (
    diets.find((x) => x[0] === "vegan") &&
    diets.find((x) => x[0] === "vegetarian")
  ) {
    diets = diets.filter(([d]) => d !== "vegetarian");
  }

  let allergies = Object.entries(item.meta?.allergies || {});

  const allergans = allergies.filter(([_, value]) => value === true);

  const DISPLAY_ALLERGY_FREE: (typeof ALLERGIES)[number][] = ["gluten", "milk"];

  const calories = item?.meta?.calories;

  const hasCalories =
    calories !== undefined && (calories as any) !== "" && calories !== null;

  allergies = allergies?.filter?.(
    ([name, value]) =>
      value === false && DISPLAY_ALLERGY_FREE.includes(name as any)
  );

  const allowNotes = !item.meta?.noCustomerNotes;

  return (
    <div className="flex flex-1 flex-col justify-between select-none h-[95vh] relative">
      <div>
        <div className="relative min-h-10 w-full">
          {item?.images?.length > 0 ? (
            <Picture
              alt={name}
              srcset={item?.images?.[0]}
              sourceHeight={600}
              sourceWidth={600}
              className="h-72 w-full object-cover pointer-events-none"
            />
          ) : null}
          {orderItem?.status && (
            <div className="absolute bottom-3 left-5 uppercase text-[10px] font-medium bg-text text-card p-2 shadow-sm rounded-md">
              {intl.formatMessage(
                {
                  id: "order_item_status",
                  defaultMessage: orderItem.status,
                },
                { status: orderItem.status }
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col px-5 gap-2 my-5">
          <h2 className="font-bold text-3xl rtl:text-right">{name}</h2>
          {Boolean(item.desc) && (
            <p className="text-muted-foreground rtl:text-right text-sm max-w-[80%]">
              {item.desc}
            </p>
          )}
          {(diets?.length > 0 || allergies.length > 0 || hasCalories) && (
            <div className="flex flex-row gap-2 my-5">
              {diets?.map?.(([name, value]) => {
                if (!value) return null;
                return (
                  <span
                    key={name}
                    className="rounded lowercase px-2 py-1 text-xs bg-primary-100 text-primary-800 dark:bg-primary-800 dark:text-primary-100 font-medium"
                  >
                    {intl.formatMessage({ id: name, defaultMessage: name })}
                  </span>
                );
              })}
              {allergies.map?.(([name, value]) => {
                if (value) return null;
                return (
                  <span
                    key={name}
                    className="rounded lowercase px-2 py-1 text-xs bg-secondary-100 text-secondary-800 dark:bg-secondary-800 dark:text-secondary-100 font-medium"
                  >
                    {intl.formatMessage({ id: name, defaultMessage: name })}{" "}
                    free
                  </span>
                );
              })}
              {hasCalories && (
                <span className="lowercase px-2 py-1 text-xs text-muted-foreground font-medium">
                  {String(calories)} cal
                </span>
              )}
            </div>
          )}
        </div>
        {item.meta?.minAge && (
          <Alert iconSize="base" type="info" className="mx-5">
            <h6 className="font-medium text-md">Age restricted item</h6>
            <p className="text-xs font-normal">
              not for sale to anyone under the age of {item.meta?.minAge}. You
              may need to provide a valid ID upon receiving your order.
            </p>
          </Alert>
        )}
        {promos?.length > 0 && (
          <div className="grid grid-cols-1 gap-3 px-5">
            <span className="text-sm text-muted-foreground font-medium">
              Promotions available for this item
            </span>
            {promos.map((promo) => {
              const { closeAt } = promo.schedule
                ? checkSchedule(promo.schedule)
                : { closeAt: null };
              return (
                <div
                  key={promo.id}
                  className="px-3 py-2 flex flex-row items-center shadow ring-4 bg-rose-100/20 ring-rose-500/10 rounded gap-2"
                >
                  <TagIcon className="w-5 h-5 flex-none stroke-rose-500" />
                  <div className="flex flex-row justify-between grow items-center">
                    <span className="font-medium capitalize">
                      {promo.displayName || promo.name}
                    </span>
                    {!!closeAt && (
                      <span className="text-xs text-muted-foreground flex-none">
                        {intl.formatMessage(
                          { id: "until_x", defaultMessage: "Until {x}" },
                          { x: intl.formatTime(closeAt) }
                        )}
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div>
          {item.type === ItemTypes.BUNDLE && item.subItemGroups.length > 0 && (
            <Stepper>
              {item.subItemGroups.map((sig, index) => {
                const selectedItemId = state.subItemGroups[sig.id]?.itemId;
                const selectedItem = items?.[selectedItemId];
                return (
                  <Stepper.Step
                    Icon={
                      selectedItem ? CheckCircleIcon : QuestionMarkCircleIcon
                    }
                    key={sig.id}
                    title={sig.displayName || sig.name}
                    active={sig.id === state.activeSubItemGroup}
                    onClick={() =>
                      setState((s) => ({
                        ...s,
                        activeSubItemGroup: sig.id,
                      }))
                    }
                  >
                    {!!selectedItem && (
                      <div>
                        <div className="flex flex-row justify-between gap-2">
                          {!!selectedItem.images?.[0] && (
                            <div className="flex flex-none w-12">
                              <Avatar
                                lazy={false}
                                size="md"
                                image={selectedItem.images?.[0]}
                              />
                            </div>
                          )}
                          <div className="flex flex-col flex-grow">
                            <span className="font-medium">
                              {selectedItem.displayName || selectedItem.name}
                            </span>
                            <span className="text-sm text-muted-foreground">
                              {selectedItem.desc}
                            </span>
                          </div>
                          <Btn
                            variant="text"
                            color="primary"
                            size="sm"
                            onClick={() => selectSubItem(sig.id, null)}
                          >
                            {intl.formatMessage({
                              id: "change_select",
                              defaultMessage: "Change",
                            })}
                          </Btn>
                        </div>
                      </div>
                    )}
                    <div className="grid grid-cols-1 gap-2">
                      {selectedItemId ? (
                        selectedItem.modifierIds.map((mId, mii) => {
                          const modifier = modifiers[mId];
                          if (!modifier || modifier.operator?.only) return null;
                          return (
                            <ItemModifier
                              key={mId}
                              horizontalPadding={false}
                              disabled={!is.mutable}
                              handleUpdate={handleSubItemModifier(sig.id)}
                              modifier={modifier}
                              state={state.subItemGroups[sig.id].modifiers[mId]}
                              currency={currency}
                              base={
                                state.subItemGroups[sig.id].defaultModifiers[
                                  mId
                                ]
                              }
                            />
                          );
                        })
                      ) : (
                        <div className="grid grid-cols-1 gap-3">
                          {sig.subItems.map((subItem) => {
                            const item = items[subItem.itemId];
                            return (
                              <div
                                key={subItem.itemId}
                                className={clsx(
                                  "flex flex-row justify-between gap-2 cursor-pointer",
                                  !item.active && "opacity-50"
                                )}
                                onClick={() =>
                                  !item.active
                                    ? null
                                    : selectSubItem(sig.id, item.id)
                                }
                              >
                                <div className="flex flex-none w-12">
                                  <Avatar
                                    lazy={false}
                                    size="md"
                                    label={item.name}
                                    image={item.images?.[0]}
                                  />
                                </div>
                                <div className="flex flex-col flex-grow">
                                  <span className="font-medium">
                                    {item.displayName || item.name}
                                  </span>
                                  <span className="text-sm text-muted-foreground">
                                    {item.desc}
                                  </span>
                                </div>
                                {subItem.price > 0 && (
                                  <span className="text-muted-foreground text-sm">
                                    {"+ " +
                                      intl.formatNumber(subItem.price, {
                                        style: "currency",
                                        currency,
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2,
                                      })}
                                  </span>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </Stepper.Step>
                );
              })}
            </Stepper>
          )}
        </div>

        {(item as any).modifierIds?.map((mId) => {
          const modifier = modifiers?.[mId];
          if (!modifier || modifier.operator?.only) return null;
          return (
            <ItemModifier
              key={mId}
              handleUpdate={handleItemModifier}
              modifier={modifier}
              state={state.modifiers[mId]}
              base={state.defaultModifiers[mId]}
              currency={currency}
              disabled={!is.mutable}
            />
          );
        })}
        {allergans?.length > 0 && (
          <Alert iconSize="base" type="default" className="rounded-none">
            <p className="text-xs font-normal inline-block">
              <span className="ltr:pr-2 rtl:pl-2 font-bold text-md">
                {intl.formatMessage({
                  id: "allergies",
                  defaultMessage: "Allergens",
                })}
              </span>
              <span>
                {[
                  intl.formatMessage({ id: "may_contain" }),
                  allergans
                    .map?.(([name, _]) =>
                      intl.formatMessage({ id: name, defaultMessage: name })
                    )
                    .join(", "),
                ].join(" ")}
              </span>
            </p>
          </Alert>
        )}
      </div>
      <div className="flex flex-col place-content-end pb-5 mt-auto">
        {is.mutable && allowNotes && (
          <div className="px-5 mt-5">
            <div className="flex flex-row items-center justify-between">
              <h5 className="text-xl font-medium">
                {intl.formatMessage({ id: "session_order_item_notes" })}
              </h5>
              <div className="flex flex-row items-center">
                {state.notes.liable?.length > 50 && (
                  <span className="text-sm font-medium mx-2 text-muted-foreground">
                    {120 - state.notes.liable.length} letters left
                  </span>
                )}
                <ChatBubbleBottomCenterTextIcon className="w-6 h-6" />
              </div>
            </div>
            <textarea
              rows={2}
              className="w-full my-2 text-sm focus:outline-none bg-transparent"
              maxLength={120}
              disabled={disabled}
              autoFocus={false}
              value={state.notes.liable}
              onChange={(e) => handleNoteChange(e.target.value)}
              placeholder={intl.formatMessage({
                id: "session_order_item_notes_placeholder",
              })}
            />
          </div>
        )}
        <div className="flex flex-row items-center content-between px-5 mb-3">
          {(is.mutable || Boolean(orderItem)) && (
            <Amount
              disabled={!is.mutable}
              min={item.amount.min}
              max={item.amount.max}
              value={state.amount}
              onChange={handleAmount}
              className="flex flex-1 justify-start"
            />
          )}
          <h6 className="font-medium flex flex-1 justify-end text-lg text-muted-foreground justify-self-end">
            {formattedCost}
          </h6>
        </div>
      </div>
      <BottomPageContainer className="w-full bg-gradient-to-t from-bg bottom-0 pb-5 fixed sm:absolute">
        <div className="flex flex-row gap-2 px-5">
          {can.add && (
            <Btn
              size="xl"
              className="flex flex-1 justify-center capitalize shadow-lg"
              variant="contained"
              fullWidth
              disabled={disabled}
              onClick={handleOrderItemSubmit}
              color="primary"
            >
              {intl.formatMessage({ id: "add" })}
            </Btn>
          )}
          {can.remove && (
            <Btn
              size="xl"
              className="flex flex-1 justify-center capitalize shadow-lg"
              fullWidth
              disabled={disabled}
              onClick={handleOrderItemRemove}
              variant="outline"
              color="text"
            >
              {intl.formatMessage({
                id: "session_order_item_remove",
                defaultMessage: "Remove",
              })}
            </Btn>
          )}
          {can.update && (
            <Btn
              size="xl"
              className="flex flex-1 justify-center capitalize shadow-lg"
              variant="contained"
              fullWidth
              disabled={disabled}
              onClick={handleOrderItemSubmit}
              color="primary"
            >
              {intl.formatMessage({
                id: "update",
                defaultMessage: "Update",
              })}
            </Btn>
          )}
        </div>
      </BottomPageContainer>
    </div>
  );
};

export default SessionItemModal;
