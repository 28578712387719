import { SUPPORTED_COUNTRIES } from "@gethere/common/countries";
import { locales } from "@gethere/common/enums";
import {
  DEFAULT_LANGUAGES,
  LOCALES_TRANSLATIONS,
} from "@gethere/common/settings";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { setCountry, setLocale } from "../state/reducers/system";
import Modal, { ModalTitle } from "./Modal";
import SelectGroup from "./SelectGroup";
import { useAppSelector } from "../state/hooks";

const LanguageDialog = ({ open, onClose, region = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();

  const LANG_ONLY = "/order" === location.pathname || !region;

  const country = useAppSelector((s) => s.system.country);
  const locale = useAppSelector((s) => s.system.locale);

  const handleCountry = (cc) => {
    const lcl = DEFAULT_LANGUAGES[cc] || locales.en_US;
    dispatch(setCountry({ country: cc, locale: lcl }));
    onClose();
  };

  const handleLocale = (locale) => {
    dispatch(setLocale(locale));
    onClose();
  };

  return (
    <Modal onClose={onClose} open={open}>
      <ModalTitle>
        {intl.formatMessage({
          id: LANG_ONLY ? "language" : "region_and_language",
        })}
      </ModalTitle>
      <div className="mt-3">
        {!LANG_ONLY ? (
          <SelectGroup value={country} onChange={handleCountry}>
            {SUPPORTED_COUNTRIES.map((cc) => {
              const locale = DEFAULT_LANGUAGES[cc] || locales.en_US;

              return (
                <SelectGroup.Item
                  key={cc}
                  value={cc}
                  name={intl.formatMessage(
                    { id: "countries_by_value" },
                    { value: cc }
                  )}
                >
                  {LOCALES_TRANSLATIONS[locale] || locale}
                </SelectGroup.Item>
              );
            })}
          </SelectGroup>
        ) : (
          <SelectGroup value={locale} onChange={handleLocale}>
            {Object.values(locales).map((l: string) => (
              <SelectGroup.Item
                key={l}
                value={l}
                name={LOCALES_TRANSLATIONS[l] || l}
              />
            ))}
          </SelectGroup>
        )}
      </div>
    </Modal>
  );
};

export default LanguageDialog;
