import clsx from "clsx";
import { memo } from "react";
import { Link } from "react-router-dom";
import TextSkeleton from "./TextSkeleton";

export const btnSizes = [
  "xs",
  "sm",
  "tiny",
  "base",
  "lg",
  "xl",
  "2xl",
  "3xl",
] as const;

export const btnVariants = ["contained", "outline", "text", "light"] as const;
export const btnColors = [
  "primary",
  "text",
  "secondary",
  "danger",
  "default",
] as const;

type BtnSize = (typeof btnSizes)[number];
type BtnVariant = (typeof btnVariants)[number];
type BtnColor = (typeof btnColors)[number];

export type BtnBaseProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  link?: boolean;
  color?: BtnColor;
  variant?: BtnVariant;
  disabled?: boolean;
  size?: BtnSize;
  children?: any;
  fullWidth?: boolean;
  className?: any;
  skeleton?: boolean;
  loading?: boolean;
  StartIcon?: any;
  EndIcon?: any;
};

type HtmlButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { ref?: any };

export const HtmlButton = memo((props: HtmlButtonProps) => (
  <button {...props} />
));

export const HtmlDiv = memo((props: React.HTMLProps<HTMLDivElement>) => (
  <div {...props} />
));

export const HtmlAnchor = memo(
  (
    props: React.AnchorHTMLAttributes<HTMLAnchorElement> & {
      href?: string;
    }
  ) => <a {...props} />
);

const btnClassName = ({
  size,
  fullWidth,
  variant,
  loading,
  skeleton,
  disabled,
  color,
  className,
}: Partial<BtnBaseProps>) =>
  clsx(
    "border-2",

    variant !== "outline" && "border-transparent",

    variant === "text" &&
      color === "primary" &&
      !disabled &&
      "text-primary hover:text-primary",

    variant === "contained" &&
      color === "primary" &&
      !disabled &&
      "bg-primary hover:bg-primary text-primary-foreground",

    variant === "contained" &&
      color === "danger" &&
      !disabled &&
      "bg-red-500 hover:bg-red-400 text-primary-foreground",

    variant === "text" &&
      color === "danger" &&
      !disabled &&
      "text-red-500 hover:text-red-400",

    variant === "light" &&
      color === "text" &&
      !disabled &&
      "bg-gray-200 hover:bg-gray-300 text-gray-900 dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:text-zinc-100",

    variant === "light" &&
      color === "primary" &&
      !disabled &&
      "bg-primary-200 hover:bg-primary-300 text-primary-900 dark:bg-primary-800 dark:hover:bg-primary-700 dark:text-primary-100",

    variant === "light" &&
      color === "danger" &&
      !disabled &&
      "bg-red-100 hover:bg-red-200 text-red-900 dark:bg-red-800 dark:hover:bg-red-700 dark:text-red-100",

    variant === "contained" &&
      color === "text" &&
      !disabled &&
      "bg-foreground hover:bg-foreground/90 text-card",

    variant === "contained" &&
      color === "text" &&
      !disabled &&
      "bg-foreground text-card",

    variant === "outline" && "bg-background",
    variant === "outline" && disabled && "border-opacity-70",

    variant === "outline" &&
      color === "text" &&
      "border-text text-text hover:bg-card hover:shadow",

    variant === "outline" &&
      color === "primary" &&
      "hover:border-primary border-primary dark:hover:border-primary dark:border-primary hover:text-primary-700 text-primary dark:hover:text-primary-300 dark:text-primary",

    // text size
    {
      "rounded text-xs font-medium": size === "xs",
      "rounded-md text-sm font-medium": size === "sm",
      "rounded-md text-tiny font-medium": size === "tiny",
      "rounded-md text-base font-medium": size === "base",
      "rounded-md text-lg font-medium": size === "lg",
      "rounded-md text-xl font-medium": size === "xl",
      "rounded-md text-2xl font-semibold": size === "2xl",
      "rounded-md text-3xl font-semibold": size === "3xl",
    },
    // paddings
    {
      "px-3 py-2": ["xs", "sm", "tiny"].includes(size),
      "px-5 py-2": size === "base",
      "px-7 py-4": ["lg", "xl", "2xl", "3xl"].includes(size),
    },
    {
      "w-full": fullWidth,

      // animations
      "animate-pulse duration-1000": loading || skeleton,

      // cursor
      "cursor-progress": loading,
      "cursor-not-allowed": disabled,

      // disabled or skeleton
      "bg-gray-300 hover:bg-gray-300 text-gray-500": !!disabled || !!skeleton,
    },

    "flex flex-row items-center place-content-center gap-1 focus:ring duration-200 text-center",
    className
  );

export const BtnLink = ({
  disabled = false,
  size = "base",
  color = "primary",
  variant = "contained",
  fullWidth = false,
  className = null,
  skeleton = false,
  loading = false,
  Component = Link,
  EndIcon = null,
  StartIcon = null,
  children,
  ...props
}: BtnBaseProps & React.ComponentPropsWithoutRef<typeof Component>) => {
  const prevent = Boolean(disabled || loading || skeleton);

  const cs = btnClassName({
    size,
    fullWidth,
    loading,
    skeleton,
    disabled,
    color,
    variant,
    className,
  });

  if (Component === Link && prevent) {
    props.to = "#";
  }

  return (
    <Component
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        prevent && e.preventDefault();
      }}
      className={cs}
    >
      {skeleton ? (
        <TextSkeleton />
      ) : (
        <>
          {StartIcon ? (
            <StartIcon
              className={clsx("flex-none stroke-current", {
                "w-3 h-3": ["tiny", "sm", "xs"].includes(size),
                "w-4 h-4": size === "base",
                "w-5 h-5": size === "lg",
                "w-6 h-6": size === "xl",
                "w-7 h-7": size === "2xl",
                "w-8 h-8": size === "3xl",
              })}
            />
          ) : null}
          {children}
          {EndIcon ? (
            <EndIcon
              className={clsx("flex-none stroke-current", {
                "w-3 h-3": ["tiny", "sm", "xs"].includes(size),
                "w-4 h-4": size === "base",
                "w-5 h-5": size === "lg",
                "w-6 h-6": size === "xl",
                "w-7 h-7": size === "2xl",
                "w-8 h-8": size === "3xl",
              })}
            />
          ) : null}
        </>
      )}
    </Component>
  );
};

const Btn = ({
  disabled = false,
  size = "base",
  color = "primary",
  variant = "contained",
  type = "button",
  fullWidth = false,
  className = null,
  skeleton = false,
  loading = false,
  StartIcon = null,
  EndIcon = null,
  children,
  onClick,
  ...props
}: BtnBaseProps & HtmlButtonProps) => {
  const prevent = Boolean(disabled || loading || skeleton);

  const cs = btnClassName({
    size,
    fullWidth,
    loading,
    skeleton,
    disabled,
    color,
    variant,
    className,
  });

  return (
    <HtmlButton
      {...props}
      type={type}
      disabled={prevent}
      onClick={onClick}
      className={cs}
    >
      {skeleton ? (
        <TextSkeleton />
      ) : (
        <>
          {StartIcon ? (
            <StartIcon
              className={clsx("flex-none stroke-current", {
                "w-3 h-3": ["tiny", "sm", "xs"].includes(size),
                "w-4 h-4": size === "base",
                "w-5 h-5": size === "lg",
                "w-6 h-6": size === "xl",
                "w-7 h-7": size === "2xl",
                "w-8 h-8": size === "3xl",
              })}
            />
          ) : null}
          {children}
          {EndIcon ? (
            <EndIcon
              className={clsx("flex-none stroke-current", {
                "w-3 h-3": ["tiny", "sm", "xs"].includes(size),
                "w-4 h-4": size === "base",
                "w-5 h-5": size === "lg",
                "w-6 h-6": size === "xl",
                "w-7 h-7": size === "2xl",
                "w-8 h-8": size === "3xl",
              })}
            />
          ) : null}
        </>
      )}
    </HtmlButton>
  );
};

export default Btn;
