import { SessionState, SessionTypes } from "@gethere/common/enums";
import {
  calcSessionStatus,
  calculateSessionCheck,
  checkSchedule,
  jsonClone,
} from "@gethere/common/utilities";
import { TPromo } from "@gethere/common/yup/Promo";
import { TSession } from "@gethere/common/yup/Session";
import { captureException } from "@sentry/react";
import { DateTime } from "luxon";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import CenteredErrorContainer from "../components/CenteredErrorContainer";
import PageContainer from "../containers/PageContainer";
import { useAppSelector } from "../state/hooks";
import {
  getEligblesUserAddresses,
  resetSession,
  sessionLoaded,
  sessionPlaceSelector,
  sessionSelector,
  sessionUpdated,
} from "../state/reducers/session";
import store, { RootState } from "../state/store";
import client from "../utils/client";
import errorify from "../utils/errorify";
import getSessionQueryParams from "../utils/getSessionQueryParams";
import * as qs from "../utils/qs";
import { useUser } from "./UserContext";
import { useAnalytics } from "./AnalyticsContext";
import { useAppEvent } from "../AppEvents";

const SessionError = ({ error }: { error: SessionLoadError }) => {
  const { user } = useUser();
  if (!user && error.statusCode === 401) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          search:
            "next=" +
            encodeURIComponent(
              window.location.pathname + window.location.search
            ),
        }}
      />
    );
  }

  return (
    <PageContainer>
      <CenteredErrorContainer title={error.message} />
    </PageContainer>
  );
};

const cleanCollectionItems = (data) => {
  const hideItemIds = Object.keys(data?.entities?.items ?? {}).filter((id) => {
    const item = data.entities.items[id];
    const isOperatorOnly = item.operator?.only;
    return isOperatorOnly || !item.active;
  });

  for (const colId in data.entities.collections) {
    const col = data.entities?.collections?.[colId] ?? { groups: [] };
    col.groups = col.groups.filter((group) => {
      group.items = group.items?.filter(
        (item) => !hideItemIds.includes(item.id)
      );
      return group.items?.length > 0;
    });

    if (col.groups.length === 0) {
      // filter from place collections if no groups;
      const session = data.entities?.sessions?.[data?.result];
      const placeId = session?.placeId;
      data.entities.places[placeId].collections = data.entities.places[
        placeId
      ]?.collections?.filter((c) => c !== col.id);
    }
  }

  return data;
};

type SessionLoadError = { message: string; statusCode?: number };
type DeliveryStatus = { eligble: boolean };

const SessionContext = createContext<{
  status: ReturnType<typeof calcSessionStatus>;
  delivery: DeliveryStatus;
  promotions: TPromo[];
  submitable: boolean;
  loading: boolean;
  error: SessionLoadError;
  id: string;
  type: SessionTypes;
  total?: number;
  missing: MissingDetails[];
  load: (params: {
    tId?: string;
    pId?: string;
    sId?: string;
    ch?: string;
    key?: string;
  }) => Promise<void>;
}>(null);

let statusCheckTimeout;

export type MissingDetails = "mobileNumber" | "address" | "capacity";

export const useSession = () => useContext(SessionContext);

export const SessionContextProvider = ({ children }) => {
  const { logEvent } = useAnalytics();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useUser();
  const missing: MissingDetails[] = [];

  const [{ status, loading, error, promotions }, setState] = useState<{
    status: ReturnType<typeof calcSessionStatus>;
    loading: boolean;
    error: SessionLoadError;
    promotions: TPromo[];
  }>({ status: null, loading: true, error: null, promotions: [] });

  const { rehydrated, userId, storeSessionId } = useSelector(
    (state: RootState) => ({
      rehydrated: (state as any)?._persist?.rehydrated,
      userId: state.user.result,
      storeSessionId: state.session.result, // session id on the store
    })
  );

  const search = useMemo(
    () => window.location.search,
    [history.location.search]
  );

  const load = useCallback(
    async ({ tId, pId, sId, ch }) => {
      try {
        const storeState = store.getState();
        const storedSessionCopy = sessionSelector(storeState);

        let oldSessionCopy: TSession | null = null;
        if (storedSessionCopy) {
          if (storedSessionCopy.state === SessionState.draft) {
            if (
              sId === storedSessionCopy.id ||
              tId === storedSessionCopy.terminalId
            ) {
              const status = calcSessionStatus({
                session: storedSessionCopy,
              });
              if (!status.ended && status.pendings.total > 0) {
                oldSessionCopy = { ...storedSessionCopy };
              }
            } else {
              // this order is probably not synced.
              // todo: what todo? sync it? same db? ask before switching?
            }
          }
        }

        // set loading
        setState((s) => ({ ...s, loading: true, error: null, promotions: [] }));

        const response = await client.post(`/sessions`, {
          pId,
          tId,
          sId,
          ch,
        });

        const res: TSession = jsonClone(
          response.data.entities?.sessions?.[response.data?.result]
        );

        logEvent("session_client_opened", {
          placeId: res?.placeId,
          terminalId: res?.terminalId,
        });

        const place = response.data.entities?.places?.[res?.placeId];

        if (!Array.isArray(res.consumerIds)) {
          res.consumerIds = [];
        }

        res.consumerIds = res.consumerIds.filter((x) => x); // avoid null bug inside. todo: check.

        if (userId && !res.consumerIds.includes(userId)) {
          res.consumerIds.push(userId);
        }

        if (res && oldSessionCopy && res.state === SessionState.draft) {
          // has session, has old, and old not ended;

          // return missing order items,
          if (oldSessionCopy?.orderItems?.length > 0) {
            for (const ooii in oldSessionCopy.orderItems) {
              const oldOrderItem = oldSessionCopy.orderItems[ooii];
              if (
                !res.orderItems.find((oi) => oi.id && oi.id === oldOrderItem.id)
              ) {
                res.orderItems.push({
                  ...oldOrderItem,
                  sessionId: res.id,
                });
              }
            }
          }

          // return missing tips,
          if (oldSessionCopy?.tips?.length > 0) {
            for (const oldTip of oldSessionCopy.tips) {
              if (
                !res.tips.find(
                  (tip) =>
                    (tip.id && tip.id === oldTip.id) ||
                    (tip.localId && tip.localId === oldTip.localId)
                )
              ) {
                res.tips.push({ ...oldTip, sessionId: res.id });
              }
            }
          }

          res.check = calculateSessionCheck(res);
        }

        const { entities, result } = cleanCollectionItems(response.data);

        entities.sessions[result] = res;

        let eligbleAddresses = [];

        let selectedPaymentMethodId =
          storeState.session.selectedPaymentMethodId;

        if (user) {
          if (
            res.state === SessionState.draft &&
            res.type === SessionTypes.delivery
          ) {
            eligbleAddresses = getEligblesUserAddresses({
              session: res,
              user,
              addresses: storeState.user.entities?.addresses,
              place,
            });

            if (!res.meta?.delivery?.refAddressId) {
              // no delivery ref defined yet;
              if (eligbleAddresses.length > 0) {
                // auto select eligble;
                res.meta.delivery.refAddressId = eligbleAddresses[0];
              }
            }
          }

          if (!selectedPaymentMethodId && user?.paymentMethods?.length > 0) {
            // not selected already, choose default or first;

            selectedPaymentMethodId =
              user.defaultPaymentMethod ||
              (user?.paymentMethods[0] as any as string);
          }
        }

        // if there is eligble addresses, and no seleceted, select by default;
        // if (!state.session.selectedAddressId && args.eligbleAddresses.length > 0) {
        //   args.selectedAddressId = args.eligbleAddresses[0];
        // }
        dispatch(
          sessionLoaded({
            entities,
            result,
            eligbleAddresses,
            selectedPaymentMethodId,
          })
        );

        let promos = Object.values(
          (entities.promotions || {}) as any
        ) as TPromo[];

        promos = promos.filter((promo: TPromo) => {
          if (promo.schedule) {
            const active = checkSchedule(promo.schedule).status;
            if (!active) return false;
          }

          return true;
        });

        setState((st) => {
          return {
            ...st,
            loading: false,
            error: null,
            promotions: promos,
          };
        });

        const currentSearch = qs.parse(search);

        if (result && result !== sId && !result.includes("@")) {
          history.replace(
            `${window.location.pathname}?${qs.stringify({ sId: result })}`
          );
        } else if (
          result.includes("@") &&
          currentSearch.tId !== res.terminalId &&
          !window.location.search.includes(res.terminalId)
        ) {
          history.replace(
            `${window.location.pathname}?${qs.stringify({
              tId: res.terminalId,
            })}`
          );
        }
      } catch (error) {
        captureException(error);
        dispatch(resetSession());
        setState((s) => ({
          ...s,
          loading: false,
          error: {
            message: errorify(error)?.message as string,
            statusCode: error?.response?.status as number,
          },
        }));
      }
    },
    [setState, search, userId]
  );  

  useEffect(() => {
    if (rehydrated) {
      const params = getSessionQueryParams(window.location.search);
      load(params);
    }
  }, [rehydrated]);

  useEffect(() => {
    if (
      storeSessionId?.length > 0 &&
      !storeSessionId.includes("@") &&
      !window.location.search.includes("sId")
    ) {
      history.replace(
        `${window.location.pathname}?${qs.stringify({ sId: storeSessionId })}`
      );
    }
  }, [storeSessionId]);

  const {
    sessionType,
    sessionState,
    placeUpdatedAt,
    sessionUpdatedAt,
    deliveryAddress,
    total,
    discounts,
  } = useSelector((s: RootState) => {
    const session = sessionSelector(s) as TSession;
    const place = sessionPlaceSelector(s);
    return {
      discounts: session?.discounts,
      sessionId: session?.id,
      sessionType: session?.type,
      sessionState: session?.state,
      placeUpdatedAt: place?.updatedAt,
      sessionUpdatedAt: session?.updatedAt,
      deliveryAddress: session?.meta?.delivery?.refAddressId,
      total: session?.check?.total,
    };
  });

  const calcStatus = useCallback(() => {
    const s = store.getState();
    const session = sessionSelector(s);
    const place = sessionPlaceSelector(s);

    if (session && place) {
      setState((st) => ({
        ...st,
        status: calcSessionStatus({
          allowed: place?.allowed,
          enabled: place?.enabled,
          sessionType: session?.type,
          sessionState: session?.state,
          deliverySchedule: place?.deliverySchedule,
          schedule: place?.schedule,
          isOperator: false,
          userId: s.user.result,
          session,
        }),
      }));
    }
  }, []);

  useEffect(() => {
    if (sessionType && placeUpdatedAt) {
      calcStatus();
    }
  }, [
    calcStatus,
    sessionType,
    placeUpdatedAt,
    sessionUpdatedAt,
    userId,
    user?.updatedAt,
  ]);

  useEffect(() => {
    if (
      status &&
      Boolean(status?.schedule?.status) &&
      DateTime.isDateTime(status?.schedule?.closeAt)
    ) {
      const ms =
        (status.schedule.closeAt as DateTime).diff(
          DateTime.fromJSDate(new Date())
        ).milliseconds + 10; // add 10 ms buffer

      if (ms > 0) {
        statusCheckTimeout = setTimeout(calcStatus, ms);
      }
    }
    return () => {
      clearTimeout(statusCheckTimeout);
    };
  }, [placeUpdatedAt]);  

  const [delivery, setDelivery] = useState<DeliveryStatus>(null);

  useEffect(() => {
    if (
      sessionType === SessionTypes.delivery &&
      sessionState === SessionState.draft
    ) {
      const s = store.getState();
      setDelivery((state) => ({
        ...state,
        eligble: deliveryAddress
          ? s.session.eligbleAddresses?.includes?.(deliveryAddress)
          : false,
      }));
    } else {
      setDelivery((s) => null);
    }
  }, [sessionType, deliveryAddress, sessionState]);

  const liables = useAppSelector(
    (s) => sessionSelector(s)?.meta?.liables || []
  );

  let submitable =
    !!status?.orderable &&
    status?.pendings?.subtotal > 0 &&
    status.hasPaymentSettings;

  if (submitable && delivery) {
    if (!delivery.eligble) submitable = false;
  }

  const isPickup = sessionType === SessionTypes.pickup;
  const isDelivery = sessionType == SessionTypes.delivery;

  if (submitable && (isPickup || isDelivery)) {
    // make sure we have mobile number;
    if (!user?.mobileNumber) {
      if (!Array.isArray(liables) || !liables.some((l) => l.mobile)) {
        submitable = false;
        missing.push("mobileNumber");
      }
    }
  }

  useAppEvent("session_updated", (update) => {
    if (update.result !== storeSessionId) return;
    dispatch(
      sessionUpdated({ entities: update.entities, result: update.result })
    );
  });

  const promos = useMemo(() => {
    return promotions.filter((promo: TPromo) => {
      if (
        promo.trigger === "manual" &&
        !discounts?.find?.((d) => d.promoId === promo.id)
      ) {
        return false;
      }
      return true;
    });
  }, [discounts, promotions]);

  return (
    <SessionContext.Provider
      value={{
        status,
        type: sessionType,
        delivery,
        submitable,
        loading,
        error,
        load,
        missing,
        id: storeSessionId,
        promotions: promos,
        total,
      }}
    >
      {error ? <SessionError error={error} /> : children}
    </SessionContext.Provider>
  );
};

export default SessionContext;
