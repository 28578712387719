import { IntlShape, createIntl, createIntlCache } from "react-intl";
import { Settings as LuxonSettings } from "luxon";
import "intl";
import "intl/locale-data/jsonp/en";
import "intl/locale-data/jsonp/es";
import "intl/locale-data/jsonp/fr";
import "intl/locale-data/jsonp/he";
import "intl/locale-data/jsonp/de";
import { localeSelector } from "../state/reducers/system";
import store from "../state/store";
import { localeLang } from "./langUtil";
import * as messages from "@gethere/common/i18n/messages";
import "@formatjs/intl-locale/polyfill";
import { DEFAULT_TIMEZONE } from "@gethere/common/settings";
import { enGB, enUS, es, fr, de } from "date-fns/locale";
import { setDefaultOptions } from "date-fns";
import { locales } from "@gethere/common/enums";

// if (!Intl.)
require("@formatjs/intl-getcanonicallocales/polyfill");

if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  // require("@formatjs/intl-pluralrules/locale-data/he");
  require("@formatjs/intl-pluralrules/locale-data/en");
  require("@formatjs/intl-pluralrules/locale-data/es");
  require("@formatjs/intl-pluralrules/locale-data/de");
  require("@formatjs/intl-pluralrules/locale-data/fr");
}

if (!("RelativeTimeFormat" in Intl)) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  // require("@formatjs/intl-relativetimeformat/locale-data/he");
  require("@formatjs/intl-relativetimeformat/locale-data/en");
  require("@formatjs/intl-relativetimeformat/locale-data/en-GB");
  require("@formatjs/intl-relativetimeformat/locale-data/en");
  require("@formatjs/intl-relativetimeformat/locale-data/en");
  require("@formatjs/intl-relativetimeformat/locale-data/es");
  require("@formatjs/intl-relativetimeformat/locale-data/de");
  require("@formatjs/intl-relativetimeformat/locale-data/fr");
}

export type MessageMap = Record<string, string>;

const cache = createIntlCache();

const intls: Record<string, IntlShape> = {};

function fnsLocalSelector(locale: string) {
  switch (locale) {
    case locales.es_ES:
      return es;
    case locales.fr_FR:
      return fr;
    case locales.de_DE:
      return de;
    case locales.en_US:
      return enUS;
    default:
      return enGB;
  }
}

export const getIntl = (locale = null, timeZone = DEFAULT_TIMEZONE) => {
  try {
    if (!locale) {
      locale = localeSelector(store.getState());
    }

    if (intls[locale]) {
      return intls[locale];
    } else {
      const lang = localeLang(locale);

      intls[locale] = createIntl(
        {
          locale: locale.replace("_", "-"),
          messages: messages[lang] || messages.en, // default en
          timeZone: timeZone,
        },
        cache
      );

      LuxonSettings.defaultLocale = lang;
      LuxonSettings.defaultZone = timeZone;

      intls[locale].timeZone = timeZone;

      setDefaultOptions({
        locale: fnsLocalSelector(locale),
      });

      return intls[locale];
    }
  } catch (error) {
    return null;
  }
};
