// import { point, booleanPointInPolygon, multiPolygon } from "@turf/turf";
import { TGeoPoint, TGeoPolygon } from "../yup/Geo";

export const latLngToArray = ({ lat, lng }) => [lat, lng];
export const arrayToLatLng = ([lat, lng]) => ({ lat, lng });

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: Array<string>;
}

export interface Address {
  street_number?: string;
  street_name?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
}
const pointInPolygonCheck = (point: number[], vertices: number[][]) => {
  const x = point[0];
  const y = point[1];

  let inside = false;
  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    const xi = vertices[i][0],
      yi = vertices[i][1];
    const xj = vertices[j][0],
      yj = vertices[j][1];

    const intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

export const pointInPolygon = (pnt: TGeoPoint, ply: TGeoPolygon) => {
  if (!ply.coordinates || !pnt.coordinates) return false;
  return pointInPolygonCheck(pnt.coordinates, ply.coordinates);
};

export const parseAddressComponents = (components: Array<AddressComponent>) => {
  const address: Address = {};

  if (!Array.isArray(components)) {
    throw Error("Address Components is not an array");
  }

  if (!components.length) {
    throw Error("Address Components is empty");
  }

  for (let i = 0; i < components.length; i++) {
    const component: AddressComponent = components[i];

    if (component.types.includes("street_number")) {
      address.street_number = component.long_name;
    }

    if (component.types.includes("route")) {
      address.street_name = component.long_name;
    }

    if (
      component.types.includes("locality") ||
      component.types.includes("postal_town")
    ) {
      address.city = component.long_name;
    }

    if (component.types.includes("country")) {
      address.country = component.short_name;
    }

    if (component.types.includes("administrative_area_level_1")) {
      address.state = component.long_name;
    }

    if (component.types.includes("postal_code")) {
      address.postal_code = component.long_name;
    }
  }

  return address;
};

export const addressNameFormatter = (address: any) => {
  let name = address?.address;

  if (address?.meta?.street_name) {
    if (address.meta?.street_number) {
      name = [
        [address.meta?.street_name, address.meta?.street_number].join(" "),
        address.meta.city,
      ].join(", ");
    } else {
      name = [address?.meta?.street_name, address?.meta?.city].join(", ");
    }
  }

  return name;
};
