import { paymentMethodTypes } from "../enums";
import * as yup from "yup";

const cardPayloadSchema = yup
  .object({
    token: yup.string(),
    store: yup.string(),
    ends: yup.string(),
    exp: yup.string(), // "2021-11-30T00:00:00.000Z"
    provider: yup.string(), // "visa"
    details: yup.object({
      cardName: yup.string().optional(), //"MAX"
      rank: yup.string().optional(), //"0"
      club: yup.string().optional(), //" 5"
      info: yup.string().optional(), //"        "
      cardUserInfo: yup.string().optional(), //""
      authVoucher: yup.string().optional(), //"15471775"
      authId: yup.string().optional(), //"0048223"
    }),
    verifiedAt: yup.string(), // "2020-09-15T08:15:58.969Z"
  })
  .defined();

const baseSchema = yup
  .object({
    id: yup.string().nullable(),
    type: yup.string().oneOf(Object.keys(paymentMethodTypes)),
    payload: cardPayloadSchema.required(),
    hide: yup.bool().default(false),
    createdAt: yup.date().nullable(),
    updatedAt: yup.date().nullable(),
  })
  .required();

export const userPaymentMethodSchema = baseSchema.concat(
  yup
    .object({
      userId: yup.string(),
    })
    .required()
);

export const businessPaymentMethodSchema = baseSchema.concat(
  yup
    .object({
      businessId: yup.string(),
    })
    .required()
);

export type TUserPaymentMethod = yup.InferType<typeof userPaymentMethodSchema>;

export type TBusinessPaymentMethod = yup.InferType<
  typeof businessPaymentMethodSchema
>;
