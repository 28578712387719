import clsx from "clsx";

export default function Stepper({ children }) {
  return <div className="">{children}</div>;
}

Stepper.Step = function StepperStep({
  Icon,
  title,
  desc = null,
  active,
  children,
  ...props
}) {
  return (
    <div className="flex" {...props}>
      <div className="flex flex-col items-center mr-4">
        <div className="flex items-center justify-center w-10 h-10 rounded-full flex-none">
          <span className="flex items-center justify-center w-8 h-8 bg-primary-100 rounded-full ring-8 ring-card dark:ring-gray-900 dark:bg-primary-900">
            <Icon
              aria-hidden="true"
              className="flex-none w-5 h-5 text-primary dark:text-primary"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            />
          </span>
        </div>
        <div className="w-px h-full bg-muted-foreground bg-opacity-20"></div>
      </div>
      <div className="pb-8 ">
        <h3
          className={clsx(
            "mb-2 text-lg text-text",
            !active ? "font-medium" : "font-bold"
          )}
        >
          {title}
        </h3>

        <span className="block mb-2 text-sm font-normal leading-none text-muted-foreground">
          {desc}
        </span>

        {!!active && <div className="pr-5">{children}</div>}
      </div>
    </div>
  );
};
