import { UserTypes } from "@gethere/common/enums";
import { AppEvent } from "@gethere/common/settings";
import { captureException, withScope } from "@sentry/react";
import {
  AnalyticsCallOptions,
  getAnalytics,
  logEvent,
  setAnalyticsCollectionEnabled,
  setUserId,
} from "firebase/analytics";
import {
  FirebaseApp,
  getApp,
  initializeApp,
  registerVersion,
  setLogLevel,
} from "firebase/app";
import { createContext, useCallback, useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { useAppSelector } from "../state/hooks";
import { useConsentStore } from "../stores/consentDialogStore";
import env, { appName, appVersion } from "../utils/env";
import * as qs from "../utils/qs";
import { useUser } from "./UserContext";
import { clarity } from "clarity-js";

type LogEventFn = (
  eventName: AppEvent,
  eventParams?: { [key: string]: any },
  options?: AnalyticsCallOptions
) => void;

const AnalyticsContext = createContext<{
  logEvent: LogEventFn;
}>({
  logEvent: () => {},
});

const firebase = () => {
  let app: FirebaseApp;
  try {
    app = getApp();
  } catch (error) {
    app = initializeApp({
      apiKey: "AIzaSyDugS-0poSTOk_AQRkDCf-5ud05tDnRLHA",
      authDomain: "gethere-215109.firebaseapp.com",
      databaseURL: "https://gethere-215109.firebaseio.com",
      projectId: "gethere-215109",
      storageBucket: "gethere-215109.appspot.com",
      messagingSenderId: "490715823948",
      appId: "1:490715823948:web:3b5ae0c3b5ba5a8e2f99e9",
      measurementId: "G-CX5N66424P",
    });

    registerVersion("gethere-web", appVersion, env.e);

    if (env.development) {
      setLogLevel("debug");
      const analytics = getAnalytics(app);
      setAnalyticsCollectionEnabled(analytics, false);
    }
  } finally {
    return app;
  }
};

const analytics = () => {
  const app = firebase();
  return getAnalytics(app);
};

export const useAnalytics = () => useContext(AnalyticsContext);

const FacebookPixel = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "fb-pixel";
    script.type = "text/javascript";
    script.innerText = `try{!function(e,t,n,c,o,a){e.fbq||(c=e.fbq=function(){c.callMethod?c.callMethod.apply(c,arguments):c.queue.push(arguments)},e._fbq||(e._fbq=c),(c.push=c).loaded=!0,c.version="2.0",c.queue=[],(o=t.createElement(n)).async=!0,o.src="https://connect.facebook.net/en_US/fbevents.js",(a=t.getElementsByTagName(n)[0]).parentNode.insertBefore(o,a))}(window,document,"script"),fbq("init","1101179074144129"),fbq("track","PageView");}catch(e){console.error(e)}`;
    document.body.appendChild(script);
    return () => {
      try {
        delete window["fbq"];
        document.removeChild(script);
      } catch (error) {}
    };
  }, []);
  return null;
};

const RedditPixel = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "reddit-pixel";
    script.type = "text/javascript";
    script.innerText = `try{!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_shao45mo', {"optOut":false,"useDecimalCurrencyValues":true});rdt('track', 'PageVisit');}catch(e){console.error(e)}`;
    document.body.appendChild(script);
    return () => {
      try {
        delete window["rdt"];
        document.removeChild(script);
      } catch (error) {}
    };
  }, []);
  return null;
};

const trackReddit = (eventName: string) => {
  try {
    window["rdt"]?.("track", eventName);
  } catch (error) {
    captureException(error);
  }
};

const trackFacebook = (eventName: string) => {
  try {
    window["fbq"]?.("track", eventName);
  } catch (error) {
    captureException(error);
  }
};

export const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const consent = useConsentStore();
  const location = useLocation();
  const { user } = useUser();
  const rehydrated = useAppSelector((s) => s._persist.rehydrated);
  const path = location.pathname + location.search;

  const userId = user?.id;
  const isAdmin = user?.type === UserTypes.ADMIN;
  const isProd = env.production;
  const allowAnalytics = rehydrated && isProd && !!consent?.pref && !isAdmin;
  const allowAds = rehydrated && isProd && !!consent?.ads && !isAdmin;

  const logEventHandler: LogEventFn = useCallback(
    (eventName, eventParams, options): void => {
      try {
        if (allowAnalytics) {
          logEvent(analytics(), eventName as string, eventParams, options);

          const firstParam =
            eventParams?.[Object.keys(eventParams || {})?.[0]] || null;

          clarity.event(eventName as string, firstParam);
        }

        if (!allowAds) return;

        // update ads plaftorms
        switch (eventName) {
          case "join_wizard_completed": {
            trackReddit("SignUp");
            trackFacebook("SubmitApplication");
            break;
          }

          case "site_contact_success": {
            trackReddit("Lead");
            trackFacebook("Contact");
            break;
          }

          default:
            break;
        }
      } catch (error) {
        captureException(error);
        // we cannot throw here, we need to be graceful to not break the app because of logging
      }
    },
    [allowAnalytics, allowAds, logEvent, analytics]
  );

  // Allow analytics
  useEffect(() => {
    try {
      if (allowAnalytics) {
        clarity.start({
          projectId: "h2rolbb14y",
          upload: "https://m.clarity.ms/collect",
          track: true,
          content: true,
        });

        clarity.set("appVersion", appVersion);

        clarity.metadata((metadata) => {
          if (!metadata.sessionId) return;
          withScope((scope) => {
            scope.setExtra("claritySessionId", metadata.sessionId);
            scope.setExtra("clarityUserId", metadata.userId);
          });
        });

        const query = qs.parse(window.location.search);

        [
          "utm_source",
          "utm_campaign",
          "utm_medium",
          "utm_content",
          "utm_term",
          "gclid",
        ].forEach((key) => {
          if (query[key]) {
            clarity.set(key, String(query[key]));
          }
        });

        if (document.referrer) {
          clarity.set("referrer", document.referrer);
        }
      }
    } catch (error) {
      captureException(error);
    }

    return () => {
      try {
        clarity.stop();
      } catch (error) {
        captureException(error);
      }
    };
  }, [allowAnalytics]);

  // UserId Changed
  useEffect(() => {
    if (allowAnalytics) {
      setUserId(analytics(), userId);
    }
  }, [userId, allowAnalytics]);

  // path changed
  useEffect(() => {
    if (allowAnalytics) {
      logEventHandler("screen_view", {
        app_name: appName,
        app_version: appVersion,
        screen_name: path,
      });
    }
  }, [path, allowAnalytics]);

  return (
    <AnalyticsContext.Provider value={{ logEvent: logEventHandler }}>
      {children}
      {allowAds && <FacebookPixel />}
      {allowAds && <RedditPixel />}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContext;
