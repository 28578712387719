import * as yup from "yup";
import {
  PlaceStatus,
  PlaceType,
  PrinterConnection,
  PrinterTypes,
  SessionState,
  SessionTypes,
} from "../enums";
import {
  DEFAULT_ESC_WIDTH,
  DEFAULT_TIMEZONE,
  OUTGOING_SESSION_TYPES,
  SORTED_SESSION_STATES,
  userFields,
} from "../settings";
import { collectionSchema } from "./Collection";
import { geoPointScheme, geoPolygonScheme } from "./Geo";
import { imageSchema } from "./Image";
import { yupMobileNumberField } from "./MobileInputForm";
import { scheduleScheme } from "./Schedule";
import { bookingSettingsSchema } from "./PlaceBookingSettings";
import { sessionTypesArraySchema } from "./SessionTypes";

export const DELIVERY_PAYMENT_STATES = [
  SessionState.approved,
  SessionState.accepted,
  SessionState.active,
  SessionState.ready,
  SessionState.complete,
  SessionState.delivering,
];

export const PICKUP_PAYMENT_STATES = [
  SessionState.approved,
  SessionState.accepted,
  SessionState.active,
  SessionState.ready,
  SessionState.complete,
];

export const OUTGOING_INITIAL_STATES = [
  SessionState.approved,
  SessionState.accepted,
  SessionState.active,
];

export const SEAT_PAYMENT_STATES = [SessionState.complete, SessionState.active];

const digitsOnly = (value) => (value ? /^\d+$/.test(value) : true);

const gaTrackerId = (value) =>
  value ? /(UA|YT|MO|G)-\d+(-\d+)?/i.test(value) : true;

const autoSignOutSecSchema = yup
  .number()
  .notRequired()
  .default(null) // default of parent / canceled
  .min(0) // canceled
  .max(3600);

export const deviceSchema = yup.object({
  id: yup.string(),
  active: yup.boolean().default(true),
  name: yup.string().default("").min(2),
  meta: yup.object({
    defaultPrinter: yup.string().notRequired(),
    defaultFloor: yup.string().notRequired(),
    loginSmsNumber: yupMobileNumberField.nullable().default(null),
    disableTimeTrack: yup.boolean().notRequired().default(false),
    autoSignOutSec: autoSignOutSecSchema,
    defaultControl: yup.string().notRequired(),
  }),
  createdAt: yup.date().optional(),
  updatedAt: yup.date().nullable(),
});

export const stationMessageSchema = yup.object({
  value: yup.string().default("").min(2),
});

export type TStationMessage = yup.InferType<typeof stationMessageSchema>;

const stationSettingsSchema = yup.object({
  copies: yup.number().min(2).max(4).notRequired(),
});

export type TStationSettings = yup.InferType<typeof stationSettingsSchema>;

export const stationSchema = yup.object({
  id: yup.string(),
  name: yup.string().default("").min(2),
  printerId: yup
    .string()
    .nullable()
    .transform((v) => (v ? v : null)),
  categoriesIds: yup.array().of(yup.string()),
  messages: yup.array().of(stationMessageSchema).default(null).notRequired(),
  settings: stationSettingsSchema.notRequired(),
  createdAt: yup.date().nullable().default(null),
  updatedAt: yup.date().nullable().default(null),
});

export const printerSchema = yup.object({
  id: yup.string(),
  name: yup.string().default("").min(2),
  type: yup
    .string()
    .default(PrinterTypes.escpos)
    .oneOf(Object.values(PrinterTypes)),
  connection: yup
    .string()
    .default(PrinterConnection.network)
    .oneOf(Object.values(PrinterConnection)),
  adapter: yup.object({
    model: yup.string().notRequired(),
    host: yup.string().nullable().default("").trim(), // serial / ip / bt address
    beep: yup.boolean().nullable().default(false),
    drawer: yup.boolean().nullable().default(false),
    openDrawerOnAcceptIds: yup
      .array()
      .of(yup.string())
      .min(0)
      .max(5)
      .nullable()
      .default([]),
    autoPrintOnDocument: yup.boolean().nullable().default(false),
    escWidth: yup.number().default(DEFAULT_ESC_WIDTH).notRequired(),
    connectedTo: yup.string().required().default(""),
  }),
  createdAt: yup.date().nullable(),
  updatedAt: yup.date().nullable(),
});

export const deliveryAreaSchema = yup.object({
  type: yup.string().default("radius").oneOf(Object.values(PrinterTypes)),
});

export const placeThemeSchema = yup
  .object({
    colors: yup
      .object({
        primary: yup.string().nullable(),
        secondary: yup.string().nullable(),
        card: yup.string().nullable(),
        background: yup.string().nullable(),
        text: yup.string().nullable(),
        palette: yup
          .mixed<"light" | "dark">()
          .oneOf(["dark", "light"])
          .nullable(),
      })
      .nullable(),
    fontTitle: yup
      .string()
      .nullable()
      .transform((v) => (v ? v : null)),
    fontBody: yup
      .string()
      .nullable()
      .transform((v) => (v ? v : null)),
    coverImg: imageSchema.notRequired().default(null),
    lightLogo: imageSchema.notRequired().default(null),
    qr: yup.object({
      text: yup.string().notRequired(),
      background: yup.string().notRequired(),
      code: yup.string().notRequired(),
      font: yup.string().notRequired(),
      logo: imageSchema.notRequired().default(null),
      frame: yup.string().notRequired(),
      corners: yup.string().oneOf(["r", "s"]).notRequired(),
      rounded: yup.boolean().notRequired(),
      promptWeight: yup.number().min(100).max(900).notRequired(),
      nameWeight: yup.number().min(100).max(900).notRequired(),
      promptSize: yup.number().min(0).max(500).notRequired(),
      lineHeight: yup.number().min(0).max(500).notRequired(),
      nameSize: yup.number().min(0).max(500).notRequired(),
      width: yup.number().min(1).max(1000).notRequired().default(55),
      height: yup.number().min(1).max(1000).notRequired().default(85),
      unit: yup.string().oneOf(["mm", "in"]).notRequired().default("mm"),
      prompt: yup.lazy((v) => {
        if (typeof v === "undefined") {
          return yup.mixed().default(undefined).optional();
        }

        if (typeof v === "boolean") {
          return yup.boolean().default(true).optional();
        }

        return yup.string().notRequired().max(60);
      }),
    }),
  })
  .nullable();

export const placeMetaSchema = yup.object({
  websiteUrl: yup.string().url().nullable(),
  bookingUrl: yup.string().url().nullable(),
  instagram: yup.string().nullable(),
  fbUrl: yup.string().url().nullable(),
  nhsTrack: yup.string().url().nullable(),
  fbPixelId: yup
    .string()
    .nullable()
    .test("Digits only", "The field should have digits only", digitsOnly)
    .max(50),
  gaTrackerId: yup
    .string()
    .nullable()
    .test("Tracker Code", "Invalid Google Analytics tracker code", gaTrackerId),
  address: yup.string().nullable().max(100),
  phone: yup.string().nullable(),
});

const initialStateValue = yup
  .mixed<SessionState>()
  .oneOf(OUTGOING_INITIAL_STATES)
  .default(SessionState.approved)
  .nullable();

const paymentStateValue = yup
  .mixed<SessionState>()
  .default(SessionState.complete)
  .defined()
  .nullable();

export const placeInitialStatesSchema = yup
  .object({
    [SessionTypes.delivery]: initialStateValue,
    [SessionTypes.pickup]: initialStateValue,
  })
  .nullable()
  .optional();

export const placePaymentStateSettings = yup
  .object({
    [SessionTypes.delivery]: paymentStateValue.oneOf(DELIVERY_PAYMENT_STATES),
    [SessionTypes.pickup]: paymentStateValue.oneOf(PICKUP_PAYMENT_STATES),
    [SessionTypes.seat]: paymentStateValue.oneOf(SEAT_PAYMENT_STATES),
  })
  .nullable()
  .optional();

export const tipSettingsTypes = ["personal", "shared"] as const;
export type TipSettingsType = (typeof tipSettingsTypes)[number];
export const defaultTipSettingsType: TipSettingsType = "shared";

export const seatingServingStyles = ["asap", "together"] as const;
export type SeatingServingStyle = (typeof seatingServingStyles)[number];
export const defaultSeatingServingStyle: SeatingServingStyle = "asap";

export const defaultTipOptions = [0.1, 0.15, 0.2];

export const tipsSettingsSchema = yup
  .object({
    askCard: yup.string().oneOf(["pre", "post", "manual"]).notRequired(),

    options: yup
      .array()
      .of(yup.number().min(0.01).max(1))
      .min(1)
      .max(3)
      .notRequired(),

    type: yup
      .mixed<TipSettingsType>()
      .oneOf(tipSettingsTypes as any)
      .default(defaultTipSettingsType),
  })
  .nullable()
  .default(null);

export const serviceChargeSettingsSchema = yup
  .object({
    percent: yup.number().default(0).min(0).max(1),
    types: sessionTypesArraySchema,
  })
  .nullable()
  .default(null);

// export const bookingSettingsSchema = yup.object({
//   enabled: yup.boolean(),

//   minutesSlot: yup.number().min(10).max(3600),
//   minutesInterval: yup.number().min(10).max(240),

//   authorizeAmount: yup.number().min(0).max(100).nullable(),

//   maxDaysAdvance: yup.number().min(0).max(365),

//   maxGuests: yup.number().min(1).max(200),
//   minGuests: yup.number().min(1).max(200),

//   schedule: yup
//     .array()
//     .of(scheduleScheme)
//     .max(25)
//     .default(null)
//     .nullable()
//     .optional(),
// });

export const placeMessageTypes = [
  "success",
  "danger",
  "info",
  "warning",
] as const;

export type PlaceMessageType = (typeof placeMessageTypes)[number];

export const placeMessageSchema = yup.object({
  txt: yup.string().min(3).required(),
  typ: yup.string().oneOf(placeMessageTypes).optional(),
});

export const timeLimitSchema = yup.object({
  minutes: yup.number().min(5).max(3600).notRequired(),
  tresholdStaff: yup.number().min(0).max(1).notRequired(),
  bar: yup.boolean().notRequired(),
});

export const placeReviewSchema = yup.object({
  gRateRef: yup.number().min(0).max(5).notRequired(), // min rate for google reviews ref
});

export const placeSettingsSchema = yup
  .object({
    gPlaceId: yup.string().notRequired(),

    review: placeReviewSchema.notRequired(),

    messages: yup.array().of(placeMessageSchema).optional().default([]),

    daily: yup
      .object({
        automateOpen: yup.boolean().nullable(),
        automateClose: yup.boolean().nullable(),
        forceTimeTracksEnded: yup.boolean().nullable(),
      })
      .nullable(),

    devices: yup
      .object({
        defaultFloor: yup.string().notRequired(),
        autoSignOutSec: autoSignOutSecSchema,
      })
      .nullable(),

    intialStates: placeInitialStatesSchema,

    paymentState: placePaymentStateSettings,

    pickup: yup
      .object({
        fields: yup.array().of(yup.string().oneOf(userFields)).notRequired(),
      })
      .notRequired(),

    seat: yup
      .object({
        rounds: yup.boolean().notRequired(),

        defaultStyle: yup
          .mixed<SeatingServingStyle>()
          .oneOf(seatingServingStyles as any)
          .default(defaultSeatingServingStyle)
          .test(
            "default_serving_part_of_given_styles",
            "The default serving style most be one of given serving styles",
            function (value: any) {
              if (value && Array.isArray(this.parent.servingStyle)) {
                return this.parent.servingStyle.includes(value);
              }
              return true;
            }
          )
          .optional()
          .nullable(),

        servingStyles: yup
          .array()
          .of(
            yup.mixed<SeatingServingStyle>().oneOf(seatingServingStyles as any)
          )
          .default([defaultSeatingServingStyle])
          .min(1)
          .max(seatingServingStyles.length)
          .notRequired(),

        limit: timeLimitSchema.notRequired(),
        highlightBookings: yup.boolean().notRequired().default(true),
        autoComplete: yup.bool().notRequired(),
      })
      .notRequired(),

    card: yup.object({
      provider: yup.string(),
      payload: yup.object({
        publicKey: yup.string(),
        accountId: yup.string(),
        platformId: yup.string().notRequired().default(null),
        locationId: yup.string().notRequired().default(null),
        livemode: yup.boolean(),
      }),
    }),

    tips: tipsSettingsSchema,

    serviceCharge: serviceChargeSettingsSchema,

    // roundsEnabled: yup.boolean().notRequired(),

    // defaultSeatingServingStyles: yup
    //   .mixed<SeatingServingStyle>()
    //   .oneOf(seatingServingStyles as any)
    //   .default(defaultSeatingServingStyle)
    //   .test(
    //     "default_serving_part_of_given_styles",
    //     "The default serving style most be one of given serving styles",
    //     function (value: any) {
    //       if (value && Array.isArray(this.parent.seatingServingStyles)) {
    //         return this.parent.seatingServingStyles.includes(value);
    //       }
    //       return true;
    //     }
    //   )
    //   .optional()
    //   .nullable(),

    // seatingServingStyles: yup
    //   .array()
    //   .of(yup.mixed<SeatingServingStyle>().oneOf(seatingServingStyles as any))
    //   .default([defaultSeatingServingStyle])
    //   .min(1)
    //   .max(seatingServingStyles.length)
    //   .nullable()
    //   .optional(),

    checkerOutPrinter: yup.string().notRequired(),
    cancelTicketPrinter: yup.string().notRequired(),

    esc: yup.object({
      logo: imageSchema.notRequired().default(null),
      promoteTip: yup.string().nullable().min(0).max(100),
      footerText: yup.string().min(0).max(160),
    }),

    // booking: bookingSettingsSchema.default(bookingSettingsSchema.cast({})),

    timeZone: yup.string().nullable().default(DEFAULT_TIMEZONE),
  })
  .test(
    "Payment Comes After Initial State",
    "Payment State cannot comes after Initial State",
    (settings) => {
      if (settings) {
        for (let type of OUTGOING_SESSION_TYPES) {
          if (settings?.paymentState?.[type]) {
            // defiend payment state for type;
            if (settings?.intialStates?.[type]) {
              // defined initial state for type;
              // both defined, lets make sure the get along.

              const pi = SORTED_SESSION_STATES.indexOf(
                settings.intialStates[type]
              );

              const is = SORTED_SESSION_STATES.indexOf(
                settings.intialStates[type]
              );

              if (is > pi) {
                return false;
              }
            }
          }
        }
      }

      return true;
    }
  );

export const deliverySettingsSchema = yup
  .object({
    estTime: yup.number().default(0),
    minOrderValue: yup.number().default(0),
    freeMinValue: yup.number().nullable().min(0).max(1000),
    baseFee: yup.number().default(0).min(0).max(1000),
    taxIds: yup.array().of(yup.string()).optional(),
  })
  .nullable();

export const placeServiceSchema = yup.object({
  defaultPickupTerminal: yup.string().nullable().default(null),
  defaultDeliveryTerminal: yup.string().nullable().default(null),

  minutesBlockedBeforeClose: yup
    .number()
    .min(0)
    .max(120)
    .nullable()
    .default(null),
});

export const placeAddressSchema = yup.object({
  line1: yup.string().notRequired().default(undefined),
  line2: yup.string().notRequired().default(undefined),
  city: yup.string().notRequired().default(undefined),
  state: yup.string().notRequired().default(undefined),
  postal_code: yup.string().notRequired().default(undefined),
  country: yup.string().notRequired().default(undefined),
});

export const placeSchema = yup
  .object({
    id: yup.string(),
    name: yup.string().default(""),
    type: yup
      .string()
      .oneOf(Object.values(PlaceType))
      .default(PlaceType.restaurant),
    active: yup.boolean().default(true),
    indexable: yup.boolean().default(true),
    status: yup
      .string()
      .nullable()
      .oneOf(Object.values(PlaceStatus))
      .default(PlaceStatus.PENDING),
    allowed: yup.object({
      // allowed by place - for clients orders
      [SessionTypes.delivery]: yup.boolean().default(false),
      [SessionTypes.pickup]: yup.boolean().default(false),
      [SessionTypes.seat]: yup.boolean().default(false),
    }),
    enabled: yup.object({
      // enabled by gethere - for business orders
      [SessionTypes.delivery]: yup.boolean().default(false),
      [SessionTypes.pickup]: yup.boolean().default(false),
      [SessionTypes.seat]: yup.boolean().default(false),
    }),
    categories: yup.array().of(yup.string()).default([]).nullable(),
    desc: yup.string().default("").nullable(),
    logo: imageSchema.notRequired().default(null),
    coverImg: imageSchema.notRequired().default(null),
    businessId: yup.string().nullable(),
    accountId: yup.string().notRequired(),
    mobile: yupMobileNumberField.nullable(),
    schedule: yup.array().of(scheduleScheme).max(25),
    meta: placeMetaSchema,
    theme: placeThemeSchema,
    settings: placeSettingsSchema,
    devices: yup.array().of(deviceSchema).default([]).nullable(),
    cols: yup.array().of(collectionSchema).default([]).nullable(),
    printers: yup.array().of(printerSchema).default([]).nullable(),
    stations: yup.array().of(stationSchema).default([]).nullable(),
    service: placeServiceSchema,
    deliveryArea: geoPolygonScheme.nullable().default(null),
    deliverySettings: deliverySettingsSchema,
    deliverySchedule: yup.array().of(scheduleScheme).max(25),
    location: geoPointScheme.nullable(),
    address: placeAddressSchema.optional(),
    bookings: bookingSettingsSchema.optional(),
    createdAt: yup.date().nullable(),
    updatedAt: yup.date().nullable(),
  })
  .required();

export const placeDailySearchReqSchema = yup.object({
  lastId: yup.number().nullable(),
});

export type TPlaceDailySearchReq = yup.InferType<
  typeof placeDailySearchReqSchema
>;

export type TPlace = yup.InferType<typeof placeSchema>;
export type TPlaceBooking = yup.InferType<typeof bookingSettingsSchema>;
export type TPlaceBookingSettings = TPlaceBooking["settings"];
export type TPlaceService = yup.InferType<typeof placeServiceSchema>;
export type TPlaceAddress = yup.InferType<typeof placeAddressSchema>;
export type TPlaceDevice = yup.InferType<typeof deviceSchema>;
export type TPlaceStation = yup.InferType<typeof stationSchema>;
export type TPrinter = yup.InferType<typeof printerSchema>;
export type TPlaceSettings = yup.InferType<typeof placeSettingsSchema>;
export type TPlaceMeta = yup.InferType<typeof placeMetaSchema>;
export type TPlaceTheme = yup.InferType<typeof placeThemeSchema>;
export type TPlaceDeliverySettings = yup.InferType<
  typeof deliverySettingsSchema
>;

export type TDeviceSwitchListResponse = {
  places: {
    name: string;
    id: string;
    devices: { name: string; id: string }[];
  }[];
};
