import {
  BookmarkSquareIcon,
  CalendarIcon,
  CheckBadgeIcon,
  CreditCardIcon,
  CubeIcon,
  MapPinIcon,
  QrCodeIcon,
  ReceiptRefundIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { LucideHourglass } from "lucide-react";

export const site_solutions = [
  {
    name: "Platform of Sale",
    description:
      "Connecting the points into an intelligent platform that drives more sales, efficiently.",
    href: "/pages/pos",
    Icon: CubeIcon,
  },
  {
    name: "Order & Pay at the Table",
    description:
      "Instantly serve your customers, even when you're short on staff.",
    href: "/pages/order-and-pay",
    Icon: QrCodeIcon,
  },
  {
    name: "Reservations",
    description:
      "Optimise Seats & reduce no-shows with our table managment and booking product.",
    href: "/pages/reservations",
    Icon: BookmarkSquareIcon,
  },
  {
    name: "Queue Managment",
    description:
      "Manage waiting queue efficiently, and keep your customers on track efortlessly.",
    href: "/pages/waitlist",
    Icon: LucideHourglass,
  },
];

export const personal_routes = [
  { Icon: UserIcon, name: "Profile", href: "/me" },
  { Icon: CreditCardIcon, name: "Payment Methods", href: "/me/methods" },
  { Icon: MapPinIcon, name: "Addresses", href: "/me/addresses" },
  { Icon: ReceiptRefundIcon, name: "Orders", href: "/me/orders" },
  { Icon: CalendarIcon, name: "Reservations", href: "/me/reservations" },
  { Icon: CheckBadgeIcon, name: "Memberships", href: "/me/memberships" },
];
