import { ChevronRightIcon } from "@heroicons/react/24/outline";
import TextSkeleton from "../components/TextSkeleton";

const ProfileListItemSkeleton = ({ Icon }) => (
  <div className="p-3 flex flex-row items-center justify-between">
    <div className="flex flex-row items-center justify-items-start w-full">
      <div className="flex w-6 h-6 mr-3 rounded-full items-center justify-center">
        <Icon className="mx-auto w-6 h-6 opacity-50 group-hover:opacity-100" />
      </div>
      <div className="flex flex-col w-full">
        <TextSkeleton className="h-3 mx-0" />
      </div>
    </div>
    <ChevronRightIcon className="w-6 h-6 opacity-0 group-hover:opacity-100" />
  </div>
);

export default ProfileListItemSkeleton;
