import { useMemo } from "react";

import { Disclosure } from "@headlessui/react";
import { PlusIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import clsx from "clsx";

export default function Faq() {
  const intl = useIntl();
  const faqs: Array<[string, string, any]> = useMemo(
    () =>
      [
        [
          "faq_4_title",
          "faq_4_text",
          {
            join: (
              <Link
                className="group-hover:text-primary dark:group-hover:text-primary-300 hover:text-primary underline"
                to="/join"
              >
                {intl.formatMessage({ id: "here", defaultMessage: "here" })}
              </Link>
            ),
          },
        ],
        [
          "faq_6_title",
          "faq_6_text",
          {
            pricing: (
              <Link
                className="group-hover:text-primary dark:group-hover:text-primary-300 hover:text-primary underline"
                to="/pages/pricing"
              >
                {intl.formatMessage({
                  id: "pricing_title",
                  defaultMessage: "Pricing",
                })}
              </Link>
            ),
          },
        ],
        ["faq_5_title", "faq_5_text"],
        ["faq_1_title", "faq_1_text"],
        ["faq_2_title", "faq_2_text"],
        ["faq_3_title", "faq_3_text"],
      ] as any,
    [intl.locale]
  );
  return (
    <div className="flex self-center w-full px-5">
      <div className="rounded-xl flex flex-col mx-auto w-full max-w-screen-xl items-center justify-center ring-1 hover:shadow-lg overflow-hidden ring-zinc-200 dark:ring-zinc-700 shadow-md md:flex-4 bg-card duration-500 hover:bg-zinc-100 dark:hover:bg-zinc-800">
        <h3 className="pb-10 font-medium text-2xl md:text-5xl flex flex-row items-center gap-2 mx-auto p-10">
          <QuestionMarkCircleIcon className="w-6 h-6 md:w-12 md:h-12 stroke-text" />
          {intl.formatMessage({ id: "faq", defaultMessage: "FAQs" })}
        </h3>
        <div className="flex flex-col gap-3 px-5 md:px-10 pb-10">
          {faqs.map(([title, text, values]) => (
            <Disclosure key={title}>
              {({ open }) => (
                <Disclosure.Button>
                  <div className="group max-w-prose mx-auto w-full bg-white dark:bg-black overflow-hidden rounded-2xl shadow hover:scale-[0.99] duration-200 ring-1 hover:shadow-lg ring-zinc-200 dark:ring-zinc-700">
                    <div className="relative gap-10 flex w-full items-center justify-between px-5 py-5 text-left text-xl font-medium focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75">
                      <h6
                        className={clsx(
                          "text-left rtl:text-right",
                          open && "text-primary dark:text-primary"
                        )}
                      >
                        {intl.formatMessage({ id: title })}
                      </h6>
                      <PlusIcon
                        className={clsx(
                          "h-5 w-5 duration-200 text-text flex-none self-start mt-15 opacity-0 group-hover:opacity-100",
                          open && "rotate-45 transform opacity-100"
                        )}
                      />
                    </div>
                    <motion.div
                      initial="hide"
                      exit="hide"
                      animate={open ? "show" : "hide"}
                      variants={{
                        show: {
                          transition: {
                            duration: 0.3,
                            ease: "easeInOut",
                          },
                          height: "auto",
                          visibility: "visible",
                          opacity: 100,
                          scale: 1,
                        },
                        hide: {
                          transition: {
                            duration: 0.3,
                            ease: "easeInOut",
                          },
                          height: 0,
                          visibility: "hidden",
                          opacity: 0,
                          scale: 0.95,
                        },
                      }}
                    >
                      <p className="px-4 pb-4 text-lg text-gray-500 dark:text-gray-300 group-hover:text-gray-800 dark:group-hover:text-gray-100 text-left rtl:text-right">
                        {intl.formatMessage({ id: text }, values)}
                      </p>
                    </motion.div>
                  </div>
                </Disclosure.Button>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </div>
  );
}
