import { useEffect, EffectCallback, DependencyList } from "react";

type AsyncEffectCallback = () => Promise<EffectCallback>;

function useAsyncEffect(effect: AsyncEffectCallback, deps?: DependencyList) {
  useEffect(() => {
    let cleanupFn: EffectCallback | undefined;
    const promise = effect();
    promise.then((returnedCleanupFn) => {
      cleanupFn = returnedCleanupFn;
    });
    return () => {
      if (cleanupFn) {
        cleanupFn();
      }
    };
  }, deps);
}

export default useAsyncEffect;
