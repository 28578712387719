import { useMemo } from "react";
import { useAppSelector } from "../state/hooks";
import { useAppIntl } from "./AppIntlContext";
import { ThemeProvider, createTheme } from "./ThemeContext";

export const AppThemeProvider = ({ children }) => {
  const palette = useAppSelector((s) => s.system.theme);
  const { dir, locale } = useAppIntl();
  const theme = useMemo(
    () => createTheme({ direction: dir, type: palette }, locale),
    [palette, dir, locale]
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AppThemeProvider;
