import { checkSchedule } from "@gethere/common/utilities";
import { TCollection, TCollectionGroup } from "@gethere/common/yup/Collection";
import { useOnValueChange } from "@shopify/react-hooks";
import clsx from "clsx";
import { useInView } from "framer-motion";
import { useContext, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import SessionCollectionsSectionsContext from "../contexts/SessionCollectionsSectionsContext";
import { useAppSelector } from "../state/hooks";
import {
  sessionCollectionsSelector,
  sessionCurrencySelector,
} from "../state/reducers/session";
import useInterval from "../utils/useInterval";
import PageContainer from "./PageContainer";
import PageWrapper from "./PageWrapper";
import { scheduleTitle } from "./Schedule";
import SessionCollectionListItem from "./SessionCollectionListItem";
import { SessionCollectionsScrollSpyContext } from "./SessionCollectionsScrollSpy";

const AvailabilityText = ({ status, text }) => (
  <p
    className={clsx(
      "text-xs max-w-lg text-muted-foreground",
      !status && "text-orange-400 dark:text-orange-700"
    )}
  >
    {text}
  </p>
);

const SessionCollection = ({
  id,
  name,
  desc,
  children,
  Ref,
  schedules,
}: {
  id: string;
  desc: TCollection["desc"];
  name: TCollection["name"];
  schedules: TCollection["schedule"];
  Ref: any;
  children: any;
}) => {
  const intl = useIntl();

  useUpdateActiveSection({ Ref, id });

  const [availability, setAvailability] = useState(
    checkSchedule(schedules, new Date())
  );

  useInterval(() => {
    if (schedules && Array.isArray(schedules) && schedules.length) {
      setAvailability(checkSchedule(schedules, new Date()));
    }
  }, 1000 * 60);

  const availabilityText = useMemo(() => {
    return schedules
      ?.map?.((s) =>
        scheduleTitle(s, intl, {
          showAllWeekdays: false,
          openTimeMessage: "menu_schedule_hour_open",
          closeTimeMessage: "menu_schedule_hour_close",
        })
      )
      .join(", ");
  }, [availability?.status]);

  const hasName = typeof name === "string" && name.length > 0;
  const hasDesc = typeof desc === "string" && !!desc?.length;

  return (
    <section className="my-5">
      {(hasName || hasDesc) && (
        <div className="px-5 md:px-0" ref={Ref}>
          {hasName && <h6 className="font-bold text-xl">{name}</h6>}
          {hasDesc && (
            <p className="text-sm max-w-lg text-muted-foreground">{desc}</p>
          )}
          {availability?.status !== undefined && (
            <AvailabilityText
              status={availability?.status}
              text={availabilityText}
            />
          )}
        </div>
      )}
      {availability?.status && <div>{children}</div>}
    </section>
  );
};

const defaultRef = { current: null };

const useUpdateActiveSection = ({ Ref, id }: { Ref: any; id: string }) => {
  const { getSectionIndex } = useContext(SessionCollectionsSectionsContext);

  const { updateActiveSession } = useContext(
    SessionCollectionsScrollSpyContext
  );

  const inView = useInView(Ref || defaultRef, {
    margin: "50% 0px -50% 0px",
  });

  useOnValueChange(inView, (now) => {
    if (now) {
      const index = getSectionIndex(id);
      updateActiveSession(index);
    }
  });

  return null;
};

const SessionCollectionGroup = ({
  id,
  name,
  desc,
  children,
  single,
  Ref,
  schedules,
}: {
  id: string;
  desc: TCollectionGroup["desc"];
  name: TCollectionGroup["name"];
  schedules: TCollectionGroup["schedule"];
  Ref: any;
  single: boolean;
  children: any;
}) => {
  const intl = useIntl();

  const [availability, setAvailability] = useState(
    checkSchedule(schedules, new Date())
  );

  useUpdateActiveSection({ Ref, id });

  useInterval(() => {
    if (schedules && Array.isArray(schedules) && schedules.length) {
      setAvailability(checkSchedule(schedules, new Date()));
    }
  }, 1000 * 60);

  const availabilityText = useMemo(() => {
    return schedules
      ?.map?.((s) =>
        scheduleTitle(s, intl, {
          showAllWeekdays: false,
          openTimeMessage: "menu_schedule_hour_open",
          closeTimeMessage: "menu_schedule_hour_close",
        })
      )
      .join(", ");
  }, [availability?.status]);

  const hasName = typeof name === "string" && !single;
  const hasDesc = typeof desc === "string" && !!desc?.length;

  return (
    <section className="mb-5" ref={Ref}>
      {(hasName || hasDesc) && (
        <div className="px-5 md:px-0">
          {hasName && (
            <h6 className="font-medium text-base text-muted-foreground">
              {name}
            </h6>
          )}
          {hasDesc && (
            <p className="text-sm max-w-lg text-muted-foreground">{desc}</p>
          )}
          {availability?.status !== undefined && (
            <AvailabilityText
              status={availability?.status}
              text={availabilityText}
            />
          )}
        </div>
      )}
      {availability?.status && (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 md:gap-3 mt-5 bg-card drop-shadow md:bg-transparent md:drop-shadow-none divide-y md:divide-y-0 md:rounded-none overflow-hidden">
          {children}
        </div>
      )}
    </section>
  );
};

const SessionCollectionsContent = () => {
  const { getSectionRefById } = useContext(SessionCollectionsSectionsContext);

  const { collections, currency } = useAppSelector((state) => ({
    collections: sessionCollectionsSelector(state),
    currency: sessionCurrencySelector(state),
  }));

  if (!Array.isArray(collections)) return null;

  return (
    <PageWrapper disableHorizonalPadding className="md:px-5">
      <PageContainer>
        {collections.map((collection) => (
          <SessionCollection
            key={collection.id}
            name={collection.name}
            desc={collection.desc}
            schedules={collection.schedule}
            Ref={getSectionRefById(collection.id)}
            id={collection.id}
          >
            {collection.groups?.map((group) => (
              <SessionCollectionGroup
                id={group.id}
                name={group.name}
                desc={group.desc}
                schedules={group.schedule}
                key={group.id}
                Ref={getSectionRefById(group.id)}
                single={collection.groups?.length <= 1}
              >
                {group.items?.map((item) => (
                  <SessionCollectionListItem
                    soldOut={item.soldOut}
                    itemId={item.id}
                    key={item.id}
                    currency={currency}
                  />
                ))}
              </SessionCollectionGroup>
            ))}
          </SessionCollection>
        ))}
      </PageContainer>
    </PageWrapper>
  );
};

export default SessionCollectionsContent;
