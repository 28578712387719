type CountryTax = {
  type: string;
  rate: number;
  before?: Record<string, { type: string; rate: number }>;
};

type CountryInfo = {
  name: string;
  currency: string;
  phoneCode: string;
  taxes?: CountryTax[];
  states?: Record<string, { name: string; taxes?: CountryTax[] }>;
};

const countriesInfo: Record<string, CountryInfo> = {
  AU: {
    name: "Australia",
    currency: "AUD",
    phoneCode: "61",
    taxes: [{ rate: 0.1, type: "gst" }],
  },
  FR: {
    name: "France",
    currency: "EUR",
    phoneCode: "33",
    taxes: [{ rate: 0.2, type: "vat" }],
  },
  DE: {
    name: "Germany",
    currency: "EUR",
    phoneCode: "49",
    taxes: [
      {
        rate: 0.19,
        type: "vat",
        before: {
          "2020-12-31T22:00:00.000Z": { type: "vat", rate: 0.16 },
          "2020-06-30T22:00:00.000Z": { type: "vat", rate: 0.19 },
        },
      },
    ],
  },
  ES: {
    name: "Spain",
    currency: "EUR",
    phoneCode: "34",
    taxes: [{ rate: 0.21, type: "vat" }],
  },
  GB: {
    name: "United Kingdom",
    currency: "GBP",
    phoneCode: "44",
    taxes: [{ rate: 0.2, type: "vat" }],
  },
  US: {
    name: "United States",
    currency: "USD",
    phoneCode: "1",
    states: {
      AL: { name: "Alabama", taxes: [{ rate: 0.04, type: "vat" }] },
      AK: { name: "Alaska" },
      AS: { name: "American Samoa" },
      AZ: { name: "Arizona", taxes: [{ rate: 0.066, type: "vat" }] },
      AR: { name: "Arkansas", taxes: [{ rate: 0.06, type: "vat" }] },
      "UM-81": { name: "Baker Island" },
      CA: { name: "California", taxes: [{ rate: 0.0825, type: "vat" }] },
      CO: { name: "Colorado", taxes: [{ rate: 0.029, type: "vat" }] },
      CT: { name: "Connecticut", taxes: [{ rate: 0.06, type: "vat" }] },
      DE: { name: "Delaware" },
      DC: {
        name: "District of Columbia",
        taxes: [{ rate: 0.06, type: "vat" }],
      },
      FL: { name: "Florida", taxes: [{ rate: 0.06, type: "vat" }] },
      GA: { name: "Georgia", taxes: [{ rate: 0.04, type: "vat" }] },
      GU: { name: "Guam" },
      HI: { name: "Hawaii", taxes: [{ rate: 0.04, type: "vat" }] },
      "UM-84": { name: "Howland Island" },
      ID: { name: "Idaho", taxes: [{ rate: 0.06, type: "vat" }] },
      IL: { name: "Illinois", taxes: [{ rate: 0.0625, type: "vat" }] },
      IN: { name: "Indiana", taxes: [{ rate: 0.07, type: "vat" }] },
      IA: { name: "Iowa", taxes: [{ rate: 0.06, type: "vat" }] },
      "UM-86": { name: "Jarvis Island" },
      "UM-67": { name: "Johnston Atoll" },
      KS: { name: "Kansas", taxes: [{ rate: 0.063, type: "vat" }] },
      KY: { name: "Kentucky", taxes: [{ rate: 0.06, type: "vat" }] },
      "UM-89": { name: "Kingman Reef" },
      LA: { name: "Louisiana", taxes: [{ rate: 0.04, type: "vat" }] },
      ME: { name: "Maine", taxes: [{ rate: 0.05, type: "vat" }] },
      MD: { name: "Maryland", taxes: [{ rate: 0.06, type: "vat" }] },
      MA: { name: "Massachusetts", taxes: [{ rate: 0.0625, type: "vat" }] },
      MI: { name: "Michigan", taxes: [{ rate: 0.06, type: "vat" }] },
      "UM-71": { name: "Midway Atoll" },
      MN: { name: "Minnesota", taxes: [{ rate: 0.06875, type: "vat" }] },
      MS: { name: "Mississippi", taxes: [{ rate: 0.07, type: "vat" }] },
      MO: { name: "Missouri", taxes: [{ rate: 0.04225, type: "vat" }] },
      MT: { name: "Montana" },
      "UM-76": { name: "Navassa Island" },
      NE: { name: "Nebraska", taxes: [{ rate: 0.055, type: "vat" }] },
      NV: { name: "Nevada", taxes: [{ rate: 0.0685, type: "vat" }] },
      NH: { name: "New Hampshire" },
      NJ: { name: "New Jersey", taxes: [{ rate: 0.07, type: "vat" }] },
      NM: { name: "New Mexico", taxes: [{ rate: 0.05, type: "vat" }] },
      NY: { name: "New York", taxes: [{ rate: 0.04, type: "vat" }] },
      NC: { name: "North Carolina", taxes: [{ rate: 0.0575, type: "vat" }] },
      ND: { name: "North Dakota", taxes: [{ rate: 0.05, type: "vat" }] },
      MP: { name: "Northern Mariana Islands" },
      OH: { name: "Ohio", taxes: [{ rate: 0.055, type: "vat" }] },
      OK: { name: "Oklahoma", taxes: [{ rate: 0.045, type: "vat" }] },
      OR: { name: "Oregon" },
      "UM-95": { name: "Palmyra Atoll" },
      PA: { name: "Pennsylvania", taxes: [{ rate: 0.06, type: "vat" }] },
      PR: { name: "Puerto Rico" },
      RI: { name: "Rhode Island", taxes: [{ rate: 0.07, type: "vat" }] },
      SC: { name: "South Carolina", taxes: [{ rate: 0.06, type: "vat" }] },
      SD: { name: "South Dakota", taxes: [{ rate: 0.04, type: "vat" }] },
      TN: { name: "Tennessee", taxes: [{ rate: 0.07, type: "vat" }] },
      TX: { name: "Texas", taxes: [{ rate: 0.0625, type: "vat" }] },
      UM: { name: "United States Minor Outlying Islands" },
      VI: { name: "United States Virgin Islands" },
      UT: { name: "Utah", taxes: [{ rate: 0.0595, type: "vat" }] },
      VT: { name: "Vermont", taxes: [{ rate: 0.06, type: "vat" }] },
      VA: { name: "Virginia", taxes: [{ rate: 0.05, type: "vat" }] },
      "UM-79": { name: "Wake Island" },
      WA: { name: "Washington", taxes: [{ rate: 0.065, type: "vat" }] },
      WV: { name: "West Virginia", taxes: [{ rate: 0.06, type: "vat" }] },
      WI: { name: "Wisconsin", taxes: [{ rate: 0.05, type: "vat" }] },
      WY: { name: "Wyoming", taxes: [{ rate: 0.04, type: "vat" }] },
    },
  },
};

export const SUPPORTED_COUNTRIES = ["US", "AU", "GB", "ES", "DE", "FR"];

export default countriesInfo;
