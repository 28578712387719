const CookieSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 18 18">
    <g>
      <path d="M17.638 8.158a.906.906 0 0 0-.769-.155c-.257.067-.47.097-.67.097-1.489 0-2.7-1.211-2.703-2.643l.015-.153a.9.9 0 0 0-.336-.737.89.89 0 0 0-.793-.168 2.686 2.686 0 0 1-.684.102A2.704 2.704 0 0 1 9 1.8a2.62 2.62 0 0 1 .089-.644.9.9 0 0 0-.193-.805.9.9 0 0 0-.768-.308A8.96 8.96 0 0 0 0 9c0 4.963 4.037 9 9 9s9-4.037 9-9c0-.044-.003-.087-.006-.144a.904.904 0 0 0-.355-.698zM9 16.2A7.21 7.21 0 0 1 1.8 9a7.165 7.165 0 0 1 5.405-6.975A4.506 4.506 0 0 0 11.7 6.3l.091-.001a4.506 4.506 0 0 0 4.353 3.6C15.7 13.447 12.666 16.2 9 16.2z" />
      <circle cx={9.45} cy={8.55} r={1} />
      <circle cx={5.85} cy={5.85} r={1} />
      <circle cx={4.95} cy={9.45} r={1} />
      <circle cx={12.15} cy={12.15} r={1} />
      <circle cx={7.65} cy={13.05} r={1} />
    </g>
  </svg>
);

export default CookieSvg;
