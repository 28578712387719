import * as yup from "yup";
import { scheduleScheme } from "./Schedule";
import { sessionTypesArraySchema } from "./SessionTypes";
import utcIsoDateString from "./Date";

export const PROMO_TRIGGERS = ["auto", "code", "manual"] as const;
export type TPromoTrigger = (typeof PROMO_TRIGGERS)[number];

export const PROMO_TYPES = ["percent", "fixed"] as const;
export type TPromoType = (typeof PROMO_TYPES)[number];

export const promoFixedRuleSchema = yup
  .object({
    value: yup.number().min(0).required(),
  })
  .required();

export const promoPercentRuleSchema = yup
  .object({
    value: yup.number().min(0).max(1).required(),
    limitValue: yup.number().min(0).nullable().default(null),
  })
  .required();

export const promoRequirements = yup
  .object({
    minSubtotal: yup.number().min(0).nullable(),
    code: yup.string().min(3).max(30).nullable().default(null),
  })
  .optional()
  .nullable();

export type TPromoFixBenfit = yup.InferType<typeof promoFixedRuleSchema>;
export type TPromoPercentBenfit = yup.InferType<typeof promoPercentRuleSchema>;

const promoTypeSchema = yup
  .mixed<TPromoType>()
  .oneOf(PROMO_TYPES as any)
  .default(PROMO_TYPES[0]) // percent
  .required();

const promoTriggerSchema = yup
  .mixed<TPromoTrigger>()
  .oneOf(PROMO_TRIGGERS as any)
  .required()
  .default(PROMO_TRIGGERS[0]); // auto

export const promoBenfitSchema = yup
  .mixed<TPromoFixBenfit | TPromoPercentBenfit>()
  .test(function (value, options) {
    try {
      const type = options.parent.type;
      if (type === "percent") {
        promoPercentRuleSchema.validateSync(value);
        return true;
      } else if (type === "fixed") {
        promoFixedRuleSchema.validateSync(value);
        return true;
      }
      return this.createError({
        path: "benfit",
        message: "benfit required",
      });
    } catch (error) {
      const e = error as yup.ValidationError;
      return this.createError({
        path: "benfit." + e.path,
        message: e.message,
      });
    }
  })
  .default(
    promoPercentRuleSchema.cast({
      value: 0.1,
      limitValue: null,
    })
  );

export const promoTargetSchema = yup.object({
  placeIds: yup.array().of(yup.string().required()).nullable().nullable(),
  placesExcluded: yup.boolean().default(false).nullable(),

  itemIds: yup.array().of(yup.string().required()).nullable().nullable(),
  itemsExcluded: yup.boolean().default(false).nullable(),

  noCustomer: yup.boolean().notRequired(),
  noOperator: yup.boolean().notRequired(),

  categoryIds: yup.array().of(yup.string().required()).nullable().nullable(),

  categoriesExcluded: yup.boolean().default(false).nullable(),

  sessionTypes: sessionTypesArraySchema,
});

export const promoSchema = yup
  .object({
    id: yup.string(),
    businessId: yup.string(),

    active: yup.boolean().default(true).nullable(),

    name: yup.string().default("").min(2),

    displayName: yup.string().default("").min(2).nullable().default(null),

    desc: yup.string().optional().nullable(),

    // Benfit
    type: promoTypeSchema,

    benfit: promoBenfitSchema,

    schedule: yup
      .array()
      .of(scheduleScheme)
      .max(25)
      .default(null)
      .nullable()
      .optional(),

    // Targeting
    // null - all, array - specific
    target: promoTargetSchema,

    meta: yup.mixed<{}>().notRequired(),

    // Settings
    trigger: promoTriggerSchema,
    requires: promoRequirements,

    // Limit
    limits: yup.object({
      times: yup.number().min(0).nullable().default(null),
      hours: yup.number().min(0).nullable().default(null),
    }),

    validFrom: utcIsoDateString.notRequired(),
    validThrough: utcIsoDateString.notRequired(),

    createdAt: yup.string().notRequired(),
    updatedAt: yup.string().notRequired(),
  })
  .test(function (value) {
    if (!value) return true;
    if (value.trigger === "code" && !value.requires?.code) {
      return this.createError({
        path: "requires.code",
        message: "code is required",
      });
    }
    return true;
  })
  .required();

export type TPromo = yup.InferType<typeof promoSchema>;

// .shape({
// })
