import React from "react";
import { useOutsideClick } from "../utils/hooks";

export default function OutsideClick({
  onOutsideClick,
  children,
  enabled = true,
  ...props
}) {
  const ref = React.useRef(null);
  useOutsideClick({ ref, handler: onOutsideClick, enabled });
  return (
    <span {...props} ref={ref}>
      {children}
    </span>
  );
}
