import * as yup from "yup";
import utcIsoDateString, { timeStringSchema } from "./Date";
import { scheduleScheme } from "./Schedule";

export const flowControlSchema = yup.object().shape({
  maxCovers: yup.number().min(1).max(200),
  perMinutes: yup.number().min(1).max(240),
});

export const bookingShiftSchema = yup.object().shape({
  dayOfWeek: yup.number().min(0).max(6),
  startTime: yup.string(),
  endTime: yup.string(),
  flowControl: flowControlSchema,
  turnTimes: yup.mixed<Record<number, number>>(),
});

const nonEmptyString: yup.TestConfig = {
  name: "nonEmptyString",
  message: "message cannot be empty",
  test: (value: string, context) => {
    if (typeof value === "string" && value.length === 0) {
      return context.createError({ message: "message cannot be empty" });
    }
    return true;
  },
};

const smsMessageSchema = yup
  .string()
  .min(5)
  .max(100)
  .test(nonEmptyString)
  .notRequired();

export const generalBookingSettingsSchema = yup.object().shape({
  waitlist: yup
    .object({
      enabled: yup.boolean().default(false).notRequired(),
      autoSession: yup.boolean().default(false).notRequired(),
      maxGuests: yup.number().min(1).max(200),
      minGuests: yup.number().min(1).max(200),
      messages: yup.object({
        confirmSms: smsMessageSchema,
        cancelSms: smsMessageSchema,
        readySms: smsMessageSchema,
      }),
    })
    .notRequired(),

  schedule: yup.array().of(scheduleScheme).notRequired(),
  enableNotify: yup.boolean().default(false).notRequired(),

  // auto open session on arrived
  autoSession: yup.boolean().default(false).notRequired(),

  minutesSlot: yup.number().min(10).max(3600),
  minutesInterval: yup.number().min(10).max(240),
  maxDaysAdvance: yup.number().min(0).max(365),

  maxGuests: yup.number().integer().min(1).max(200),
  minGuests: yup.number().integer().min(1).max(200),

  blockMinAhead: yup.number().integer().min(0).max(2880).notRequired(),

  holdLateArrive: yup.number().min(0).max(240).nullable(),
  mayContact: yup.boolean().default(true).notRequired(),
  customDetails: yup.string().notRequired(),
  minDate: utcIsoDateString.notRequired(),

  messages: yup.object({
    // sms
    confirmSms: smsMessageSchema,
    cancelSms: smsMessageSchema,
    // reminders
    reminderSms: smsMessageSchema,
    reminderMinutes: yup.number().min(10).max(360).nullable(),
    // website
    fullyBooked: smsMessageSchema,
  }),
});

const terminalIdSchema = yup.string().required().min(5).max(32);
const comboCapacitySchema = yup.number().min(2).max(100);

const comboItemSchema = yup.mixed();

export const combosSchema = yup.array().of(
  yup
    .array()
    .of(comboItemSchema)
    .min(2)
    .test({
      name: "custom-capacity-validation",
      message: "Invalid combo capacity",
      test: function (combo, context) {
        if (!Array.isArray(combo)) return;
        if (combo.length < 2) return;

        for (let i = 0; i < combo.length; i++) {
          if (combo.length - 1 === i && typeof combo[i] === "number") {
            return comboCapacitySchema.isValidSync(combo[i]);
          }
          return terminalIdSchema.isValidSync(combo[i]);
        }
      },
    })
);

export const blockedDatesSchema = yup.object({
  date: utcIsoDateString,
  note: yup.string().optional(),
  fromTime: timeStringSchema.optional(),
  toTime: timeStringSchema.optional(),
});

export const cancellationPolicySchema = yup.object().shape({
  text: yup.string().optional(),
  modifyHours: yup.number().min(0).max(240).nullable(), // null means no limit, 0 means no modification allowed.
  minPayableGuests: yup.number().min(0).max(100).nullable(), // null means dont require, 0 always.
  // deposit
  deposit: yup.object({
    autoCharge: yup.boolean(),
    authorize: yup.boolean(),
    perGuest: yup.number().min(0).max(1000).nullable(),
    maxAmount: yup.number().min(0).max(5000).nullable(),
    refundableHrs: yup.number().min(0).max(240).nullable(), // null means no limit, 0 means no refund allowed.
  }),
});

export const bookingSettingsSchema = yup.object().shape({
  placeId: yup.string().notRequired(),
  enabled: yup.boolean().required().default(false),
  // yup.lazy((value) => {
  //   if (typeof value === "string") return utcIsoDateString.required();
  //   return yup.boolean().required().default(false);
  // }),
  shifts: yup.array().of(bookingShiftSchema).notRequired(),
  settings: generalBookingSettingsSchema,
  combos: combosSchema.notRequired(),
  blocks: yup.array().of(blockedDatesSchema).notRequired(),
  cancel: yup.object({
    default: cancellationPolicySchema,
    custom: yup.array().of(cancellationPolicySchema.concat(scheduleScheme)),
  }),
});

export type TFlowControl = yup.InferType<typeof flowControlSchema>;
export type TBookingShift = yup.InferType<typeof bookingShiftSchema>;
export type TGeneralBookingSettings = yup.InferType<
  typeof generalBookingSettingsSchema
>;
export type TTerminalCombos = yup.InferType<typeof combosSchema>;
export type TBookingSettings = yup.InferType<typeof bookingSettingsSchema>;
