import { parse } from "./qs";

const getSessionQueryParams = (search: string) => {
  const qs = parse(search);
  return qs as {
    tId: string;
    pId: string;
    sId: string;
    ch: string;
  };
};

export default getSessionQueryParams;
