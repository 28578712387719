import { captureException } from "@sentry/react";
import { useEffect } from "react";
import env from "../utils/env";

const getCaptcha = (ms = 100000) =>
  new Promise<{
    execute: (token: string, arg: { action: string }) => Promise<string>;
  }>((resolve, reject) => {
    // if (env.development) {
    //   resolve({
    //     execute: async (token, arg) => {
    //       return "flightmode";
    //     },
    //   });
    //   return; // remove!!flightmode
    // }

    let timeout, interval;

    timeout = setTimeout(() => {
      reject(new Error("timeout waitng for recaptcha to be loaded"));
      clearInterval(interval);
    }, ms);

    interval = setInterval(() => {
      if (typeof window["grecaptcha"]?.ready === "function") {
        clearInterval(interval);
        window["grecaptcha"].ready(() => {
          clearTimeout(timeout);
          resolve(window["grecaptcha"]);
        });
      }
    }, 100);
  });

const recap_src = `https://www.google.com/recaptcha/api.js?render=${env.recaptcha_public}`;

export const getReCaptchaToken = async () => {
  const grecaptcha = await getCaptcha();

  const token = await grecaptcha.execute(env.recaptcha_public, {
    action: "submit",
  });

  return token;
};

export const useRecaptcha = () => {
  return useEffect(() => {
    try {
      const script = document.createElement("script");
      script.src = recap_src;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      return () => {
        try {
          const badge = document.querySelector(".grecaptcha-badge");
          if (badge) document.body.removeChild(badge.parentNode);
          if (script) script.remove();
        } catch (error) {
          captureException(error);
        }
      };
    } catch (error) {
      captureException(error);
    }
  }, []);
};
