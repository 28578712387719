import { useEffect, useRef } from "react"; // Use useRef instead of useState
import { usePopper } from "react-popper";
import type Popper from "@popperjs/core";
import MainPortal from "../containers/MainPortal";

const Popover = ({
  children,
  referenceElement,
  placement = "bottom-start" as Popper.Placement,
  portal = false,
  onClickOutside = () => {},
}: {
  children: any;
  referenceElement: any;
  placement?: Popper.Placement;
  portal?: boolean;
  onClickOutside?: () => void;
}) => {
  const popperElement = useRef(null); // Use useRef here instead of useState
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement.current,
    {
      placement,
      modifiers: [
        {
          enabled: true,
          name: "preventOverflow",
          options: {
            altBoundary: true, // false by default
          },
        },
      ],
    }
  );

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (popperElement.current && !popperElement.current.contains(e.target)) {
        onClickOutside?.();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    document.addEventListener("touchstart", checkIfClickedOutside);

    return () => {
      // Cleanup the event listeners
      document.removeEventListener("mousedown", checkIfClickedOutside);
      document.removeEventListener("touchstart", checkIfClickedOutside);
    };
  }, [popperElement, onClickOutside]);

  if (!portal)
    return (
      <div
        ref={popperElement}
        style={styles.popper}
        className="z-[1900]"
        {...attributes.popper}
      >
        {children}
      </div>
    );

  return (
    <MainPortal>
      <div
        ref={popperElement}
        style={styles.popper}
        className="z-[1900]"
        {...attributes.popper}
      >
        {children}
      </div>
    </MainPortal>
  );
};

export default Popover;
