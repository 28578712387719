import * as yup from "yup";
import { WEEKDAYS_INDEX } from "../settings";
import { simpleDateSchema } from "./Date";

export const scheduleScheme = yup.object({
  // general
  opens: yup.string().default("00:00"),
  closes: yup.string().default("23:59"),
  daysOfWeek: yup
    .array()
    .of(yup.number().min(1).max(7))
    .min(1)
    .max(7)
    .nullable()
    .default(null)
    .transform((v) =>
      Array.isArray(v) ? v.filter((d) => WEEKDAYS_INDEX.includes(d)) : null
    ),
  validFrom: simpleDateSchema.notRequired().default(null),
  validThrough: simpleDateSchema.notRequired().default(null),

  name: yup.string().notRequired(),
  notes: yup.string().notRequired(),

  // booking specific
  maxGuests: yup.number().min(0).max(1000).default(undefined).notRequired(), // maxGuests per schedule
  maxSlotGuests: yup.number().min(0).max(1000).default(undefined).notRequired(), // maxGuests per slot
});

export type TSchedule = yup.InferType<typeof scheduleScheme>;
