import { staticImageBuilder } from "@gethere/common/utilities";
import { TPlace } from "@gethere/common/yup/Place";
import { TUser } from "@gethere/common/yup/User";
import {
  ClockIcon,
  LanguageIcon,
  MapIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import { CalendarIcon, StarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { parsePhoneNumber } from "awesome-phonenumber";
import clsx from "clsx";
import { motion } from "framer-motion";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { BottomSheet } from "react-spring-bottom-sheet";
import Btn, { BtnLink, HtmlAnchor, HtmlDiv } from "../components/Btn";
import CloseIcon from "../components/CloseIcon";
import Indicator from "../components/Indicator";
import { Picture } from "../components/Picture";
import BottomPageContainer from "../components/SafeArea";
import { InstagramIcon } from "../components/SocialIcons";
import TextSkeleton from "../components/TextSkeleton";
import { useLanguageDialog } from "../contexts/LanguageContext";
import { useSession } from "../contexts/SessionContext";
import { sessionPlaceSelector } from "../state/reducers/session";
import {
  isAdminSelector,
  onUserUpdate,
  userSelector,
} from "../state/reducers/user";
import store, { RootState } from "../state/store";
import client from "../utils/client";
import errorify from "../utils/errorify";
import useToggle from "../utils/useToggle";
import Modal from "./Modal";
import PageContainer from "./PageContainer";
import PageWrapper from "./PageWrapper";
import { scheduleTitle } from "./Schedule";
import { useAppDispatch, useAppSelector } from "../state/hooks";

export const SessionCoverWraper = ({
  children = null,
  coverImage,
  fixed = false,
}: {
  children: any;
  fixed: boolean;
  coverImage;
}) => {
  const img = useMemo(
    () =>
      staticImageBuilder(coverImage?.src || coverImage, {
        width: 820,
        height: 360,
      }),
    [coverImage]
  );
  return (
    <PageWrapper
      disableVerticalMargin
      className={clsx(
        "relative flex flex-col justify-end basis-80 bg-background"
      )}
    >
      <div className="absolute overflow-hidden inset-0 min-h-full">
        <div
          className="place-cover-img"
          style={img ? { backgroundImage: `url('${img}')` } : {}}
        />
      </div>
      <div className="z-10">{children}</div>
    </PageWrapper>
  );
};

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export const SessionCover = ({
  children = null,
  fixed = false,
  loading = null,
  status = null,
  place = null,
  variant = "session",
}: any & { variant: "session" | "place" }) => {
  return (
    <SessionCoverWraper
      fixed={fixed}
      coverImage={!loading && place?.theme?.coverImg}
    >
      <motion.div initial="hidden" animate="visible" variants={container}>
        <PageContainer className="w-full">
          <div className="flex self-end">
            {!loading && Boolean(place?.theme?.lightLogo) ? (
              <div className="flex self-end pt-10">
                <Picture
                  srcset={place?.theme?.lightLogo}
                  className="max-h-[10rem] max-w-[200px] w-auto"
                  alt={place?.name}
                />
              </div>
            ) : (
              <motion.h2
                variants={item}
                className="font-semibold text-5xl max-w-xl"
              >
                {loading ? <TextSkeleton className="w-48" /> : place?.name}
              </motion.h2>
            )}
          </div>
          {children}
        </PageContainer>
        <SessionHeaderInfo
          place={place}
          status={status}
          variant={variant}
          loading={loading}
        />
      </motion.div>
    </SessionCoverWraper>
  );
};

const PlaceCategoriesList = ({ categories }) => {
  return (
    <div className="flex flex-wrap gap-1">
      {categories?.map((cat) => (
        <Indicator key={String(cat)} color="gray" label={cat} />
      ))}
    </div>
  );
};

// const useProgressStyle = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       position: "relative",
//     },
//     bottom: {
//       color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
//     },
//     top: {
//       color: "#1a90ff",
//       animationDuration: "550ms",
//       position: "absolute",
//       left: 0,
//     },
//     circle: {
//       strokeLinecap: "round",
//     },
//     innerText: {
//       // maxWidth: "85%",
//     },
//   })
// );

// const SessionProgressBar = ({ state, value }) => {
//   const intl = useIntl();
//   const styles = useGlobalStyles();
//   // const percent = Math.round(value * 100);
//   const classes = useProgressStyle();
//   return (
//     <Box position="relative" display="inline-flex">
//       {/* <CircularProgress
//         variant="determinate"
//         value={percent !== 100 ? percent * 0.6 : percent}
//         size={200}
//         thickness={2.5}
//         color="inherit"
//         classes={classes}
//       /> */}
//       <Box display="flex" alignItems="center" justifyContent="center">
//         <Typography
//           variant="h4"
//           component="div"
//           color="inherit"
//           align="center"
//           className={clsx(styles.bold, classes.innerText)}
//         >
//           {intl.formatMessage({ id: "outgoing_session_state" }, { state })}
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

// const calcProgress = ({ type, state }) => {
//   const steps: SessionState[] = STEPS_BY_TYPE[type] || [];
//   if (steps.length > 0) {
//     const index = steps.indexOf(state);
//     return index === -1
//       ? 0
//       : Big(index + 1)
//           .div(steps.length)
//           .round(2)
//           .toNumber();
//   }
//   return 0;
// };

// const SessionHandoffHeader = ({ place }) => {
//   const session = useSelector(
//     (state: RootState) => sessionSelector(state) as SessionType
//   );
//   const styles = useGlobalStyles();
//   const classes = useHeaderStyles();
//   const progress = calcProgress({ state: session.state, type: session.type });

//   if (!session) return null;

//   return (
//     <Container
//       maxWidth="lg"
//       className={clsx(
//         classes.container,
//         styles.marginBottomMedium,
//         styles.centeredContainer,
//         styles.column
//       )}
//     >
//       <div className={clsx(styles.marginBottomMedium)}>
//         <SessionProgressBar value={progress} state={session.state} />
//       </div>
//       <Typography color="inherit" variant="h5" className={styles.bold}>
//         #{session.orderId}
//       </Typography>
//       <Typography color="inherit" variant="h6">
//         {place.name}
//       </Typography>
//     </Container>
//   );
// };

/*  */

const InformationRow = <T extends ReactElement>({
  Icon,
  Component = HtmlDiv,
  children = null,
  loading = false,
  className = null,
  ...props
}: {
  Component: T;
  Icon: any;
  children: any;
  loading: boolean;
} & T["props"]) => {
  if (!children && !loading) return null;

  return (
    <motion.div variants={item}>
      <Component
        {...props}
        className={clsx(
          "flex flex-row items-center  select-none rtl:text-right text-sm",
          className
        )}
      >
        <Icon className="flex-none ltr:mr-2 rtl:ml-2 w-4 h-4 fill-disabled" />
        <span>{loading ? <TextSkeleton className="w-24" /> : children}</span>
      </Component>
    </motion.div>
  );
};

export const SessionPlaceInfo = ({
  onClose,
  status,
  loading,
  variant = "session",
  place,
}: {
  onClose: any;
  place: TPlace;
  status: any;
  loading?: boolean;
  variant?: "session" | "place";
}) => {
  const intl = useIntl();

  return (
    <div className="p-5">
      <div className="flex flex-row flex-1 items-center justify-between">
        <a
          target="_blank"
          rel="noreferrer"
          href={loading ? "#" : `/p/${place?.id}`}
          className="text-2xl font-medium cursor-pointer"
        >
          {place?.name}
        </a>
      </div>
      <div className="grid grid-cols-1 gap-5 mb-5">
        <p className="text-muted-foreground text-base">{place?.desc}</p>
        <PlaceCategoriesList categories={place?.categories} />
        <div className="grid grid-cols-1 gap-3">
          <InformationRow
            loading={loading}
            Icon={MapIcon}
            children={place?.meta?.address}
          />

          {!!place?.meta?.phone && (
            <InformationRow
              loading={loading}
              Icon={PhoneIcon}
              Component={HtmlAnchor}
              href={`tel:${place.meta.phone}`}
              children={
                <span dir="ltr">
                  {parsePhoneNumber(place.meta.phone)?.number?.national}
                </span>
              }
            />
          )}

          <InstagramStatusRow
            loading={loading}
            username={place?.meta?.instagram}
          />

          <ScheduleStatusRow
            loading={loading}
            schedule={status?.schedule}
            variant={variant}
          >
            {place?.schedule?.map?.((s, i) => {
              return (
                <div key={String(i)}>
                  <p>{scheduleTitle(s, intl)}</p>
                </div>
              );
            })}
          </ScheduleStatusRow>
        </div>
      </div>

      <BottomPageContainer className="fixed sm:absolute bottom-0 w-full">
        <div className="flex flex-row items-center gap-2 pb-5 sm:pb-0">
          {!!place?.bookings?.enabled && (
            <BtnLink
              fullWidth
              Component={HtmlAnchor}
              StartIcon={CalendarIcon}
              target="_blank"
              rel="noreferrer"
              href={`/p/${place.id}/booking`}
              color="primary"
              size="lg"
            >
              {intl.formatMessage({
                id: "place_reservations",
                defaultMessage: "Reservations",
              })}
            </BtnLink>
          )}
          <Btn
            onClick={onClose}
            fullWidth
            color="text"
            className="capitalize"
            variant="light"
            StartIcon={XMarkIcon}
            size="lg"
          >
            {intl.formatMessage({ id: "close" })}
          </Btn>
        </div>
      </BottomPageContainer>
    </div>
  );
};

const UserPreferences = ({ onClose }) => {
  // const theme = useTheme();
  const dispatch = useAppDispatch();
  const prev: TUser["pref"] = useMemo(
    () => userSelector(store.getState())?.pref ?? ({} as any),
    []
  );

  const [state, setstate] = useState({
    allergies: {},
    flavors: {},
    diets: {},
    ...prev,
    changed: false,
    submitting: false,
    error: null,
    hadAny:
      Object.keys(
        JSON.parse(
          JSON.stringify({
            ...(prev?.allergies || {}),
            ...(prev?.flavors || {}),
            ...(prev?.diets || {}),
          })
        )
      )?.length > 0,
  });

  const set = useCallback(
    (type, name, value) => {
      setstate((s) => ({
        ...s,
        [type]: { ...s[type], [name]: value },
        changed: true,
      }));
    },
    [setstate]
  );

  const onSubmit = useCallback(async () => {
    try {
      setstate((s) => ({
        ...s,
        submitting: true,
      }));
      const response = await client.put("/me", {
        pref: {
          allergies: state.allergies,
          flavors: state.flavors,
          diets: state.diets,
        },
      });
      dispatch(onUserUpdate(response.data));
      onClose();
    } catch (error) {
      setstate((s) => ({
        ...s,
        submitting: false,
        error: errorify(error),
      }));
    }
  }, [dispatch, onClose, state]);

  const onReset = useCallback(async () => {
    try {
      setstate((s) => ({
        ...s,
        allergies: {},
        flavors: {},
        diets: {},
        submitting: true,
      }));
      const response = await client.put("/me", {
        pref: {
          allergies: {},
          flavors: {},
          diets: {},
        },
      });
      dispatch(onUserUpdate(response.data));
      onClose();
    } catch (error) {
      setstate((s) => ({
        ...s,
        submitting: false,
        error: errorify(error),
      }));
    }
  }, [dispatch, onClose]);

  const hasAny = useMemo(
    () =>
      Object.keys(
        JSON.parse(
          JSON.stringify({
            ...state.allergies,
            ...state.flavors,
            ...state.diets,
          })
        )
      )?.length > 0,
    [state]
  );

  return (
    <div className="grid grid-cols-1 gap-5">
      <div className="flex flex-row flex-1 items-center justify-between">
        <h5 className="text-2xl font-medium">My Prefrences</h5>
        <CloseIcon onClick={onClose} />
      </div>
      <p className="text-muted-foreground">
        You can set your prefrences here to get more relevant recomendations
        based on your diet, allergies, and flavours.
      </p>

      {/*
      <DialogContent className="my-10">
        <div className={styles.marginVerticalMedium}>
          <Typography variant="subtitle1" className='mb-5'>
            My Flavours
          </Typography>
          {ITEM_FLAVORS.map((f) => (
            <Chip
              disabled={state.submitting}
              variant="outlined"
              classes={{
                root: clsx(
                  styles.chipSpacer,
                  state.flavors[f] === undefined
                    ? undefined
                    : state.flavors[f]
                    ? styles.colorGreen
                    : styles.colorRed
                ),
                label: clsx(styles.textCapitalize, {
                  [styles.bold]: typeof state.flavors[f] === "boolean",
                }),
              }}
              key={f}
              label={f}
              onClick={() =>
                set(
                  "flavors",
                  f,
                  state.flavors[f] === false ? undefined : !state.flavors[f]
                )
              }
              onDelete={() =>
                set(
                  "flavors",
                  f,
                  state.flavors[f] === false ? undefined : false
                )
              }
              deleteIcon={
                <Icon
                  fontSize="small"
                  className={
                    !(state.flavors[f] === false)
                      ? styles.colorDisabled
                      : styles.colorRed
                  }
                >
                  remove_circle
                </Icon>
              }
              icon={
                <Icon
                  fontSize="small"
                  className={
                    !(state.flavors[f] === true)
                      ? styles.colorDisabled
                      : styles.colorGreen
                  }
                >
                  add_circle
                </Icon>
              }
            />
          ))}
        </div>
        <div className={styles.marginVerticalMedium}>
          <Typography variant="subtitle1" className='mb-5'>
            Dietary
          </Typography>
          {DIETS.map((d) => {
            return (
              <Chip
                disabled={state.submitting}
                // color={!state.diets[d] ? "default" : "primary"}
                variant={!state.diets[d] ? "outlined" : "default"}
                classes={{
                  root: styles.chipSpacer,
                  label: clsx(styles.textCapitalize, {
                    [styles.bold]: state.diets[d],
                  }),
                }}
                key={d}
                label={d}
                onClick={() =>
                  set("diets", d, state.diets[d] ? undefined : true)
                }
                clickable
                avatar={<Avatar>{prefSymbols.diets[d]}</Avatar>}
              />
            );
          })}
        </div>
        <div className={styles.marginVerticalMedium}>
          <Typography variant="subtitle1">Allergies</Typography>
          <div className='mb-5'>
            <Typography variant="caption" color="textSecondary">
              Full allergen information on the ingredients in the food we serve
              is available upon request –
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              className={styles.textUppercase}
            >
              please consult to a member of the staff if you have food allergie
              and do not order online.
            </Typography>
          </div>
          {ALLERGIES.map((a) => {
            const emoji = prefSymbols.allergies[a]
              ? prefSymbols.allergies[a] + " "
              : "";

            return (
              <Chip
                // color={!state.allergies[a] ? "default" : "secondary"}
                variant={!state.allergies[a] ? "outlined" : "default"}
                disabled={state.submitting}
                classes={{
                  root: styles.chipSpacer,
                  label: clsx(styles.textCapitalize, {
                    [styles.bold]: state.allergies[a],
                  }),
                }}
                key={a}
                label={`${emoji}${a}`}
                clickable
                onClick={() =>
                  set("allergies", a, state.allergies[a] ? undefined : true)
                }
              />
            );
          })}
        </div>
      </DialogContent>
      <Divider />
    */}
      <div className="flex flex-row flex-wrap items-center">
        {hasAny && state.hadAny && (
          <Btn disabled={state.submitting} onClick={onReset} color="primary">
            Reset
          </Btn>
        )}
        {!state.changed && (
          <Btn disabled={state.submitting} onClick={onClose} color="primary">
            Close
          </Btn>
        )}
        {state.changed && !(!hasAny && !state.hadAny) && (
          <Btn
            onClick={onSubmit}
            variant="contained"
            color="primary"
            disabled={state.submitting}
            loading={state.submitting}
          >
            Save
          </Btn>
        )}
        <div className="safe-area-bottom" />
      </div>
    </div>
  );
};

const ScheduleStatusRow = ({
  schedule,
  loading,
  variant = "session",
  children = null,
}) => {
  const intl = useIntl();
  return (
    <InformationRow Icon={ClockIcon} loading={loading}>
      {loading && !schedule ? null : (
        <div>
          <div className="flex flex-row gap-2">
            <span
              className={clsx(
                "capitalize",
                variant === "session"
                  ? schedule?.status
                    ? "text-green-600"
                    : "text-red-500"
                  : null
              )}
            >
              {intl.formatMessage({
                id: schedule?.status ? "opened" : "closed",
              })}
            </span>
            {!!schedule?.closeAt && (
              <>
                <span className="text-muted-foreground">&middot;</span>
                <span className="text-muted-foreground">
                  {intl.formatMessage(
                    { id: "closes_at", defaultMessage: "closes at {at}" },
                    { at: intl.formatTime(schedule?.closeAt) }
                  )}
                </span>
              </>
            )}
          </div>
          {children}
        </div>
      )}
    </InformationRow>
  );
};

const InstagramStatusRow = ({ username, loading }) => {
  if (!username) return null;

  return (
    <InformationRow
      loading={loading}
      Icon={({ ...props }) => (
        <InstagramIcon
          {...props}
          className="flex-none ltr:mr-2 rtl:ml-2 w-4 h-4 stroke-disabled fill-none"
        />
      )}
      href={`https://www.instagram.com/${username}`}
      Component={HtmlAnchor}
      target="_blank"
      rel="noreferrer"
    >
      {username}
    </InformationRow>
  );
};

export const SessionHeaderInfo = ({
  place,
  status,
  loading = null,
  variant = "session",
}: {
  place: TPlace;
  status: any;
  loading?: boolean;
  variant?: "session" | "place";
}) => {
  const infoToggle = useToggle(false);
  const intl = useIntl();
  const langDialog = useLanguageDialog();
  const preferencesToggle = useToggle(false);
  const isAdmin = useAppSelector(isAdminSelector);

  const meta = place?.meta;
  const address = meta?.address;
  const phone = meta?.phone;

  return (
    <PageContainer className="w-full py-5">
      <div className="flex flex-col gap-2">
        <InformationRow
          Icon={MapIcon}
          width={200}
          loading={loading}
          children={address}
        />

        {!!place?.meta?.instagram && (
          <InstagramStatusRow
            loading={loading}
            username={place?.meta?.instagram}
          />
        )}
        <ScheduleStatusRow
          loading={loading}
          schedule={status?.schedule}
          variant={variant}
        />
        <div className="flex flex-row items-center justify-between">
          <div className="flex gap-2 flex-row items-center">
            <motion.div variants={item}>
              <Btn
                size="xs"
                color="text"
                variant="outline"
                // startIcon={<Icon>info</Icon>}
                onClick={infoToggle.onOpen}
                disabled={loading}
              >
                {intl.formatMessage({ id: "more_info" })}
              </Btn>
            </motion.div>
            <motion.div variants={item}>
              <LanguageIcon
                onClick={langDialog.onOpen}
                className="w-4 h-4 fill-disabled hover:fill-current cursor-pointer"
              />
            </motion.div>
          </div>
          {isAdmin && variant === "session" && (
            <motion.div variants={item}>
              <Btn
                size="xs"
                color="text"
                variant="outline"
                StartIcon={StarIcon}
                onClick={preferencesToggle.onOpen}
                disabled={loading}
              >
                {intl.formatMessage({ id: "my_preferences" })}
              </Btn>
            </motion.div>
          )}
        </div>
      </div>

      <BottomSheet
        onDismiss={infoToggle.onClose}
        open={Boolean(infoToggle.isOpen)}
        snapPoints={({ minHeight }) => [minHeight]}
      >
        <SessionPlaceInfo
          place={place}
          loading={loading}
          onClose={infoToggle.onClose}
          status={status}
          variant={variant}
        />
      </BottomSheet>

      <Modal
        onClose={preferencesToggle.onClose}
        open={preferencesToggle.isOpen as boolean}
        maxWidth="lg"
        fullScreen="sm"
      >
        <UserPreferences onClose={preferencesToggle.onClose} />
      </Modal>
    </PageContainer>
  );
};

const SessionHeader = () => {
  const { status, loading } = useSession();
  const place = useAppSelector(sessionPlaceSelector);
  if (status?.handoff && !loading) return null;
  return <SessionCover status={status} place={place} loading={loading} />;
};

export default SessionHeader;
