import { SessionDrawerNavigationProvider } from "../contexts/SessionDrawerNavigationContext";
import { SessionContextProvider } from "../contexts/SessionContext";
import { SessionDrawerProvider } from "../contexts/SessionDrawerContext";
import { SessionItemModalProvider } from "../contexts/SessionItemModalContext";
import { SessionSocketProvider } from "../contexts/SessionSocketProvider";
import SessionContent from "./SessionContent";
import SessionHeader from "./SessionHeader";
import { SessionCheckoutProvider } from "../contexts/SessionCheckoutContext";

const SessionOrderable = () => (
  <div className="flex-auto flex h-full flex-col">
    <SessionHeader />
    <SessionContent />
  </div>
);

const SessionScreen = () => {
  return (
    <div className="flex-auto flex h-full flex-col">
      <SessionCheckoutProvider>
        <SessionDrawerNavigationProvider>
          <SessionContextProvider>
            <SessionItemModalProvider>
              <SessionDrawerProvider>
                <SessionSocketProvider>
                  <SessionOrderable />
                </SessionSocketProvider>
              </SessionDrawerProvider>
            </SessionItemModalProvider>
          </SessionContextProvider>
        </SessionDrawerNavigationProvider>
      </SessionCheckoutProvider>
    </div>
  );
};

export default SessionScreen;
