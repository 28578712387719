import { modifierStyle, modifyOptionType } from "@gethere/common/enums";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronDoubleRightIcon,
  XMarkIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";
import { useIntl } from "react-intl";
import modifierSelectDescription from "../utils/modifierSelectDescription";

const ModifiersOptions = ({
  modifierId,
  modifier,
  currency,
  state,
  disabled,
  handleUpdate,
  style,
}) => {
  const intl = useIntl();
  const options = modifier.options?.values;

  return (
    <div
      className="grid grid-cols-2 gap-3"
      // style={{
      //   flexDirection: "row",
      //   flexWrap: "wrap",
      //   // paddingHorizontal: styleUnits.spaces.giant,
      // }}
    >
      {options?.map((option) => {
        const name =
          style === modifierStyle.SELECT
            ? option.displayName || option.name
            : intl.formatMessage(
                {
                  id: "modifier_verbal_descriptor",
                  defaultMessage:
                    "{type, select, EXTRA {Extra {value}} LESS {Less {value}} SIDE {{value} Aside} WITH {With {value}} WITHOUT {No {value}} other {{value} {type}}}",
                },
                {
                  value: option.displayName || option.name,
                  type: state?.[option.id],
                }
              );

        const selected =
          style === modifierStyle.SELECT
            ? state[option.id] > 0
            : option.default !== state[option.id];

        const multi =
          style === modifierStyle.SELECT &&
          modifier.options.min > 0 &&
          modifier.options.max > 1;

        const count = Object.values(state).reduce(
          (prev: number, curr: number) =>
            (isNaN(prev) ? 0 : Number(prev)) + (isNaN(curr) ? 0 : Number(curr)),
          0
        ); // total amount of modifiers selected

        const onClick = () => {
          if (style === modifierStyle.SELECT) {
            if (multi) {
              const next = state[option.id] + 1;
              const optionMax = next > option.max;
              const modifierMax = modifier.options.max <= count;
              const isMax = optionMax || modifierMax;
              return handleUpdate(
                modifierId,
                option.id,
                isMax ? option.min || 0 : next
              );
            } else {
              return handleUpdate(modifierId, option.id, selected ? 0 : 1);
            }
          } else if (style === modifierStyle.VERBAL) {
            const poss = option.possibilities?.map?.((p) => p.type);
            const pIndex = poss.indexOf(state[option.id]);
            let type = poss[0];
            if (pIndex >= 0 && poss.length - 1 > pIndex) {
              type = poss[pIndex + 1];
            }

            return handleUpdate(modifierId, option.id, type);
          }
        };

        // price;

        let price = null;

        if (style === modifierStyle.VERBAL) {
          const poss = option.possibilities.find(
            (p) => p.type === state[option.id]
          );
          if (poss.price) price = poss.price;
        } else if (option.price) {
          price = option.price;
        }

        if (price > 0) {
          price =
            "+ " +
            intl.formatNumber(price, {
              currency,
              style: "currency",
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            });
        }

        let ModifierIcon = null;
        let Icon;

        if (style === modifierStyle.SELECT) {
          if (multi) {
            // mutliModifierAmount: {
            //   color: theme.palette.grey[700],
            //   background: theme.palette.grey[200],
            //   fontSize: theme.typography.body1.fontSize + "!important",
            //   fontWeight: 800,
            //   paddingRight: theme.spacing(1),
            //   paddingLeft: theme.spacing(1),
            //   borderRadius: theme.shape.borderRadius,
            // },
            // selectedModifier: {
            //   color: theme.palette.secondary.main,
            //   background: theme.palette.secondary.contrastText,
            // },
            if (selected || state[option.id] !== 0) {
              ModifierIcon = (
                <span
                  className={clsx(
                    "ltr:mr-2 rtl:ml-2 font-medium",
                    !disabled && selected
                      ? "text-primary"
                      : "text-gray-500 dark:bg-gray-700"
                  )}
                >
                  x{state[option.id]}
                </span>
              );
            }
          } else {
            if (selected) {
              Icon = CheckIcon;
            } else {
              Icon = XMarkIcon;
            }

            ModifierIcon = Icon ? (
              <Icon className="ltr:mr-2 rtl:ml-2 w-4 h-4" />
            ) : null;
          }
        } else if (style === modifierStyle.VERBAL) {
          switch (state[option.id]) {
            case modifyOptionType.WITH:
              Icon = CheckIcon;
              break;
            case modifyOptionType.EXTRA:
              Icon = ChevronDoubleUpIcon;
              break;
            case modifyOptionType.WITHOUT:
              Icon = XMarkIcon;
              break;
            case modifyOptionType.LESS:
              Icon = ChevronDoubleDownIcon;
              break;
            case modifyOptionType.SIDE:
              Icon = ChevronDoubleRightIcon;
              break;
            default:
              return null;
          }

          ModifierIcon = Icon ? (
            <Icon className="ltr:mr-2 rtl:ml-2 w-4 h-4" />
          ) : null;
        }

        return (
          <button
            // disableElevation
            // disableRipple
            type="button"
            key={option.id}
            // color={selected ? "secondary" : "default"}
            // variant={selected ? "contained" : "outlined"}
            disabled={disabled}
            // className={clsx([styles.sessionModifierOptionButton])}
            onClick={onClick}
            className={clsx(
              !disabled // available
                ? "bg-card shadow-sm cursor-pointer"
                : "bg-gray-50 text-gray-500 dark:bg-gray-700 dark:text-gray-500 cursor-not-allowed",
              selected ? "ring-2 ring-primary" : "",
              "group border border-opacity-30 border-gray-500 relative py-3 px-4 flex items-center justify-center text-sm font-medium hover:bg-gray-50 dark:hover:bg-gray-800 focus:outline-none sm:flex-1",
              style === modifierStyle.VERBAL && "underline",
              "rounded-md flex flex-row items-center justify-between"
            )}
          >
            <span className="flex flex-row items-center">
              {ModifierIcon}
              {name}
            </span>
            <span
              className={clsx(
                !disabled && "dark:text-gray-300 text-gray-700 font-normal"
              )}
            >
              {price}
            </span>
          </button>
        );
      })}
    </div>
  );
};

const ItemModifier = ({
  modifier,
  state,
  base,
  currency,
  handleUpdate,
  horizontalPadding = true,
  disabled,
  collapsable = true,
}: {
  modifier: any;
  state: any;
  base: any;
  currency: any;
  handleUpdate: any;
  horizontalPadding?: boolean;
  disabled?: boolean;
  collapsable?: boolean;
}) => {
  const intl = useIntl();

  const [s, setState] = useState({
    open: true,
    option: null,
  });

  const name = modifier.displayName || modifier.name;
  let short = "";
  let description;

  if (modifier.style === modifierStyle.SELECT) {
    const { values, min, max } = modifier.options;
    short = values
      ?.filter((option) => state?.[option.id] > 0)
      .map(
        (option) =>
          `${state?.[option.id] > 1 ? state?.[option.id] + " " : ""}${
            option.displayName || option.name
          }`
      )
      .join(", ");

    description = modifierSelectDescription(intl, { min, max });
  } else if (modifier.style === modifierStyle.VERBAL) {
    short = modifier.options?.values
      ?.filter((option) => state?.[option.id] !== base?.[option.id])
      .map((option) =>
        intl.formatMessage(
          {
            id: "modifier_verbal_descriptor",
            defaultMessage:
              "{type, select, EXTRA {Extra {value}} LESS {Less {value}} SIDE {{value} Aside} WITH {With {value}} WITHOUT {No {value}} other {{value} {type}}}",
          },
          { value: option.displayName || option.name, type: state?.[option.id] }
        )
      )
      .join(", ");
  }

  const ToggleIcon = !s.open ? ChevronDownIcon : ChevronUpIcon;

  return (
    <>
      <div
        onClick={() => collapsable && setState({ open: !s.open, option: null })}
        className={clsx("cursor-pointer", horizontalPadding && "px-5")}
      >
        <div className="flex flex-row justify-between items-center py-2">
          <div className="rtl:text-right">
            <h5 className="text-base font-bold">{name}</h5>
            {!!modifier.desc && (
              <p className="text-muted-foreground text-sm">{modifier.desc}</p>
            )}
            {s.open && !!description && (
              <p className="text-muted-foreground text-sm">{description}</p>
            )}
            {!s.open && !!short && (
              <p className="text-muted-foreground text-sm">{short}</p>
            )}
          </div>
          {collapsable && <ToggleIcon className="w-5 h-5 stroke-current" />}
        </div>
      </div>
      {s.open && (
        <div className={clsx(horizontalPadding && "px-5")}>
          <ModifiersOptions
            handleUpdate={handleUpdate}
            modifierId={modifier.id}
            style={modifier.style}
            modifier={modifier}
            currency={currency}
            state={state}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
};

export default ItemModifier;
