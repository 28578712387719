import { useIntl } from "react-intl";
// import { useSelector } from "react-redux";
// import { RootState } from "../state/store";
// import { useHistory } from "react-router";
import Alert from "./Alert";

export default function UnauthorizedScreen() {
  const intl = useIntl();

  // const history = useHistory();

  // const { user } = useAppSelector((state) => ({
  //   user: state.user.result,
  // }));

  return (
    <div className="flex w-full h-full content-center justify-center items-center">
      <img
        alt="no-perm"
        src="/pale-no-perm.png"
        style={{ maxWidth: "400px", width: "60vw", marginBottom: "20px" }}
      />
      <Alert
        type="danger"
        // action={
        //   !user ? (
        //     <Button color="inherit" onClick={() => history.push("login")}>
        //       <Box fontWeight="fontWeightBold">
        //         {intl.formatMessage({ id: "sign_in" })}
        //       </Box>
        //     </Button>
        //   ) : null
        // }
      >
        {intl.formatMessage({ id: "auth_type_unauthorized" })}
      </Alert>
    </div>
  );
}
