import * as React from "react";
import { Link } from "react-router-dom";
import { cn } from "@gethere/web/lib/utils";

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@gethere/web/components/ui/navigation-menu";
import { PhoneIcon } from "@heroicons/react/24/outline";
import { site_solutions } from "./links";
import { useIntl } from "react-intl";

export function DesktopSiteLinks() {
  const intl = useIntl();
  return (
    <NavigationMenu className="hidden md:block">
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Solutions</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-4">
                <NavigationMenuLink asChild>
                  <Link
                    className="flex group h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                    to="/pages/contact"
                  >
                    <PhoneIcon className="h-6 w-6" />
                    <div className="mb-2 mt-4 text-lg font-medium group-hover:text-foreground">
                      Schedule demo
                    </div>
                    <p className="text-sm leading-tight text-muted-foreground group-hover:text-foreground/80">
                      Let's talk about how we can help your business.
                    </p>
                  </Link>
                </NavigationMenuLink>
              </li>
              {site_solutions.map(({ name, Icon, description, href }) => (
                <ListItem href={href} title={name} key={href}>
                  {description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink className={navigationMenuTriggerStyle()} asChild>
            <Link to="/pages/pricing">
              {intl.formatMessage({
                id: "pricing_title",
                defaultMessage: "Pricing",
              })}
            </Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink asChild className={navigationMenuTriggerStyle()}>
            <Link to="/pages/contact">
              {intl.formatMessage({
                id: "wpa_link_contact",
                defaultMessage: "Contact",
              })}
            </Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & {
    Icon?: React.ComponentType<{ className?: string }>;
  }
>(({ className, title, children, Icon, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block group select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-3 text-sm leading-snug text-muted-foreground group-hover:text-foreground/80">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});

ListItem.displayName = "ListItem";
