import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "@sentry/react";
import App from "./App";

export function setWithExpiry(key, value, ttl) {
  if (window?.localStorage) {
    const item = {
      value: value,
      expiry: new Date().getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
}

export function getWithExpiry(key) {
  if (window?.localStorage) {
    const itemString = window.localStorage.getItem(key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }
}

const onAppError = (
  error: Error,
  componentStack: string,
  eventId: string
): void => {
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;
  if (error?.message && chunkFailedMessage.test(error.message)) {
    if (!getWithExpiry("chunk_failed")) {
      setWithExpiry("chunk_failed", "true", 10000);
      window?.location?.reload?.();
    }
  }
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ErrorBoundary fallback={null} onError={onAppError}>
    <App />
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
