import { captureException } from "@sentry/react";
import { useCallback, useState } from "react";

// set this hook to also accept function for default value like react.setState
function useToggle<T>(initialState: T | (() => T)) {
  const [isOpen, setOpen] = useState(() => {
    if (
      typeof initialState === "function" &&
      initialState instanceof Function
    ) {
      try {
        const result =
          typeof initialState === "function"
            ? (initialState as () => T)()
            : initialState;

        return result;
      } catch (e) {
        captureException(e);
        return false;
      }
    }
    return initialState;
  });

  const onOpen = useCallback(
    (_?: any, key?: T) => {
      setOpen(key ? key : (true as any));
    },
    [setOpen]
  );

  const onClose = useCallback(() => {
    setOpen(null);
  }, [setOpen]);

  return { onOpen, onClose, isOpen: !!isOpen, value: isOpen };
}

export default useToggle;
