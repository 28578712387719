import { filterItemPromotions } from "@gethere/common/utilities";
import { TItem } from "@gethere/common/yup/Item";
import { TagIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useCallback, useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { LazyPicture } from "../components/Picture";
import { useSession } from "../contexts/SessionContext";
import SessionItemModalContext from "../contexts/SessionItemModalContext";
import { RootState } from "../state/store";

const thumb = { width: 150, height: 150 };

const RoundIcon = ({ className = "", children }) => (
  <span
    className={clsx(
      "text-[9px] w-[16px] h-[16px] inset-0 flex items-center justify-center justify-items-center rounded-full p-1 font-bold",
      className
    )}
  >
    <span>{children}</span>
  </span>
);

const SessionCollectionListItem = ({
  itemId,
  currency,
  soldOut,
}: {
  itemId: string;
  currency: string;
  soldOut: boolean;
}) => {
  const intl = useIntl();
  const { status, type } = useSession();
  const { promotions } = useSession();
  const item = useSelector((s: RootState) => {
    return s.session?.entities?.items?.[itemId] as TItem;
  });
  const { openCollectionItemModal } = useContext(SessionItemModalContext);

  const promos = useMemo(() => {
    return filterItemPromotions({
      item,
      promotions,
      at: new Date().toISOString(),
      promoCodeIds: status.promoCodeIds,
      discounts: [],
    });
  }, [promotions, status.promoCodeIds]);

  const eligble = Array.isArray(item?.sessionTypes)
    ? item.sessionTypes.includes(type)
    : true;

  const disabled = !eligble || soldOut;

  const onPress = useCallback(() => {
    if (disabled) return;
    openCollectionItemModal(itemId);
  }, [disabled, openCollectionItemModal]);

  if (!item) return null;

  const vegiterian = item?.meta?.diets?.vegetarian;
  const vegan = item?.meta?.diets?.vegan;

  return (
    <div
      className="md:bg-card md:rounded-md md:drop-shadow-sm overflow-hidden flex w-full flex-row cursor-pointer align-center justify-between border-opacity-10 border-gray-500"
      onClick={onPress}
    >
      <div className="flex flex-col overflow-hidden text-ellipsis grow content-between h-full py-4 mx-5">
        <div className="flex flex-row gap-1 items-center">
          <h6
            className={clsx(
              "text-base font-medium",
              disabled && "text-muted-foreground"
            )}
          >
            {item.displayName || item.name}
          </h6>
        </div>
        <div className="text-ellipsis overflow-hidden -mr-5 pr-5 w-auto max-h-10">
          <span className="capitalize text-xs font-medium flex flex-row gap-3">
            {soldOut ? (
              <span className="text-rose-500">Sold Out</span>
            ) : !eligble ? (
              <span className="text-rose-500">Not Available for {type}</span>
            ) : null}
          </span>
          <p className="text-muted-foreground text-sm">{item.desc}</p>
        </div>
        <div style={{ display: "flex", flexGrow: 1 }}></div>
        <div className="justify-self-end flex flex-row items-center justify-between">
          <div className="justify-self-end">
            {item.price > 0 && (
              <div className="flex flex-row items-center gap-2">
                <span className=" font-normal text-sm ">
                  {intl.formatNumber(item.price, {
                    style: "currency",
                    currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </span>
                {promos.length > 0 && (
                  <span className="flex whitespace-nowrap flex-none bg-rose-300/20 text-rose-600 dark:text-rose-300 p-1 rounded items-center flex-row gap-1">
                    <TagIcon className="w-4 h-4 flex-none" />
                    <span className="text-xs font-medium">Promotion</span>
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-row items-center gap-1 max-h-fit">
            {vegiterian && !vegan && (
              <RoundIcon className="bg-green-200">V</RoundIcon>
            )}
            {vegan && (
              <RoundIcon className="bg-green-500 text-white">VE</RoundIcon>
            )}
            {item?.meta?.allergies?.gluten === false && (
              <RoundIcon className="bg-amber-500 text-white">GF</RoundIcon>
            )}
          </div>
        </div>
      </div>
      <div
        className="flex flex-none relative"
        style={{
          height: "100%",
          minHeight: thumb.height,
          width: thumb.width,
        }}
      >
        {Boolean(item.images[0]) && (
          <LazyPicture
            height={thumb.height}
            width={thumb.width}
            offset={1000}
            className={clsx(
              "w-full h-full object-center object-cover",
              disabled && "filter grayscale opacity-50"
            )}
            srcset={item.images[0]}
            sourceHeight={600}
            sourceWidth={600}
            alt={item.name}
          />
        )}
      </div>
    </div>
  );
};

export default SessionCollectionListItem;
