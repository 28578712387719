import { CheckIcon } from "@heroicons/react/20/solid";
import {
  ArrowRightIcon,
  BookmarkSquareIcon,
  ChevronRightIcon,
  CubeIcon,
  FingerPrintIcon,
  FlagIcon,
  QrCodeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { BtnLink } from "../components/Btn";
import CardsSvg, { VisaSvg } from "../components/CardsSvg";
import { IpadMockImage, IphoneMockImage } from "../components/DeviceMocks";
import BottomCallToAction from "../components/home/BottomCallToAction";
import { LazyPicture } from "../components/Picture";
import { useAppSelector } from "../state/hooks";
import { RootState } from "../state/store";
import Faq from "./Faq";
import TestemonialsBlock from "./TestemonialsBlock";

const FeatureBlock = ({ Icon, title, desc }) => {
  return (
    <div className="flex flex-row items-start justify-center content-center mb-5 group">
      <div className="rounded-xl p-3 flex items-center justify-center bg-primary-100 group-hover:bg-primary dark:bg-primary dark:group-hover:bg-primary duration-500 w-12 h-12">
        <Icon className="text-primary group-hover:text-primary-foreground duration-300 w-6 h-6" />
      </div>
      <span className="px-5 max-w-sm w-full">
        <h5 className="text-gray-800 dark:text-gray-200 font-medium text-2xl mb-5">
          {title}
        </h5>
        <p className="text-gray-600 dark:text-gray-400 text-base">{desc}</p>
      </span>
    </div>
  );
};

const MainFeatures = () => {
  return (
    <div className="max-w-screen-xl self-center w-full px-5 xl:px-0">
      <div className="flex flex-col  px-5 py-10 lg:py-20">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          <FeatureBlock
            Icon={UsersIcon}
            title="Intuitive"
            desc="Ordering and payments should be easy. We'll help you work more efficiently and have happier clients."
          />
          <FeatureBlock
            Icon={FingerPrintIcon}
            title="Personalization"
            desc="Each customer has their own preferences, we help you make your offerings most relevant to them."
          />

          <FeatureBlock
            Icon={FlagIcon}
            title="Ecosystem"
            desc="A single platform to manage all your orders, payments, bookings, tables, employees and much more!"
          />
        </div>
      </div>
    </div>
  );
};

function IPadShape({ ...props }) {
  const isDark = useAppSelector((state) => state.system.theme === "dark");

  const srcset = !isDark
    ? {
        type: "image/png",
        src: "https://static.tableport.io/il6np73l5000109ml5n9036fx.png",
        webp: "https://static.tableport.io/il6np73l5000109ml5n9036fx.webp",
      }
    : {
        type: "image/png",
        src: "https://static.tableport.io/il6np23wt000009mlh3ikf97g.png",
        webp: "https://static.tableport.io/il6np23wt000009mlh3ikf97g.webp",
      };

  return (
    <LazyPicture
      offset={800}
      {...props}
      alt="pos"
      srcset={srcset}
      height="auto"
      width="80%"
    />
  );
}

const BULLET_POINTS = [
  "5 Minute setup",
  "Zero upfront cost",
  "No credit card required",
];

const VisaInnovation = () => {
  const intl = useIntl();

  return (
    <p className="flex w-full uppercase tracking-widest font-medium justify-center md:justify-start row items-center text-xs text-gray-500 dark:text-gray-500">
      {intl.formatMessage(
        { id: "wpa_visa_innovation" },
        {
          visa: <VisaSvg width={62} height={40} gray={true} />,
        }
      )}
    </p>
  );
};

// const HomeBlock = ({ children }) => (
//   <div className="self-center px-5 container my-10 flex flex-col md:flex-row items-center">
//     {children}
//   </div>
// );

const HomeHero = () => {
  const intl = useIntl();

  return (
    <div className="mx-auto max-w-screen-xl px-5 container my-5 flex flex-col md:flex-row">
      <div className="flex flex-col text-left justify-center gap-10 my-10">
        <h1 className="text-5xl sm:text-6xl md:text-7xl tracking-tight font-medium text-gray-900 dark:text-gray-50 max-w-4xl">
          {intl.formatMessage(
            {
              id: "wpa_welcom_title",
              defaultMessage:
                "The all-in-one restaurants POS - that works for you",
            },
            { n: <br /> }
          )}
        </h1>
        <p className="text-lg text-gray-500 dark:text-gray-400 sm:text-xl md:text-2xl max-w-prose">
          {intl.formatMessage({ id: "wpa_welcom_description" })}
        </p>
        <div className="inline-flex flex-wrap items-center gap-x-5 place-content-center md:place-content-start">
          {BULLET_POINTS.map((point, i) => (
            <div
              className="flex flex-row items-center gap-x-1"
              key={i.toString()}
            >
              <CheckIcon className="fill-green-600 dark:fill-green-400 w-4 h-4" />
              <p className="text-sm">{point}</p>
            </div>
          ))}
        </div>
        <div className="flex place-content-center md:place-content-start flex-col sm:flex-row gap-2">
          <BtnLink size="xl" to="/join" StartIcon={ChevronRightIcon}>
            {intl.formatMessage({
              id: "wpa_join_now",
              defaultMessage: "Get started for free",
            })}
          </BtnLink>
          <BtnLink
            size="xl"
            to="/pages/contact"
            color="default"
            variant="outline"
          >
            {intl.formatMessage({
              id: "wpa_book_demo",
              defaultMessage: "Book a demo",
            })}
          </BtnLink>
        </div>
        <div className="flex flex-row flex-wrap">
          <VisaInnovation />
        </div>
      </div>
    </div>
  );
};

const ServiceBlock = ({ Icon, title, desc, to, image = null }) => {
  return (
    <Link
      to={to}
      className="rounded-xl flex flex-row items-center justify-center ring-1 hover:shadow-lg overflow-hidden ring-zinc-200 dark:ring-zinc-700 shadow-md md:flex-4  bg-card duration-500 hover:bg-zinc-100 dark:hover:bg-zinc-800 group"
    >
      <div className="py-10 px-10 gap-5 grid items-start justify-start w-[70%]">
        <div className="flex self-start duration-500">
          <Icon className="w-7 h-7 stroke-zinc-400 group-hover:stroke-zinc-700 dark:group-hover:stroke-zinc-200 group-hover:scale-110" />
        </div>
        <h6 className="duration-500 text-3xl font-medium dark:text-zinc-400 text-zinc-600 group-hover:text-zinc-900 dark:group-hover:text-zinc-100">
          {title}
        </h6>
        <p className="text-base text-slate-500 dark:text-zinc-200 group-hover:text-slate-800 dark:group-hover:text-slate-100">
          {desc}
        </p>
        <span className="flex gap-2 flex-row items-center">
          <ArrowRightIcon className="w-0 group-hover:w-3 h-3 duration-200 stroke-gray-500 group-hover:stroke-blue-500" />
          <span className="font-medium text-sm text-gray-500 group-hover:text-blue-500 group-hover:underline">
            Learn More
          </span>
        </span>
      </div>
      <div className="relative justify-items-center justify-center items-center self-center w-[30%] flex-none">
        <IphoneMockImage
          alt={title}
          image={image}
          className="absolute -right-10 group-hover:-right-5 group-hover:scale-125 duration-500 ease-in-out m-auto"
        />
      </div>
    </Link>
  );
};

const ExplainsBlock = () => {
  const intl = useIntl();
  return (
    <div className="max-w-screen-xl self-center w-full px-5 xl:px-0">
      <div className="rounded-xl flex flex-row items-center justify-center ring-1 hover:shadow-lg overflow-hidden ring-zinc-200 dark:ring-zinc-700 shadow-md md:flex-4  bg-card duration-500 hover:bg-zinc-100 dark:hover:bg-zinc-800 group">
        <div className="flex flex-col lg:flex-row items-center justify-center">
          <div className="flex flex-1 lg:flex-2 items-center justify-center place-content-center place-items-center">
            {/* <IPadShape className="mx-auto group-hover:scale-125 group-hover:-rotate-3 duration-500" /> */}
            <IpadMockImage
              image={{
                src: "https://static.tableport.io/images/iltebsi4a000008k198tm0l0g.png",
                webp: "https://static.tableport.io/images/iltebsi4a000008k198tm0l0g.webp",
                type: "image/png",
              }}
              className="transform group-hover:-rotate-1 duration-300 scale-75 group-hover:scale-90"
            />
          </div>
          <div className="flex flex-1 lg:flex-2 flex-col p-10">
            <h4 className="text-4xl font-medium mb-5 max-w-lg">
              {intl.formatMessage({ id: "wpa_explains_block_title" })}
            </h4>
            <p className="text-lg font-normal">
              {intl.formatMessage(
                { id: "wpa_explains_block_text" },
                {
                  b: (str) => <span className="font-medium">{str}</span>,
                  br: <span className="block my-2" />,
                }
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const CollectPaymentsBlock = () => {
  return (
    <div className="max-w-screen-xl self-center w-full px-5 xl:px-0">
      <div className="rounded-xl lighter-bg-1 group-hover:darker-bg-1 shadow-md group text-center flex flex-col p-10 text-white ring-1 hover:shadow-lg overflow-hidden ring-zinc-200 dark:ring-zinc-700">
        <div className="flex flex-col items-center justify-center">
          <h4 className="text-5xl font-medium my-5 max-w-lg drop-shadow">
            Collect payments, superfast.
          </h4>
          <p className="text-xl max-w-prose drop-shadow-sm mb-5">
            Customers can use their digital wallets (like Apple Pay or Google
            Pay) or securely store their cards for a fast and secure checkout
            experience.
          </p>
        </div>
        <div className="overflow-hidden w-full grayscale">
          <CardsSvg />
        </div>
      </div>
    </div>
  );
};

const ServicesBlocks = () => {
  return (
    <div className="max-w-screen-xl self-center w-full px-5 xl:px-0">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
        <ServiceBlock
          Icon={CubeIcon}
          desc="The next-generation point-of-sale software is here"
          to="/pages/pos"
          title="Point of Sale"
          image={{
            src: "https://static.tableport.io/images/ilteb0ht2000008l2a1mngisr.png",
            webp: "https://static.tableport.io/images/ilteb0ht2000008l2a1mngisr.webp",
            type: "image/png",
          }}
        />
        <ServiceBlock
          Icon={QrCodeIcon}
          desc="Collect orders and payments directly from customers, even when you're short on staff"
          to="/pages/order-and-pay"
          title="Order & Pay"
          image={{
            src: "https://static.tableport.io/images/il82teu3a000109mlhlj0557l.png",
            webp: "https://static.tableport.io/images/il82teu3a000109mlhlj0557l.webp",
            type: "image/png",
          }}
        />
        <ServiceBlock
          Icon={BookmarkSquareIcon}
          desc="Table reservation service that will help you reduce no-shows and get more bookings"
          to="/pages/reservations"
          title="Reservations"
          image={{
            src: "https://static.tableport.io/images/ilteb8xhf000108jr84jc5tki.png",
            webp: "https://static.tableport.io/images/ilteb8xhf000108jr84jc5tki.webp",
            type: "image/png",
          }}
        />
      </div>
    </div>
  );
};

const CountryPath = () => {
  const params = useParams<{ country: string }>();
  const history = useHistory();

  const ready = useAppSelector((s) => s._persist.rehydrated);
  const country = useAppSelector((s) => s.system.country);

  useEffect(() => {
    if (ready && params.country) {
      if (params.country !== country) {
        history.push("/" + country?.toLowerCase?.());
      }
    }
  }, [params.country, country, ready, history]);

  return null;
};

const HomeScreen = () => {
  return (
    <div className="flex flex-col">
      <CountryPath />
      <HomeHero />
      <div className="flex flex-col gap-y-5 z-0">
        <ServicesBlocks />
        <CollectPaymentsBlock />
        <ExplainsBlock />
        <MainFeatures />
        <TestemonialsBlock />
        <Faq />
      </div>
      <BottomCallToAction />
    </div>
  );
};

//

export default HomeScreen;
