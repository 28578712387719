import { SUPPORTED_COUNTRIES } from "@gethere/common/countries";
import { CountryCodes } from "@gethere/common/enums";
import loadable from "@loadable/component";
import * as Sentry from "@sentry/react";
import { captureException } from "@sentry/react";
import "firebase/analytics";
import React, { useEffect } from "react";
import { ToastBar, Toaster } from "react-hot-toast";
import { Provider, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import NotFoundScreen from "./components/NotFoundScreen";
import UnauthorizedScreen from "./components/UnauthorizedScreen";
import HomeScreen from "./containers/HomeScreen";
import JoinLayout from "./containers/JoinLayout";
import PoweredBy from "./containers/SessionPoweredBy";
import SessionScreen from "./containers/SessionScreen";
import SiteHeader from "./containers/SiteHeader";
import { BusinessProvider } from "./contexts/BusinessContext";
import { LanguageDialogProvider } from "./contexts/LanguageContext";
import SessionThemeProvider from "./contexts/SessionThemeProvider";
import { useTheme } from "./contexts/ThemeContext";
import { UserContextProvider, useUser } from "./contexts/UserContext";
import { setCountry } from "./state/reducers/system";
import { isAdminUserSelector, userSelector } from "./state/reducers/user";
import store from "./state/store";
import env, { appVersion } from "./utils/env";
import usePrevious from "./utils/usePrevious";

import "react-spring-bottom-sheet/dist/style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import ConsentDialog from "./components/ConsentDialog";
import { AnalyticsProvider } from "./contexts/AnalyticsContext";
import { AppIntlProvider } from "./contexts/AppIntlContext";
import AppThemeProvider from "./contexts/AppThemeContext";
import { SocketProvider } from "./contexts/SocketProvider";
import { HTTP_REFRESH_HEADER } from "@gethere/common/settings";
import { useAppSelector } from "./state/hooks";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./containers/queryClient";

Sentry.init({
  dsn: "https://32d0960aac1f484dba9828d05f25a550@o206422.ingest.sentry.io/5260611",
  release: `@gethere/web@${appVersion}`,
  beforeSend(event, hint) {
    // Prevent sending data to Sentry during development
    if (!env.production) {
      // log original excecption to console with stack trace
      console.error(hint.originalException);
      return null;
    }

    // Filter sensitive headers
    if (event.request && event.request.headers) {
      const headers = event.request.headers;
      if (headers["Cookie"]) {
        headers["Cookie"] = "[__FILTERED]";
      }
      if (headers[HTTP_REFRESH_HEADER]) {
        headers[HTTP_REFRESH_HEADER] = "[__FILTERED]";
      }
    }

    // Filter sensitive data in the response
    if (event.request && event.request.data) {
      const responseData = event.request.data;

      if (responseData && typeof responseData === "object") {
        if ("access" in responseData) {
          responseData.access = "[__FILTERED]";
        }

        if ("refresh" in responseData) {
          responseData.refresh = "[__FILTERED]";
        }
      }
    }

    return event;
  },
});

const homeByCountryPath = `/:country(${SUPPORTED_COUNTRIES.join(
  "|"
).toLowerCase()})?`;

const LoadableJoinWizard = loadable(
  () =>
    import(
      /* webpackChunkName: "JoinBusinessWizard" */ "./containers/JoinBusinessWizard"
    ),
  { fallback: null }
);

const LoadableLoginScreen = loadable(
  () =>
    import(/* webpackChunkName: "LoginScreen" */ "./containers/LoginScreen"),
  {
    fallback: null,
  }
);

const LoadableAdminRouter = loadable(
  () =>
    import(/* webpackChunkName: "AdminRouter" */ "./containers/AdminRouter"),
  {
    fallback: null,
  }
);

const LoadableBusinessesRouter = loadable(
  () =>
    import(
      /* webpackChunkName: "BusinessesRouter" */ "./containers/BusinessesRouter"
    ),
  { fallback: null }
);

const LoadableAppFooter = loadable(
  () => import(/* webpackChunkName: "AppFooter" */ "./components/AppFooter"),
  { fallback: null }
);

const LoadableUserProfileScreen = loadable(
  () =>
    import(/* webpackChunkName: "Personal" */ "./containers/UserProfileScreen"),
  { fallback: null }
);

const LoadableCreditsPage = loadable(
  () =>
    import(/* webpackChunkName: "CreditsPage" */ "./components/CreditsPage"),
  { fallback: null }
);

const LoadablePlaceScreen = loadable(
  () =>
    import(/* webpackChunkName: "PlaceScreen" */ "./containers/PlaceScreen"),
  { fallback: null }
);

const LoadableBusinessLayout = loadable(
  () =>
    import(
      /* webpackChunkName: "BusinessLayout" */ "./containers/BusinessLayout"
    ),
  { fallback: null }
);

const LoadablePricingPage = loadable(
  () => import(/* webpackChunkName: "PricingPage" */ "./pages/PricingPage"),
  { fallback: null }
);

const LoadableContactPage = loadable(
  () => import(/* webpackChunkName: "ContactPage" */ "./pages/ContactPage"),
  { fallback: null }
);

const LoadableOrderAndPayPage = loadable(
  () =>
    import(/* webpackChunkName: "OrderAndPayPage" */ "./pages/OrderAndPayPage"),
  { fallback: null }
);

const LoadableLoyaltyPage = loadable(
  () => import(/* webpackChunkName: "LoyaltyPage" */ "./pages/LoyaltyPage"),
  { fallback: null }
);

const LoadablePosPage = loadable(
  () => import(/* webpackChunkName: "PosPage" */ "./pages/PosPage"),
  { fallback: null }
);

const LoadablePickupAndDeliveryPage = loadable(
  () =>
    import(
      /* webpackChunkName: "PickupAndDeliveryPage" */ "./pages/PickupAndDeliveryPage"
    ),
  { fallback: null }
);

const LoadableReservationsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "ReservationsPage" */ "./pages/ReservationsPage"
    ),
  { fallback: null }
);

const LoadableWaitlistPage = loadable(
  () => import(/* webpackChunkName: "WaitlistPage" */ "./pages/WaitlistPage"),
  { fallback: null }
);

const LoadableDownloadAppForBusinesses = loadable(
  () =>
    import(
      /* webpackChunkName: "DownloadAppForBusinesses" */ "./pages/DownloadAppForBusinesses"
    ),
  { fallback: null }
);

const LoadableLegalLayout = loadable(
  () => import(/* webpackChunkName: "LegalLayout" */ "./layouts/LegalLayout"),
  { fallback: null }
);

const fetchGeoData = async () => {
  const res = await fetch("https://ipapi.co/json", {
    method: "get",
    mode: "cors",
  });
  const data = (await res.json()) as {
    ip: string;
    version: string;
    city: string;
    region: string;
    region_code: string;
    country: string;
    country_name: string;
    country_code: string;
    country_code_iso3: string;
    country_capital: string;
    country_tld: string;
    continent_code: string;
    in_eu: boolean;
    postal: string;
    latitude: number;
    longitude: number;
    timezone: string;
    utc_offset: string;
    country_calling_code: string;
    currency: string;
    currency_name: string;
    languages: string;
    country_area: number;
    country_population: number;
    asn: string;
    org: string;
  };

  return data;
};

const Notifications = () => {
  const theme = useTheme();
  return (
    <Toaster position="bottom-center" reverseOrder={false}>
      {(t) => (
        <ToastBar
          toast={t}
          style={{
            color: theme.colors.foreground,
            background: theme.colors.card,
          }}
        >
          {({ icon, message }) => (
            <>
              {icon}
              {message}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

const Application = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <UserContextProvider>
          <SocketProvider>
            <AnalyticsProvider>
              <>
                <ScrollToTop />
                <AppIntlProvider>
                  <AppThemeProvider>
                    <Notifications />
                    <LanguageDialogProvider>
                      <AppRouter />
                    </LanguageDialogProvider>
                    <ConsentDialog />
                  </AppThemeProvider>
                </AppIntlProvider>
              </>
            </AnalyticsProvider>
          </SocketProvider>
        </UserContextProvider>
      </Router>
    </QueryClientProvider>
  );
};
/* <Direction key="dir" rtl={rtl}> */
/* </Direction> */

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AppRouter = () => {
  const user = useAppSelector(userSelector);
  const ready = useAppSelector((state) => state._persist?.rehydrated);

  const history = useHistory();
  const dispatch = useDispatch();

  const prev = usePrevious({ user: Boolean(user), ready });

  const is = {
    admin: isAdminUserSelector(user),
  };

  // kick logged out users to main screen;
  useEffect(() => {
    if (prev && prev.ready) {
      // page is loaded & ready
      if (prev.user && !user && history.location.pathname !== "/order")
        history.push("/"); // had prev user, now not. kick.
    }
  }, [!!user]);

  const getAndSetCountry = async () => {
    try {
      const data = await fetchGeoData();
      const countryCode = data?.country_code;

      if (SUPPORTED_COUNTRIES.includes(countryCode as any)) {
        dispatch(setCountry({ country: countryCode }));
      } else {
        dispatch(setCountry({ country: CountryCodes.UnitedStates }));
      }

      localStorage?.setItem?.(
        "fetchedDefaultGeo",
        new Date().getTime().toString()
      );
    } catch (error) {
      captureException(error);
      dispatch(setCountry({ country: CountryCodes.UnitedKingdom }));
    }
  };

  useEffect(() => {
    try {
      if (!localStorage?.getItem("fetchedDefaultGeo")) {
        getAndSetCountry();
      }
    } catch (error) {
      captureException(error);
    }
  }, []);

  return (
    <AppLayout>
      <Switch>
        <Route path="/login" exact component={LoadableLoginScreen} />

        <Route path="/admin">
          {is.admin ? <LoadableAdminRouter /> : <UnauthorizedScreen />}
        </Route>

        <Route path="/businesses">
          <LoadableBusinessesRouter />
        </Route>

        <Route path="/order" component={SessionScreen} />

        <Route
          path={["/p/:placeId", "/b/:bookingId"]}
          component={LoadablePlaceScreen}
        />

        <Route exact path={["/privacypolicy", "/privacy"]}>
          <Redirect to="/legal/privacy" />
        </Route>

        <Route path="/legal" component={LoadableLegalLayout} />
        <Route
          path="/pages/download-os"
          exact
          component={LoadableDownloadAppForBusinesses}
        />
        <Route path="/pages/pricing" exact component={LoadablePricingPage} />
        <Route path="/pages/contact" exact component={LoadableContactPage} />

        <Route
          path="/pages/order-and-pay"
          exact
          component={LoadableOrderAndPayPage}
        />
        <Route path="/pages/loyalty" exact component={LoadableLoyaltyPage} />
        <Route path="/pages/pos" exact component={LoadablePosPage} />
        <Route
          path="/pages/pickup-and-delivery"
          exact
          component={LoadablePickupAndDeliveryPage}
        />
        <Route
          path="/pages/reservations"
          exact
          component={LoadableReservationsPage}
        />
        <Route path="/pages/waitlist" exact component={LoadableWaitlistPage} />

        <Route path="/opensource" exact component={LoadableCreditsPage} />
        <Route path="/join" component={LoadableJoinWizard} />
        <Route
          path="/me"
          component={user ? LoadableUserProfileScreen : UnauthorizedScreen}
        />

        <Route path={homeByCountryPath} exact component={HomeScreen} />

        <Route component={NotFoundScreen} />
      </Switch>
    </AppLayout>
  );
};

const AppLayout = ({ children }) => {
  const user = useUser();

  const businessLayout = useRouteMatch<{ businessId: string }>([
    "/businesses/:businessId",
  ]);

  const adminLayout = useRouteMatch(["/admin"]);
  const orderLayout = useRouteMatch(["/order" /** session */]);
  const placeLayout = useRouteMatch(["/p" /** place */, "/b" /** booking */]);
  const joinLayout = useRouteMatch(["/join" /** booking */]);

  if (joinLayout) {
    return (
      <JoinLayout>
        <div id="content">{children}</div>
      </JoinLayout>
    );
  }

  if (orderLayout) {
    return (
      <SessionThemeProvider>
        <div id="content">{children}</div>
      </SessionThemeProvider>
    );
  }

  if (placeLayout) {
    return (
      <>
        <div id="content">{children}</div>
        <PoweredBy />
      </>
    );
  }

  if (businessLayout || (adminLayout && user?.isAdmin))
    return (
      <BusinessProvider
        businessId={adminLayout ? null : businessLayout.params.businessId}
      >
        <LoadableBusinessLayout>{children}</LoadableBusinessLayout>
      </BusinessProvider>
    );

  // site layout
  return (
    <React.Fragment>
      <SiteHeader />
      <div id="content">{children}</div>
      <LoadableAppFooter />
    </React.Fragment>
  );
};

const App: React.FC = () => (
  <Provider store={store}>
    <Application />
  </Provider>
);

export default App;
