import * as yup from "yup";

// const MapSchema = <T extends yup.Schema>(schema: T) =>
//   yup
//     .mixed<Record<string, yup.InferType<T>>>()
//     .test(function (value: any) {
//       if (value === null || value === undefined) {
//         if (
//           this?.schema?.spec?.nullable ||
//           this?.schema?.spec?.presence === "optional"
//         )
//           return true;
//       }

//       if (!value || typeof value !== "object") {
//         return false;
//       }

//       for (let key in value) {
//         if (!schema.isValidSync(value[key])) {
//           return false;
//         }
//       }

//       return true;
//     })
//     .default(null);

// function MapSchema<T extends yup.Schema>(schema: T) {
//   return yup.lazy((value: any) => {
//     let keys: string[] = [];

//     if (typeof value === "object" && !!value) {
//       keys = Object.keys(value);
//     }

//     const shape: Record<string, T> = {};

//     for (const key of keys) {
//       shape[key] = schema;
//     }

//     return yup.object(shape);
//   }) as any as yup.Schema<Record<string, yup.InferType<T>>>;
// }

function MapSchema<T extends yup.Schema<any>>(schema: T) {
  return yup.mixed<Record<string, yup.InferType<T>>>().test({
    name: "MapSchema",
    message: "Invalid map",
    test: function (value: any) {
      if (!value && this.schema.spec.optional) {
        return true;
      }

      if (typeof value !== "object") {
        return false;
      }

      const keys = Object.keys(value);
      const shape: Record<string, T> = {};

      for (const key of keys) {
        shape[key] = schema;
      }

      const objectSchema = yup.object(shape);
      return objectSchema.isValidSync(value);
    },
  });
}

export default MapSchema;
