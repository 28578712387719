import { useToggle } from "@shopify/react-hooks";
import clsx from "clsx";
import { useMemo } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { UserFields, useUser } from "../contexts/UserContext";
import SignInContainer from "./SignInContainer";

export default function EnsureUserSignedWhenNeeded({
  children,
  requiredUserFields,
}: {
  requiredUserFields: UserFields[];
  children: React.ReactNode;
}) {
  const { hasBasicFields, isSigned, user } = useUser();

  const active = useToggle(false);

  const hasRequireds = useMemo(
    () => hasBasicFields(requiredUserFields),
    [user?.updatedAt]
  );

  const shouldCompleteIdentification = !isSigned || !hasRequireds?.status;

  if (!shouldCompleteIdentification) return children;

  return (
    <div
      className="cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        active.setTrue();
      }}
    >
      {
        active.value && (
          <BottomSheet
            open={active.value}
            snapPoints={({ minHeight }) => [minHeight]}
          >
            <div className="py-10 px-5">
              <SignInContainer
                fields={requiredUserFields}
                allowGuest
                forceDetails
                onSuccess={() => {
                  active.setFalse();
                }}
              />
            </div>
          </BottomSheet>
        ) /** backdrop with blur */
      }
      <div
        className={clsx(
          "pointer-events-none duration-150",
          active.value && "blur-sm"
        )}
      >
        {children}
      </div>
    </div>
  );
}
