import { integrationProviders } from "@gethere/common/enums";
import * as RestAPI from "@gethere/common/RestAPI";
import { TSessionPaymentsSettings } from "@gethere/common/yup/Session";
import { SessionStripeCheckoutProvider } from "../contexts/SessionStripeCheckout";
import { StripeElementsProvider } from "../contexts/StripeElementsProvider";
import { useAppSelector } from "../state/hooks";
import { sessionSelector } from "../state/reducers/session";
import SessionStripePayments from "./SessionStripePayments";

export type PayIntentSync = ({
  localValue,
}: {
  localValue: number;
}) => Promise<RestAPI.Session.Sync.Response>;

const isStripeConnect = (int: TSessionPaymentsSettings) =>
  int?.provider === integrationProviders.STRIPE_CONNECT;

export default function SessionPaymentsProvider({ children }) {
  const paymentsSettings = useAppSelector(
    (s) => sessionSelector(s)?.settings?.payments as TSessionPaymentsSettings
  );

  if (isStripeConnect(paymentsSettings)) {
    return (
      <StripeElementsProvider settings={paymentsSettings}>
        {children}
      </StripeElementsProvider>
    );
  } else {
    return children;
  }
}

export const SessionPaymentsComponent = ({
  payIntentSync,
  value,
  onSuccess,
}: {
  value: number;
  payIntentSync: PayIntentSync;
  onSuccess?: () => void;
}) => {
  const paymentsSettings = useAppSelector(
    (s) => sessionSelector(s)?.settings?.payments as TSessionPaymentsSettings
  );

  if (isStripeConnect(paymentsSettings)) {
    return (
      <SessionStripeCheckoutProvider
        onSuccess={onSuccess}
        payIntentSync={payIntentSync}
        value={value}
      >
        <SessionStripePayments />
      </SessionStripeCheckoutProvider>
    );
  } else {
    return null;
  }
};
