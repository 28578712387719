import React, { useCallback, useEffect, useState, useMemo } from "react";

const defaultOptions = { immediate: false };

type AsyncStatus = "success" | "idle" | "loading" | "error";

export type AsyncContext<J, T> = ReturnType<typeof useAsync<J, T>>;

const defaultArgs: React.DependencyList = [];

export const getInitialAsyncContext = <J, T>(): AsyncContext<J, T> => ({
  execute: () => {},
  reset: () => {},
  status: "idle",
  loading: false,
  successed: false,
  failed: false,
  value: null,
  error: null,
});

const useAsync = <J, T>(
  asyncFunction: (payload?: J) => Promise<T>,
  options = defaultOptions as Partial<typeof defaultOptions>,
  args: React.DependencyList = defaultArgs
): {
  reset: () => void;
  execute: (payload?: J) => void;
  status: AsyncStatus;
  loading: boolean;
  successed: boolean;
  failed: boolean;
  value: T | null;
  error: any;
} => {
  const [status, setStatus] = useState<AsyncStatus>("idle");
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<any>(null);

  const opts = useMemo(() => ({ ...defaultOptions, ...options }), [options]);
  const fn = useMemo(() => asyncFunction, args);

  const execute = useCallback(
    (payload?: J) => {
      setStatus("loading");
      setValue(null);
      setError(null);
      return fn(payload)
        .then((response) => {
          setStatus("success");
          setValue(response);
        })
        .catch((error) => {
          setStatus("error");
          setError(error);
        });
    },
    [fn]
  );

  useEffect(() => {
    if (opts.immediate) {
      execute();
    }
  }, [execute, opts.immediate]);

  const reset = useCallback(() => {
    setStatus("idle");
    setValue(null);
    setError(null);
  }, []);

  return {
    reset,
    execute,
    successed: status === "success",
    failed: status === "error",
    loading: status === "loading",
    status,
    value,
    error,
  };
};

export default useAsync;
