import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import { getAddressTypeIcon } from "./UserAddresses";
import { TUserAddress } from "@gethere/common/yup/UserAddress";
import { addressNameFormatter } from "@gethere/common/utils/geo";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { CheckIcon } from "@heroicons/react/24/outline";

export const UserAddress = ({
  name,
  selected,
  type,
  eligble = null,
  hideIcon,
}) => {
  const intl = useIntl();
  const Icon = getAddressTypeIcon(type);

  return (
    <>
      {!hideIcon && (
        <div className="relative">
          {typeof eligble === "boolean" ? (
            eligble ? (
              <CheckBadgeIcon className="w-2 h-2 fill-blue-400 absolute bottom-0 right-0" />
            ) : (
              <XCircleIcon className="w-2 h-2 fill-red-400 absolute bottom-0 right-0" />
            )
          ) : null}
          <Icon
            className={clsx(
              "w-4 h-4",
              selected ? "fill-text" : "fill-disabled"
            )}
          />
        </div>
      )}
      <div className="flex flex-col">
        <h6 className="font-medium">{name}</h6>
        <p className="text-sm text-muted-foreground">
          {intl.formatMessage(
            {
              id: "user_address_type",
            },
            { type }
          )}
        </p>
      </div>
      {selected && <CheckIcon className="w-4 h-4 fill-current" />}
    </>
  );
};

export default function UserAddressButton({
  addressId,
  selected = false,
  eligble = null,
  hideIcon = false,
}) {
  const address = useSelector((s: RootState) => {
    return s.user.entities?.addresses?.[addressId] as TUserAddress;
  });

  if (!address) return null;

  const { type } = address;

  const name = addressNameFormatter(address);

  return (
    <UserAddress
      name={name}
      type={type}
      selected={selected}
      eligble={eligble}
      hideIcon={hideIcon}
    />
  );
}
