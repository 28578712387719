import {
  paymentMethodTypes,
  transactionAction,
  transactionStatus,
} from "@gethere/common/enums";
import { TBusinessAccept } from "@gethere/common/yup/Business";
import { TSessionTransaction, TSessionUser } from "@gethere/common/yup/Session";
import { TUser } from "@gethere/common/yup/User";
import {
  CheckIcon,
  ClockIcon,
  LockClosedIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import colors from "tailwindcss/colors";
import {
  sessionBusinessSelector,
  sessionSelector,
} from "../state/reducers/session";
import { userShortName } from "../state/reducers/user";
import { RootState } from "../state/store";
import openNewTab from "../utils/openNewTab";

const SessionTransactions = ({ currency }) => {
  const { session, accepts } = useSelector((s: RootState) => ({
    session: sessionSelector(s),
    accepts: sessionBusinessSelector(s)?.accepts,
  }));
  if (Array.isArray(session.transactions) && session.transactions.length > 0) {
    return (
      <div className="grid grid-cols-1 gap-5 my-3">
        {session.transactions.map((t) => (
          <TransactionItem
            key={t.id}
            transaction={t}
            currency={currency}
            users={session?.users}
            accepts={accepts}
          />
        ))}
      </div>
    );
  } else return null;
};

const transactionIconProps = (status: any, action: transactionAction) => {
  switch (status) {
    case transactionStatus.CANCELED:
      return { Icon: XMarkIcon, color: colors.red[500] };
    case transactionStatus.COMPLETE:
      return {
        Icon:
          action === transactionAction.AUTHORIZE ? LockClosedIcon : CheckIcon,
        color:
          action === transactionAction.AUTHORIZE
            ? colors.blue[500]
            : colors.green[500],
      };
    case transactionStatus.FAILED:
      return { Icon: "error", color: colors.red[500] };
    case transactionStatus.PENDING:
    case transactionStatus.PROCCESSING:
      return { Icon: ClockIcon, color: colors.blue[500] };
    default:
      return { Icon: QuestionMarkCircleIcon, color: colors.orange[500] };
  }
};

const TransactionItem = ({
  transaction,
  currency,
  users,
  accepts,
}: {
  transaction: TSessionTransaction;
  currency: string;
  users: TSessionUser[];
  accepts: TBusinessAccept[];
}) => {
  const intl = useIntl();

  const accept = transaction.acceptId
    ? accepts.find((a) => a.id === transaction.acceptId)
    : null;

  const methodLabel =
    transaction?.method === paymentMethodTypes.OTHER && accept
      ? accept?.name
      : intl.formatMessage(
          { id: "transaction_method" },
          { method: transaction.method }
        );

  const desc = useMemo(() => {
    if (!transaction) return null;
    const at = intl.formatTime(transaction.createdAt);
    if (
      transaction.method === paymentMethodTypes.CARD &&
      typeof transaction.payload === "object" &&
      "details" in transaction.payload
    ) {
      const { brand, last4 } = transaction.payload.details;
      return `${brand} ${last4}, ${at}`;
    } else {
      return `${methodLabel}, ${at}`;
    }
    // eslint-disable-next-line
  }, [intl]);

  if (!transaction) return null;

  const value = intl.formatNumber(transaction.value, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const { Icon, color } = transactionIconProps(
    transaction.status,
    transaction.action
  );

  const sessionUser = users?.find?.((su) => su.userId === transaction.byId);
  const user: TUser = sessionUser?.["user"];
  const byName = user ? userShortName(user?.name) : "";

  const title = [
    intl.formatMessage(
      { id: "transaction_action" },
      { action: transaction.action }
    ),
    transaction.status !== transactionStatus.COMPLETE &&
      intl.formatMessage(
        { id: "transaction_status" },
        { status: transaction.status }
      ),
    byName,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div
      className="flex flex-row justify-between items-center gap-5"
      onClick={() => {
        if ("providerReciptUrl" in transaction?.payload)
          openNewTab(transaction.payload.providerReciptUrl);
      }}
    >
      <Icon
        style={{
          stroke: color,
        }}
        className="w-4 h-4"
      />
      <div className="flex flex-grow flex-row flex-wrap gap-1 text-sm items-center">
        <span className="capitalize font-medium">{title}</span>
        <span className="text-muted-foreground uppercase">{desc}</span>
      </div>
      <div className="text-sm">{value}</div>
      {/* {transaction?.payload?.providerReciptUrl && (
        <ReceiptTaxIcon
          className="w-5 h-5 stroke-disabled opacity-75 hover:opacity-100 duration-200 cursor-pointer"
        />
      )} */}
    </div>
  );
};

export default SessionTransactions;
