type TesterFunction<T> = (...args: any[]) => T | undefined;

interface WaitForOptions {
  ms?: number;
  maxRetries?: number;
  shouldReject?: boolean;
}

/**
 * Waits for a condition to be met by repeatedly calling a tester function.
 *
 * @template T - The type of the expected result.
 * @param {TesterFunction<T>} tester - The function that tests the condition.
 * @param {WaitForOptions} [options] - The options for waiting.
 * @param {number} [options.ms=250] - The interval in milliseconds between each test.
 * @param {number} [options.maxRetries=10] - The maximum number of retries before timing out.
 * @param {boolean} [options.shouldReject=true] - Determines whether to reject with an error on timeout.
 * @returns {Promise<T>} - A promise that resolves with the result when the condition is met.
 * @throws {Error} - If the condition is not met within the specified number of retries and `shouldReject` is `true`.
 */
const waitFor = <T>(
  tester: TesterFunction<T>,
  { ms = 250, maxRetries = 10, shouldReject = true }: WaitForOptions = {}
): Promise<T> =>
  new Promise((resolve, reject) => {
    let tries = 0;

    const interval = setInterval(() => {
      const result = tester();
      if (result) {
        clearInterval(interval);
        return resolve(result);
      } else if (tries > maxRetries) {
        clearInterval(interval);
        return shouldReject
          ? reject(new Error("tester timeout"))
          : resolve(undefined as any);
      } else {
        tries += 1;
      }
    }, ms);

    // Immediately test once
    const first = tester();
    if (first) {
      clearInterval(interval);
      resolve(first);
    }
  });

export default waitFor;
