import { LoadingAnimation } from "../components/LoadingScreen";
import { SessionCollectionsSectionsProvider } from "../contexts/SessionCollectionsSectionsContext";
import { useSession } from "../contexts/SessionContext";
import SessionCollectionsContent from "./SessionCollectionsContent";
import { SessionCollectionsScrollSpyProvider } from "./SessionCollectionsScrollSpy";
import SessionCollectionsTabs from "./SessionCollectionsTabs";
import { DrawerContent, OpenOrderDrawerButton } from "./SessionDrawer";
import SessionMessages from "./SessionMessages";
import PoweredBy from "./SessionPoweredBy";

const OrderableSessionContent = () => {
  return (
    <SessionCollectionsSectionsProvider>
      <SessionCollectionsScrollSpyProvider>
        <div className="relative">
          <SessionCollectionsTabs />
          <SessionMessages />
          <SessionCollectionsContent />
          <div className="py-5">
            <PoweredBy />
          </div>
          <OpenOrderDrawerButton />
        </div>
      </SessionCollectionsScrollSpyProvider>
    </SessionCollectionsSectionsProvider>
  );
};

export default function SessionContent() {
  const { status, loading } = useSession();

  return loading ? (
    <LoadingAnimation />
  ) : status?.handoff ? (
    <DrawerContent />
  ) : (
    <OrderableSessionContent />
  );
}
