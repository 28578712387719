import { TSignInWithGoogle } from "@gethere/common/yup/User";
import clsx from "clsx";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import env from "../utils/env";
import useMeasure from "../utils/useMeasure";

function SignInWithGoogle({
  allowOneTap,
  onSuccess,
  loading,
}: {
  allowOneTap: boolean;
  onSuccess: (x: TSignInWithGoogle) => Promise<void>;
  loading: boolean;
}) {
  const [ref, { width }] = useMeasure();

  useEffect(() => {
    if (window) {
      window["googleSignInCallback"] = (result: TSignInWithGoogle) => {
        onSuccess(result);
      };
    }
  }, []);

  return (
    <div
      ref={ref}
      className={clsx(
        loading && "pointer-events-none opacity-50",
        "w-ful mb-5"
      )}
    >
      <Helmet>
        <script
          src="https://accounts.google.com/gsi/client"
          async
          defer
        ></script>
      </Helmet>
      {width > 0 && (
        <>
          <div
            id="g_id_onload"
            data-client_id={env.googleClientId}
            data-context="signup"
            data-ux_mode="popup"
            data-callback="googleSignInCallback"
            data-nonce
            data-auto_select="true"
            data-auto_prompt={allowOneTap ? "true" : "false"}
            data-itp_support={allowOneTap ? "true" : "false"}
          ></div>
          <div
            className="g_id_signin block w-full"
            data-type="standard"
            data-shape="rectangular"
            data-theme="outline"
            data-text="continue_with"
            data-size="large"
            data-logo_alignment="left"
            data-width={String(width)}
          ></div>
        </>
      )}
    </div>
  );
}

export default SignInWithGoogle;
