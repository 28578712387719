import { TImage } from "@gethere/common/yup/Image";
import clsx from "clsx";
import { LazyPicture, Picture } from "./Picture";

const getInitials = (words: string[]) =>
  words
    .map((s) => s?.[0])
    .filter(Boolean)
    .filter((_, i) => i < 2)
    .join("")
    .toUpperCase();

const Avatar = ({
  name,
  image,
  size = "sm",
  label,
  lazy = true,
}: {
  name?: Partial<{ first: string; last: string }>;
  image?: TImage;
  size?: "sm" | "md";
  label?: string;
  lazy?: boolean;
}) => {
  const Comp = lazy ? LazyPicture : Picture;

  const isNameObject = typeof name === "object" && name?.first?.length > 0;
  const hasLabel = typeof label === "string" && label?.length > 0;

  const initials = isNameObject
    ? getInitials([name?.first, name?.last])
    : hasLabel
    ? getInitials(label.split(" "))
    : null;

  return image ? (
    <Comp
      height={32}
      sourceHeight={600}
      sourceWidth={600}
      className={clsx(
        size === "sm" && "h-8 w-8",
        size === "md" && "h-12 w-12",
        "rounded-full object-cover bg-gray-200 dark:bg-gray-700"
      )}
      srcset={image}
      alt="Menu"
    />
  ) : (
    <div
      className={clsx(
        size === "sm" && "h-8 w-8",
        size === "md" && "h-12 w-12",
        "rounded-full bg-gray-200 dark:bg-gray-700 items-center justify-center flex flex-none"
      )}
    >
      <span className="text-gray-400 dark:text-gray-500 select-none">
        {initials}
      </span>
    </div>
  );
};

export default Avatar;
