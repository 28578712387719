import clsx from "clsx";

const TextSkeleton = ({ className = "w-2/3", ...props }) => (
  <span
    {...props}
    className={clsx(
      "flex bg-gray-200 dark:bg-gray-600 text-gray-200 h-fit animate-pulse rounded-md",
      className
    )}
  >
    <span className="opacity-0">.</span>
  </span>
);

export default TextSkeleton;
