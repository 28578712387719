import { IntlShape } from "react-intl";
import { AxiosError } from "axios";

export type ErrorFault =
  | "CLIENT"
  | "SERVER"
  | "OFFLINE"
  | "NO_RESPONSE"
  | "UNKNOWN";

type Errorified = { fault: ErrorFault; message: string; error: any };

const buildAxiosResError = (
  error: AxiosError<any>,
  intl: IntlShape
): Errorified => {
  const response = error.response!;
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  // console.log(error.response.data);
  // console.log(error.response.status);
  // console.log(error.response.headers);

  if (error.code === "ERR_NETWORK") {
    return buildAxionsReqError(error, intl);
  }

  let m: {
    message: string;
    values?: Record<string, string>;
    defaultMeassage?: string;
  };

  if (typeof response.data?.data === "object") {
    m = response.data.data;
  } else if (response.data?.message) {
    m = response.data;
  } else if (response.status === 401) {
    m = {
      message: "auth_type_unauthorized",
    };
  } else {
    m = {
      message: "error_server_default",
    };
  }

  let message: string;

  if (m.message in intl.messages) {
    message = intl.formatMessage(
      {
        id: m.message,
        defaultMessage: m.defaultMeassage,
      },
      m.values
    );
  } else if (m.message) {
    message = m.defaultMeassage || m.message;
  } else {
    message = intl.formatMessage({
      id: "error_server_default",
      defaultMessage: "Error occured",
    });
  }

  return {
    fault: "SERVER",
    error: response.data,
    message,
  };
};

const buildAxionsReqError = (
  error: AxiosError,
  intl: IntlShape
): Errorified => {
  // The request was made but no response was received
  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  // http.ClientRequest in node.js
  // console.log(error.request);
  console.log("axios request error", error);
  return {
    fault: "OFFLINE",
    error: error.request,
    message: intl?.formatMessage({
      id: "error_no_response_to_server",
      defaultMessage:
        "Looks like an internet connection issue, please check your connection and try again.",
    }),
  };
};

const buildGenericError = (e: any, intl: IntlShape): Errorified => {
  let error: any = null;

  console.log("generic error", e);

  if (e?.message) error = e;
  else if (e?.error?.message) error = e.error;

  if (error?.message) {
    return {
      fault: "NO_RESPONSE",
      error: error.request,
      message:
        intl && intl.messages
          ? error.message in intl.messages
            ? intl?.formatMessage({ id: error.message }, error.values || {})
            : intl?.messages.error_client_setup_request
          : error?.message || "error_client_setup_request",
    };
  } else {
    return {
      fault: "CLIENT",
      error: error,
      message: intl?.formatMessage({ id: "error_client_setup_request" }),
    };
  }
};

const buildAxiosError = (error: AxiosError, intl: IntlShape): Errorified => {
  if (error.response) {
    return buildAxiosResError(error, intl);
  } else if (error.request) {
    return buildAxionsReqError(error, intl);
  } else {
    return buildGenericError(error, intl);
  }
};

const buildErrorify =
  (getIntl: () => IntlShape) =>
  (e: any): Errorified => {
    try {
      const intl = getIntl();

      if (!e) return { fault: "UNKNOWN", message: "missing error", error: e };

      if (e instanceof AxiosError) {
        return buildAxiosError(e, intl);
      }

      if (e.error instanceof AxiosError) {
        return buildAxiosError(e.error, intl);
      }

      return buildGenericError(e, intl);
    } catch (error) {
      // console.error("Errorify error!", error);
      return { fault: "UNKNOWN", message: "Failure occured", error: e };
    }
  };

export default buildErrorify;
