import { useIntl } from "react-intl";
import { BtnLink } from "../Btn";

export default function BottomCallToAction() {
  const intl = useIntl();
  return (
    <div className="max-w-screen-xl w-full mx-auto py-12 px-5 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <h2 className="text-3xl font-medium tracking-tight text-gray-900 sm:text-4xl">
        <span className="block dark:text-gray-100">
          {intl.formatMessage({
            id: "wpa_prompt_pre_cta",
            defaultMessage: "Hungry for more?",
          })}
        </span>
        <span className="block text-primary dark:text-primary-300">
          {intl.formatMessage({
            id: "wpa_prompt_cta",
            defaultMessage: "Start now for free",
          })}
        </span>
      </h2>
      <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
        <div className="inline-flex rounded-md space-x-3 rtl:space-x-reverse">
          <BtnLink className="flex items-center" size="lg" to="/join">
            {intl.formatMessage({
              id: "wpa_get_started",
              defaultMessage: "Get started",
            })}
          </BtnLink>
          <BtnLink
            className="flex items-center"
            size="lg"
            to="/pages/contact"
            variant="outline"
            color="secondary"
          >
            {intl.formatMessage({
              id: "wpa_link_contact",
              defaultMessage: "Contact",
            })}
          </BtnLink>
        </div>
      </div>
    </div>
  );
}
