import * as yup from "yup";

import {
  SessionState,
  SessionSubmitIntents,
  SessionUpdateActions,
  TipStatus,
  paymentMethodTypes,
} from "../enums";
import utcIsoDateString from "./Date";
import {
  orderItemAmountSchema,
  orderItemMetaSchema,
  orderItemModifiersSchema,
  orderItemNotesSchema,
  orderItemSchema,
  orderItemStatusSchema,
  orderItemSubItemGroupsSchema,
  orderItemUnitSchema,
} from "./OrderItem";
import {
  sessionDiscountSchema,
  sessionEventTypeSchema,
  sessionMetaSchema,
  sessionTipSchema,
  sessionTransactionSchema,
  transactionStatusSchema,
} from "./Session";
import { sessionTypeSchema } from "./SessionTypes";
import { yupMobileNumberField } from "./MobileInputForm";

export const sessionAddOrderItemSchema = orderItemSchema.pick([
  "ppu",
  "amount",
  "itemId",
  "liableId",
  "meta",
  "modifiers",
  "notes",
  "subItemGroups",
  "status",
  "createdAt",
]);

export const sessionUpdateAddTransactionSchema = sessionTransactionSchema
  .pick(["method", "acceptId", "action", "value", "refs"])
  .concat(
    yup.object({
      autocomplete: yup.boolean().optional().nullable().default(undefined),
    })
  )
  .required();

export const sessionUpdateAddDiscountSchema = sessionDiscountSchema
  .pick(["amount", "type", "limit", "settings", "note", "promoId"])
  .required();

export const sessionSyncDraftSchema = yup.object({
  orderItems: yup.array().of(sessionAddOrderItemSchema).nullable(),
  tips: yup.array().of(sessionTipSchema).nullable(),
  meta: sessionMetaSchema.nullable().nullable(),
  type: yup.string().nullable().nullable(),
  localId: yup.string().nullable().nullable(),
  // localValue: yup.number().notRequired().min(0),
  discounts: yup.array().of(sessionUpdateAddDiscountSchema).nullable(),
  transactions: yup.array().of(sessionUpdateAddTransactionSchema).nullable(),
  paymentIntent: yup
    .object({
      autoComplete: yup.boolean().notRequired().default(false),
      value: yup.number().required().min(0.01).max(2000),
      present: yup.boolean().notRequired().default(false),
      tip: yup.number().notRequired(),
    })
    .notRequired()
    .default(null),
});

export const sessionSyncSchema = yup
  .object({
    intent: yup
      .mixed<SessionSubmitIntents>()
      .oneOf(Object.values(SessionSubmitIntents))
      .notRequired(),

    tId: yup.string().notRequired(),
    tIds: yup.array().of(yup.string()).notRequired(),

    sId: yup.string().notRequired(),
    pId: yup.string().notRequired(),
    type: sessionTypeSchema.notRequired(),
    bookingId: yup.string().notRequired(),
    ch: yup.string().notRequired(),
    capacity: yup.number().notRequired(),
    key: yup.string().notRequired(),
    draft: sessionSyncDraftSchema.optional().nullable().default(null),
  })
  .test(
    "have required fields",
    "required fields missing, please specifiy terminal, place or session",
    function validateRequiredSyncFields(value) {
      if (!value.tId && !value.sId && !value.pId) {
        return false;
      }
      return true;
    }
  );

export const sessionMetaUpdateSchema = yup.object({
  state: yup
    .mixed<SessionState>()
    .oneOf(Object.values(SessionState))
    .notRequired(),

  documentClient: yup.string().notRequired(), // custom name for reciepts and documents
  houseAccountId: yup.string().notRequired(),
  memberId: yup.string().notRequired(),

  capacity: yup.number().min(0).default(0).notRequired(),
  rejectedServiceCharge: yup.boolean().notRequired(),
  targetAt: utcIsoDateString.notRequired(),
});

export const sendOrderLinkSchema = yup.object({
  mobileNumber: yupMobileNumberField.required(),
});

export const sessionUpdateSwitchTerminalSchema = yup
  .object({
    nextTerminalId: yup.string().required(),
  })
  .required();

export const sessionEventUpdateSchema = yup
  .object({
    type: sessionEventTypeSchema.required(),
    ids: yup.array().of(yup.string()).max(35).notRequired(),
  })
  .required();

export const sessionTransferOrderItemsSessionSchema = yup
  .object({
    orderItemIds: yup.array().of(yup.string()).required().min(1).max(50),
    toSessionId: yup.string().required(),
  })
  .required();

export const sessionUserFeedbackSchema = yup
  .object({
    feedbackRate: yup
      .number()
      .min(0)
      .max(5)
      .required()
      .nullable()
      .default(null),
    feedbackTags: yup.array().of(yup.string()).default(null).nullable(),
    feedbackNote: yup.string().optional().default(null).nullable().min(5),
  })
  .required();

// order items

export const sessionRemoveOrderItemSchema = yup.object({
  orderItemIds: yup.array().of(yup.string()).required().min(0).max(50),
});

export const sessionCancelOrderItemsSchema = yup.object({
  orderItemIds: yup.array().of(yup.string()).required().min(0).max(50),
  note: yup.string().optional(),
  amount: yup.number().notRequired().min(1).max(500),
});

export const sessionVoidSchema = yup.object({
  note: yup.string().optional(),
});

export const sessionReprintOrderItemSchema = yup.object({
  orderItemIds: yup.array().of(yup.string()).required().min(0).max(50),
});

export const sessionApproveOrderItemsSchema = yup.object({
  orderItemIds: yup.array().of(yup.string()).min(1).required(),
});

export const sessionUpdateOrderItemSchema = yup
  .object({
    id: yup.string().notRequired(),
    status: orderItemStatusSchema.optional(),
    liableId: yup.string().optional(),
    modifiers: orderItemModifiersSchema,
    subItemGroups: orderItemSubItemGroupsSchema.optional(),
    itemId: yup.string().optional(),
    meta: orderItemMetaSchema,
    notes: orderItemNotesSchema.optional(),
    amount: orderItemAmountSchema.optional(),
    unit: orderItemUnitSchema.nullable().optional(),
    ppu: yup.number().min(0).optional(),
    cost: yup.number().min(0).optional(),
  })
  .required();

// transactions

export const sessionUpdateTransactionUpdateSchema = yup
  .object({
    id: yup.string().required(),
    status: transactionStatusSchema.optional(),
  })
  .required();

export const sessionOperatorCreatePaymentIntentSchema = yup
  .object({
    value: yup.number().min(0).required(),
    meta: yup.object({
      present: yup.boolean().optional(),
      autoComplete: yup.boolean().optional(),
    }),
  })
  .required();

export const sessionUpdatePaymentIntentSuccessSchema = yup
  .object({
    id: yup.string().required(),
    provider: yup.string().required(),
    autoComplete: yup.boolean().optional(),
  })
  .required();

// tips
export const sessionUpdateAddTipSchema = yup
  .object({
    value: yup.number().min(0).required(),
    charge: yup
      .object({
        transactionId: yup.string().notRequired(),
        acceptId: yup.string().notRequired(),
        method: yup
          .mixed<paymentMethodTypes>()
          .oneOf(Object.values(paymentMethodTypes))
          .notRequired(),
        refs: yup.object({
          printerId: yup.string().notRequired(),
        }),
      })
      .notRequired(),
  })
  .required();

export const sessionUpdateTipUpdateSchema = yup
  .object({
    status: yup.mixed<TipStatus>().oneOf(Object.values(TipStatus)).optional(),
    id: yup.string().required(),
    value: yup.number().min(0).optional().nullable(),
  })
  .required();

// discounts

export const sessionUpdateDiscountUpdateSchema = yup
  .object({
    id: yup.string().required(),
    cancel: yup.boolean().notRequired(),
  })
  .required();

export const getSessionUpdatePayloadSchema = (action: SessionUpdateActions) => {
  switch (action) {
    case SessionUpdateActions.EVENT_UPDATE:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.EVENT_UPDATE>().required(),
          payload: sessionEventUpdateSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.ORDER_ITEM_ADD:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.ORDER_ITEM_ADD>().required(),
          payload: sessionAddOrderItemSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.ORDER_ITEM_REMOVE:
      return yup
        .object({
          action: yup
            .mixed<SessionUpdateActions.ORDER_ITEM_REMOVE>()
            .required(),
          payload: sessionRemoveOrderItemSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.ORDER_ITEM_CANCEL:
      return yup
        .object({
          action: yup
            .mixed<SessionUpdateActions.ORDER_ITEM_CANCEL>()
            .required(),
          payload: sessionCancelOrderItemsSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.ORDER_ITEMS_REPRINT:
      return yup
        .object({
          action: yup
            .mixed<SessionUpdateActions.ORDER_ITEMS_REPRINT>()
            .required(),
          payload: sessionReprintOrderItemSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.ORDER_ITEM_UPDATE:
      return yup
        .object({
          action: yup
            .mixed<SessionUpdateActions.ORDER_ITEM_UPDATE>()
            .required(),
          payload: sessionUpdateOrderItemSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.TIP_ADD:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.TIP_ADD>().required(),
          payload: sessionUpdateAddTipSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.TIP_UPDATE:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.TIP_UPDATE>().required(),
          payload: sessionUpdateTipUpdateSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.DISCOUNT_ADD:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.DISCOUNT_ADD>().required(),
          payload: sessionUpdateAddDiscountSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.DISCOUNT_UPDATE:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.DISCOUNT_UPDATE>().required(),
          payload: sessionUpdateDiscountUpdateSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.TRANSACTION_ADD:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.TRANSACTION_ADD>().required(),
          payload: sessionUpdateAddTransactionSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.SESSION_TRANSFER_ORDER_ITEMS:
      return yup
        .object({
          action: yup
            .mixed<SessionUpdateActions.SESSION_TRANSFER_ORDER_ITEMS>()
            .required(),
          payload: sessionTransferOrderItemsSessionSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.TRANSACTION_UPDATE:
      return yup
        .object({
          action: yup
            .mixed<SessionUpdateActions.TRANSACTION_UPDATE>()
            .required(),
          payload: sessionUpdateTransactionUpdateSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.TRANSACTION_CANCEL:
      return yup
        .object({
          action: yup
            .mixed<SessionUpdateActions.TRANSACTION_CANCEL>()
            .required(),
          payload: sessionUpdateTransactionUpdateSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.TRANSACTION_SYNC:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.TRANSACTION_SYNC>().required(),
          payload: sessionUpdateTransactionUpdateSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.PAYMENT_INTENT_SUCCESSED:
      return yup
        .object({
          action: yup
            .mixed<SessionUpdateActions.PAYMENT_INTENT_SUCCESSED>()
            .required(),
          payload: sessionUpdatePaymentIntentSuccessSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.SUBMITION:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.SUBMITION>().required(),
          payload: sessionSyncSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.SESSION_UPDATE:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.SESSION_UPDATE>().required(),
          payload: sessionMetaUpdateSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.SEND_ORDER_LINK:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.SEND_ORDER_LINK>().required(),
          payload: sendOrderLinkSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.TERMINAL_REPLACE:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.TERMINAL_REPLACE>().required(),
          payload: sessionUpdateSwitchTerminalSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.OPERATOR_CREATE_PAYMENT_INTENT:
      return yup
        .object({
          action: yup
            .mixed<SessionUpdateActions.OPERATOR_CREATE_PAYMENT_INTENT>()
            .required(),
          payload: sessionOperatorCreatePaymentIntentSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.USER_FEEDBACK:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.USER_FEEDBACK>().required(),
          payload: sessionUserFeedbackSchema.required(),
        })
        .required()
        .defined();
    case SessionUpdateActions.SESSION_USER_LEAVE:
      return yup
        .object({
          action: yup
            .mixed<SessionUpdateActions.SESSION_USER_LEAVE>()
            .required(),
          payload: yup.mixed<any>().nullable().optional().default(null),
        })
        .required()
        .defined();
    case SessionUpdateActions.SESSION_COMPLETE:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.SESSION_COMPLETE>().required(),
          payload: yup.mixed<any>().nullable().optional().default(null),
        })
        .required()
        .defined();
    case SessionUpdateActions.SESSION_REOPEN:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.SESSION_REOPEN>().required(),
          payload: yup.mixed<any>().nullable().optional().default(null),
        })
        .required()
        .defined();
    case SessionUpdateActions.VOID_SESSION:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.VOID_SESSION>().required(),
          payload: sessionVoidSchema.optional(),
        })
        .required()
        .defined();
    case SessionUpdateActions.GET_INVITE_KEY:
      return yup
        .object({
          action: yup.mixed<SessionUpdateActions.GET_INVITE_KEY>().required(),
          payload: yup.mixed<any>().nullable().optional().default(null),
        })
        .required()
        .defined();
    default:
      throw new Error(`unimplemented schema for action ${action}`);
  }
};

const sessionUpdateRequestSchema = yup
  .object({
    action: yup
      .mixed<SessionUpdateActions>()
      .oneOf(Object.values(SessionUpdateActions))
      .required(),
  })
  .required();

export const validateSessionUpdateBody = async (body: any) => {
  const { action } = await sessionUpdateRequestSchema.validate(body);
  const schema = getSessionUpdatePayloadSchema(action);
  return schema.validate(body);
};
