import Btn from "../components/Btn";
import { useSessionCheckout } from "../contexts/SessionCheckoutContext";
import { useSession } from "../contexts/SessionContext";
import { useSessionDrawerNavigation } from "../contexts/SessionDrawerNavigationContext";
import { SubmitSessionPayment } from "../contexts/SubmitSessionPayment";
import { useAppSelector } from "../state/hooks";
import { sessionSelector } from "../state/reducers/session";

export default function SubmitPaymentsOptions() {
  const drawerNav = useSessionDrawerNavigation();
  const { manualCard } = useSessionCheckout();
  const { status } = useSession();
  const check = useAppSelector((s) => sessionSelector(s)?.check);

  if (!check || status.leftOrder == 0) return;

  return (
    <div className="flex flex-col gap-3">
      <SubmitSessionPayment value={status.leftOrder} />
      {!manualCard.value && (
        <div className="grid grid-cols-2 gap-3">
          <Btn
            size="sm"
            variant="outline"
            onClick={() => {
              drawerNav.setScreen({ name: "split_equally", params: {} });
            }}
          >
            Split Bill Equally
          </Btn>
          <Btn
            size="sm"
            variant="outline"
            onClick={() => {
              drawerNav.setScreen({ name: "pay_custom", params: {} });
            }}
          >
            Pay Custom Amount
          </Btn>
        </div>
      )}
    </div>
  );
}
