export default async function withTimeout<T>(
  promise: Promise<T>,
  timeout: number,
  errorMessage: string = "Timeout"
): Promise<T> {
  let timeoutId: NodeJS.Timeout | undefined;
  try {
    const result = await Promise.race<T>([
      promise,
      new Promise((_, reject) => {
        timeoutId = setTimeout(() => reject(new Error(errorMessage)), timeout);
      }),
    ]);

    return result;
  } finally {
    if (timeoutId) clearTimeout(timeoutId);
  }
}
