import { createContext, useContext } from "react";
import LanguageDialog from "../containers/LanguageDialog";
import useToggle from "../utils/useToggle";

const LanguageDialogContext = createContext<ReturnType<typeof useToggle>>({
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  value: null,
});

export const useLanguageDialog = () => useContext(LanguageDialogContext);

export const LanguageDialogProvider = ({ children }) => {
  const state = useToggle<boolean | "region">(false);

  return (
    <LanguageDialogContext.Provider value={state}>
      {children}
      <LanguageDialog
        open={Boolean(state.isOpen)}
        region={state.value === "region"}
        onClose={state.onClose}
      />
    </LanguageDialogContext.Provider>
  );
};

export default LanguageDialogContext;
