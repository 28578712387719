import { ClientOptions, SharedClient } from "./Client";
import { SocketActions } from "./enums";
import * as SocketAPI from "./SocketAPI";
import { PrintingJob } from "./types";

export type WebSocketClientOptions = {
  uri: string;
};

type SocketActionToMessageMap = {
  [P in SocketActions]: P extends SocketActions.PLACE_SESSION_UPDATED
    ? SocketAPI.Messages.PlaceSessionUpdate
    : P extends SocketActions.SESSION_UPDATED
      ? SocketAPI.Messages.SessionUpdate
      : P extends SocketActions.PLACE_TIMETRACK_UPDATED
        ? SocketAPI.Messages.PlaceTimeTracksUpdate
        : P extends SocketActions.PRINTING_JOB
          ? { jobs: PrintingJob[] }
          : P extends SocketActions.PLACE_BOOKING_UPDATED
            ? SocketAPI.Messages.PlaceBookingUpdate
            : P extends SocketActions.INTERNAL_MESSAGE
              ? SocketAPI.Messages.InternalMessage
              : P extends SocketActions.CLIENT_BOOKING_UPDATE
                ? SocketAPI.Messages.ClientBookingUpdated
                : never;
};

class SocketClient {
  private socket?: WebSocket;
  private client: SharedClient;
  private options: ClientOptions;

  // fix fns types declaration
  private fns: {
    onConnected?: () => void;
    onConnect?: () => void;
    onClose?: () => void;
    onCustom: {
      [P in SocketActions]?: (data: SocketActionToMessageMap[P]) => void;
    };
  };

  constructor(client: SharedClient, options: ClientOptions) {
    this.client = client;

    this.options = options;

    this.fns = {
      onCustom: {},
    };
  }

  // fix types
  on<P extends SocketActions>(
    eventName: P,
    fn: (data: SocketActionToMessageMap[P]) => void
  ) {
    this.fns.onCustom[eventName] = fn as any;
  }

  connect = async () => {
    try {
      if (!this.client.authenticator.hasAccessToken) {
        await this.client.authenticator.refreshAccess();
      }

      if (typeof this.fns.onConnect === "function") this.fns.onConnect();

      // if we still don't have it, abort;
      if (!this.client.authenticator.hasAccessToken) {
        throw new Error("Missing access token");
      }

      // close before open
      if (this.socket?.readyState === WebSocket.OPEN) this.socket?.close?.();

      const uri =
        this.options.ws.uri + `?token=${this.client.authenticator.accessToken}`;

      this.socket = new WebSocket(uri);

      this.socket.onopen = this.onOpen;
      this.socket.onclose = this.onClose;
      this.socket.onerror = this.onError;
      this.socket.onmessage = this.onMessage;
    } catch (error) {
      console.error("ws connect error", JSON.stringify(error, null, 2));
      this.client.authenticator
        .refreshAccess()
        .then(() => {
          this.connect().catch((e) => {
            console.error("failed to reconnect to socket after token refresh");
            console.error(error);
          });
        })
        .catch((error) => {
          console.error("failed refreshing token on socket error");
          console.error(error);
        });
    }
  };

  get state() {
    return this.socket?.readyState || WebSocket.CLOSED;
  }

  set onSocketConnect(fn: () => void) {
    this.fns.onConnect = fn;
  }

  set onSocketConnected(fn: () => void) {
    this.fns.onConnected = fn;
  }

  set onSocketClose(fn: () => void) {
    this.fns.onClose = fn;
  }

  private onMessage = (e) => {
    if (e) {
      const message = JSON.parse(e.data);

      if (
        typeof message.action === "string" &&
        typeof this.fns.onCustom[message.action] === "function"
      ) {
        this.fns.onCustom[message.action](message.body);
      }
    }
  };

  private onOpen = () => {
    if (typeof this.fns.onConnected === "function") this.fns.onConnected();
  };

  private onClose = () => {
    console.error("close ws");
    if (typeof this.fns.onClose === "function") this.fns.onClose();
  };

  private onError = (ev: any) => {
    try {
      this.options.addBreadcrumb?.({
        category: "SharedClient/WebSocket",
        message: "onSocket error",
        data: {
          ev,
        },
      });

      this.options.captureException?.(
        new Error(ev?.message || "onSocket error")
      );

      if (
        typeof ev?.message === "string" &&
        ["401", "489", "Expected HTTP 101"].some((msg) =>
          ev.message.includes(msg)
        )
      ) {
        this.client.authenticator
          .refreshAccess()
          .then(() => {
            this.connect().catch((e) => {
              console.error(
                "failed to reconnect to socket after token refresh"
              );
              console.error(e);
            });
          })
          .catch((error) => {
            console.error("failed refreshing token on socket error");
            console.error(error);
          });
      }
    } catch (error) {
      console.log("failed to refresh token", error);
    }
  };

  // synm to disconnect
  close = () => {
    this.disconnect();
  };

  disconnect = () => {
    try {
      if (this.socket && this.socket.readyState <= 2) this.socket.close();
    } catch (error) {
      console.log(error);
    }
  };

  reconnect = () => {
    this.disconnect();
    return this.connect();
  };

  emit = (action: SocketActions, body: any) => {
    if (this.socket && this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ action, body }));
    }
  };
}

export default SocketClient;
