import { useToggle } from "@shopify/react-hooks";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import PoweredBy from "../containers/SessionPoweredBy";
import { useConsentStore } from "../stores/consentDialogStore";
import CookieSvg from "../svgs/CookieSvg";
import Btn from "./Btn";

const ConsentTopic = ({
  disabled = false,
  checked,
  title,
  id,
  children,
  onChange = (e: any): void => {},
}) => {
  return (
    <div className={"mb-5 flex flex-row items-center"}>
      <input
        id={id}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        value={checked}
        onChange={onChange}
        className={clsx(
          !disabled && "cursor-pointer",
          "form-checkbox h-5 w-5 focus:ring-primary text-primary rounded disabled:bg-gray-500 hover:disabled:bg-gray-500"
        )}
        // color="primary"
        // disableRipple
      />
      <label
        htmlFor={id}
        className={clsx("pl-5", !disabled && "cursor-pointer")}
      >
        <h3 className="font-medium text-lg">{title}</h3>
        <p className="text-sm">{children}</p>
      </label>
    </div>
  );
};

const ConsentCustom = () => {
  const consent = useConsentStore();
  const [ads, setAds] = useState(consent.ads !== null ? consent.ads : true);
  const [pref, setPref] = useState(consent.pref !== null ? consent.pref : true);

  const onPrefChange = useCallback((e) => setPref(e.target.checked), [setPref]);
  const onAdsChange = useCallback((e) => setAds(e.target.checked), [setAds]);

  return (
    <div className="grid grid-col-1">
      <h6 className="text-xl font-medium mb-5">
        Manage your cookies preferences
      </h6>
      <div className="mb-5">
        <ConsentTopic id="ess" title="Essential" disabled checked>
          Cookies that are required for your use of our site or services, such
          as account login, authentication & security.
        </ConsentTopic>
        <ConsentTopic
          id="ana"
          title="Analytics"
          checked={pref}
          onChange={onPrefChange}
        >
          With the use of analytics cookies, TablePort can analyze your browsing
          and actions on our and other web apps and websites to be able to
          identify your interests and make recommendations.
        </ConsentTopic>
        <ConsentTopic
          id="adv"
          title="Advertising"
          checked={ads}
          onChange={onAdsChange}
        >
          With these cookies, TablePort allows agencies like social media
          companies to use your information to send you ads on other apps and
          websites that are more relevant to you, and for different purposes
          determined by them.
        </ConsentTopic>
        <p className="text-sm text-muted-foreground">
          Blocking some types of cookies may impact your experience of our
          sites. You may review and change your choices at any time by clicking
          Cookie preferences in the footer of this site. We and selected third
          parties use cookies or similar technologies as specified in the{" "}
          <a
            href="/privacypolicy"
            className="underline"
            rel="noreferrer"
            target="_blank"
          >
            TablePort privacy notice & terms of use
          </a>
          .
        </p>
      </div>
      <div className="grid grid-cols-1 gap-2">
        <Btn
          onClick={() =>
            consent.acceptCustom({
              pref,
              ads,
            })
          }
          color="primary"
          variant="contained"
          size="lg"
          fullWidth
        >
          Save preferences
        </Btn>
        <Btn
          onClick={consent.acceptAll}
          fullWidth
          variant="outline"
          color="text"
          size="lg"
          style={{ marginBottom: 5 }}
        >
          Accept all cookies
        </Btn>
      </div>
    </div>
  );
};
const ConsentAll = ({ onCustomize }) => {
  const consent = useConsentStore();

  return (
    <div className="grid grid-col-1 gap-5">
      <div className="flex flex-row items-center justify-between">
        <h6 className="text-xl font-medium ">Cookie Preferences</h6>
        <span className="text-xl">
          <CookieSvg className="fill-disabled w-6 h-6" />
        </span>
      </div>
      <p className="text-sm">
        By clicking <i>"Accept"</i> you agree to the storing of cookies on your
        device to enhance site navigation, analyze site usage and assist in our
        marketing efforts.{" "}
        <a
          href="/privacypolicy"
          className="underline outline-none"
          rel="noreferrer"
          target="_blank"
        >
          More Info
        </a>
        .
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <Btn
          onClick={consent.acceptAll}
          fullWidth
          color="text"
          variant="contained"
          size="lg"
        >
          Accept
        </Btn>
        <Btn
          onClick={onCustomize}
          color="text"
          variant="outline"
          size="lg"
          fullWidth
        >
          Customise Preferences
        </Btn>
      </div>
    </div>
  );
};

const ConsentDialog = () => {
  const { pathname } = useLocation();
  const customize = useToggle(false);
  const consent = useConsentStore();
  const isPrivacyPage = pathname === "/legal/privacy";

  return (
    <BottomSheet
      open={consent.dialogOpened}
      blocking={false}
      skipInitialTransition
      snapPoints={({ minHeight }) =>
        isPrivacyPage ? [minHeight, 20] : [minHeight]
      }
    >
      <div className="mx-auto max-w-prose p-5">
        <script dangerouslySetInnerHTML={{ __html: `<!--googleoff: all-->` }} />
        {customize.value ? (
          <ConsentCustom />
        ) : (
          <ConsentAll onCustomize={() => customize.setTrue()} />
        )}
        <script dangerouslySetInnerHTML={{ __html: `<!--googleon: all-->` }} />
      </div>
      <PoweredBy className="mt-5 w-fit" />
    </BottomSheet>
  );
};

export default ConsentDialog;
