import { UserTypes } from "@gethere/common/enums";
import { LOCALES_TRANSLATIONS } from "@gethere/common/settings";
import { Popover, Transition } from "@headlessui/react";
import {
  ArrowLeftEndOnRectangleIcon,
  Bars3Icon,
  BriefcaseIcon,
  GlobeAltIcon,
  LanguageIcon,
  MoonIcon,
  SunIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { motion, useScroll } from "framer-motion";
import {
  createElement,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import Avatar from "../components/Avatar";
import { useLanguageDialog } from "../contexts/LanguageContext";
import { useUser } from "../contexts/UserContext";
import { setTheme } from "../state/reducers/system";
import { RootState } from "../state/store";
import client from "../utils/client";
import { CompanyLogo } from "./LogoSymbol";
import { DesktopSiteLinks } from "./header/site-links";
import { personal_routes, site_solutions } from "./header/links";
import { Button } from "../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Briefcase, CircleUserIcon, LogOutIcon } from "lucide-react";

const NavLink = ({ to, children }) => {
  return (
    <NavButton as={"span"}>
      <Link to={to}>{children}</Link>
    </NavButton>
  );
};

const NavButton = ({
  active = false,
  expendable = false,
  children,
  ...props
}) => (
  <span
    {...props}
    className={clsx(
      active
        ? "text-gray-900 dark:text-zinc-100"
        : "text-gray-500 dark:text-zinc-400",
      "group rounded text-md inline-flex items-center text-sm cursor-pointer font-medium hover:text-gray-900 dark:hover:text-zinc-100 focus:outline-none"
    )}
  >
    {children}
    {expendable && (
      <ChevronDownIcon
        className={clsx(
          active
            ? "text-gray-900 dark:text-zinc-100"
            : "text-gray-500 dark:text-zinc-400",
          "ml-2 h-5 w-5 group-hover:text-gray-900 dark:group-hover:text-gray-100"
        )}
        aria-hidden="true"
      />
    )}
  </span>
);

const SmallScreenMenuButton = ({ Icon = null, children, ...props }) => {
  const btnClasses = clsx(
    "flex text-md rounded cursor-pointer font-medium w-full px-2 gap-x-2 py-2 items-center hover:bg-gray-200 dark:hover:bg-zinc-700 text-gray-900 hover:text-gray-700 dark:text-zinc-100 dark:hover:text-white"
  );

  const inner = [];
  if (Icon)
    inner.push(
      <Icon key="icon" className="w-5 h-5 inline-block text-gray-500" />
    );
  inner.push(
    <span key="child" className="inline-block">
      {children}
    </span>
  );

  const component = props.to
    ? createElement(
        Link,
        {
          ...props,
          to: props.to,
          key: props.to,
          className: btnClasses,
        },
        inner
      )
    : createElement(
        "span",
        {
          ...props,
          key: props.key,
          className: btnClasses,
        },
        inner
      );

  return component;
};

const SmallScreenNav = ({ personal = false }) => {
  const intl = useIntl();
  const links = personal ? personal_routes : site_solutions;
  return (
    <Transition
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        className="absolute top-0 overflow-hidden inset-x-0 p-2 transition transform origin-top-right md:hidden"
      >
        <div className="ring-1 ring-black rounded-md overflow-hidden ring-opacity-5 bg-card divide-y dark:divide-gray-100 dark:divide-opacity-5 divide-gray-200">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <CompanyLogo
                  className="h-6"
                  labelClassName="fill-slate-900 dark:fill-white"
                  symbolClassName="fill-primary dark:fill-primary-400"
                />
              </div>
              <SmallScreenCloseMenu />
            </div>
            {links.length > 0 && (
              <div
                className={
                  "mt-6 space-y-2 divide-y w-full rtl:space-x-reverse dark:divide-gray-100 dark:divide-opacity-5 divide-gray-200"
                }
              >
                <nav className={clsx("grid gap-y-8", !personal && "mb-6")}>
                  {links.map((item) => (
                    <Popover.Button
                      as={Link}
                      key={item.name}
                      to={item.href}
                      className="-m-3 p-3 group flex items-center rounded hover:bg-gray-50 dark:hover:bg-zinc-700"
                    >
                      <item.Icon
                        className="flex-shrink-0 h-6 w-6 text-primary"
                        aria-hidden="true"
                      />
                      <span className="ltr:ml-3 rtl:mr-3 text-base font-medium text-gray-900 dark:text-zinc-100">
                        {item.name}
                      </span>
                    </Popover.Button>
                  ))}
                </nav>
                {!personal && (
                  <div className="pt-5">
                    <Popover.Button
                      as={SmallScreenMenuButton}
                      key="pricing-page"
                      to="/pages/pricing"
                    >
                      {intl.formatMessage({
                        id: "pricing_title",
                        defaultMessage: "Pricing",
                      })}
                    </Popover.Button>
                    <Popover.Button
                      as={SmallScreenMenuButton}
                      key="contact-page"
                      to="/pages/contact"
                    >
                      {intl.formatMessage({
                        id: "wpa_link_contact",
                        defaultMessage: "Contact",
                      })}
                    </Popover.Button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="p-3 bg-gray-100 dark:bg-zinc-800">
            <Popover.Button className="w-full">
              <UserAwareMobile />
            </Popover.Button>
          </div>
          <div className="p-3 bg-gray-100 dark:bg-zinc-800">
            <Popover.Button
              as={SmallScreenMenuButton}
              to="/businesses"
              key="manage"
              Icon={BriefcaseIcon}
            >
              {intl.formatMessage({ id: "manage", defaultMessage: "Manage" })}
            </Popover.Button>

            <ThemeSwitchWrapper>
              {({ dark, switchTheme }) => (
                <SmallScreenMenuButton
                  Icon={dark ? SunIcon : MoonIcon}
                  onClick={switchTheme}
                  key="theme"
                >
                  {intl.formatMessage({ id: "dark_mode" })}
                </SmallScreenMenuButton>
              )}
            </ThemeSwitchWrapper>
            <LangSwitchWrapper>
              {({ locale, onOpen }) => (
                <SmallScreenMenuButton
                  key="lang"
                  onClick={onOpen}
                  Icon={LanguageIcon}
                >
                  {LOCALES_TRANSLATIONS[locale] || locale}
                </SmallScreenMenuButton>
              )}
            </LangSwitchWrapper>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  );
};

const UserAwareMobile = () => {
  const { user } = useUser();
  const intl = useIntl();
  if (!user || user.type === UserTypes.GUEST)
    return (
      <Button asChild>
        <Link to="/login" className="w-full ">
          {intl.formatMessage({ id: "login_title", defaultMessage: "Connect" })}
        </Link>
      </Button>
    );

  const name =
    [user?.name?.first, user?.name?.last].filter(Boolean).join(" ") ||
    "Anonymous";

  return (
    <div className="flex flex-col gap-x-8 w-full">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Avatar name={user?.name} image={user?.profileImg} />
        </div>
        <Link to="/me">
          <div className="flex flex-col ltr:ml-3 rtl:mr-3 items-start justify-center">
            <div className="text-base font-bold leading-none text-gray-900 dark:text-zinc-100">
              {name}
            </div>
            <div className="text-sm leading-none text-gray-400">
              {user?.email || user?.mobileNumber}
            </div>
          </div>
        </Link>
        <LogoutWrapper>
          {({ handleLogout }) => (
            <span
              onClick={handleLogout}
              className="ltr:ml-auto rtl:mr-auto rounded flex flex-row flex-shrink-0 gap-x-1 p-1 text-gray-500 hover:text-gray-800 dark:hover:text-zinc-100 hover:bg-gray-200 dark:hover:bg-zinc-700 px-3 py-2 focus:outline-none "
            >
              <span className="sr-only">Logout</span>
              <ArrowLeftEndOnRectangleIcon
                className="h-5 w-5"
                aria-hidden="true"
              />{" "}
              <span>Logout</span>
            </span>
          )}
        </LogoutWrapper>
      </div>
    </div>
  );
};

export const AvatarMenu = ({ personal = false, business = false }) => {
  const intl = useIntl();
  const { user } = useUser();

  if (!user || user.type === UserTypes.GUEST)
    return (
      <div className="flex flex-row items-center gap-5">
        <NavLink to="/login">
          {intl.formatMessage({ id: "login_title", defaultMessage: "Connect" })}
        </NavLink>
        <ThemeSwitchWrapper>
          {({ dark, switchTheme }) => {
            const Icon = dark ? SunIcon : MoonIcon;
            return (
              <Icon
                onClick={switchTheme}
                className="h-5 w-5 stroke-gray-500 dark:stroke-zinc-400 hover:stroke-gray-900 hover:dark:stroke-zinc-100 cursor-pointer duration-100"
              />
            );
          }}
        </ThemeSwitchWrapper>
        <LangSwitchWrapper>
          {({ locale, onOpen }) => {
            const label = LOCALES_TRANSLATIONS[locale] || locale;
            return (
              <GlobeAltIcon
                className="h-5 w-5 stroke-gray-500 dark:stroke-zinc-400 hover:stroke-gray-900 hover:dark:stroke-zinc-100 cursor-pointer duration-100"
                aria-label={label}
                onClick={onOpen}
              />
            );
          }}
        </LangSwitchWrapper>
      </div>
    );

  const name =
    [user?.name?.first, user?.name?.last].filter(Boolean).join(" ") ||
    "Profile";

  const showManageLink = !personal && !business;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" size="icon" className="rounded-full">
          <Avatar name={user?.name} image={user?.profileImg} />
          <span className="sr-only">Toggle user menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="min-w-[180px]">
        <DropdownMenuItem disabled>{name}</DropdownMenuItem>
        {showManageLink && <DropdownMenuSeparator />}
        {showManageLink && (
          <DropdownMenuItem asChild>
            <Link className="gap-3 cursor-pointer" to="/businesses">
              <Briefcase className="w-3 h-3" />
              {intl.formatMessage({ id: "manage", defaultMessage: "Manage" })}
            </Link>
          </DropdownMenuItem>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem className="gap-3 cursor-pointer">
          <CircleUserIcon className="w-3 h-3" />
          <Link to="/me">Profile</Link>
        </DropdownMenuItem>
        <ThemeSwitchWrapper>
          {({ dark, switchTheme }) => {
            const Icon = dark ? SunIcon : MoonIcon;
            return (
              <DropdownMenuItem
                onClick={switchTheme}
                className="gap-3 cursor-pointer"
              >
                <Icon className="w-3 h-3" />
                {dark ? "Light" : "Dark"}
              </DropdownMenuItem>
            );
          }}
        </ThemeSwitchWrapper>
        <LangSwitchWrapper>
          {({ locale, onOpen }) => (
            <DropdownMenuItem onClick={onOpen} className="gap-3 cursor-pointer">
              <GlobeAltIcon className="w-3 h-3" />
              {LOCALES_TRANSLATIONS[locale] || locale}
            </DropdownMenuItem>
          )}
        </LangSwitchWrapper>
        <DropdownMenuSeparator />
        <LogoutWrapper>
          {({ handleLogout }) => (
            <DropdownMenuItem
              onClick={handleLogout}
              className="gap-3 cursor-pointer"
            >
              <LogOutIcon className="w-3 h-3" />
              {intl.formatMessage({ id: "settings_items_logout_title" })}
            </DropdownMenuItem>
          )}
        </LogoutWrapper>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const SmallScreenIconMenu = ({ srOnly, Icon }) => (
  <Popover.Button className="p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none">
    <span className="sr-only text-black">{srOnly}</span>
    <Icon className="h-6 w-6" aria-hidden="true" />
  </Popover.Button>
);

const SmallScreenOpenMenu = () => (
  <div className="-ltr:mr-2 rtl:ml-2 -my-2 md:hidden">
    <SmallScreenIconMenu srOnly="Open menu" Icon={Bars3Icon} />
  </div>
);

const SmallScreenCloseMenu = () => (
  <div className="-ltr:mr-2 rtl:ml-2">
    <SmallScreenIconMenu srOnly="Close menu" Icon={XMarkIcon} />
  </div>
);

const LogoutWrapper = ({
  children,
}: {
  children: ({ handleLogout }: { handleLogout: () => void }) => JSX.Element;
}) => {
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    client.logout();
  }, [dispatch]);

  return children({ handleLogout });
};

const ThemeSwitchWrapper = ({
  children,
}: {
  children: ({
    dark,
    switchTheme,
  }: {
    dark: boolean;
    switchTheme: () => void;
  }) => JSX.Element;
}) => {
  const dispatch = useDispatch();
  const dark = useSelector((s: RootState) => s.system.theme === "dark");

  const switchTheme = useCallback(() => {
    dispatch(setTheme(dark ? "light" : "dark"));
  }, [dispatch, dark]);

  return children({ switchTheme, dark });
};

const LangSwitchWrapper = ({
  children,
}: {
  children: ({
    locale,
    onOpen,
  }: {
    locale: string;
    onOpen: (...arg: any[]) => void;
  }) => JSX.Element;
}) => {
  const locale = useSelector((s: RootState) => s.system.locale);
  const { onOpen } = useLanguageDialog();
  return children({ onOpen, locale });
};

const ProfileLinks = () => {
  return (
    <nav className="hidden md:flex space-x-10 rtl:space-x-reverse">
      {personal_routes.map((link) => (
        <NavLink to={link.href}>{link.name}</NavLink>
      ))}
    </nav>
  );
};

export default function SiteHeader() {
  const profile = useRouteMatch("/me");
  const { scrollY } = useScroll();
  const [transparent, setTransparent] = useState(true);

  useEffect(() => {
    return scrollY.on("change", (x: number) => {
      setTransparent((_) => x < 45);
    });
  }, []);

  return (
    <motion.div
      className={clsx(
        "sticky top-0 w-full border-b duration-300 z-20",
        transparent
          ? "bg-transparent border-transparent"
          : "bg-white dark:bg-zinc-900 border-black dark:border-zinc-800 border-opacity-5"
      )}
    >
      <Popover>
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 xl:px-0">
          <div className="flex justify-between items-center py-5 md:justify-start md:space-x-10 rtl:space-x-reverse">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <CompanyLogo
                  className="h-6"
                  labelClassName="fill-slate-900 dark:fill-white"
                  symbolClassName="fill-primary dark:fill-primary-400"
                />
              </Link>
            </div>
            <SmallScreenOpenMenu />
            {profile ? <ProfileLinks /> : <DesktopSiteLinks />}
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <AvatarMenu personal={!!profile} />
            </div>
          </div>
        </div>
        <SmallScreenNav personal={!!profile} />
      </Popover>
    </motion.div>
  );
}
