import {
  parsePhoneNumber,
  getAsYouType as _getAsYouType,
} from "awesome-phonenumber";

export default parsePhoneNumber;

export const getAsYouType = _getAsYouType;

export function formatNationalNumber(e164: string) {
  try {
    if (typeof e164 !== "string" && !e164) {
      return null;
    }
    return parsePhoneNumber(e164).number?.national;
  } catch (error) {
    return e164;
  }
}
