function mergeEntities<T extends any>(prev: T = {} as any, next = {}): T {
  const result = {} as any;

  const keys = Array.from(
    new Set([...Object.keys(prev), ...Object.keys(next)])
  );

  const hasPrev = typeof prev === "object" && prev;

  for (const k of keys) {
    if (hasPrev && k in (prev as any)) {
      if (k in next) {
        result[k] = { ...prev[k], ...next[k] };
      } else {
        result[k] = { ...prev[k] };
      }
    } else if (k in next) {
      result[k] = next[k];
    }
  }

  return result;
}

export default mergeEntities;
