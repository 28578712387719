import { UserTypes } from "@gethere/common/enums";
import { TUser } from "@gethere/common/yup/User";
import { createContext, useCallback, useContext } from "react";
import { useAppSelector } from "../state/hooks";
import { userSelector } from "../state/reducers/user";

const UserContext = createContext<{
  userId?: string;
  user: TUser;
  isAdmin: boolean;
  isSigned: boolean;
  isGuest: boolean;
  hasBasicFields: (fields: string[]) => { status: boolean; missing: string[] };
}>({
  userId: null,
  user: null,
  isAdmin: false,
  isGuest: false,
  isSigned: false,
  hasBasicFields: () => ({ status: false, missing: [] }),
});

export const useUser = () => useContext(UserContext);

export type UserFields = "name.first" | "name.last" | "email";

export const UserContextProvider = ({ children }) => {
  const user = useAppSelector(userSelector);

  const hasBasicFields = useCallback(
    (fields: UserFields[]) => {
      const missing: UserFields[] = [];

      if (fields.includes("name.first") && !user?.name?.first)
        missing.push("name.first");

      if (fields.includes("name.last") && !user?.name?.last)
        missing.push("name.last");

      if (fields.includes("email") && !user?.email) missing.push("email");

      return { status: missing.length === 0, missing };
    },
    [user]
  );

  return (
    <UserContext.Provider
      value={{
        user,
        userId: user?.id,
        isSigned: !!user?.id,
        isAdmin: user && user?.type === UserTypes.ADMIN,
        isGuest: user && user?.type === UserTypes.GUEST,
        hasBasicFields,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
