import * as yup from "yup";
import utcIsoDateString from "./Date";
import { promoTargetSchema } from "./Promo";
import cuid from "cuid";
import { yupMobileNumberField } from "./MobileInputForm";
import { gracefulNameSchema } from "./User";

export const RewardTriggerTypes = ["after", "every"] as const;

export const RewardTrigerSegments = ["session", "booking", "points"] as const;

export const RewardBenfitTypes = ["fixed", "percent"] as const;
export type TRewardBenfitType = (typeof RewardBenfitTypes)[number];

export const membershipSettingsSchema = yup.object().shape({
  points: yup.boolean().notRequired().default(false), // enable points system
  retentionDays: yup.number().notRequired().min(0), // how many days member data will be retain after joining
  // monthlyCost: yup.number().notRequired().min(0), // monthly subscription cost
  // annualCost: yup.number().notRequired().min(0), // annual subscription
});

export type TBusinessMembershipSettings = yup.InferType<
  typeof membershipSettingsSchema
>;

export const membershipDefaultsSchema = yup.object().shape({
  pointsPerOrder: yup.number().notRequired().min(0),
  pointsPerValue: yup.number().notRequired().min(0),
  pointsPerItem: yup.number().notRequired().min(0),
});

export const rewardStepBenfitSchema = yup.object().shape({
  type: yup
    .string()
    .oneOf(RewardBenfitTypes)
    .required()
    .default(RewardBenfitTypes["0"]),
  value: yup
    .number()
    .required()
    .min(0)
    .test(function validateRewardValue(value, context) {
      const type = context.parent.type;
      if (type === "percent") {
        if (value > 1) {
          throw new yup.ValidationError(
            "Percent value should be between 0 and 1"
          );
        }
      }
      return true;
    }),
  limitValue: yup.number().notRequired().min(0).default(0), // 0 means no limit
  target: promoTargetSchema,
});

export type TBusinessMembershipSettingsDefaults = yup.InferType<
  typeof membershipDefaultsSchema
>;

export const rewardStepSchema = yup.object({
  id: yup.string().notRequired(),
  __tempId: yup.string().notRequired().default(cuid), // client only field
  i: yup.number().notRequired().min(0).max(100),
  hidden: yup.boolean().notRequired().default(false),
  active: yup.boolean().required(),
  businessId: yup.string().notRequired(),
  name: yup.string().required().min(2),
  displayName: yup.string().notRequired().min(2),
  benfit: rewardStepBenfitSchema,
  settings: yup.object({}).notRequired(),
  trigger: yup.string().oneOf(RewardTriggerTypes).required(),
  value: yup.number().required().min(1),
  segment: yup.string().oneOf(RewardTrigerSegments).required(),
  validDays: yup.number().notRequired().min(0.01),
  createdAt: yup.date().default(() => new Date()),
  updatedAt: yup.date().default(() => new Date()),
});

export type TRewardStep = yup.InferType<typeof rewardStepSchema>;

export const businessMembershipSettingsSchema = yup.object({
  businessId: yup.string().notRequired(),
  name: yup.string().required().min(2).default(""), // "Zhug Members Club" / "The Gate Friends" / ..etc
  active: yup.boolean().notRequired().default(false),
  settings: membershipSettingsSchema,
  defaults: membershipDefaultsSchema,
  steps: yup.array().of(rewardStepSchema).min(0).max(100), // Define RewardStep schema reference if needed
  policy: yup.string().notRequired().max(5048),
  policyUpdatedAt: utcIsoDateString.notRequired(),
});

export type TBusinessMembership = yup.InferType<
  typeof businessMembershipSettingsSchema
>;

export const bdaySchema = yup
  .string()
  .matches(/^([0-2][0-9]|3[0-1])-(0[0-9]|1[0-2])$/, "Invalid date format");
// MM-YYYY

export const memberJoinFormSchema = yup
  .object({
    email: yup.string().email().optional(),
    mobile: yupMobileNumberField.required(),
    name: gracefulNameSchema.required(),

    placeId: yup.string().required(),
    businessId: yup.string().required(),
    ref: yup.string().notRequired(),

    bday: bdaySchema.notRequired(),

    termsConsent: yup.boolean().required().oneOf([true]).default(false),
    marketingConsent: yup.boolean().notRequired().default(false),
  })
  .defined();

export type TMemberJoinForm = yup.InferType<typeof memberJoinFormSchema>;

export const memberSchema = yup.object({
  id: yup.string().required(),
  active: yup.boolean().notRequired().default(true),
  businessId: yup.string().required(),
  placeId: yup.string().notRequired(),
  userId: yup.string().required(),
  name: gracefulNameSchema.required(),
  email: yup.string().email().notRequired(),
  mobile: yupMobileNumberField.required(),
  meta: yup
    .object({
      bday: bdaySchema.notRequired(),
      ref: yup
        .string()
        .notRequired()
        .max(32)
        .min(2)
        .matches(/^[a-zA-Z0-9-]+$/),
    })
    .notRequired(),
  settings: yup.object().notRequired(),
  consentAt: utcIsoDateString.notRequired(),
  retentionUntil: utcIsoDateString.notRequired(),
  points: yup.number().notRequired().min(0),
  orders: yup.number().notRequired().min(0),
  ltv: yup.number().notRequired().min(0),
  createdAt: utcIsoDateString.notRequired(),
  updatedAt: utcIsoDateString.notRequired(),
});

export type TMember = yup.InferType<typeof memberSchema>;

export const membershipSearchSchema = yup.object().shape({
  businessId: yup.string().required("Business ID is required"),

  skip: yup
    .number()
    .min(0, "Skip must be greater than or equal to 0")
    .default(0),

  limit: yup
    .number()
    .min(1, "Limit must be greater than 0")
    .max(100, "Limit must be less than or equal to 100")
    .default(50),

  sort: yup.object().shape({
    key: yup
      .string()
      .oneOf(["createdAt", "rate"])
      .default("createdAt")
      .required("Sort key is required"),

    value: yup
      .string()
      .oneOf(["ASC", "DESC"], "Sort value must be either ASC or DESC")
      .default("DESC")
      .required("Sort value is required"),
  }),

  memberId: yup.string().notRequired().default(null),
  mobile: yupMobileNumberField.notRequired().default(null),
  email: yup.string().email().notRequired().default(null),
});

export type TMembershipSearchQuery = yup.InferType<
  typeof membershipSearchSchema
>;

export const userMembershipUpdateSchema = yup.object().shape({
  name: gracefulNameSchema.notRequired(),
  email: yup.string().email().notRequired(),
  mobile: yupMobileNumberField.notRequired(),
  marketingConsent: yup.boolean().notRequired(),
});
