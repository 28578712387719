import * as yup from "yup";
import { permissions } from "../enums";

export const controlRoleSchema = yup
  .object({
    id: yup.string(),
    name: yup.string().default("").min(2),
    master: yup
      .bool()
      .default(false)
      .transform((v) =>
        typeof v === "string" ? (v === "true" ? true : false) : v
      ),
    businessId: yup.string().nullable(),
    permissions: yup
      .array()
      .of(yup.string().oneOf(Object.values(permissions)))
      .default([]),
    createdAt: yup.date().nullable(),
    updatedAt: yup.date().nullable(),
  })
  .required();

export type TControlRole = yup.InferType<typeof controlRoleSchema>;
