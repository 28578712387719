import { TipModes, TipStatus } from "@gethere/common/enums";
import { calculateTip } from "@gethere/common/utilities";
import { TSessionTip } from "@gethere/common/yup/Session";
import { EllipsisHorizontalIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Big from "big.js";
import clsx from "clsx";
import { motion } from "framer-motion";
import React, { useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Input, { InputAdornment } from "../components/Input";
import { useSession } from "../contexts/SessionContext";
import { sessionSelector, updateLocalTip } from "../state/reducers/session";
import { RootState } from "../state/store";
import { Button } from "../components/ui/button";

const TipSelection = ({ type, value, onSelect, selected, emoji = null, i }) => {
  const intl = useIntl();
  const handleSelect = useCallback(() => onSelect(i), [i, onSelect]);

  return (
    <TipButton
      selected={selected}
      onClick={handleSelect}
      className={type === "percent" && "flex-grow"}
    >
      {!!(selected === null || selected) && !!emoji && emoji}
      {type === "percent" ? (
        intl.formatNumber(value / 100, {
          style: "percent",
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        })
      ) : (
        <EllipsisHorizontalIcon className="stroke-text w-4 h-4 flex-none self-center" />
      )}
    </TipButton>
  );
};

const TipButton = ({
  children,
  selected = false,
  className = null,
  onClick,
}) => (
  <Button
    variant={selected ? "secondary" : "outline"}
    className="capitalize flex-1"
    // className={clsx(
    //   "select-none rounded border-transparent border-2 cursor-pointer flex flex-1 px-2 py-2 font-medium capitalize duration-200 whitespace-nowrap justify-center",
    //   selected
    //     ? "text-card bg-text"
    //     : "hover:border-2 hover:border-disabled bg-muted-foreground/5",
    //   className
    // )}
    // variant={selected ? "contained" : "text"}
    onClick={onClick}
  >
    {children}
  </Button>
);

const TipMode = ({ mode, selected, onSelect }) => {
  const intl = useIntl();
  const handleSelect = useCallback(() => onSelect(mode), [mode, onSelect]);
  return (
    <TipButton selected={selected} onClick={handleSelect}>
      {intl.formatMessage({
        id: CustomModeMessages[mode],
        defaultMessage: mode,
      })}
    </TipButton>
  );
};

const CustomModeMessages = {
  [TipModes.percent]: "session_tip_type_percent",
  [TipModes.fixed]: "session_tip_type_fixed",
  [TipModes.complete]: "session_tip_type_complete",
};

const TipModesArray = Object.keys(TipModes);

const SessionTips = ({ currency }) => {
  const inputRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const { status } = useSession();
  const intl = useIntl();

  const { uId, session } = useSelector((s: RootState) => ({
    uId: s.user.result,
    session: sessionSelector(s),
  }));

  const active = session.tips.find(
    (t) => t.liableId === uId && t.status === TipStatus.PENDING
  );

  const [selects, setSelects] = useState(() => {
    let selected = null;
    const options = [
      { type: TipModes.percent, value: 10, emoji: "🙂 " },
      { type: TipModes.percent, value: 12, emoji: "😊 " },
      { type: TipModes.percent, value: 15, emoji: "😍 " },
      { type: "custom" },
    ];

    // finding existed tip option if there is
    if (active) {
      if (active.meta.mode === TipModes.percent) {
        const optionIndex = options.findIndex(
          (o) => o.value === active.meta.base
        );
        if (optionIndex !== -1) {
          selected = optionIndex;
        } else {
          selected = 3;
        }
      } else {
        selected = 3;
      }
    }

    return {
      selected,
      options,
    };
  });

  const [customize, setCustomize] = useState({
    mode: (active ? active.meta.mode : TipModes.fixed) as TipModes,
    value: active ? active.meta.base : "",
  });

  const subtotal = status.pendings?.subtotal;

  const dispatchTip = useCallback(
    (tip) => {
      dispatch(updateLocalTip({ tip }) as any);
    },
    [dispatch]
  ); //,
  //   750,
  //   { trailing: true, leading: false }
  // );

  const handleTipChange = useCallback(
    ({ mode, base }) => {
      // if (mode ==='customize')
      if (mode === "custom") return;

      const update = { meta: { mode, base }, value: 0 };

      let a = active;

      if (!a) {
        a = {
          ...update,
          liableId: uId,
          status: TipStatus.PENDING,
        } as TSessionTip;
      } else {
        a = { ...a, ...update } as TSessionTip;
      }

      a.value = calculateTip({
        mode,
        base: mode === TipModes.percent && base > 0 ? base / 100 : base,
        total: subtotal,
      });

      dispatchTip(a);
    },
    [active, dispatchTip, subtotal, uId] //tips, subtotal
  );

  const onOptionSelected = useCallback(
    (selected: number | null) => {
      setSelects((s) => ({ ...s, selected }));
      const s = selects.options[selected];
      if (s.type !== "custom") {
        handleTipChange({ mode: s.type, base: s.value });
      } else {
        inputRef?.current?.focus?.();
        setCustomize((s) => ({ ...s, mode: TipModes.fixed, value: "" }));
        handleTipChange({ mode: TipModes.fixed, base: 0 });
      }
    },
    [setSelects, handleTipChange, selects]
  );

  const onModeSelected = useCallback(
    (mode) => {
      setCustomize((s) => ({ ...s, mode }));
      handleTipChange({ mode, base: customize.value });
      inputRef?.current?.focus?.();
    },
    [setCustomize, customize.value]
  );

  const max =
    customize.mode === TipModes.percent
      ? 100
      : customize.mode === TipModes.fixed
      ? Math.max(100, subtotal)
      : customize.mode === TipModes.complete
      ? subtotal * 2
      : 0;

  const onCustomValueChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = String(e.target.value)
        .replace(/^0+/, "")
        .replace(/(\.\d{1})\d+/g, "$1");

      const v = Big(value || 0)
        .round(2)
        .toNumber();

      if (!isNaN(v)) {
        if (v <= max) {
          setCustomize((s) => ({
            ...s,
            value,
          }));
          handleTipChange({
            mode: customize.mode,
            base: v,
          });
        }
      } else {
        e.preventDefault();
      }
    },
    [setCustomize, max, handleTipChange]
  );

  const onCleanCustom = useCallback(() => {
    setSelects((s) => ({ ...s, selected: null }));
    dispatchTip(null);
  }, [setSelects, dispatchTip]);

  const isCustom =
    selects.selected ===
    selects.options.findIndex(({ type }) => type === ("custom" as any));

  if (!status.pendings) return null;

  return (
    <div className="flex flex-col gap-2">
      {/* <Typography variant="h5" className={styles.bold}>
        {intl.messages["session_consumer_tip_title"]}
      </Typography>
      {<Typography variant="body2">
        {intl.messages["session_consumer_tip_description_didnt_yet"]}
      </Typography> }
      <br /> */}
      <div className="flex justify-items-stretch gap-2">
        {isCustom
          ? TipModesArray.map((mode) => (
              <TipMode
                key={mode}
                mode={mode}
                onSelect={onModeSelected}
                selected={mode === customize.mode}
              />
            ))
          : selects.options.map((opt, i) => (
              <TipSelection
                key={`${opt.type}-${i}`}
                value={opt.value}
                type={opt.type}
                emoji={opt.emoji}
                onSelect={onOptionSelected}
                selected={
                  Number.isInteger(selects.selected)
                    ? selects.selected === i
                    : null
                }
                i={i}
              />
            ))}
        {selects.selected !== null && (
          <TipButton onClick={onCleanCustom} key="reset">
            <XMarkIcon className="stroke-text w-4 h-4 flex-none self-center" />
          </TipButton>
        )}
      </div>
      <motion.div
        // layout
        initial="hide"
        animate={isCustom ? "show" : "hide"}
        variants={{
          show: { height: "auto", visibility: "visible" },
          hide: { height: 0, visibility: "hidden" },
        }}
      >
        <div className="mb-2">
          <Input
            value={customize.value}
            onChange={onCustomValueChanged}
            ref={inputRef}
            type="number"
            inputMode="decimal"
            className="pl-8"
            min={0}
            max={max}
            start={
              <InputAdornment position="start" absolute className="px-4">
                {customize.mode === TipModes.percent
                  ? "%"
                  : intl
                      .formatNumber(0, { style: "currency", currency })
                      ?.replace(/[0.]/g, "")}
              </InputAdornment>
            }
          />
        </div>
      </motion.div>
    </div>
  );
};

export default SessionTips;
