import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface ConsentState {
  pref?: boolean;
  ads?: boolean;
}

interface ConsentStore extends ConsentState {
  at: string;
  dialogOpened: boolean;
  acceptCustom: (accepts: ConsentState) => void;
  acceptAll: () => void;
  closeConsentDialog: () => void;
  openConesntDialog: () => void;
}

export const useConsentStore = create<ConsentStore>()(
  devtools(
    persist(
      (set) => ({
        at: null,
        pref: null,
        ads: null,
        dialogOpened: true,
        closeConsentDialog: () => set((s) => ({ ...s, dialogOpened: false })),
        openConesntDialog: () => set((s) => ({ ...s, dialogOpened: true })),
        acceptCustom: ({ pref, ads }) =>
          set((s) => ({
            ...s,
            at: new Date().toISOString(),
            pref,
            ads,
            dialogOpened: false,
          })),
        acceptAll: () =>
          set((s) => ({
            ...s,
            at: new Date().toISOString(),
            pref: true,
            ads: true,
            dialogOpened: false,
          })),
      }),
      {
        name: "privacy-consent",
      }
    )
  )
);
