import CommonEnvironment from "@gethere/common/environment";

declare const VERSION: string;
export const appVersion = VERSION;

export const appName = "@gethere/web";

const env = new CommonEnvironment(process.env.NODE_ENV);
console.log(`@gethere/web@${appVersion} [${env.e}]`);

export default env;
