import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import Lottie, { Options } from "react-lottie";
import { useTheme } from "../contexts/ThemeContext";
import { replaceColor } from "../utils/lottieHelper";
import { captureException } from "@sentry/react";

export const LoadingAnimation = ({
  size = 200,
  className = null,
  ...props
}) => {
  const theme = useTheme();

  const [animationData, setAnimationData] = useState<string>(null);

  useEffect(() => {
    if (!animationData) {
      fetch("/lotties/forkspoonload.json")
        .then((res) => res.json())
        .then(setAnimationData)
        .catch(captureException);
    }
  }, []);

  const options = useMemo(() => {
    if (!animationData) return null;
    let data = animationData;
    data = replaceColor("#FF4656", theme.colors.primary, data);
    data = replaceColor("#2E3542", theme.colors["muted-foreground"], data);

    const result: Options = {
      loop: true,
      autoplay: true,
      animationData: data,
    };
    return result;
  }, [theme.colors.primary, theme.colors["muted-foreground"], animationData]);

  if (!options) return null;

  return (
    <div className={className}>
      <Lottie {...props} height={size} width={size} options={options} />
    </div>
  );
};

const LoadingScreen = ({ className = null }) => {
  return (
    <div
      className={clsx(
        "h-full w-full realtive min-h-screen place-items-center items-center justify-center",
        className
      )}
    >
      <LoadingAnimation className="" />
    </div>
  );
};

export default LoadingScreen;
