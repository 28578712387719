import { useEffect } from "react";

// Custom hook for handling before unload event
export function useBeforeUnload(handler: () => void, dependencies: any[]) {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      handler();
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, dependencies);
}
